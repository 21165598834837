import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FloatingLabel,
  Card,
  Alert,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FormData from 'form-data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import moment from 'moment'
import { convertArrayToCSV } from 'convert-array-to-csv'
import {
  APIGetContactLeadDetails,
  APIGetEventUserDetails,
} from '../../utils/config/API'
import EventData from '../../components/EventData/EventData'
import withLoginRequired from '../../HOC/LoginModal'

export default withLoginRequired(() => {
  const [eventDate, setEventDate] = useState(null)
  const [eventDate2, setEventDate2] = useState(null)
  const [eventDetails, setEventDetails] = useState([])
  const [getEvent, setGetEvent] = useState(false)

  const [showBasicAlert, setShowBasicAlert] = useState(false)
  const [responseStatus, setresponseStatus] = useState(false)
  const [responseMessage, setresponseMessage] = useState('')

  const handleSubmit = async () => {
    try {
      console.log('trying')
      const headers = {
        Authorization: `Bearer ${localStorage.token}`,
      }
      // const insertResponse = await fetch(APIGetEventUserDetails)
      const getDetails = await axios.post(
        `${APIGetContactLeadDetails}`,
        {
          date: moment(eventDate).toDate(),
          date2: eventDate2 ? moment(eventDate2).toDate() : null,
        },
        {
          headers,
        }
      )

      setEventDetails(getDetails.data.data)
      if (getDetails.status === 200) {
        // setresponseStatus(true)
        // setresponseMessage(insertResponse.message)
        // console.log('setting sucess message', responseMessage)
        const csvFromArrayOfObjects = convertArrayToCSV(
          getDetails.data.data.map((item) => {
            return {
              ...item,
              PHONE: item.PHONE
                ? '+'.concat(
                    item.PHONE.replace('+')
                      .concat('\t')
                      .replace('undefined', '')
                  )
                : null,
              PHONENUMBER2: item.PHONENUMBER2
                ? '+'.concat(
                    item.PHONENUMBER2.replace('+')
                      .concat('\t')
                      .replace('undefined', '')
                  )
                : null,
            }
          })
        )
        const csvContent = `data:text/csv;charset=utf-8,${csvFromArrayOfObjects}`
        const encodedUri = encodeURI(csvContent)
        const downloadLink = document.createElement('a')
        downloadLink.href = encodedUri
        downloadLink.download = `Contact_Lead_${moment(eventDate)
          .format('MMMM')
          .concat('_', moment(eventDate).format('Do'))}.csv`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
        // window.open(encodedUri);
      } else {
        setresponseStatus(false)
        setresponseMessage(getDetails.data.message || 'Something went wrong')
        setShowBasicAlert(true)
        console.log('setting unsucessful message', responseMessage)
      }
      // console.log(insertResponse)
    } catch (err) {
      console.log('Error ----------------->', err)
      setresponseStatus(false)
      setresponseMessage('Something went wrong')
      setShowBasicAlert(true)
    }
  }
  return (
    <Container fluid className="w-100">
      <Row className="mb-3">
        <h3 className="title">
          <Link to="/">
            <i className="bi bi-arrow-left"></i>
          </Link>
          CONTACT LEAD
        </h3>
      </Row>
      <Form>
        <Row>
          <Col className="mb-4">
            {/* <FloatingLabel
              controlId="EventDate"
              label="Event Date"
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            > */}
            <Form.Label
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            >
              Select Date/From Date
            </Form.Label>
            <DatePicker
              className="form-control"
              showTimeSelect
              // timeIntervals={15}
              placeholderText="Select Target Date"
              selected={eventDate}
              onChange={(date) => setEventDate(date)}
              dateFormat="MMMM do, yyyy" // showTimeInput
            />
            {/* </FloatingLabel> */}
          </Col>

          <Col className="mb-4">
            {/* <FloatingLabel
              controlId="EventDate"
              label="Event Date"
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            > */}
            <Form.Label
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            >
              Select To Date
            </Form.Label>
            <DatePicker
              className="form-control"
              showTimeSelect
              // timeIntervals={15}
              placeholderText="Select Target Date"
              selected={eventDate2}
              onChange={(date) => setEventDate2(date)}
              dateFormat="MMMM do, yyyy" // showTimeInput
            />
            {/* </FloatingLabel> */}
          </Col>
        </Row>
        <Row>
          <Col className="mb-4">
            <Button
              className="btn-primary"
              style={{
                margin: 'auto!important',
              }}
              onClick={handleSubmit}
            >
              Get Details
            </Button>
          </Col>
        </Row>
        <EventData eventDetails={eventDetails} getEvent={getEvent} />
      </Form>

      <Row className="mt-3 px-2">
        {showBasicAlert && responseStatus === false ? (
          <Alert
            variant="danger"
            onClose={() => setShowBasicAlert(false)}
            dismissible
            className="rounded"
            style={{
              position: 'fixed',
              top: 1,
              // left: 0,
              right: 0,
              zIndex: 999,
              textAlign: 'center',
              width: '40%',
            }}
          >
            {responseMessage}
          </Alert>
        ) : null}
      </Row>
    </Container>
  )
})
