import axios from 'axios'
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap'
import moment from 'moment'
import {
  getTotalUsers,
  getActiveUsers,
  getPlusUsers,
  getPremiumPlusUsers,
  getSpentTime,
  SpentTimePlus,
  getTimeSpentActiveUser,
  getVideosWatched,
  getUniqueVideosWatched,
  getVideoData,
  updateSeries,
  getEpisodewiseData,
} from '../../utils/config/API'
import './styles.css'
import withLoginRequired from '../../HOC/LoginModal'

export default withLoginRequired(() => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const utcAdd = '+0530'

  const date1 = new Date()
  const date2 = new Date()
  // const FromDate = date1.getDate()-30
  date1.setDate(date1.getDate() - 30)

  date2.setDate(date2.getDate() - 1)
  const day11 = new Date(date2.toLocaleDateString('en-CA'))
  const day22 = new Date(date1.toLocaleDateString('en-CA'))
  const DifferenceInTime1 = day11.getTime() - day22.getTime()
  console.log('diff-->', DifferenceInTime1)
  const DifferenceInDays1 = DifferenceInTime1 / (1000 * 3600 * 24)
  console.log(DifferenceInDays1)
  const someDate1 = new Date(date1.toLocaleDateString('en-CA'))
  someDate1.setDate(someDate1.getDate() - DifferenceInDays1)

  // console.log(date2.toLocaleDateString('en-CA'))
  const [TotalUsers, setTotalUsers] = useState(null)
  const [TotalUsersPrev, setTotalUsersPrev] = useState(null)
  const [MonthlyActiveUsers, setMonthlyActiveUsers] = useState(null)
  const [MonthlyActiveUsersPrev, setMonthlyActiveUsersPrev] = useState(null)
  const [TotalPlusUsers, setTotalPlusUsers] = useState(null)
  const [TotalPlusUsersPrev, setTotalPlusUsersPrev] = useState(null)
  const [TotalPremiumPlusUsers, setTotalPremiumPlusUsers] = useState(null)
  const [TotalPremiumPlusUsersPrev, setTotalPremiumPlusUsersPrev] =
    useState(null)
  const [SpentTime, setSpentTime] = useState(null)
  const [SpentTimePrev, setSpentTimePrev] = useState(null)
  const [SpentTimePLus, setSpentTimePlus] = useState(null)
  const [SpentTimePLusPrev, setSpentTimePlusPrev] = useState(null)
  const [ActiveUserTimeSpent, setActiveUserTimeSpent] = useState(null)
  const [ActiveUserTimeSpentPrev, setActiveUserTimeSpentPrev] = useState(null)
  const [NoofVideoWatched, setNoofVideoWatched] = useState(null)
  const [NoofVideoWatchedPrev, setNoofVideoWatchedPrev] = useState(null)
  const [NoofUniqueVideoWatched, setNoofUniqueVideoWatched] = useState(null)
  const [NoofUniqueVideoWatchedPrev, setNoofUniqueVideoWatchedPrev] =
    useState(null)
  const [VideoData, setVideoData] = useState(null)
  const [VideoList, setVideoList] = useState(null)
  const [EpisodeData, setEpisodeData] = useState(null)
  const [VideoIndex, setVideoIndex] = useState(0)
  const [CountryTags, setCountryTags] = useState([])
  const [Specialization, setSpecialization] = useState([])
  const [Grades, setGrades] = useState([])
  const [DateFrom, setDateFrom] = useState(date1.toLocaleDateString('en-CA'))
  const [activeSort, setactiveSort] = useState()

  const [DateTo, setDateTo] = useState(date2.toLocaleDateString('en-CA'))
  const [PrevDateFrom, PrevsetDateFrom] = useState(
    someDate1.toLocaleDateString('en-CA')
  )
  const [PrevDateTo, PrevsetDateTo] = useState(
    date2.toLocaleDateString('en-CA')
  )
  const [firstCall, setfirstCall] = useState(false)
  const [noOfCalls, setnoOfCalls] = useState({
    TotalUsers: false,
    TotalUsersPrev: false,
  })
  const [batch, setbatch] = useState([])
  // PrevsetDateFrom(someDate1.toLocaleDateString('en-CA'))
  // MonthlyActiveUsers:false,
  // MonthlyActiveUsersPrev:false,
  // TotalPlusUsers:false,
  // TotalPlusUsersPrev:false,
  // TotalPremiumPlusUsers:false,
  // TotalPremiumPlusUsersPrev:false,
  // SpentTime:false,
  // SpentTimePrev:false,
  // SpentTimePLus:false,
  // SpentTimePLusPrev:false,
  // ActiveUserTimeSpent:false,
  // ActiveUserTimeSpentPrev:false,
  // NoofVideoWatched:false,
  // NoofVideoWatchedPrev:false,
  // NoofUniqueVideoWatched:false,
  // NoofUniqueVideoWatchedPrev:false
  //     // To calculate the time difference of two dates
  // var Difference_In_Time = date2.getTime() - date1.getTime();

  // // To calculate the no. of days between two dates
  // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  const SortBy = async (e, type) => {
    if (e) e.preventDefault()
    let collegeListComponent = []
    if (VideoData.length > 0) {
      if (activeSort === type && e) {
        console.log('same sort')
        if (type === '1') {
          console.log('sorting by name')
          collegeListComponent = VideoData.sort((a, b) => {
            if (a.EVENT > b.EVENT) {
              return -1
            }
            if (a.EVENT < b.EVENT) {
              return 1
            }
            return 0
          })
        } else if (type === '2') {
          console.log('sorting by date')
          collegeListComponent = VideoData.sort((a, b) => {
            if (a.date_time < b.date_time) {
              return -1
            }
            if (a.date_time > b.date_time) {
              return 1
            }
            return 0
          })
        } else if (type === '3') {
          console.log('sorting by views')
          collegeListComponent = VideoData.sort((a, b) => {
            if (a.views < b.views) {
              return -1
            }
            if (a.views > b.views) {
              return 1
            }
            return 0
          })
        } else collegeListComponent = VideoData
      } else {
        setactiveSort(type)
        if (type === '1') {
          console.log('sorting by name')
          collegeListComponent = VideoData.sort((a, b) => {
            if (a.EVENT < b.EVENT) {
              return -1
            }
            if (a.EVENT > b.EVENT) {
              return 1
            }
            return 0
          })
        } else if (type === '2') {
          console.log('sorting by date')
          collegeListComponent = VideoData.sort((a, b) => {
            if (a.date_time > b.date_time) {
              return -1
            }
            if (a.date_time < b.date_time) {
              return 1
            }
            return 0
          })
        } else if (type === '3') {
          console.log('sorting by views')
          collegeListComponent = VideoData.sort((a, b) => {
            if (a.views > b.views) {
              return -1
            }
            if (a.views < b.views) {
              return 1
            }
            return 0
          })
        } else collegeListComponent = VideoData
      }
    }
    console.log('updated list', collegeListComponent)
    if (collegeListComponent.length > 0) {
      console.log('updating video data')
      setVideoData([...collegeListComponent])
    } else setVideoData(null)
  }

  useEffect(() => {
    console.log(
      'Total prem users',
      TotalPremiumPlusUsers,
      TotalPremiumPlusUsersPrev
    )
    console.log('Total users', TotalUsers, TotalUsersPrev)
    console.log('spent time prev', SpentTimePLusPrev)
  }, [
    TotalPremiumPlusUsers,
    TotalPremiumPlusUsersPrev,
    TotalUsers,
    TotalUsersPrev,
    SpentTimePLusPrev,
  ])
  useEffect(() => {
    const day1 = new Date(DateTo)
    const day2 = new Date(DateFrom)
    const DifferenceInTime = day1.getTime() - day2.getTime()
    console.log('diff-->', DifferenceInTime)
    const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24)
    console.log(DifferenceInDays)
    const someDate = new Date(DateFrom)
    someDate.setDate(someDate.getDate() - DifferenceInDays)
    PrevsetDateFrom(someDate.toLocaleDateString('en-CA'))
    console.log('added->', someDate.toLocaleDateString('en-CA'))
    return null
  }, [DateTo, DateFrom])
  const onSubmitHandler = async (d) => {
    console.log(
      '--------------------------async',
      DateFrom,
      DateTo,
      PrevDateFrom
    )
    const tags = [...CountryTags, ...Specialization]
    setnoOfCalls({ ...noOfCalls, TotalUsers: false, TotalUsersPrev: false })
    axios
      .post(getTotalUsers, {
        grades: d,
        fromDate: DateFrom,
        toDate: DateTo,
        tags,
      })
      .then((e) => {
        console.log('got total users', e.data.message[0].value)
        setTotalUsers(e.data.message[0].value)
        setnoOfCalls({ ...noOfCalls, TotalUsers: true })
      })
      .catch((error) => {
        // console.log(error)
      })
    axios
      .post(getTotalUsers, {
        grades: d,
        fromDate: PrevDateFrom,
        toDate: DateFrom,
        tags,
      })
      .then((e) => {
        console.log('got total prev users', e.data.message[0].value)
        setTotalUsersPrev(e.data.message[0].value)
        setnoOfCalls({ ...noOfCalls, TotalUsersPrev: true })
      })
      .catch((error) => {
        // console.log(error)
      })

    axios
      .post(getActiveUsers, {
        grades: d,
        fromDate: DateFrom,
        toDate: DateTo,
        tags,
      })
      .then((e) => {
        setMonthlyActiveUsers(e.data.message[0].value)
        setnoOfCalls(noOfCalls - 1)
      })
      .catch((error) => {
        // console.log(error)
      })
    axios
      .post(getActiveUsers, {
        grades: d,
        fromDate: PrevDateFrom,
        toDate: DateFrom,
        tags,
      })
      .then((e) => {
        setMonthlyActiveUsersPrev(e.data.message[0].value)
        setnoOfCalls(noOfCalls - 1)
      })
      .catch((error) => {
        // console.log(error)
      })

    axios
      .post(getPlusUsers, {
        grades: d,
        fromDate: DateFrom,
        toDate: DateTo,
        tags,
      })
      .then((e) => {
        setTotalPlusUsers(e.data.message[0].value)
        setnoOfCalls(noOfCalls - 1)
      })
      .catch((error) => {
        // console.log(error)
      })
    axios
      .post(getPlusUsers, {
        grades: d,
        fromDate: PrevDateFrom,
        toDate: DateFrom,
        tags,
      })
      .then((e) => {
        setTotalPlusUsersPrev(e.data.message[0].value)
        setnoOfCalls(noOfCalls - 1)
      })
      .catch((error) => {
        // console.log(error)
      })

    axios
      .post(getPremiumPlusUsers, {
        grades: d,
        fromDate: DateFrom,
        toDate: DateTo,
        tags,
      })
      .then((e) => {
        setTotalPremiumPlusUsers(e.data.message[0].value)
        setnoOfCalls(noOfCalls - 1)
      })
      .catch((error) => {
        //   console.log(error)
      })
    axios
      .post(getPremiumPlusUsers, {
        grades: d,
        fromDate: PrevDateFrom,
        toDate: DateFrom,
        tags,
      })
      .then((e) => {
        console.log('Prev date From', PrevDateFrom)
        console.log('Prev date  to', DateFrom)
        console.log(e.data.message[0].value)
        setTotalPremiumPlusUsersPrev(e.data.message[0].value)
        setnoOfCalls(noOfCalls - 1)
      })
      .catch((error) => {
        // console.log(error)
      })

    axios
      .post(getSpentTime, {
        grades: d,
        fromDate: DateFrom,
        toDate: DateTo,
        tags,
      })
      .then((e) => {
        setSpentTime(e.data.message[0].value)
        setnoOfCalls(noOfCalls - 1)
      })
      .catch((error) => {
        //   console.log(error)
      })
    axios
      .post(getSpentTime, {
        grades: d,
        fromDate: PrevDateFrom,
        toDate: DateFrom,
        tags,
      })
      .then((e) => {
        setSpentTimePrev(e.data.message[0].value)
        setnoOfCalls(noOfCalls - 1)
      })
      .catch((error) => {
        // console.log(error)
      })
    // axios
    // .post(getSpentTime, { grades: d, fromDate: PrevDateFrom, toDate: DateFrom })
    // .then((e) => {
    //     setTotalPremiumPlusUsersPrev(e.data.message[0].value)
    // })
    // .catch((error) => {
    //     // console.log(error)
    // })
    axios
      .post(SpentTimePlus, {
        grades: d,
        fromDate: DateFrom,
        toDate: DateTo,
        tags,
      })
      .then((e) => {
        setSpentTimePlus(e.data.message[0].value)
        // setnoOfCalls(noOfCalls-1)
      })
      .catch((error) => {
        //   console.log(error)
      })
    axios
      .post(SpentTimePlus, {
        grades: d,
        fromDate: PrevDateFrom,
        toDate: DateFrom,
        tags,
      })
      .then((e) => {
        console.log('settting spent time plus prev', e.data.message[0].value)
        setSpentTimePlusPrev(e.data.message[0].value)
        // setnoOfCalls(noOfCalls-1)
      })
      .catch((error) => {
        // console.log(error)
      })

    axios
      .post(getTimeSpentActiveUser, {
        grades: d,
        fromDate: DateFrom,
        toDate: DateTo,
        tags,
      })
      .then((e) => {
        setActiveUserTimeSpent(e.data.message[0].value)
        // setnoOfCalls(noOfCalls-1)
      })
      .catch((error) => {
        //   console.log(error)
      })
    axios
      .post(getTimeSpentActiveUser, {
        grades: d,
        fromDate: PrevDateFrom,
        toDate: DateFrom,
        tags,
      })
      .then((e) => {
        setActiveUserTimeSpentPrev(e.data.message[0].value)
        // setnoOfCalls(noOfCalls-1)
      })
      .catch((error) => {
        // console.log(error)
      })

    axios
      .post(getVideosWatched, {
        grades: d,
        fromDate: DateFrom,
        toDate: DateTo,
        tags,
      })
      .then((e) => {
        setNoofVideoWatched(e.data.message[0].value)
        setnoOfCalls(noOfCalls - 1)
      })
      .catch((error) => {
        //   console.log(error)
      })
    axios
      .post(getVideosWatched, {
        grades: d,
        fromDate: PrevDateFrom,
        toDate: DateFrom,
        tags,
      })
      .then((e) => {
        setNoofVideoWatchedPrev(e.data.message[0].value)
        setnoOfCalls(noOfCalls - 1)
      })
      .catch((error) => {
        // console.log(error)
      })

    axios
      .post(getUniqueVideosWatched, {
        grades: d,
        fromDate: DateFrom,
        toDate: DateTo,
        tags,
      })
      .then((e) => {
        setNoofUniqueVideoWatched(e.data.message[0].value)
        setnoOfCalls(noOfCalls - 1)
      })
      .catch((error) => {
        //   console.log(error)
      })
    axios
      .post(getUniqueVideosWatched, {
        grades: d,
        fromDate: PrevDateFrom,
        toDate: DateFrom,
        tags,
      })
      .then((e) => {
        setNoofUniqueVideoWatchedPrev(e.data.message[0].value)
        setnoOfCalls(noOfCalls - 1)
      })
      .catch((error) => {
        // console.log(error)
      })

    axios
      .post(getVideoData, { grades: d, fromDate: DateFrom, toDate: DateTo })
      .then((e) => {
        setVideoData(e.data.message)
        setVideoList(e.data.message)
        const b = []
        e.data.message.forEach((event, index) => {
          if (index % 3 === 0) {
            b.push(event)
          }
        })
        setbatch([...b])
        console.log('b is', b)
        // setnoOfCalls(noOfCalls-1)
      })
      .catch((error) => {
        //   console.log(error)
      })
  }
  // useEffect(()=>{
  //     onSubmitHandler(Grades)
  //     return () => {
  //         // cleanup
  //     }
  // },[])
  // onSubmitHandler(Grades)

  const showEpisodeData = async (event, series) => {
    event.preventDefault()
    console.log('getting series data', series)
    const tags = [...CountryTags, ...Specialization]
    await axios
      .post(getEpisodewiseData, {
        grades: Grades,
        fromDate: DateFrom,
        toDate: DateTo,
        tags,
        seriesID: series,
      })
      .then((e) => {
        console.log('series data', e.data.message)
        setEpisodeData(e.data.message)
        handleShow()
        setnoOfCalls(noOfCalls - 1)
      })
      .catch((error) => {
        // console.log(error)
      })
  }

  useEffect(() => {
    SortBy(null, '2')
    console.log('calling sort', VideoData?.length)
  }, [VideoList])
  const searchData = async (event, search) => {
    event.preventDefault()
    console.log('searching1')
    let collegeListComponent = []
    if (VideoList.length > 0) {
      if (search.length > 0) {
        console.log('searching2')
        collegeListComponent = VideoList.filter((e) => {
          if (e.EVENT !== undefined) {
            console.log('checking', e.EVENT)
            return e.EVENT.toLowerCase().includes(search.toLowerCase())
          }
          return false
        })
      } else collegeListComponent = VideoList
    }
    console.log('updated list', collegeListComponent)
    if (collegeListComponent.length > 0) setVideoData([...collegeListComponent])
    else setVideoData(null)
  }
  const onSubmit = async () => {
    console.log('*******************************************getting data')
    await onSubmitHandler(Grades)
    console.log('+++++++++++++++++++++++++++++++++++++++++++got data')
  }
  const update = () => {
    onSubmit()
  }
  useEffect(() => {
    if (firstCall === false) {
      setfirstCall(true)
      update()
      update()
    }
  }, [firstCall])

  // useEffect(() => {

  //      onSubmit()

  //     return () => {
  //         // cleanup
  //     }
  // }, [Grades, DateFrom, DateTo, CountryTags, PrevDateFrom, Specialization])
  // const Videoselected = (e) => {
  //     console.log(e)
  //     setVideoIndex(e)
  // }

  let videoDetails = (
    <Col className="tile-detail">
      <p>
        Total No. of views - {VideoData ? VideoData[VideoIndex].views : null}{' '}
      </p>
      <p>
        No. of unique views -{' '}
        {VideoData ? VideoData[VideoIndex].uniqueViews : null}
      </p>
      {/* <p>Viewed on iOS - times </p>
    <p>Viewed on Android - times </p>
    <p>Viewed on website - times </p> */}
      <p>
        Total Minutes consumed -{' '}
        {VideoData ? VideoData[VideoIndex].Minutes : null}mins{' '}
      </p>
      {/* <p>Total Minutes consumed on iOS  - mins </p>
    <p>Total Minutes consumed on Android - mins </p>
    <p>Total Minutes consumed on website - mins </p> */}
      <p>Consumption Pattern (Total time) </p>
    </Col>
  )
  useEffect(() => {
    videoDetails = (
      <Col className="tile-detail">
        <p>
          Total No. of views - {VideoData ? VideoData[VideoIndex].views : null}{' '}
        </p>
        <p>
          No. of unique views -{' '}
          {VideoData ? VideoData[VideoIndex].uniqueViews : null}
        </p>
        {/* <p>Viewed on iOS - times </p>
        <p>Viewed on Android - times </p>
        <p>Viewed on website - times </p> */}
        <p>
          Total Minutes consumed -{' '}
          {VideoData ? VideoData[VideoIndex].Minutes : null}mins{' '}
        </p>
        {/* <p>Total Minutes consumed on iOS  - mins </p>
        <p>Total Minutes consumed on Android - mins </p>
        <p>Total Minutes consumed on website - mins </p> */}
        <p>Consumption Pattern (Total time) </p>
      </Col>
    )
  }, [VideoIndex])

  const clearFilters = () => {
    console.log('dsfd')
    // reset(Grades)
    setGrades([])
    setCountryTags([])
    setSpecialization([])
    setDateFrom(date1.toLocaleDateString('en-CA'))
    setDateTo(date2.toLocaleDateString('en-CA'))
  }

  return (
    <Container fluid className="w-100">
      <Row
        className="mt-5 mb-5 p-4"
        style={{
          background: 'rgb(45, 47, 68)',
        }}
      >
        <Col>
          <Row
            className="text-center mt-2 mb-4 pt-3 pb-3"
            style={{
              background: '#27293d',
              border: '1px solid #3f3f40',
            }}
          >
            <Col>
              <Form.Group controlId="DateFrom">
                <Form.Label style={{ color: 'white' }}>Date From</Form.Label>
                <Form.Control
                  format="YYYY-MM-DD"
                  type="date"
                  placeholder="Date From"
                  value={DateFrom}
                  name="Date From"
                  onChange={(e) => {
                    console.log(e.target.value)
                    setDateFrom(e.target.value)
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="dob">
                <Form.Label style={{ color: 'white' }}>Date To</Form.Label>
                <Form.Control
                  format="YYYY-MM-DD"
                  type="date"
                  placeholder="Date To"
                  value={DateTo}
                  name="Date To"
                  onChange={(e) => {
                    console.log(e.target.value)
                    setDateTo(e.target.value)
                  }}
                />
              </Form.Group>
            </Col>

            <Col className="mt-3">
              <Button onClick={clearFilters}>Clear all filters</Button>
            </Col>
          </Row>

          <Row
            className="text-center mt-2 mb-2 pt-3 pb-3"
            style={{
              background: '#27293d',
              border: '1px solid #3f3f40',
            }}
          >
            <Col>
              <DropdownMultiselect
                options={[
                  { key: '1', label: 'Grade 8' },
                  { key: '2', label: 'Grade 9' },
                  { key: '3', label: 'Grade 10' },
                  { key: '4', label: 'Grade 11' },
                  { key: '5', label: 'Grade 12' },
                  { key: '6', label: 'Undergaraduate' },
                ]}
                placeholderMultipleChecked="Multiple selected"
                name="Relevant Grades"
                handleOnChange={(selected) => {
                  // console.log(selected)
                  setGrades([...selected])
                }}
                placeholder="Grade"
              />
            </Col>
            <Col>
              {/* {console.log()} */}
              <DropdownMultiselect
                options={[
                  { key: '1', label: 'UK' },
                  { key: '3', label: 'USA' },
                  { key: '4', label: 'Canada' },
                  { key: '58', label: 'Europe' },
                  { key: '6', label: 'Singapore' },
                  { key: '11', label: 'HongKong' },
                ]}
                name="countries"
                handleOnChange={(selected) => {
                  // console.log("chang",selected)
                  if (selected.length === 6) {
                    setCountryTags([])
                  } else {
                    setCountryTags([...selected])
                  }
                }}
                placeholder="Countries of Interest"
              />
            </Col>
            <Col>
              <DropdownMultiselect
                handleOnChange={(selected) => {
                  // console.log("chang",selected)
                  if (selected.length === 25) {
                    setSpecialization([])
                  } else {
                    setSpecialization([...selected])
                  }
                }}
                options={[
                  { key: '16', label: 'Computer Science' },
                  { key: '71', label: 'Datascience' },
                  { key: '69', label: 'Astrophysics' },
                  { key: '72', label: 'Psychology' },
                  { key: '46', label: 'Economics' },
                  { key: '60', label: 'Political Science' },
                  { key: '59', label: 'History' },
                  { key: '62', label: 'Gender Studies' },
                  { key: '63', label: 'Gender Studies' },
                  { key: '19', label: 'Architecture' },
                  { key: '68', label: 'Business' },
                  { key: '31', label: 'Design' },
                  { key: '64', label: 'Aeronautical Engineering' }, // Aerospace Engineering
                  { key: '70', label: 'Biochemistry' }, // BioMed Engineering
                  { key: '26', label: 'Life Science & Environment' }, // Environmental Engineering
                  { key: '27', label: 'Math' }, // Mathematics
                  { key: '25', label: 'Physical Science' }, // Physics
                  { key: '70', label: 'Biochemistry' }, // Biology
                  { key: '28', label: 'Medicine' }, // Pre Med
                  { key: '42', label: 'Law' }, // Pre Med
                  { key: '33', label: 'Media & Communication' }, // Communication
                  { key: '33', label: 'Media & Communication' }, // Journalism
                  { key: '66', label: 'Design / Interior Design' }, // Interior Design
                  { key: '32', label: 'Animation & Graphics' }, // Graphic Design
                  { key: '26', label: 'Life Science & Environment' }, // Environmental Design
                  { key: '68', label: 'Business' }, // Business of the Creative
                ]}
                name="Specialization"
                placeholder="Specialization"
              />
            </Col>
            {/* <Col>
                            <Button>Ivy League</Button>
                        </Col> */}
          </Row>
          <Row>
            <Col></Col>
            <Col>
              <Col className="mt-3 text-center">
                <Button onClick={update}>Update</Button>
              </Col>
            </Col>
            <Col></Col>
          </Row>
        </Col>
      </Row>
      {console.log(Object.values(noOfCalls)) &&
      false in Object.values(noOfCalls) ? (
        <p>Loading ..</p>
      ) : (
        <>
          <Row
            className="mb-5 p-4 "
            style={{
              background: '#2d2f44',
            }}
          >
            <Col>
              <Form>
                <Row className="mb-3">
                  <h3 className="title">
                    {/* <Link to="/notifications">
                            <i className="bi bi-arrow-left"></i>
                            </Link> */}
                    User Stats
                  </h3>
                </Row>
                <Row>
                  <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box box m-2 p-3">
                        <Row>
                          <Col className="text-center color-white">
                            Total Users
                          </Col>
                        </Row>
                        <Row>
                          <Col className="box-value">
                            <h5>{TotalUsers}</h5>
                          </Col>
                          {TotalUsers - TotalUsersPrev > 0 ? (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'lightgreen' }}>
                                <i
                                  className="fa fa-caret-up"
                                  aria-hidden="true"
                                ></i>{' '}
                                {TotalUsers - TotalUsersPrev}
                              </h6>
                            </Col>
                          ) : (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'red' }}>
                                <i
                                  className="fa fa-caret-down"
                                  aria-hidden="true"
                                ></i>{' '}
                                {TotalUsersPrev - TotalUsers}
                              </h6>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box m-2 p-3">
                        <Row>
                          <Col className=" text-center color-white">
                            Active Users
                          </Col>
                        </Row>
                        <Row>
                          <Col className="box-value">
                            <h5>{MonthlyActiveUsers}</h5>
                          </Col>
                          {MonthlyActiveUsers - MonthlyActiveUsersPrev > 0 ? (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'lightgreen' }}>
                                <i
                                  className="fa fa-caret-up"
                                  aria-hidden="true"
                                ></i>{' '}
                                {MonthlyActiveUsers - MonthlyActiveUsersPrev}
                              </h6>
                            </Col>
                          ) : (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'red' }}>
                                <i
                                  className="fa fa-caret-down"
                                  aria-hidden="true"
                                ></i>{' '}
                                {MonthlyActiveUsersPrev - MonthlyActiveUsers}
                              </h6>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box m-2 p-3">
                        <Row>
                          <Col className=" text-center color-white">
                            Total Plus Users
                          </Col>
                        </Row>

                        <Row>
                          <Col className="box-value">
                            <h5>{TotalPlusUsers}</h5>
                          </Col>
                          {TotalPlusUsers - TotalPlusUsersPrev > 0 ? (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'lightgreen' }}>
                                <i
                                  className="fa fa-caret-up"
                                  aria-hidden="true"
                                ></i>{' '}
                                {TotalPlusUsers - TotalPlusUsersPrev}
                              </h6>
                            </Col>
                          ) : (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'red' }}>
                                <i
                                  className="fa fa-caret-down"
                                  aria-hidden="true"
                                ></i>{' '}
                                {TotalPlusUsersPrev - TotalPlusUsers}
                              </h6>
                            </Col>
                          )}
                        </Row>
                        {/* <Row>
                                            <Col className="box-value"><h5>{TotalPlusUsers}</h5></Col>
                                            <Col className="value-icon"><h6><i className="fa fa-caret-up" aria-hidden="true"></i> -</h6></Col>
                                        </Row> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box m-2 p-3">
                        <Row>
                          <Col className=" text-center color-white">
                            Total Premium Plus Users
                          </Col>
                        </Row>
                        <Row>
                          <Col className="box-value">
                            <h5>{TotalPremiumPlusUsers}</h5>
                          </Col>
                          {TotalPremiumPlusUsers - TotalPremiumPlusUsersPrev >
                          0 ? (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'lightgreen' }}>
                                <i
                                  className="fa fa-caret-up"
                                  aria-hidden="true"
                                ></i>{' '}
                                {TotalPremiumPlusUsers -
                                  TotalPremiumPlusUsersPrev}
                              </h6>
                            </Col>
                          ) : (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'red' }}>
                                <i
                                  className="fa fa-caret-down"
                                  aria-hidden="true"
                                ></i>{' '}
                                {TotalPremiumPlusUsersPrev -
                                  TotalPremiumPlusUsers}
                              </h6>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <Row>
                                    <Col className="box m-2 p-3">
                                        <Row>
                                            <Col className=" text-center color-white">UG Admissions Landing Page Visit</Col>
                                        </Row>
                                        <Row>
                                            <Col className="box-value"><h5>-</h5></Col>
                                            <Col className="value-icon"><h6><i className="fa fa-caret-up" aria-hidden="true"></i> -</h6></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col> */}
                  {/* <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <Row>
                                    <Col className="box m-2 p-3">
                                        <Row>
                                            <Col className=" text-center color-white">SAT Landing Page Visits</Col>
                                        </Row>
                                        <Row>
                                            <Col className="box-value"><h5>-</h5></Col>
                                            <Col className="value-icon"><h6><i className="fa fa-caret-down" aria-hidden="true"></i> -</h6></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col> */}
                </Row>
                {/* <Row>
                            <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <Row>
                                    <Col className="box m-2 p-3">
                                        <Row>
                                            <Col className=" text-center color-white">UG Admissions Landing Page submitted</Col>
                                        </Row>
                                        <Row>
                                            <Col className="box-value"><h5>-</h5></Col>
                                            <Col className="value-icon"><h6><i className="fa fa-caret-up" aria-hidden="true"></i>-</h6></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <Row>
                                    <Col className="box m-2 p-3 ">
                                        <Row>
                                            <Col className="text-center color-white">SAT Landing Page submitted</Col>
                                        </Row>
                                        <Row>
                                            <Col className="box-value"><h5>-</h5></Col>
                                            <Col className="value-icon"><h6><i className="fa fa-caret-down" aria-hidden="true"></i> -</h6></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row> */}
              </Form>
            </Col>
          </Row>

          <Row
            className="mt-5 mb-5 p-4"
            style={{
              background: '#2d2f44',
            }}
          >
            <Col>
              <Form>
                <Row className="mb-3">
                  <h3 className="title">
                    {/* <Link to="/notifications">
                            <i className="bi bi-arrow-left"></i>
                            </Link> */}
                    Engagement (Time) Stats
                  </h3>
                </Row>
                <Row>
                  <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box box m-2 p-3">
                        <Row>
                          <Col className="text-center color-white">
                            Time Spent
                          </Col>
                        </Row>
                        <Row>
                          <Col className="box-value">
                            <h5>
                              {' '}
                              {Math.round(SpentTime)} <span>mins</span>
                            </h5>
                          </Col>
                          {SpentTime - SpentTimePrev > 0 ? (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'lightgreen' }}>
                                <i
                                  className="fa fa-caret-up"
                                  aria-hidden="true"
                                ></i>{' '}
                                {Math.round(SpentTime - SpentTimePrev)}
                              </h6>
                            </Col>
                          ) : (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'red' }}>
                                <i
                                  className="fa fa-caret-down"
                                  aria-hidden="true"
                                ></i>{' '}
                                {Math.round(SpentTimePrev - SpentTime)}
                              </h6>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box m-2 p-3">
                        <Row>
                          <Col className=" text-center color-white">
                            Time spent by Monthly Active Users
                          </Col>
                        </Row>
                        <Row>
                          <Col className="box-value">
                            <h5>
                              {' '}
                              {Math.round(ActiveUserTimeSpent)}{' '}
                              <span>mins</span>
                            </h5>
                          </Col>
                          {ActiveUserTimeSpent - ActiveUserTimeSpentPrev > 0 ? (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'lightgreen' }}>
                                <i
                                  className="fa fa-caret-up"
                                  aria-hidden="true"
                                ></i>{' '}
                                {Math.round(
                                  ActiveUserTimeSpent - ActiveUserTimeSpentPrev
                                )}
                              </h6>
                            </Col>
                          ) : (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'red' }}>
                                <i
                                  className="fa fa-caret-down"
                                  aria-hidden="true"
                                ></i>{' '}
                                {Math.round(
                                  ActiveUserTimeSpentPrev - ActiveUserTimeSpent
                                )}
                              </h6>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box m-2 p-3">
                        <Row>
                          <Col className=" text-center color-white">
                            Time spent by Plus users
                          </Col>
                        </Row>
                        <Row>
                          <Col className="box-value">
                            <h5>
                              {' '}
                              {Math.round(SpentTimePLus)} <span>mins</span>
                            </h5>
                          </Col>
                          {SpentTimePLus - SpentTimePLusPrev > 0 ? (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'lightgreen' }}>
                                <i
                                  className="fa fa-caret-up"
                                  aria-hidden="true"
                                ></i>{' '}
                                {Math.round(SpentTimePLus - SpentTimePLusPrev)}
                              </h6>
                            </Col>
                          ) : (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'red' }}>
                                <i
                                  className="fa fa-caret-down"
                                  aria-hidden="true"
                                ></i>{' '}
                                {Math.round(SpentTimePLusPrev - SpentTimePLus)}
                              </h6>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box m-2 p-3">
                        <Row>
                          <Col className=" text-center color-white">
                            Avg. time spent per MAU
                          </Col>
                        </Row>
                        <Row>
                          <Col className="box-value">
                            <h5>
                              {Math.round(
                                ActiveUserTimeSpent / MonthlyActiveUsers
                              )}
                              <span>mins</span>
                            </h5>
                          </Col>
                          {ActiveUserTimeSpent / MonthlyActiveUsers -
                            ActiveUserTimeSpentPrev / MonthlyActiveUsersPrev >
                          0 ? (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'lightgreen' }}>
                                <i
                                  className="fa fa-caret-up"
                                  aria-hidden="true"
                                ></i>{' '}
                                {Math.round(
                                  ActiveUserTimeSpent / MonthlyActiveUsers -
                                    ActiveUserTimeSpentPrev /
                                      MonthlyActiveUsersPrev
                                )}
                              </h6>
                            </Col>
                          ) : (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'red' }}>
                                <i
                                  className="fa fa-caret-down"
                                  aria-hidden="true"
                                ></i>{' '}
                                {Math.round(
                                  ActiveUserTimeSpentPrev /
                                    MonthlyActiveUsersPrev -
                                    ActiveUserTimeSpent / MonthlyActiveUsers
                                )}
                              </h6>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col></Col>
                </Row>
              </Form>
            </Col>
          </Row>

          <Row
            className="mt-5 mb-5 p-4"
            style={{
              background: '#2d2f44',
            }}
          >
            <Col>
              <Form>
                <Row className="mb-3">
                  <h3 className="title">
                    {/* <Link to="/notifications">
                            <i className="bi bi-arrow-left"></i>
                            </Link> */}
                    Engagement Stats - Detailed
                  </h3>
                </Row>
                <Row>
                  <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box box m-2 p-3">
                        <Row>
                          <Col className="text-center color-white">
                            No. of Videos watched
                          </Col>
                        </Row>
                        <Row>
                          <Col className="box-value">
                            <h5>{NoofVideoWatched}</h5>
                          </Col>
                          {NoofVideoWatched - NoofVideoWatchedPrev > 0 ? (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'lightgreen' }}>
                                <i
                                  className="fa fa-caret-up"
                                  aria-hidden="true"
                                ></i>{' '}
                                {Math.round(
                                  NoofVideoWatched - NoofVideoWatchedPrev
                                )}
                              </h6>
                            </Col>
                          ) : (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'red' }}>
                                <i
                                  className="fa fa-caret-down"
                                  aria-hidden="true"
                                ></i>{' '}
                                {Math.round(
                                  NoofVideoWatchedPrev - NoofVideoWatched
                                )}
                              </h6>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box m-2 p-3">
                        <Row>
                          <Col className=" text-center color-white">
                            No. of unique Videos watched
                          </Col>
                        </Row>
                        <Row>
                          <Col className="box-value">
                            <h5>{NoofUniqueVideoWatched}</h5>
                          </Col>
                          {NoofUniqueVideoWatched - NoofUniqueVideoWatchedPrev >
                          0 ? (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'lightgreen' }}>
                                <i
                                  className="fa fa-caret-up"
                                  aria-hidden="true"
                                ></i>{' '}
                                {Math.round(
                                  NoofUniqueVideoWatched -
                                    NoofUniqueVideoWatchedPrev
                                )}
                              </h6>
                            </Col>
                          ) : (
                            <Col className="value-icon ">
                              <h6 style={{ color: 'red' }}>
                                <i
                                  className="fa fa-caret-down"
                                  aria-hidden="true"
                                ></i>{' '}
                                {Math.round(
                                  NoofUniqueVideoWatchedPrev -
                                    NoofUniqueVideoWatched
                                )}
                              </h6>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <Row>
                                    <Col className="box m-2 p-3">
                                        <Row>
                                            <Col className=" text-center color-white">Chance Calculator</Col>
                                        </Row>
                                        <Row>
                                            <Col className="box-value"><h5>-</h5></Col>
                                            <Col className="value-icon"><h6><i className="fa fa-caret-up" aria-hidden="true"></i> -</h6></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col> */}
                </Row>
                {/* <Row>
                            <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <Row>
                                    <Col className="box m-2 p-3">
                                        <Row>
                                            <Col className=" text-center color-white">GPA ConverterU</Col>
                                        </Row>
                                        <Row>
                                            <Col className="box-value"><h5>-</h5></Col>
                                            <Col className="value-icon"><h6><i className="fa fa-caret-down" aria-hidden="true"></i> -</h6></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <Row>
                                    <Col className="box m-2 p-3">
                                        <Row>
                                            <Col className=" text-center color-white">Contact Us Page</Col>
                                        </Row>
                                        <Row>
                                            <Col className="box-value"><h5>-</h5></Col>
                                            <Col className="value-icon"><h6><i className="fa fa-caret-down" aria-hidden="true"></i> -</h6></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <Row>
                                    <Col className="box m-2 p-3">
                                        <Row>
                                            <Col className=" text-center color-white">Submit Contact Us</Col>
                                        </Row>
                                        <Row>
                                            <Col className="box-value"><h5>-</h5></Col>
                                            <Col className="value-icon"><h6><i className="fa fa-caret-up" aria-hidden="true"></i> -</h6></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row> */}
              </Form>
            </Col>
          </Row>
        </>
      )}
      <Row
        className="mt-5 mb-5 p-4"
        style={{
          background: '#2d2f44',
        }}
      >
        <Col>
          <Form>
            <Row className="mb-4">
              <Col lg={4} md={4} sm={12} xs={12}>
                <h3 className="title">Individual Stats</h3>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={12}
                xs={12}
                className="individual-searchbar"
              >
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                    onChange={(e) => {
                      searchData(e, e.target.value)
                    }}
                  />
                  {/* <Button variant="outline-success" onClick={}>Search</Button> */}
                </Form>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={12}
                xs={12}
                className="individual-shorting"
              >
                <p>SORT BY: </p>
                <Form.Select
                  className="bg-grey color-white"
                  value={2}
                  aria-label="Floating label select example"
                  //   value={uploadObj?.event_premium_level}
                  name="Sort Type"
                  onChange={(e) => {
                    SortBy(e, e.target.value)
                  }}
                >
                  <option>--Select Sort Method--</option>
                  <option value="1">Sort by Name</option>
                  <option value="2">Sort by Date</option>
                  <option value="3">Sort by Views</option>
                </Form.Select>
              </Col>
            </Row>
            <div>
              {VideoData && VideoData.length > 0
                ? VideoData.map((event, index) => {
                    // if(index % 3 === 0)
                    // {
                    // console.log(event)
                    return index % 3 === 0 ? (
                      <div>
                        <Row>
                          <Col
                            className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 tile-main-box"
                            // onClick={() => {
                            //     const a = batch
                            //     a[Math.floor(index / 3)] = VideoData[index]
                            //     setbatch([...a])
                            // }}
                            onClick={
                              VideoData[index]?.EVENT_TYPE === 'SERIES'
                                ? (e) => {
                                    showEpisodeData(
                                      e,
                                      VideoData[index].EVENT_ID
                                    )
                                  }
                                : null
                            }
                            // onClick={()=>{showEpisodeData()}}
                          >
                            <Row>
                              <Col className="tile-img-sec">
                                <img
                                  src="brown_test.webp"
                                  alt="test"
                                  style={{
                                    opacity: '0.4',
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row className="tile-text-box">
                              <Col className="">
                                <Row>
                                  <Col
                                    style={{
                                      marginTop: '10px',
                                      marginLeft: '-6%',
                                    }}
                                  >
                                    <span
                                      style={{
                                        background: '#c83232',
                                        padding: '2px 8px',
                                        textAlign: 'left',
                                        marginLeft: '-25px',
                                        marginTop: '15px',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        letterSpacing: '.12em',
                                        lineHeight: 'normal',
                                        textTransform: 'uppercase',
                                        borderRadius: '4px',
                                        transition:
                                          'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                        verticalAlign: 'middle',
                                        border: '0',
                                        fontFamily:
                                          'Lato,Helvetica,Arial,sans-serif!important',
                                      }}
                                    >
                                      {VideoData[index].EVENT_TYPE}
                                    </span>
                                  </Col>
                                  <Col>
                                    <span
                                      style={{
                                        background: '#ffffff',
                                        padding: '2px 5px',
                                        color: '#000000',
                                        textAlign: 'left',
                                        float: 'right',
                                        marginTop: '10px',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        letterSpacing: '.12em',
                                        lineHeight: 'normal',
                                        textTransform: 'uppercase',
                                        borderRadius: '4px',
                                        transition:
                                          'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                        verticalAlign: 'middle',
                                        border: '0',
                                        fontFamily:
                                          'Lato,Helvetica,Arial,sans-serif!important',
                                      }}
                                    >
                                      {VideoData[index].premium >= 3
                                        ? 'PREMIUM'
                                        : VideoData[index].premium === 2
                                        ? 'PLUS'
                                        : 'PLUS'}
                                    </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    style={{
                                      paddingLeft: '10%',
                                    }}
                                  >
                                    <Row>
                                      <Col>
                                        <h2
                                          style={{
                                            fontSize: '20px',
                                            marginTop: '10%',
                                            lineHeight: '25px',
                                            textTransform: 'uppercase',
                                            fontWeight: '800',
                                            color: '#ffffff',
                                            opacity: '.8',
                                          }}
                                        >
                                          {VideoData[index].EVENT}
                                        </h2>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <h4
                                          style={{
                                            fontSize: '18px',
                                            fontWeight: '600',
                                            letterSpacing: '0.03rem',
                                            lineHeight: '1.2',
                                            color: '#e6a851',
                                          }}
                                        >
                                          {moment
                                            .utc(VideoData[index].date_time)
                                            .utcOffset(utcAdd)
                                            .format('dddd, MMMM DD YYYY')}
                                        </h4>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <h6
                                          style={{
                                            fontWeight: '800',
                                            color: '#fff',
                                            opacity: '.8',
                                            lineHeight: '1',
                                            letterSpacing: '0.03rem',
                                            fontSize: '16px',
                                            marginTop: '1%',
                                          }}
                                        >
                                          {moment
                                            .utc(VideoData[index].date_time)
                                            .utcOffset(utcAdd)
                                            .format('hh:mm A')}
                                        </h6>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          {VideoData.length >= index + 1 + 1 ? (
                            <Col
                              className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 tile-main-box"
                              // onClick={() => {
                              //     const a = batch
                              //     a[Math.floor(index / 3)] = VideoData[index + 1]
                              //     setbatch([...a])
                              // }}
                              onClick={
                                VideoData[index + 1]?.EVENT_TYPE === 'SERIES'
                                  ? (e) => {
                                      showEpisodeData(
                                        e,
                                        VideoData[index + 1].EVENT_ID
                                      )
                                    }
                                  : null
                              }
                            >
                              <Row>
                                <Col className="tile-img-sec">
                                  <img
                                    src="brown_test.webp"
                                    alt="test"
                                    style={{
                                      opacity: '0.4',
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row className="tile-text-box">
                                <Col className="">
                                  <Row>
                                    <Col
                                      style={{
                                        marginTop: '10px',
                                        marginLeft: '-6%',
                                      }}
                                    >
                                      <span
                                        style={{
                                          background: '#c83232',
                                          padding: '2px 8px',
                                          textAlign: 'left',
                                          marginLeft: '-25px',
                                          marginTop: '15px',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          letterSpacing: '.12em',
                                          lineHeight: 'normal',
                                          textTransform: 'uppercase',
                                          borderRadius: '4px',
                                          transition:
                                            'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                          verticalAlign: 'middle',
                                          border: '0',
                                          fontFamily:
                                            'Lato,Helvetica,Arial,sans-serif!important',
                                        }}
                                      >
                                        {VideoData[index + 1]?.EVENT_TYPE}
                                      </span>
                                    </Col>
                                    <Col>
                                      <span
                                        style={{
                                          background: '#ffffff',
                                          padding: '2px 5px',
                                          color: '#000000',
                                          textAlign: 'left',
                                          float: 'right',
                                          marginTop: '10px',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          letterSpacing: '.12em',
                                          lineHeight: 'normal',
                                          textTransform: 'uppercase',
                                          borderRadius: '4px',
                                          transition:
                                            'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                          verticalAlign: 'middle',
                                          border: '0',
                                          fontFamily:
                                            'Lato,Helvetica,Arial,sans-serif!important',
                                        }}
                                      >
                                        {VideoData[index + 1]?.premium >= 3
                                          ? 'PREMIUM'
                                          : VideoData[index + 1]?.premium === 2
                                          ? 'PLUS'
                                          : 'PLUS'}
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      style={{
                                        paddingLeft: '10%',
                                      }}
                                    >
                                      <Row>
                                        <Col>
                                          <h2
                                            style={{
                                              fontSize: '20px',
                                              marginTop: '10%',
                                              lineHeight: '25px',
                                              textTransform: 'uppercase',
                                              fontWeight: '800',
                                              color: '#ffffff',
                                              opacity: '.8',
                                            }}
                                          >
                                            {VideoData[index + 1]?.EVENT}
                                          </h2>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <h4
                                            style={{
                                              fontSize: '18px',
                                              fontWeight: '600',
                                              letterSpacing: '0.03rem',
                                              lineHeight: '1.2',
                                              color: '#e6a851',
                                            }}
                                          >
                                            {moment
                                              .utc(
                                                VideoData[index + 1]?.date_time
                                              )
                                              .utcOffset(utcAdd)
                                              .format('dddd, MMMM DD YYYY')}
                                          </h4>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <h6
                                            style={{
                                              fontWeight: '800',
                                              color: '#fff',
                                              opacity: '.8',
                                              lineHeight: '1',
                                              letterSpacing: '0.03rem',
                                              fontSize: '16px',
                                              marginTop: '1%',
                                            }}
                                          >
                                            {moment
                                              .utc(
                                                VideoData[index + 1]?.date_time
                                              )
                                              .utcOffset(utcAdd)
                                              .format('hh:mm A')}
                                          </h6>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          ) : null}
                          {VideoData.length >= index + 2 + 1 ? (
                            <Col
                              className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 tile-main-box"
                              // onClick={() => {
                              //     const a = batch
                              //     a[Math.floor(index / 3)] = VideoData[index + 2]
                              //     setbatch([...a])
                              // }}
                              onClick={
                                VideoData[index + 2]?.EVENT_TYPE === 'SERIES'
                                  ? (e) => {
                                      showEpisodeData(
                                        e,
                                        VideoData[index + 2].EVENT_ID
                                      )
                                    }
                                  : null
                              }
                            >
                              <Row>
                                <Col className="tile-img-sec">
                                  <img
                                    src="brown_test.webp"
                                    alt="test"
                                    style={{
                                      opacity: '0.4',
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row className="tile-text-box">
                                <Col className="">
                                  <Row>
                                    <Col
                                      style={{
                                        marginTop: '10px',
                                        marginLeft: '-6%',
                                      }}
                                    >
                                      <span
                                        style={{
                                          background: '#c83232',
                                          padding: '2px 8px',
                                          textAlign: 'left',
                                          marginLeft: '-25px',
                                          marginTop: '15px',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          letterSpacing: '.12em',
                                          lineHeight: 'normal',
                                          textTransform: 'uppercase',
                                          borderRadius: '4px',
                                          transition:
                                            'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                          verticalAlign: 'middle',
                                          border: '0',
                                          fontFamily:
                                            'Lato,Helvetica,Arial,sans-serif!important',
                                        }}
                                      >
                                        {VideoData[index + 2]?.EVENT_TYPE}
                                      </span>
                                    </Col>
                                    <Col>
                                      <span
                                        style={{
                                          background: '#ffffff',
                                          padding: '2px 5px',
                                          color: '#000000',
                                          textAlign: 'left',
                                          float: 'right',
                                          marginTop: '10px',
                                          fontSize: '14px',
                                          fontWeight: '600',
                                          letterSpacing: '.12em',
                                          lineHeight: 'normal',
                                          textTransform: 'uppercase',
                                          borderRadius: '4px',
                                          transition:
                                            'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                          verticalAlign: 'middle',
                                          border: '0',
                                          fontFamily:
                                            'Lato,Helvetica,Arial,sans-serif!important',
                                        }}
                                      >
                                        {VideoData[index + 2]?.premium >= 3
                                          ? 'PREMIUM'
                                          : VideoData[index + 2]?.premium === 2
                                          ? 'PLUS'
                                          : 'PLUS'}
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      style={{
                                        paddingLeft: '10%',
                                      }}
                                    >
                                      <Row>
                                        <Col>
                                          <h2
                                            style={{
                                              fontSize: '20px',
                                              marginTop: '10%',
                                              lineHeight: '25px',
                                              textTransform: 'uppercase',
                                              fontWeight: '800',
                                              color: '#ffffff',
                                              opacity: '.8',
                                            }}
                                          >
                                            {VideoData[index + 2]?.EVENT}
                                          </h2>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <h4
                                            style={{
                                              fontSize: '18px',
                                              fontWeight: '600',
                                              letterSpacing: '0.03rem',
                                              lineHeight: '1.2',
                                              color: '#e6a851',
                                            }}
                                          >
                                            {moment
                                              .utc(
                                                VideoData[index + 2]?.date_time
                                              )
                                              .utcOffset(utcAdd)
                                              .format('dddd, MMMM DD YYYY')}
                                          </h4>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <h6
                                            style={{
                                              fontWeight: '800',
                                              color: '#fff',
                                              opacity: '.8',
                                              lineHeight: '1',
                                              letterSpacing: '0.03rem',
                                              fontSize: '16px',
                                              marginTop: '1%',
                                            }}
                                          >
                                            {moment
                                              .utc(
                                                VideoData[index + 2]?.date_time
                                              )
                                              .utcOffset(utcAdd)
                                              .format('hh:mm A')}
                                          </h6>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          ) : null}
                        </Row>
                        <Row>
                          {/* <Col className="tile-detail">
                                                    <p>Event -  {batch.length > Math.floor(index / 3) ? batch[Math.floor(index / 3)].EVENT : null} </p>
                                                    <p>Total No. of views -  {batch.length > Math.floor(index / 3) ? batch[Math.floor(index / 3)].views : null} </p>
                                                    <p>No. of unique views - {batch.length > Math.floor(index / 3) ? batch[Math.floor(index / 3)].uniqueViews : null} </p>
                                                    <p>Total Minutes consumed - {batch.length > Math.floor(index / 3) ? Math.round(batch[Math.floor(index / 3)].Minutes) : null} mins</p>
                                                    
                                                </Col> */}

                          {/* <p>Viewed on iOS - 800 times </p> */}
                          {/* <p>Viewed on Android - 1200 times </p> */}
                          {/* <p>Viewed on website - 240 times </p> */}
                          {/* <p>Total Minutes consumed on iOS  - 200 mins </p> */}
                          {/* <p>Total Minutes consumed on Android - 33 mins </p> */}
                          {/* <p>Total Minutes consumed on website -  120 mins </p> */}
                          {/* <p>Consumption Pattern (Total time) </p> */}
                          <Col className=" mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 tile-detail">
                            {/* {['top'].map((placement) => (
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip>
                                                                    {VideoData[index] ? VideoData[index].EVENT : 0}
                                                                </Tooltip>
                                                            }
                                                        ><p>Event -  {VideoData[index] ? VideoData[index].EVENT : 0} </p></OverlayTrigger>
                                                    ))} */}
                            <p>
                              Total No. of views -{' '}
                              {VideoData[index] ? VideoData[index].views : 0}{' '}
                            </p>
                            <p>
                              No. of unique views -{' '}
                              {VideoData[index]
                                ? VideoData[index].uniqueViews
                                : 0}{' '}
                            </p>
                            <p>
                              Total Minutes consumed -{' '}
                              {VideoData[index]
                                ? Math.round(VideoData[index].Minutes)
                                : 0}{' '}
                              mins
                            </p>
                            <p>
                              Viewed on iOS -{' '}
                              {VideoData[index] ? VideoData[index].IOS : 0}{' '}
                            </p>
                            <p>
                              Viewed on Android -{' '}
                              {VideoData[index] ? VideoData[index].ANDROID : 0}{' '}
                            </p>
                            <p>
                              Viewed on website -{' '}
                              {VideoData[index] ? VideoData[index].WEB : 0}{' '}
                            </p>
                            {/* <p>Untracked Views - {VideoData[index] ? VideoData[index].NULL : 0} </p> */}
                            <p>
                              Total Minutes consumed on iOS -{' '}
                              {VideoData[index]
                                ? Math.round(VideoData[index].IOS_SPENT_TIME)
                                : 0}{' '}
                            </p>
                            <p>
                              Total Minutes consumed on Android -{' '}
                              {VideoData[index]
                                ? Math.round(
                                    VideoData[index].ANDROID_SPENT_TIME
                                  )
                                : 0}{' '}
                            </p>
                            <p>
                              Total Minutes consumed on website -{' '}
                              {VideoData[index]
                                ? Math.round(VideoData[index].WEB_SPENT_TIME)
                                : 0}{' '}
                            </p>
                            {/* <p>Untracked Total Minutes consumed -  {VideoData[index] ? Math.round(VideoData[index].NULL_SPENT_TIME) : 0} </p> */}
                          </Col>
                          {VideoData.length >= index + 1 + 1 ? (
                            <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 tile-detail">
                              {/* {['top'].map((placement) => (
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip>
                                                                    {VideoData[index + 1] ? VideoData[index + 1].EVENT : null}
                                                                </Tooltip>
                                                            }
                                                        ><p>Event -  {VideoData[index + 1] ? VideoData[index + 1].EVENT : null} </p></OverlayTrigger>
                                                    ))} */}
                              <p>
                                Total No. of views -{' '}
                                {VideoData[index + 1]
                                  ? VideoData[index + 1].views
                                  : 0}{' '}
                              </p>
                              <p>
                                No. of unique views -{' '}
                                {VideoData[index + 1]
                                  ? VideoData[index + 1].uniqueViews
                                  : 0}{' '}
                              </p>
                              <p>
                                Total Minutes consumed -{' '}
                                {VideoData[index + 1]
                                  ? Math.round(VideoData[index + 1].Minutes)
                                  : 0}{' '}
                                mins
                              </p>
                              <p>
                                Viewed on iOS -{' '}
                                {VideoData[index + 1]
                                  ? VideoData[index + 1].IOS
                                  : 0}{' '}
                              </p>
                              <p>
                                Viewed on Android -{' '}
                                {VideoData[index + 1]
                                  ? VideoData[index + 1].ANDROID
                                  : 0}{' '}
                              </p>
                              <p>
                                Viewed on website -{' '}
                                {VideoData[index + 1]
                                  ? VideoData[index + 1].WEB
                                  : 0}{' '}
                              </p>
                              {/* <p>Untracked Views - {VideoData[index + 1] ? VideoData[index + 1].NULL : 0} </p> */}
                              <p>
                                Total Minutes consumed on iOS -{' '}
                                {VideoData[index + 1]
                                  ? Math.round(
                                      VideoData[index + 1].IOS_SPENT_TIME
                                    )
                                  : 0}{' '}
                              </p>
                              <p>
                                Total Minutes consumed on Android -{' '}
                                {VideoData[index + 1]
                                  ? Math.round(
                                      VideoData[index + 1].ANDROID_SPENT_TIME
                                    )
                                  : 0}{' '}
                              </p>
                              <p>
                                Total Minutes consumed on website -{' '}
                                {VideoData[index + 1]
                                  ? Math.round(
                                      VideoData[index + 1].WEB_SPENT_TIME
                                    )
                                  : 0}{' '}
                              </p>
                              {/* <p>Untracked Total Minutes consumed -  {VideoData[index + 2] ? Math.round(VideoData[index + 2].NULL_SPENT_TIME) : 0} </p> */}
                            </Col>
                          ) : null}
                          {VideoData.length >= index + 2 + 1 ? (
                            <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 tile-detail">
                              {/* {['top'].map((placement) => (
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip>
                                                                    {VideoData[index + 2] ? VideoData[index + 2].EVENT : null}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <p>Event -  {VideoData[index + 2] ? VideoData[index + 2].EVENT : null} </p></OverlayTrigger>
                                                    ))} */}
                              <p>
                                Total No. of views -{' '}
                                {VideoData[index + 2]
                                  ? VideoData[index + 2].views
                                  : 0}{' '}
                              </p>
                              <p>
                                No. of unique views -{' '}
                                {VideoData[index + 2]
                                  ? VideoData[index + 2].uniqueViews
                                  : 0}{' '}
                              </p>
                              <p>
                                Total Minutes consumed -{' '}
                                {VideoData[index + 2]
                                  ? Math.round(VideoData[index + 2].Minutes)
                                  : 0}{' '}
                                mins
                              </p>
                              <p>
                                Viewed on iOS -{' '}
                                {VideoData[index + 2]
                                  ? VideoData[index + 2].IOS
                                  : 0}{' '}
                              </p>
                              <p>
                                Viewed on Android -{' '}
                                {VideoData[index + 2]
                                  ? VideoData[index + 2].ANDROID
                                  : 0}{' '}
                              </p>
                              <p>
                                Viewed on website -{' '}
                                {VideoData[index + 2]
                                  ? VideoData[index + 2].WEB
                                  : 0}{' '}
                              </p>
                              {/* <p>Untracked Views - {VideoData[index + 1] ? VideoData[index + 1].NULL : 0} </p> */}
                              <p>
                                Total Minutes consumed on iOS -{' '}
                                {VideoData[index + 2]
                                  ? Math.round(
                                      VideoData[index + 2].IOS_SPENT_TIME
                                    )
                                  : 0}{' '}
                              </p>
                              <p>
                                Total Minutes consumed on Android -{' '}
                                {VideoData[index + 2]
                                  ? Math.round(
                                      VideoData[index + 2].ANDROID_SPENT_TIME
                                    )
                                  : 0}{' '}
                              </p>
                              <p>
                                Total Minutes consumed on website -{' '}
                                {VideoData[index + 2]
                                  ? Math.round(
                                      VideoData[index + 2].WEB_SPENT_TIME
                                    )
                                  : 0}{' '}
                              </p>
                              {/* <p>Untracked Total Minutes consumed -  {VideoData[index + 2] ? Math.round(VideoData[index + 2].NULL_SPENT_TIME) : 0} </p> */}
                            </Col>
                          ) : null}
                        </Row>
                      </div>
                    ) : null
                  })
                : null}
            </div>
            {/* <Row>
                            {videoDetails}
                        </Row> */}
          </Form>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose} className="dash-indi-modal">
        <Modal.Header
          closeButton
          style={{
            border: '0',
          }}
        ></Modal.Header>
        <Modal.Body>
          {EpisodeData && EpisodeData.length > 0
            ? EpisodeData.map((event, index) => {
                return index % 3 === 0 ? (
                  <div className="indi-modal-devider">
                    <Row>
                      <Col>
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                            <Row>
                              <Col>
                                {/* <Row>
                                                                    <Col className='dash-indi-modal-img-tile'>
                                                                        <img src="brown_test.webp" alt="test" />
                                                                    </Col>
                                                                </Row> */}
                                <Row>
                                  <Col
                                    className="dash-indi-modal-texts"
                                    style={{
                                      // backgroundImage: 'url(brown_test.webp)'
                                      background:
                                        'linear-gradient(0deg, rgb(112 112 112 / 45%), rgb(0 0 0 / 65%)), url(brown_test.webp)',
                                      backgroundRepeat: 'no-repeat',
                                      backgroundSize: 'cover',
                                    }}
                                  >
                                    <Row>
                                      <Col
                                        style={{
                                          textAlign: 'left',
                                          marginLeft: '6%',
                                        }}
                                      >
                                        <span>
                                          {EpisodeData[index].EVENT_TYPE}{' '}
                                          Minutes
                                        </span>
                                      </Col>
                                      <Col
                                        style={{
                                          textAlign: 'right',
                                        }}
                                      >
                                        {EpisodeData[index].premium ? (
                                          <span>PAID</span>
                                        ) : (
                                          <span>FREE</span>
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <h2>
                                          EP{EpisodeData[index].ind + 1} -{' '}
                                          {EpisodeData[index].EVENT}
                                        </h2>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        {/* <h4>SATURDAY, AUGUST 22 2020</h4> */}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>{/* <h6>06:00 PM</h6> */}</Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="modal-text-details">
                                {/* <p>Episode - {EpisodeData[index].EVENT}</p> */}
                                <p>
                                  Total No. of views -{' '}
                                  {EpisodeData[index].views}
                                </p>
                                <p>
                                  No. of unique views -{' '}
                                  {EpisodeData[index].uniqueViews}
                                </p>
                                <p>
                                  Total Minutes consumed -{' '}
                                  {Math.round(EpisodeData[index].Minutes)} mins
                                </p>
                                <p>Viewed on iOS - {EpisodeData[index].IOS}</p>
                                <p>
                                  Viewed on Android -{' '}
                                  {EpisodeData[index].ANDROID}
                                </p>
                                <p>
                                  Viewed on website - {EpisodeData[index].WEB}
                                </p>
                                {/* <p>Untracked Views - {EpisodeData[index].NULL}</p> */}
                                <p>
                                  Total Minutes consumed on iOS -{' '}
                                  {Math.round(
                                    EpisodeData[index].IOS_SPENT_TIME
                                  )}
                                </p>
                                <p>
                                  Total Minutes consumed on Android -{' '}
                                  {Math.round(
                                    EpisodeData[index].ANDROID_SPENT_TIME
                                  )}
                                </p>
                                <p>
                                  Total Minutes consumed on website -{' '}
                                  {Math.round(
                                    EpisodeData[index].WEB_SPENT_TIME
                                  )}
                                </p>
                                {/* <p>Untracked Total Minutes consumed - {EpisodeData[index].NULL_SPENT_TIME}</p> */}
                              </Col>
                            </Row>
                          </Col>
                          {EpisodeData.length >= index + 1 + 1 ? (
                            <Col lg={4} md={4} sm={12} xs={12}>
                              <Row>
                                <Col>
                                  {/* <Row>
                                                                    <Col className='dash-indi-modal-img-tile'>
                                                                        <img src="brown_test.webp" alt="test" />
                                                                    </Col>
                                                                </Row> */}
                                  <Row>
                                    <Col
                                      className="dash-indi-modal-texts"
                                      style={{
                                        // backgroundImage: 'url(brown_test.webp)'
                                        background:
                                          'linear-gradient(0deg, rgb(112 112 112 / 45%), rgb(0 0 0 / 65%)), url(brown_test.webp)',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                      }}
                                    >
                                      <Row>
                                        <Col
                                          style={{
                                            textAlign: 'left',
                                            marginLeft: '6%',
                                          }}
                                        >
                                          <span>
                                            {EpisodeData[index + 1].EVENT_TYPE}{' '}
                                            Minutes
                                          </span>
                                        </Col>
                                        <Col
                                          style={{
                                            textAlign: 'right',
                                          }}
                                        >
                                          {EpisodeData[index + 1].premium ? (
                                            <span>PAID</span>
                                          ) : (
                                            <span>FREE</span>
                                          )}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <h2>
                                            EP{EpisodeData[index + 1].ind + 1} -{' '}
                                            {EpisodeData[index + 1].EVENT}
                                          </h2>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          {/* <h4>SATURDAY, AUGUST 22 2020</h4> */}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>{/* <h6>06:00 PM</h6> */}</Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="modal-text-details">
                                  {/* <p>Episode - {EpisodeData[index].EVENT}</p> */}
                                  <p>
                                    Total No. of views -{' '}
                                    {EpisodeData[index + 1].views}
                                  </p>
                                  <p>
                                    No. of unique views -{' '}
                                    {EpisodeData[index + 1].uniqueViews}
                                  </p>
                                  <p>
                                    Total Minutes consumed -{' '}
                                    {Math.round(EpisodeData[index + 1].Minutes)}{' '}
                                    mins
                                  </p>
                                  <p>
                                    Viewed on iOS - {EpisodeData[index + 1].IOS}
                                  </p>
                                  <p>
                                    Viewed on Android -{' '}
                                    {EpisodeData[index + 1].ANDROID}
                                  </p>
                                  <p>
                                    Viewed on website -{' '}
                                    {EpisodeData[index + 1].WEB}
                                  </p>
                                  {/* <p>Untracked Views - {EpisodeData[index+1].NULL}</p> */}
                                  <p>
                                    Total Minutes consumed on iOS -{' '}
                                    {Math.round(
                                      EpisodeData[index + 1].IOS_SPENT_TIME
                                    )}
                                  </p>
                                  <p>
                                    Total Minutes consumed on Android -{' '}
                                    {Math.round(
                                      EpisodeData[index + 1].ANDROID_SPENT_TIME
                                    )}
                                  </p>
                                  <p>
                                    Total Minutes consumed on website -{' '}
                                    {Math.round(
                                      EpisodeData[index + 1].WEB_SPENT_TIME
                                    )}
                                  </p>
                                  {/* <p>Untracked Total Minutes consumed - {EpisodeData[index+1].NULL_SPENT_TIME}</p> */}
                                </Col>
                              </Row>
                            </Col>
                          ) : null}
                          {EpisodeData.length >= index + 2 + 1 ? (
                            <Col lg={4} md={4} sm={12} xs={12}>
                              <Row>
                                <Col>
                                  {/* <Row>
                                                                    <Col className='dash-indi-modal-img-tile'>
                                                                        <img src="brown_test.webp" alt="test" />
                                                                    </Col>
                                                                </Row> */}
                                  <Row>
                                    <Col
                                      className="dash-indi-modal-texts"
                                      style={{
                                        // backgroundImage: 'url(brown_test.webp)'
                                        background:
                                          'linear-gradient(0deg, rgb(112 112 112 / 45%), rgb(0 0 0 / 65%)), url(brown_test.webp)',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                      }}
                                    >
                                      <Row>
                                        <Col
                                          style={{
                                            textAlign: 'left',
                                            marginLeft: '6%',
                                          }}
                                        >
                                          <span>
                                            {EpisodeData[index + 2].EVENT_TYPE}{' '}
                                            Minutes
                                          </span>
                                        </Col>
                                        <Col
                                          style={{
                                            textAlign: 'right',
                                          }}
                                        >
                                          {EpisodeData[index + 2].premium ? (
                                            <span>PAID</span>
                                          ) : (
                                            <span>FREE</span>
                                          )}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <h2>
                                            EP{EpisodeData[index + 2].ind + 1} -{' '}
                                            {EpisodeData[index + 2].EVENT}
                                          </h2>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          {/* <h4>SATURDAY, AUGUST 22 2020</h4> */}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>{/* <h6>06:00 PM</h6> */}</Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="modal-text-details">
                                  {/* <p>Episode - {EpisodeData[index+2].EVENT}</p> */}
                                  <p>
                                    Total No. of views -{' '}
                                    {EpisodeData[index + 2].views}
                                  </p>
                                  <p>
                                    No. of unique views -{' '}
                                    {EpisodeData[index + 2].uniqueViews}
                                  </p>
                                  <p>
                                    Total Minutes consumed -{' '}
                                    {Math.round(EpisodeData[index + 2].Minutes)}{' '}
                                    mins
                                  </p>
                                  <p>
                                    Viewed on iOS - {EpisodeData[index + 2].IOS}
                                  </p>
                                  <p>
                                    Viewed on Android -{' '}
                                    {EpisodeData[index + 2].ANDROID}
                                  </p>
                                  <p>
                                    Viewed on website -{' '}
                                    {EpisodeData[index + 2].WEB}
                                  </p>
                                  {/* <p>Untracked Views - {EpisodeData[index+2].NULL}</p> */}
                                  <p>
                                    Total Minutes consumed on iOS -{' '}
                                    {Math.round(
                                      EpisodeData[index + 2].IOS_SPENT_TIME
                                    )}
                                  </p>
                                  <p>
                                    Total Minutes consumed on Android -{' '}
                                    {Math.round(
                                      EpisodeData[index + 2].ANDROID_SPENT_TIME
                                    )}
                                  </p>
                                  <p>
                                    Total Minutes consumed on website -{' '}
                                    {Math.round(
                                      EpisodeData[index + 2].WEB_SPENT_TIME
                                    )}
                                  </p>
                                  {/* <p>Untracked Total Minutes consumed - {EpisodeData[index+2].NULL_SPENT_TIME}</p> */}
                                </Col>
                              </Row>
                            </Col>
                          ) : null}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                ) : null
              })
            : null}
        </Modal.Body>
      </Modal>
    </Container>
  )
})
