import { isValidPhoneNumber } from 'react-phone-number-input'

const SUPPORTED_IMAGE_MIME_TYPES = ['image/jpeg', 'image/png']

export const validate = (value, validators = []) => {
  if (typeof value === 'string') value = value.trim()

  console.log('Type', typeof value)
  console.log('Is array', Array.isArray(value))
  console.log('Value', value)

  let isValid = true
  validators.forEach((validator) => {
    if (validator === 'REQUIRE') isValid = isValid && value && value.length
    if (validator === 'REQUIRE_MIN')
      // Min length = 3
      isValid = isValid && value.length >= 3
    else if (validator.startsWith('MIN_LENGTH#')) isValid = isValid && value.length >= validator.split('#')[1]
    else if (validator.startsWith('MAX_LENGTH#')) isValid = isValid && value.length <= validator.split('#')[1]
    else if (validator === 'EMAIL') isValid = isValid && /^\S+@\S+\.(\S){2,3}$/.test(value)
    else if (validator === 'ALPHABETS') isValid = isValid && /^[a-z|A-Z]*$/.test(value)
    else if (validator === 'NUMBER') isValid = isValid && /^\d*$/.test(value)
    else if (validator === 'PASSWORD')
      isValid = isValid && /^((?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16})*$/.test(value)
    else if (validator === 'NO_SPACES') isValid = isValid && /^\S*$/.test(value)
    else if (validator === 'AMOUNT') isValid = isValid && !Number.isNaN(value)
    else if (validator.startsWith('GREATER_THAN#')) isValid = isValid && value >= +validator.split('#')[1]
    else if (validator.startsWith('SMALLER_THAN#')) isValid = isValid && value <= +validator.split('#')[1]
    else if (validator === 'REQUIRE_ARR') isValid = isValid && Array.isArray(value) && !!value.length
    else if (validator === 'VALID_PHONENUMBER') isValid = isValid && isValidPhoneNumber(value || '')
  })
  console.log('Value', isValid)

  return isValid
}

export const valdiateFile = (file) => {
  if (!file) throw new Error('Invalid Image!!! Try again with a different image.')

  if (!SUPPORTED_IMAGE_MIME_TYPES.includes(file.type)) throw new Error('Invalid Image!!! Only PNG and JPG/JPEG images are supported.')

  if (file.size >= 3 * 1024 * 1024)
    // Max Size = 3MB
    throw new Error('Invalid File!!! File size too big. (Max size 3MB).')
}
