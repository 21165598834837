import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = ({ className }) => {
  return (
    <div className={"d-flex align-items-center justify-content-center color-white text-center " + className}>
      <Spinner animation="border" role="status">
      </Spinner>
    </div>
  );
};

export default Loader;