/* eslint-disable @next/next/no-img-element */
import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = ({ expanded }) => {
  return (
    <div className={expanded ? 'sidebar sidebar--expanded' : 'sidebar'}>
      <span className="shape"></span>
      <span className="shape"></span>

      <div
        className={expanded ? 'sidebar-items-container expanded' : 'sidebar-items-container'}
        style={{ overflowY: 'auto', height: 'calc(100vh - 64px)' }}
      >
        {/* <div className="sidebar-link" onClick={toggleSidebar}>
          <i className="bx bx-menu"></i>
        </div> */}
        <div>
          <Link to="/" className="sidebar-link sidebar-logo">
            <img src="/White-holo-no-background.png" alt="logo" className="icon logo" style={{ width: '2.3rem' }} />
            <span className="sidebar-logo-text">COLLEGEPASS</span>
          </Link>
        </div>
        <Link to="/dashboard" className="sidebar-link">
          <i className="bi bi-app-indicator icon"></i>
          <span className="sidebar-text">Dashboard</span>
        </Link>
        <Link to="/review-table" className="sidebar-link">
          <i className="bi bi-hand-thumbs-up icon"></i>
          <span className="sidebar-text">Review</span>
        </Link>
        <Link to="/add-premium-plus-user" className="sidebar-link">
          <i className="bi bi-person-plus-fill icon"></i>
          <span className="sidebar-text">Add Premium+ User</span>
        </Link>
        <Link to="/add-masters-user" className="sidebar-link">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-person-fill-add icon" viewBox="0 0 16 16">
  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
  <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>
</svg>
          <span className="sidebar-text">Add Masters User</span>
        </Link>
        {/* <Link to="/individual-stats" className="sidebar-link">
          <i className="bi bi-bar-chart-steps icon"></i>
          <span className="sidebar-text">Individual Stats</span>
        </Link> */}
        <Link to="/user-list-100" className="sidebar-link">
          <i className="bi bi-person icon"></i>
          <span className="sidebar-text">User List</span>
        </Link>

        <Link to="/events" className="sidebar-link">
          <i className="bi bi-calendar-event icon"></i>
          <span className="sidebar-text">Events</span>
        </Link>

        <Link to="/physical-events-list" className="sidebar-link">
          <i className="bi bi-people-fill icon"></i>
          <span className="sidebar-text">Physical Events</span>
        </Link>

        <Link to="/classes" className="sidebar-link">
          <i className="bi bi-book icon"></i>
          <span className="sidebar-text">Classes</span>
        </Link>
        <Link to="/campaign-list" className="sidebar-link">
          <i className="bi bi-app-indicator icon"></i>
          <span className="sidebar-text">Notifications</span>
        </Link>
        <Link to="/notification-analytics" className="sidebar-link">
          <i className="bi bi-bell icon"></i>
          <span className="sidebar-text">Notification Analytics</span>
        </Link>
        <Link to="/series" className="sidebar-link">
          <i className="bi bi-collection-play icon"></i>
          <span className="sidebar-text">Series</span>
        </Link>
        <Link to="/series-list" className="sidebar-link">
          <i className="bi bi-card-checklist icon"></i>
          <span className="sidebar-text">Series</span>
        </Link>
        <Link to="/event-Details" className="sidebar-link">
          <i className="bi bi-calendar2-event-fill icon"></i>
          <span className="sidebar-text">Event Details</span>
        </Link>

        <Link to="/archive-video" className="sidebar-link">
          <i className="bi bi-upload icon"></i>
          <span className="sidebar-text">Upload Recording</span>
        </Link>

        <Link to="/contact-lead" className="sidebar-link">
          <i className="bi bi-person-lines-fill icon"></i>
          <span className="sidebar-text">Contact Lead</span>
        </Link>

        <Link to="/blog-list" className="sidebar-link">
          <i className="bi bi-pip-fill icon"></i>
          <span className="sidebar-text">Blog List</span>
        </Link>

        <Link to="/post-list" className="sidebar-link">
          <i className="bi bi-file-post icon"></i>
          <span className="sidebar-text">Posts</span>
        </Link>

        <Link to="/stories-list" className="sidebar-link">
          <i className="bi bi-brightness-high-fill icon"></i>
          <span className="sidebar-text">Success Stories</span>
        </Link>

        <Link to="/referral-list" className="sidebar-link">
          <i className="bi bi-person-plus-fill icon"></i>
          <span className="sidebar-text">Referral</span>
        </Link>

        <Link to="/referral-analytics" className="sidebar-link">
          <i className="bi bi-person-plus-fill icon"></i>
          <span className="sidebar-text">Referral Analytics</span>
        </Link>
      </div>
    </div>
  )
}

export default Sidebar
