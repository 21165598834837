/* eslint-disable prefer-template */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback, useEffect, useState } from "react";
import { faCaretDown, faCaretUp, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SelectInput = ({
    id,
    options = {},
    name,
    label,
    defaultKey,
    onChange,
    // className,
    isError = false,
    errorMessage
}) => {
    const [showDropDown, setShowDropDown] = useState(false)
    const [selectedOption, setSelectedOption] = useState(options[defaultKey]);

    const onOptionSelectHandler = useCallback((key) => {
        onChange(id, options[key].value, !!options[key].value)
        setSelectedOption(options[key])
        setShowDropDown(false);
    }, [id, onChange, options])

    useEffect(()=> {
        setSelectedOption(options[defaultKey])
    }, [defaultKey, options])

    const toggleDropDown = useCallback(()=> setShowDropDown(state => !state) , [])

    if (!options) {
        return <></>
    }

    return (
        <div className={""}>
            <div className="mb-1.5">
                {
                    label &&
                    <label
                        className="font-medium text-base whitespace-nowrap text-[#091A42]"
                        htmlFor={name}
                    >
                        {`${label || "DropDown"}`}
                    </label>
                }
            </div>
            <div className="">
                <div className="d-flex justify-between w-full mb-3" onClick={toggleDropDown} style={{
                    padding: '5px 10px',
                    border: '1px solid #898989',
                    width: '100%',
                    borderRadius: '5px'
                }}>
                    <div>
                        {selectedOption?.name || "Select "}
                    </div>
                    <div className="" style={{
                        position: 'absolute',
                        right: '40px'
                    }}>
                        <FontAwesomeIcon icon={showDropDown ? faCaretUp : faCaretDown} />
                    </div>
                </div>
                {
                    showDropDown &&
                    <div className="absolute w-auto right-0 mt-11 border rounded z-20 bg-[#ffffff] border-[#aeaeae] border" style={{
                        position: 'absolute',
                        display: 'block',
                        background: 'rgb(255, 255, 255)',
                        maxHeight: '300px',
                        overflow: 'auto',
                        height: 'fit-content',
                        minWidth: '45%',
                        marginTop: '-15px'
                    }}>
                        {
                            Object.entries(options).map(([key, value]) => {
                                if(key === "none") {
                                    return <div key={key} className={"py-2 px-4 block whitespace-no-wrap " + (selectedOption?.key === value.key ? "bg-[#d0e1fd]" : "hover:bg-[#f0f0f0]") } 
                                        onClick={() => onOptionSelectHandler(key)}> Select An Option </div>
                                }

                                return <div key={key} className={"py-2 px-4 block whitespace-no-wrap " + (selectedOption?.key === value.key ? "bg-[#d0e1fd]" : "hover:bg-[#f0f0f0]") } 
                                    onClick={() => onOptionSelectHandler(key)}> {value?.name} </div>
                            })
                        }
                    </div>
                }
            </div>

			{isError && (
				<span className="text-warning text-sm">
					<FontAwesomeIcon icon={faCircleInfo} className="" /> {errorMessage}
				</span>
			)}
        </div>
    )
}

export default SelectInput;