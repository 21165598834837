import React, { useState, useEffect } from 'react'
import {
  Col,
  Container,
  Form,
  Row,
  Table,
  Button,
  Modal,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import {
  APIDeleteStory,
  APIGetBlogsData,
  APIGetStories,
} from '../../utils/config/API'
import withLoginRequired from '../../HOC/LoginModal'

export default withLoginRequired(() => {
  const [stories, setStories] = useState([])

  const getStoriesData = async () => {
    try {
      const result = await axios.get(APIGetStories)
      console.log('data ------>', result.data.data)
      if (result.status === 200) {
        setStories(result.data.body)
      }
    } catch (err) {
      console.log('error ----->', err)
    }
  }

  useEffect(() => {
    getStoriesData()
  }, [])

  const [show, setShow] = useState(false)
  const [removeId, setRemoveId] = useState(null)

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = (index) => {
    setRemoveId(index)
    setShow(true)
  }

  const [search, setSearch] = useState('')

  const handleDelete = async () => {
    try {
      const result = await axios.delete(APIDeleteStory + removeId)
      if (result.status !== 200) {
        console.log('Result :', result.data)
      }
      await getStoriesData()
      setShow(false)
    } catch (err) {
      console.log('Error')
    }
  }
  let tableBodyComponent = ''
  if (stories.length) {
    tableBodyComponent = stories
      ?.filter((item) => item.TITLE.toLowerCase().includes(search))
      .map((story, index) => {
        return (
          <tr
            key={story.ID}
            // onClick={() => handleClick(userData.ID)}
            role="button"
            className="data-row"
          >
            <td>{index + 1}</td>
            <td>{story.TITLE}</td>
            <td>
              <Link to={`/edit-story/${story.ID}`}>
                <Button className="btn-primary">Edit</Button>
              </Link>
            </td>
            <td>
              <Button className="btn-dlt" onClick={() => handleShow(story.ID)}>
                DELETE
              </Button>
            </td>
          </tr>
        )
      })
  }
  return (
    <Container fluid className="w-100">
      <Row className="heading mb-3">
        <h3 className="title">
          Story List
          {/* {userListData !== undefined
            ? userListData.length > 0
              ? userListData.length
              : 0
            : 0}
          ) */}
        </h3>
      </Row>

      <Row className="mb-3 w-50 pr-3">
        <Col>
          {/* <Form onSubmit={onSearchHandler}> */}
          <Form>
            <Form.Control
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Form>
        </Col>
        <Col>
          <Link to="/edit-story">
            <Button
              className="btn-primary"
              // onClick={handleSubmit}
              style={{
                padding: '8px 12px',
                fontSize: '1rem',
              }}
            >
              ADD
            </Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Table responsive className="table">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Title</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>{tableBodyComponent}</tbody>
        </Table>
      </Row>

      <Modal show={show} onHide={() => handleClose()} size="l">
        <Modal.Header closeButton>
          <h5 className="text-center ml-4 pt-3 pb-3 width-100">
            Are you sure you want to delete this Story ?
          </h5>
        </Modal.Header>
        <Modal.Footer>
          <span className="mt-3 mb-3 width-100 text-center">
            <Button
              variant="primary"
              onClick={() => handleDelete()}
              className="mr-2"
              id=""
              style={{
                marginRight: '10px',
              }}
            >
              Yes
            </Button>
            <Button
              variant="primary"
              onClick={() => handleClose()}
              className="ml-2"
              style={{
                marginLeft: '10px',
              }}
            >
              No
            </Button>
          </span>
        </Modal.Footer>
      </Modal>
    </Container>
  )
})
