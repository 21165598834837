import React, { useState, useEffect } from 'react'
import {
  Col,
  Container,
  Form,
  Row,
  Table,
  Button,
  Pagination,
  PageItem,
  Spinner,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import {
  APIGetBlogsData,
  APIGetPostCount,
  APIGetPosts,
  APIGetPostsByName,
} from '../../utils/config/API'
import withLoginRequired from '../../HOC/LoginModal'

export default withLoginRequired(() => {
  const [posts, setPosts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPosts, setTotalPosts] = useState(0)

  const [searchString, setSearchString] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const getPostsData = async () => {
    try {
      setIsLoading(true)
      if (totalPosts === 0) {
        const totalP = await axios.get(APIGetPostCount)
        console.log('Count -------->', totalP.data.count)
        setTotalPosts(totalP.data.count)
      }
      const result = await axios.get(`${APIGetPosts}/${currentPage}`)
      console.log('data ------>', result.data.body)
      if (result.status === 200) {
        setPosts(result.data.body)
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log('error ----->', err)
    }
  }

  useEffect(() => {
    currentPage > 1 && getPostsData()
  }, [currentPage])

  useEffect(() => {
    let cancel
    const timerId = setTimeout(() => {
      if (searchString) {
        setIsLoading(true)
        axios
          .get(APIGetPostsByName + searchString, {
            cancelToken: new axios.CancelToken((c) => {
              // An executor function receives a cancel function as a parameter
              cancel = c
            }),
          })
          .then((response) => {
            setPosts(response.data.body)
            setIsLoading(false)
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              // Request was canceled
              console.log('Request canceled: ', error.message)
            } else {
              console.log(error)
              setIsLoading(false)
            }
          })
      } else {
        setCurrentPage(1)
        setIsLoading(true)
        if (totalPosts === 0) {
          axios.get(APIGetPostCount).then((totalP) => {
            console.log('Count -------->', totalP.data.count)
            setTotalPosts(totalP.data.count)
          })
        }
        axios.get(`${APIGetPosts}/1`).then((result) => {
          console.log('data ------>', result.data.body)
          if (result.status === 200) {
            setPosts(result.data.body)
          }
          setIsLoading(false)
        })
      }
    }, 1000)
    return () => {
      clearTimeout(timerId)
      if (cancel) {
        cancel('Request canceled by user')
      }
    }
  }, [searchString])

  let tableBodyComponent = ''
  if (posts.length) {
    tableBodyComponent = isLoading ? (
      <tr>
        {/* eslint-disable-next-line */}
        <td colSpan={6}>
          <Spinner animation="border" size="sm" />
        </td>
      </tr>
    ) : (
      posts?.map((blog) => {
        return (
          <tr
            key={blog.id}
            // onClick={() => handleClick(userData.ID)}
            role="button"
            className="data-row"
          >
            <td>{blog.title}</td>
            <td>{blog.type}</td>
            <td>{blog.tagNames || '-'}</td>
            <td>{blog.likeCount}</td>
            <td>{blog.commentCount}</td>
            <td>
              <Link to={`/edit-post/${blog.id}`}>
                <Button className="btn-primary">Edit</Button>
              </Link>
            </td>
          </tr>
        )
      })
    )
  } else if (!posts.length && !isLoading) {
    tableBodyComponent = (
      <tr>
        <td colSpan={6}>No Posts Found!</td>
      </tr>
    )
  }

  const handlePageSelect = (event) => {
    setCurrentPage(Number(event.target.text))
  }

  const renderPageItems = () => {
    const pageItems = []
    const totalPages = Math.ceil(totalPosts / 10)

    if (totalPages > 1) {
      // Add "First" page item
      pageItems.push(
        <Pagination.First
          key="first"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(1)}
        />
      )

      // Add "Previous" page item
      pageItems.push(
        <Pagination.Prev
          key="prev"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        />
      )

      // Calculate page range
      let startPage
      let endPage
      const pageRange = 15 // number of pages to show in the pagination
      if (totalPages <= pageRange) {
        startPage = 1
        endPage = totalPages
      } else {
        // eslint-disable-next-line
        if (currentPage <= Math.floor(pageRange / 2) + 1) {
          startPage = 1
          endPage = pageRange
        } else if (currentPage >= totalPages - Math.floor(pageRange / 2)) {
          startPage = totalPages - pageRange + 1
          endPage = totalPages
        } else {
          startPage = currentPage - Math.floor(pageRange / 2)
          endPage = currentPage + Math.floor(pageRange / 2)
        }
      }

      // Add ellipsis before page items
      if (startPage > 1) {
        pageItems.push(<Pagination.Ellipsis key="ellipsis-start" />)
      }

      // Add page items
      // eslint-disable-next-line
      for (let i = startPage; i <= endPage; i++) {
        pageItems.push(
          <PageItem
            key={i}
            active={i === currentPage}
            onClick={handlePageSelect}
          >
            {i}
          </PageItem>
        )
      }

      // Add ellipsis after page items
      if (endPage < totalPages) {
        pageItems.push(<Pagination.Ellipsis key="ellipsis-end" />)
      }

      // Add "Next" page item
      pageItems.push(
        <Pagination.Next
          key="next"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        />
      )

      // Add "Last" page item
      pageItems.push(
        <Pagination.Last
          key="last"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(totalPages)}
        />
      )
    }

    return pageItems
  }

  return (
    <Container fluid className="w-100">
      <Row className="heading mb-3">
        <h3 className="title">
          Post List
          {/* {userListData !== undefined
            ? userListData.length > 0
              ? userListData.length
              : 0
            : 0}
          ) */}
        </h3>
      </Row>

      <Row className="mb-3 w-50 pr-3">
        <Col>
          {/* <Form onSubmit={onSearchHandler}> */}
          <Form>
            <Form.Control
              type="text"
              placeholder="Post Title"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
          </Form>
        </Col>
      </Row>

      <Row className="mb-3 w-50 pr-3">
        {/* <Col> */}
        {/* <Form onSubmit={onSearchHandler}> */}
        {/* <Form> */}
        {/* <Form.Control */}
        {/* // type="text" */}
        {/* // placeholder="Enter Email / Phone" */}
        {/* // value={searchText} */}
        {/* // onChange={(e) => setSearchText(e.target.value)} */}
        {/* // /> */}
        {/* </Form> */}
        {/* </Col> */}
        {/* <Col>
          <Link to="/edit-post">
            <Button
              className="btn-primary"
              // onClick={handleSubmit}
              style={{
                padding: '8px 12px',
                fontSize: '1rem',
              }}
            >
              ADD
            </Button>
          </Link>
        </Col> */}
      </Row>

      <Row>
        <Table responsive className="table">
          <thead>
            <tr>
              <th>Post Title</th>
              <th>Type</th>
              <th>Tags</th>
              <th>Likes Count</th>
              <th>Comments Count</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{tableBodyComponent}</tbody>
        </Table>
      </Row>
      {searchString ? null : (
        <Row>
          <Pagination>{renderPageItems()}</Pagination>
        </Row>
      )}
    </Container>
  )
})
