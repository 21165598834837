/* eslint-disable react/jsx-fragments */
import React, { Fragment, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import EditReviewOnlineCourseForm from '../EditReviewOnlineCourseForm'
import EditReviewNGOForm from '../EditReviewNGOForm'
import MessageModal from '../Shared/MessageModal'
import EditReviewSummerProgramCourseForm from '../EditReviewSummerProgramForm'

const ReviewTableItem = ({
  index,
  id,
  type,
  name,
  status,
  raisedBy,
  approvedBy,
  createdAt,
  itemDetails,
  reFetchDetails,
  approvedAt,
}) => {
  const [showEditForm, setShowEditForm] = useState(false)
  const [messageDetails, setMessageDetails] = useState({ show: false, isError: false, message: '' })

  const toggleEditForm = () => {
    setShowEditForm((state) => !state)
  }
  const formsObject = {
    NGO: EditReviewNGOForm,
    'Online Course': EditReviewOnlineCourseForm,
    'Summer Program': EditReviewSummerProgramCourseForm,
  }
  const EditForm = formsObject[type]

  return (
    <Fragment>
      <Modal show={showEditForm} onHide={toggleEditForm} keyboard={false} className="review-edit-table" size='lg'>
        {/* <Modal.Header closeButtonOc></Modal.Header> */}
        <Modal.Body>
          <Row>
            <Col>
              <EditForm id={id} onClose={toggleEditForm} formDetails={itemDetails} reFetchDetails={reFetchDetails} />
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseOc}>
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
      </Modal>
      <MessageModal
        showMessage={messageDetails.show}
        isError={messageDetails.isError}
        message={messageDetails.message}
        closeModal={() => {
          setMessageDetails({ show: false })
        }}
      />
      <tr onClick={toggleEditForm}>
        <td>{index}</td>
        <td>{type}</td>
        <td>{name}</td>
        <td>{status}</td>
        <td>{raisedBy}</td>
        <td>{createdAt}</td>
        <td>{approvedBy}</td>
        <td>{approvedAt}</td>
      </tr>
    </Fragment>
  )
}

export default ReviewTableItem
