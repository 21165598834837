/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Container, Row, Table, Button, Form, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import axios from 'axios'

import { getEventsFullData, deleteEventAction } from '../../actions/eventsActions'
import withLoginRequired from '../../HOC/LoginModal'
import { getPhysicalEventFullData } from '../../utils/config/API'

export default withLoginRequired(() => {
  const dispatch = useDispatch()
  // const events = useSelector((state) => state.events.events)
  const [events, setEvents] = useState([])

  const [sortAsc, setSortAsc] = useState(false)
  const [activeSort, setActiveSort] = useState('date')

  const [searchInput, setSearchInput] = useState('')

  const parseHtmlString = (html) => {
    // Regular expression to match the first <p> tag and its content
    const regex = /<p>(.*?)<\/p>/

    // Extract the content of the first <p> tag
    const match = html.match(regex)
    let firstPTagContent = match ? match[1] : ''
    console.log('First tag', firstPTagContent)
    // Remove all HTML tags within the first <p> tag content
    firstPTagContent = firstPTagContent.replace(/<[^>]*>/g, '')

    // Extract the first sentence (up to the first period)
    const firstSentence = firstPTagContent.split('.')[0] + '.'

    return firstSentence || ''

    // return firstPTagContent || ''
  }

  const createDynamicLink = async (id, title, desc, dateTime, zoomId, image) => {
    const firstPTagHtml = parseHtmlString(desc)

    const apiKey = 'AIzaSyBvXx4zkclbjWdJ7t4nVvhq9e-NMYeLIls' // Replace with your Firebase project's API key

    const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`
    const response = await axios.post(url, {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://link.collegepass.org',
        link: `https://www.collegepass.org/links?TYPE=LIVE_SESSION&SOURCE_ID=${id}&ZOOM_ID=${zoomId}`, // Add your parameters here
        // other parameters as needed
        // link: `https://www.collegepass.org/registration/${zoomId}`,
        androidInfo: {
          // androidPackageName: 'com.collegepass',
          androidFallbackLink: `https://www.collegepass.org/registration/${zoomId}`,
        },
        iosInfo: {
          // iosBundleId: 'org.collegePass',
          iosFallbackLink: `https://www.collegepass.org/registration/${zoomId}`,
          iosIpadFallbackLink: `https://www.collegepass.org/registration/${zoomId}`,
        },
        socialMetaTagInfo: {
          socialTitle: `${title} | ${moment(dateTime).format('dddd, MMMM D, h:mm A')}`,
          socialDescription: firstPTagHtml,
          socialImageLink: image,
        },
      },
    })

    console.log('Respinse', response)

    return response.data ? (response.data.shortLink ? response.data.shortLink : '') : ''
  }

  const getPhysicalEvents = async () => {
    try {
      const res = await axios.get(getPhysicalEventFullData, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      const physicalEvents = res.data.body
      const result = []
      if (physicalEvents && physicalEvents.length) {
        // eslint-disable-next-line
        for (const event of physicalEvents) {
          if (event.ID >= 1107 && moment(event.DATE_TIME).utc().isSameOrAfter(moment())) {
            // eslint-disable-next-line
            const link = await createDynamicLink(
              event.ID,
              event.NAME,
              event.DESC,
              event.DATE_TIME,
              event.ZOOMID,
              // event.PHONE_DESKTOP_BANNER
              // event.banner_image
              event.SHARE_IMAGE_URL
            )
            event.LINK = link
          }
          result.push(event)
        }
      }
      setEvents(result)
    } catch (err) {
      console.log('Error --->', err)
    }
  }
  useEffect(() => {
    // dispatch(getPhysicalEventFullData())
    getPhysicalEvents()
  }, [dispatch])

  const handleDeleteEvent = (id) => {
    dispatch(deleteEventAction(id))
    // dispatch(getEventsFullData())
    getPhysicalEvents()
  }

  const handleClick = (eventData) => {
    window.open(`physical-event/edit-event/${eventData.ID}`)
  }

  const handleAdd = () => {
    window.open('physical-event/edit-event')
  }

  let tableBodyComponent = null

  if (events !== undefined && events !== null && events.length > 0) {
    if (searchInput.length > 0) {
      tableBodyComponent = events
        .filter((e) => e.NAME.toLowerCase().includes(searchInput))
        .sort((a, b) => {
          switch (activeSort) {
            case 'name': {
              if (sortAsc) return a.NAME > b.NAME ? 1 : -1
              else return a.NAME < b.NAME ? 1 : -1
            }
            case 'date': {
              const dateA = new Date(a.DATE_TIME)
              const dateB = new Date(b.DATE_TIME)
              if (sortAsc) return dateA > dateB ? 1 : -1
              else return dateA < dateB ? 1 : -1
            }
            case 'regCount': {
              if (sortAsc) return a.REGISTRATION_COUNT > b.REGISTRATION_COUNT ? 1 : -1
              else return a.REGISTRATION_COUNT < b.REGISTRATION_COUNT ? 1 : -1
            }
            case 'attendeeCount': {
              if (sortAsc) return a.ATTENDEES_COUNT > b.ATTENDEES_COUNT ? 1 : -1
              else return a.ATTENDEES_COUNT < b.ATTENDEES_COUNT ? 1 : -1
            }
            default:
              return 1
          }
        })
        .map((eventData) => {
          return (
            <tr key={eventData.ID} className="data-row">
              <td>{eventData.NAME}</td>
              <td>{moment(eventData.DATE_TIME).utc().format('DD/MM/YYYY')}</td>
              <td>{eventData.ZOOMID}</td>
              <td>{eventData.ZOOM_PASS}</td>
              <td>{eventData.REGISTRATION_COUNT}</td>
              {/* <td>{eventData.ATTENDEES_COUNT}</td> */}
              <td>
                <Button className="btn-primary" onClick={() => handleClick(eventData)}>
                  Edit
                </Button>
              </td>

              <td>
                <Button className="btn-dlt" onClick={() => handleDeleteEvent(eventData.ID)}>
                  DELETE
                </Button>
              </td>
              {eventData.LINK ? (
                <td>
                  <Button
                    className="btn-dlt"
                    // onClick={() => handleDeleteEvent(eventData.ID)}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(
                          `Hello!

We are excited to share an upcoming session on CollegePass that we think you'll love:

Session Title: ${eventData.NAME}
Description: ${parseHtmlString(eventData.DESC)}
Date: ${moment(eventData.DATE_TIME).format('dddd, MMMM D')}
Time: ${moment(eventData.DATE_TIME).format('h:mm A')} (IST)
Venue: ${eventData.VENUE}

Here’s the link to register: ${eventData.LINK}

We hope to see you there!`
                        )
                        .then(() => {
                          setEvents((prev) =>
                            prev.map((item) => {
                              if (item.ID === eventData.ID) {
                                return { ...eventData, COPIED: true }
                              }
                              return item
                            })
                          )
                        })
                    }
                  >
                    {/* <Link to={eventData.LINK || 'https://collegepass.org'}> */}
                    {eventData.COPIED ? 'COPIED' : 'COPY'}
                    {/* </Link> */}
                  </Button>
                </td>
              ) : (
                <td>
                  <Button
                    className="btn-dlt"
                    // onClick={() => handleDeleteEvent(eventData.ID)}
                  >
                    -
                  </Button>
                </td>
              )}
            </tr>
          )
        })
    } else {
      tableBodyComponent = events
        .sort((a, b) => {
          switch (activeSort) {
            case 'name': {
              if (sortAsc) return a.NAME > b.NAME ? 1 : -1
              else return a.NAME < b.NAME ? 1 : -1
            }
            case 'date': {
              const dateA = new Date(a.DATE_TIME)
              const dateB = new Date(b.DATE_TIME)
              if (sortAsc) return dateA > dateB ? 1 : -1
              else return dateA < dateB ? 1 : -1
            }
            case 'regCount': {
              if (sortAsc) return a.REGISTRATION_COUNT > b.REGISTRATION_COUNT ? 1 : -1
              else return a.REGISTRATION_COUNT < b.REGISTRATION_COUNT ? 1 : -1
            }
            case 'attendeeCount': {
              if (sortAsc) return a.ATTENDEES_COUNT > b.ATTENDEES_COUNT ? 1 : -1
              else return a.ATTENDEES_COUNT < b.ATTENDEES_COUNT ? 1 : -1
            }
            default:
              return 1
          }
        })
        .map((eventData) => {
          return (
            <tr key={eventData.ID} className="data-row">
              <td>{eventData.NAME}</td>
              <td>{moment(eventData.DATE_TIME).utc().format('DD/MM/YYYY')}</td>
              <td>{eventData.ZOOMID}</td>
              <td>{eventData.ZOOM_PASS}</td>
              <td>{eventData.REGISTRATION_COUNT}</td>
              {/* <td>{eventData.ATTENDEES_COUNT}</td> */}
              <td>
                <Button className="btn-primary" onClick={() => handleClick(eventData)}>
                  Edit
                </Button>
              </td>

              <td>
                <Button className="btn-dlt" onClick={() => handleDeleteEvent(eventData.ID)}>
                  DELETE
                </Button>
              </td>
              {eventData.LINK ? (
                <td>
                  <Button
                    className="btn-dlt"
                    // onClick={() => handleDeleteEvent(eventData.ID)}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(
                          `Hello!

We are excited to share an upcoming session on CollegePass that we think you'll love:

Session Title: ${eventData.NAME}
Description: ${parseHtmlString(eventData.DESC)}
Date: ${moment(eventData.DATE_TIME).format('dddd, MMMM D')}
Time: ${moment(eventData.DATE_TIME).format('h:mm A')} (IST)
Venue: ${eventData.VENUE}

Here’s the link to register: ${eventData.LINK}

We hope to see you there!`
                        )
                        .then(() => {
                          setEvents((prev) =>
                            prev.map((item) => {
                              if (item.ID === eventData.ID) {
                                return { ...eventData, COPIED: true }
                              }
                              return item
                            })
                          )
                        })
                    }
                  >
                    {/* <Link to={eventData.LINK || 'https://collegepass.org'}> */}
                    {eventData.COPIED ? 'COPIED' : 'COPY'}
                    {/* </Link> */}
                  </Button>
                </td>
              ) : (
                <td>
                  <Button
                    className="btn-dlt"
                    // onClick={() => handleDeleteEvent(eventData.ID)}
                  >
                    -
                  </Button>
                </td>
              )}
            </tr>
          )
        })
    }
  }

  return (
    <Container fluid className="w-100">
      <Row className="heading mb-3">
        <h3 className="title">physical Events</h3>
      </Row>

      <Row className="mb-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col xs={8} sm={4}>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              type="text"
              placeholder="Enter Event Title"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Form>
        </Col>
        <Col sm={4} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Form>
            <Button className="btn-primary" onClick={() => handleAdd()}>
              Add Event
            </Button>
          </Form>
        </Col>
      </Row>

      <Row>
        <Table responsive className="table">
          <thead>
            <tr>
              <th>
                Event Title{' '}
                <i
                  className={
                    activeSort === 'name'
                      ? 'bi bi-arrow-down-up cursor-pointer active-sort'
                      : 'bi bi-arrow-down-up cursor-pointer inactive-sort'
                  }
                  onClick={() => {
                    setSortAsc(!sortAsc)
                    setActiveSort('name')
                  }}
                ></i>
              </th>
              <th>
                Date{' '}
                <i
                  className={
                    activeSort === 'date'
                      ? 'bi bi-arrow-down-up cursor-pointer active-sort'
                      : 'bi bi-arrow-down-up cursor-pointer inactive-sort'
                  }
                  onClick={() => {
                    setSortAsc(!sortAsc)
                    setActiveSort('date')
                  }}
                ></i>
              </th>
              <th>Zoom ID </th>
              <th>Zoom Password</th>
              <th>
                Registration Count{' '}
                <i
                  className={
                    activeSort === 'regCount'
                      ? 'bi bi-arrow-down-up cursor-pointer active-sort'
                      : 'bi bi-arrow-down-up cursor-pointer inactive-sort'
                  }
                  onClick={() => {
                    setSortAsc(!sortAsc)
                    setActiveSort('regCount')
                  }}
                ></i>
              </th>
              {/* <th>
                Attendees Count{' '}
                <i
                  className={
                    activeSort === 'attendeeCount'
                      ? 'bi bi-arrow-down-up cursor-pointer active-sort'
                      : 'bi bi-arrow-down-up cursor-pointer inactive-sort'
                  }
                  onClick={() => {
                    setSortAsc(!sortAsc)
                    setActiveSort('attendeeCount')
                  }}
                ></i>
              </th> */}
              <th>Edit</th>
              <th>Delete</th>
              <th>Copy</th>
            </tr>
          </thead>
          <tbody>{tableBodyComponent}</tbody>
        </Table>
      </Row>
    </Container>
  )
})
