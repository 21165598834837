export const grades = [
  { value: '1', label: 'Grade 8' },
  { value: '2', label: 'Grade 9' },
  { value: '3', label: 'Grade 10' },
  { value: '4', label: 'Grade 11' },
  { value: '5', label: 'Grade 12' },
  { value: '8', label: 'In College' },
  { value: '9', label: 'Graduated' },
  { value: '7', label: 'Others' },
]
export const curriculum = [
  { value: '1', label: 'IGCSE' },
  { value: '2', label: 'IB' },
  { value: '3', label: 'CBSE' },
  { value: '4', label: 'ICSE' },
  { value: '5', label: 'STATE BOARD' },
  { value: '6', label: 'CAIE' },
  { value: '7', label: 'UNDERGRADUATE/ DEGREE' },
]
export const countries = [
  { value: '3', label: 'USA' },
  { value: '2', label: 'UK' },
  { value: '4', label: 'Canada' },
  { value: '9', label: 'Australia/New Zealand' },
  { value: '6', label: 'Singapore' },
  { value: '11', label: 'Hong Kong' },
  { value: '73', label: 'Other' },
]
