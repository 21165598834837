export const typesOptions = [
  "Agriculture",
  "Animal Healthcare",
  "Animal Welfare",
  "Arts &Culture",
  "Arts &Culture for Children with Disability",
  "Children",
  "Children with Disability",
  "Disaster Prevention",
  "Education",
  "Empowerment of Women",
  "Environment",
  "First Aid",
  "Forestry",
  "Health",
  "Health (Including Nutrition, Maternal Healthcare)",
  "Livelihood",
  "Mental Health Awareness and Volunteering",
  "Rural Development",
  "Sanitation & Hygiene",
  "Underprivileged with Disability",
  "Water",
  "Water Supply / Waste Management / Sanitation",
  "Women Empowerment",
  "Women Empowerment / Gender",
  "Education (Including Vocational Training)",
];

export const statesOptions = [
  "All",
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman & Diu",// union territory, recently merged with Dadra and Nagar Haveli, so not available in database
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu & Kashmir", // typo in here, database uses "and"
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh", // union territory, so not available in database
  "Lakshwadeep", // union territory, so not available in database
  "Leh and Ladakh", // union territory, so not available in database
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Online", // not a state
  "Puducherry", // union territory, so not available in database
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];
