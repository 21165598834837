import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, FloatingLabel, Card, Alert, Modal, Spinner } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import FormData from 'form-data'
import Select from 'react-select'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import {
  APIGetBlogsData,
  APIGetPostCount,
  APIGetPosts,
  cancelScheduledJob,
  getCampaign,
  getClassListDropDown,
  getEventListDropDown,
  getEventListDropDownV1,
  sendNotifcation,
  sendNotifcationTesting,
  sendOnesignalNotification,
  sendOnesignalNotificationTesting,
  updateScheduledJob,
} from '../../utils/config/API'
import withLoginRequired from '../../HOC/LoginModal'

export default withLoginRequired(() => {
  const token = localStorage.getItem('token')
  const navigate = useNavigate()

  const { id } = useParams()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  const [uploadObj, setUploadObj] = useState({
    title: '',
    image: '',
    image2: '',
    description: '',
    filterMode: 'all',
  })
  const [selectedPremiumTags, setselectedPremiumTags] = useState([])
  const [selectedGradeTags, setSelectedGradeTags] = useState([])
  const [showBasicAlert, setShowBasicAlert] = useState(false)
  const [responseStatus, setresponseStatus] = useState(false)
  const [responseMessage, setresponseMessage] = useState('')
  const [eventType, seteventType] = useState('EVENT')
  const [subjects, setSubjects] = useState([])
  // const handleImageChange = (e) => {
  //   if (e.target.files.length) {
  //     storeImage(e.target.files[0], userEmail)
  //   }
  // }
  // const handleBannerChange = (e)=>{
  //   if (e.target.files.length) {
  //     setUploadObj({
  //       ...uploadObj,
  //       image: e.target.files[0],
  //     })

  //   }
  //   }
  const [classes, setClasses] = useState([])
  const [blogs, setBlogs] = useState([])
  const [posts, setPosts] = useState([])

  const [selectedCls, setSelectedCls] = useState()
  const [selectedblog, setSelectedblog] = useState()
  const [selectedPost, setSelectedPost] = useState()

  const [selectedSubjects, setSelectedSubjects] = useState([])

  const [eventDate, setEventDate] = useState(null)

  const fetchData = async () => {
    try {
      // const evts = await axios.get(getEventListDropDown, config)
      const evts = await axios.get(getEventListDropDownV1, config)
      setClasses(evts.data.body)
      console.log('DAta ------->', evts.data.body)

      const result = await axios.get(APIGetBlogsData)
      console.log('data ------>', result.data.data)
      if (result.status === 200) {
        setBlogs(result.data.data)
      }
    } catch (err) {
      console.log('Error --------->', err)
    }
  }

  const getPostsData = async () => {
    try {
      const result = await axios.get(`${APIGetPosts}/1`)
      console.log('data ------>', result.data.body)
      if (result.status === 200) {
        setPosts(result.data.body)
      }
    } catch (err) {
      console.log('error ----->', err)
    }
  }
  const getCampaignData = async () => {
    try {
      const result = await axios.get(`${getCampaign}/${id}`)
      console.log('Data -------->', result.data.data)
      setUploadObj((prev) => {
        return {
          ...prev,
          title: result.data.data.TITLE,
          description: result.data.data.DESCRIPTION,
        }
      })
    } catch (err) {
      console.log('Error --------->', err)
    }
  }
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleCancel = () => {
    // add your cancel function logic here
    setShow(false)
  }

  const onCancelNotification = async () => {
    try {
      const result = await axios.delete(`${cancelScheduledJob}/${id}`)
      if (result.status === 200) {
        setShow(false)
        navigate('/campaign-list')
      }
    } catch (err) {
      console.log('Error --------->', err)
    }
  }

  useEffect(() => {
    fetchData().then(() => getPostsData())
  }, [])

  useEffect(() => {
    id && getCampaignData()
  }, [id])

  let classComp = []
  if (classes.length) {
    classComp = classes.map((item, index) => {
      return {
        value: item.ID,
        label: `${item.NAME} (${item.DATE_TIME})`,
      }
    })
  }
  let blogsComp = []
  if (blogs.length) {
    blogsComp = blogs.map((item, index) => {
      return {
        value: item.POST_ID,
        label: item.TITLE,
      }
    })
  }
  let postsComp = []
  if (posts.length) {
    postsComp = posts.map((item, index) => {
      return {
        value: item.id,
        label: item.title,
      }
    })
  }
  const handleGradeTagsChecked = (e) => {
    if (e.target.checked)
      if (!selectedGradeTags) setSelectedGradeTags([e.target.value])
      else setSelectedGradeTags([...selectedGradeTags, e.target.value])
    else {
      const filteredArr = selectedGradeTags.filter((tag) => tag !== e.target.value)
      setSelectedGradeTags(filteredArr)
    }
    console.log(selectedGradeTags)
  }
  const handlePremiumTagsChecked = (e) => {
    if (e.target.checked)
      if (!selectedPremiumTags) setselectedPremiumTags([e.target.value])
      else setselectedPremiumTags([...selectedPremiumTags, e.target.value])
    else {
      const filteredArr = selectedPremiumTags.filter((tag) => tag !== e.target.value)
      setselectedPremiumTags(filteredArr)
    }
    console.log(selectedPremiumTags)
  }
  const classOnChangeSelection = (e) => {
    console.log(e.target.value)
  }

  const subjectOptions = [
    {
      value: 'IB/Cambridge Math',
      label: 'IB/Cambridge Math',
    },
    {
      value: 'IB/Cambridge Physics',
      label: 'IB/Cambridge Physics',
    },
    { value: 'IB/Cambridge Chemistry', label: 'IB/Cambridge Chemistry' },
    { value: 'IB/Cambridge English', label: 'IB/Cambridge English' },
    {
      value: 'IB/Cambridge Spanish',
      label: 'IB/Cambridge Spanish',
    },
  ]

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isTesting, setIsTesting] = useState(true)

  const [paymentLink, setPaymentLink] = useState('')
  const handleSubmit = async () => {
    const data = new FormData()
    data.append('title', uploadObj.title)
    !id && data.append('image', uploadObj.image)
    data.append('description', uploadObj.description)
    !id && data.append('filterMode', uploadObj.filterMode)
    !id && data.append('tags', selectedPremiumTags)
    !id && data.append('grades', selectedGradeTags)
    // /!id && data.append('eventType', eventType)
    if (!id) {
      if (eventType === 'Talk To Us - IB Tutoring') {
        data.append('eventType', 'Talk To Us')
        data.append('card', 'IB/Cambridge Tutoring')
        data.append(
          'enquiry',
          selectedSubjects.map((item) => item.value)
        )
      } else {
        data.append('eventType', eventType)
      }
    }
    !id &&
      data.append(
        'eventId',
        eventType === 'PAYMENT'
          ? paymentLink
          : eventType === 'Talk To Us' || eventType === 'REFERRAL' || eventType === 'Talk To Us - IB Tutoring'
          ? 0
          : eventType === 'EVENT'
          ? selectedCls.value
          : eventType === 'POST'
          ? selectedPost.value
          : selectedblog.value
      )
    data.append('eventDate', eventDate ? moment(eventDate).toDate() : null)

    id && data.append('id', id)
    !id && data.append('image2', uploadObj.image2)

    console.log(data)
    try {
      console.log(
        'trying',
        eventType === 'Talk To Us' || eventType === 'REFERRAL' || eventType === 'Talk To Us - IB Tutoring'
          ? 0
          : eventType === 'EVENT'
          ? selectedCls.value
          : eventType === 'POST'
          ? selectedPost.value
          : selectedblog.value
      )
      console.log('trying', eventType)

      // return
      setIsSubmitting(true)
      const url = id ? updateScheduledJob : isTesting !== 'false' ? sendOnesignalNotificationTesting : sendOnesignalNotification

      console.log('URl where request goes:', url)
      console.log('URl where request goes:', isTesting)
      // return
      const insertResponse = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        body: data,
      })
      // for (const entry of data.entries()) {
      //   console.log(entry[0], entry[1]);
      // }

      // data.entries().forEach((entry) => {
      //   console.log(entry[0], entry[1])
      // })

      if (insertResponse.status === 200) {
        setIsSubmitting(false)

        setresponseStatus(true)
        setresponseMessage(insertResponse.message)
        navigate('/campaign-list')

        console.log('setting sucess message', responseMessage)
      } else {
        setIsSubmitting(false)

        setresponseStatus(false)
        setresponseMessage(insertResponse.message)
        console.log('setting unsucessful message', responseMessage)
      }
      console.log(insertResponse)
      setShowBasicAlert(true)
    } catch (err) {
      setIsSubmitting(false)

      console.log(err)
    }
  }
  console.log('State of testing --->', isTesting)
  console.log('State of testing --->', selectedSubjects)
  return (
    <Container fluid className="w-100">
      <Row className="mb-3 d-flex justify-content-between">
        <Col>
          <h3 className="title">
            <Link to="/campaign-list">
              <i className="bi bi-arrow-left"></i>
            </Link>
            SEND NOTIFICATIONS
          </h3>
        </Col>
        {id && (
          <Col className="d-flex justify-content-center">
            <Button onClick={() => setShow(true)}>DELETE</Button>
          </Col>
        )}
      </Row>
      <Form>
        <Row>
          <Col className="mb-4">
            <FloatingLabel controlId="title" label="Title" className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}>
              <Form.Control
                className="bg-grey color-white"
                type="text"
                placeholder="Title"
                defaultValue={uploadObj?.title}
                name="Title"
                onChange={(e) =>
                  setUploadObj({
                    ...uploadObj,
                    title: e.target.value,
                  })
                }
                // disabled={!!id}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-4">
            <FloatingLabel
              controlId="Description"
              label="Description"
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            >
              <Form.Control
                className="bg-grey color-white"
                // type="text"
                as="textarea"
                rows={3}
                placeholder="Description"
                defaultValue={uploadObj?.description}
                name="Description"
                onChange={(e) => {
                  setUploadObj({
                    ...uploadObj,
                    description: e.target.value,
                  })
                }}
                style={{
                  height: 'max-content',
                  paddingTop: '2rem',
                  paddingBottom: '2rem',
                }}
              />
            </FloatingLabel>
          </Col>
        </Row>
        {!id && (
          <Row>
            <Col className="mb-4">
              <Form.Label className="fs-6 color-light-pink">Image Banner For Notification</Form.Label>
              <Form.Control
                type="file"
                size="sm"
                accept=".jpg, .png"
                onChange={(e) => {
                  e.target.files.length
                    ? setUploadObj({
                        ...uploadObj,
                        image: e.target.files[0],
                      })
                    : null
                }}
              />
            </Col>
          </Row>
        )}
        {/* {!id && (
          <Row>
            <Col className="mb-4">
              <Form.Label className="fs-6 color-light-pink">Image Banner To Show in Mobile Application(Square Image):</Form.Label>
              <Form.Control
                type="file"
                size="sm"
                accept=".jpg, .png"
                onChange={(e) => {
                  e.target.files.length
                    ? setUploadObj({
                        ...uploadObj,
                        image2: e.target.files[0],
                      })
                    : null
                }}
              />
            </Col>
          </Row>
        )} */}
        {!id && (
          <Row>
            <Col className="mb-4">
              <Card className="bg-grey color-white p-3">
                <Form.Group controlId="gradeTag">
                  <Form.Label className="fs-6 color-light-pink">Grades</Form.Label>
                  <Form.Check
                    checked={selectedGradeTags ? selectedGradeTags.includes('1') : false}
                    type="checkbox"
                    label="Grade 8"
                    value="1"
                    name="gradeTag"
                    className="color-white"
                    onChange={(e) => handleGradeTagsChecked(e)}
                  />
                  <Form.Check
                    checked={selectedGradeTags ? selectedGradeTags?.includes('2') : false}
                    type="checkbox"
                    label="Grade 9"
                    value="2"
                    name="gradeTag"
                    className="color-white"
                    onChange={(e) => handleGradeTagsChecked(e)}
                  />
                  <Form.Check
                    checked={selectedGradeTags ? selectedGradeTags?.includes('3') : false}
                    type="checkbox"
                    label="Grade 10"
                    value="3"
                    name="gradedTag"
                    className="color-white"
                    onChange={(e) => handleGradeTagsChecked(e)}
                  />
                  <Form.Check
                    checked={selectedGradeTags ? selectedGradeTags?.includes('4') : false}
                    type="checkbox"
                    label="Grade 11"
                    value="4"
                    name="gradeTag"
                    className="color-white"
                    onChange={(e) => handleGradeTagsChecked(e)}
                  />
                  <Form.Check
                    checked={selectedGradeTags ? selectedGradeTags?.includes('5') : false}
                    type="checkbox"
                    label="Grade 12"
                    value="5"
                    name="gradeTag"
                    className="color-white"
                    onChange={(e) => handleGradeTagsChecked(e)}
                  />
                </Form.Group>
              </Card>
            </Col>
            <Col className="mb-4">
              <Card className="bg-grey color-white p-3">
                <Form.Group controlId="premiumTag">
                  <Form.Label className="fs-6 color-light-pink">Premium level</Form.Label>
                  <Form.Check
                    checked={selectedPremiumTags ? selectedPremiumTags.includes('1') : false}
                    type="checkbox"
                    label="Basic"
                    value="1"
                    name="premiumTag"
                    className="color-white"
                    onChange={(e) => handlePremiumTagsChecked(e)}
                  />
                  <Form.Check
                    checked={selectedPremiumTags ? selectedPremiumTags?.includes('2') : false}
                    type="checkbox"
                    label="Plus"
                    value="2"
                    name="premiumTag"
                    className="color-white"
                    onChange={(e) => handlePremiumTagsChecked(e)}
                  />
                  <Form.Check
                    checked={selectedPremiumTags ? selectedPremiumTags?.includes('3') : false}
                    type="checkbox"
                    label="Premium"
                    value="3"
                    name="premiumTag"
                    className="color-white"
                    onChange={(e) => handlePremiumTagsChecked(e)}
                  />
                  <Form.Check
                    checked={selectedPremiumTags ? selectedPremiumTags?.includes('4') : false}
                    type="checkbox"
                    label="Premium Plus"
                    value="4"
                    name="premiumTag"
                    className="color-white"
                    onChange={(e) => handlePremiumTagsChecked(e)}
                  />
                </Form.Group>
              </Card>
            </Col>
          </Row>
        )}

        {!id && (
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col className="mb-4">
              <FloatingLabel controlId="Notification_Type" label="Notification Type" className="fs-6 color-light-pink">
                <Form.Select
                  className="bg-grey color-white"
                  aria-label="Floating label select example"
                  defaultValue="EVENT_DETAIL"
                  name="classId"
                  onChange={(e) => {
                    // classOnChangeSelection(e)
                    seteventType(e.target.value)
                  }}
                >
                  {/* <option value={0} disabled>
                    --Select--
                  </option> */}
                  <option value="EVENT">EVENT</option>
                  <option value="BLOG">BLOG</option>
                  <option value="POST">POST</option>
                  <option value="REFERRAL">REFERRAL</option>
                  <option value="Talk To Us">Talk To Us</option>
                  <option value="Talk To Us - IB Tutoring">Talk To Us - IB Tutoring</option>
                  <option value="PAYMENT">PAYMENT</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col className="mb-4">
              {/* <Form.Label
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            >
              {eventType === 'EVENT' ? 'Select Class' : 'Select Blog'}
            </Form.Label> */}
              {eventType !== 'PAYMENT' ? (
                <Select
                  value={eventType === 'EVENT' ? selectedCls : eventType === 'POST' ? selectedPost : selectedblog}
                  name={eventType === 'EVENT' ? 'class' : eventType === 'POST' ? 'post' : 'blog'}
                  onChange={eventType === 'EVENT' ? setSelectedCls : eventType === 'POST' ? setSelectedPost : setSelectedblog}
                  options={eventType === 'EVENT' ? classComp : eventType === 'POST' ? postsComp : blogsComp}
                  placeholder={eventType === 'EVENT' ? 'Select Event' : eventType === 'POST' ? 'Select Post' : 'Select Blog'}
                  // className="h-100"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      // background: '#2d2f44',
                      // color: 'red!important',
                      boxShadow: 'none',
                      // border:
                      //   isSubmitting && !cls && !liveStream
                      //     ? '2px solid red!important'
                      //     : 'none',
                    }),
                  }}
                />
              ) : (
                <FloatingLabel
                  controlId="paymentLink"
                  label="Payment Link"
                  className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
                >
                  <Form.Control
                    className="bg-grey color-white"
                    type="text"
                    placeholder="Payment Link"
                    defaultValue={paymentLink}
                    name="paymentLink"
                    onChange={(e) =>
                      setPaymentLink({
                        ...uploadObj,
                        title: e.target.value,
                      })
                    }
                    // disabled={!!id}
                  />
                </FloatingLabel>
              )}
              {/* {isSubmitting && !cls && !liveStream ? (
              <Form.Text
                // className=""
                style={{
                  color: 'red!important',
                  letterSpacing: '0.03rem',
                }}
              >
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  style={{ color: 'red' }}
                >
                  {' '}
                </i>
                <span style={{ color: 'red' }}>This field is required</span>
              </Form.Text>
            ) : null} */}
            </Col>
          </Row>
        )}
        {eventType === 'Talk To Us - IB Tutoring' && (
          <Row>
            <Col className="mb-4">
              <Select
                components={{
                  MultiValue: ({ data }) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: 'rgb(230, 230, 230)',
                          borderRadius: '2px',
                          display: 'flex',
                          margin: '2px',
                          minWidth: '0px',
                          boxSizing: 'border-box',
                        }}
                      >
                        <span
                          style={{
                            margin: '2px',
                            boxSizing: 'border-box',
                            color: 'rgb(51, 51, 51)',
                            textOverflow: 'ellipsis',
                            padding: '5px 5px 5px 5px',
                            whiteSpace: 'nowrap',
                            fontSize: '14px',
                            overflow: 'hidden',
                          }}
                        >
                          {data.value}
                        </span>{' '}
                        <span>
                          <i
                            className="fa fa-times"
                            aria-hidden="true"
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation()

                              setSelectedSubjects((prev) => {
                                if (!prev) {
                                  return []
                                }

                                return prev.filter((item) => item.value !== data.value)
                              })
                            }}
                          ></i>
                        </span>
                      </span>
                    </div>
                  ),
                }}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    color: 'black!important',
                    backgroundColor: 'white',
                    // padding: 20,
                  }),
                }}
                value={selectedSubjects}
                onChange={setSelectedSubjects}
                options={subjectOptions.map((option) => ({
                  value: option.value,
                  label: (
                    <div className="d-flex justify-content-start">
                      <input
                        type="checkbox"
                        value={option.value}
                        checked={(selectedSubjects || []).find((item) => item.value === option.value)}
                        onChange={(e) => e.stopPropagation()}
                        onClick={(e) => e.stopPropagation()} // Prevent dropdown from opening
                      />
                      {option.label}
                    </div>
                  ),
                }))}
                isMulti
                placeholder="Select the subject(s)"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
              />
            </Col>
          </Row>
        )}
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col className="mb-4">
            <DatePicker
              className="form-control"
              showTimeSelect
              timeIntervals={10}
              placeholderText="Select Target Date"
              selected={eventDate}
              onChange={(date) => setEventDate(date)}
              dateFormat="MMMM d, yyyy HH:mm" // showTimeInput
            />
          </Col>
          <Col className="mb-4">
            {/* <Select
              value={isTesting}
              name="Testing"
              onChange={(e) => {
                console.log('E', e)
                setIsTesting(e.value)
              }}
              options={[
                { value: true, label: `Testing` },
                { value: false, label: `Production` },
              ]}
              placeholder="Select Mode"
              // className="h-100"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  // background: '#2d2f44',
                  // color: 'red!important',
                  boxShadow: 'none',
                  // border:
                  //   isSubmitting && !cls && !liveStream
                  //     ? '2px solid red!important'
                  //     : 'none',
                }),
              }}
            /> */}
            <FloatingLabel controlId="Notification_Type" label="Notification Type" className="fs-6 color-light-pink">
              <Form.Select
                className="bg-grey color-white"
                aria-label="Floating label select example"
                defaultValue="EVENT_DETAIL"
                name="modeStatus"
                onChange={(e) => {
                  // classOnChangeSelection(e)
                  console.log('Value', e.target.value, typeof e.target.value)
                  setIsTesting(e.target.value)
                }}
              >
                {/* <option value={0} disabled>
                    --Select--
                  </option> */}
                <option value>Testing</option>
                <option value={false}>Production</option>
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Row>

        <Row>
          <Col className="mb-4 d-flex justify-content-center">
            <Button className="btn-primary" onClick={handleSubmit}>
              {isSubmitting ? <Spinner animation="border" size="sm" /> : `${eventDate ? 'Schedule' : 'Send'} notification`}
            </Button>
          </Col>
        </Row>
      </Form>

      <Row className="mt-3 px-2">
        {showBasicAlert && responseStatus === true ? (
          <Alert variant="success" onClose={() => setShowBasicAlert(false)} dismissible className="rounded">
            {responseMessage}
          </Alert>
        ) : null}
        {showBasicAlert && responseStatus === false ? (
          <Alert variant="danger" onClose={() => setShowBasicAlert(false)} dismissible className="rounded">
            {responseMessage}
          </Alert>
        ) : null}
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Notification</Modal.Title>
        </Modal.Header>

        <Modal.Body>Are you sure you want to Cancel Notification ?</Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onCancelNotification}>
            Cancel
          </Button>

          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
})
