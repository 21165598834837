import React, { useCallback } from 'react'
import TextField from '@mui/material/TextField'
import { validate } from '../../../../utils/validators'
import '../styles/mui-styles.css'

const Index = ({
  id,
  label,
  name,
  value,
  type = 'text',
  className = '',
  errorClass = null,
  error = false,
  errorMessage = '',
  required = false,
  onChange = () => {},
  validators = [],
  multiline = false,
  rows = 1,
}) => {
  const onChangeHandler = useCallback(
    (e) => {
      console.log('Value', e)
      const v = e.target.value
      const isValid = validate(v, validators)
      onChange(id, v, isValid)
    },
    [id, onChange, validators]
  )

  const handlePaste = (event) => {
    event.preventDefault()

    console.log('Pasted value', event.clipboardData.getData('text'))
    // Get the pasted text from the clipboard
    const clipboardData = event.clipboardData.getData('text')

    // Remove double quotes and empty spaces
    const cleanedText = clipboardData.replace(/"| /g, '')

    // Insert the cleaned text at the cursor position
    const input = event.target
    const start = input.selectionStart || 0
    const end = input.selectionEnd || 0
    const newValue = input.value.slice(0, start) + cleanedText + input.value.slice(end)

    // Update the input value with the cleaned text
    input.value = newValue

    const v = newValue
    const isValid = validate(v, validators)
    onChange(id, newValue, isValid)
  }

  return (
    // <Tooltip title={errorMessage} arrow>
    <TextField
      fullWidth
      className={className + ' dummy'}
      FormHelperTextProps={{
        className: errorClass,
      }}
      sx={{
        fieldset: {
          borderColor: error ? '#d32f2f !important' : 'rgb(255 255 255 / 41%) !important',
        },
        marginBottom: '15px',

        label: {
          color: error ? '#d32f2f !important' : '',
        },
      }}
      onPaste={handlePaste}
      multiline={multiline}
      rows={rows}
      type={type}
      required={required}
      id={id}
      name={name}
      value={value}
      onChange={onChangeHandler}
      label={label}
      error={error}
      helperText={
        error && (
          <>
            <i className="fa fa-exclamation-triangle"></i> {errorMessage}
          </>
        )
      }
    />
  )
}

export default Index
