import axios from 'axios'
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import moment from 'moment'
import {
    getVideoData
} from '../../utils/config/API'
import './styles.css'
import withLoginRequired from '../../HOC/LoginModal';

export default withLoginRequired(() => {
    const utcAdd = '+0530'
    
    const date1 = new Date()
    const date2 = new Date()
    // const FromDate = date1.getDate()-30
    date1.setDate(date1.getDate() - 30)

    date2.setDate(date2.getDate() - 1)
    const day11 = new Date(date2.toLocaleDateString('en-CA'))
    const day22 = new Date(date1.toLocaleDateString('en-CA'))
    const DifferenceInTime1 = day11.getTime() - day22.getTime();
    console.log("diff-->", DifferenceInTime1)
    const DifferenceInDays1 = DifferenceInTime1 / (1000 * 3600 * 24);
    console.log(DifferenceInDays1)
    const someDate1 = new Date(date1.toLocaleDateString('en-CA'));
    someDate1.setDate(someDate1.getDate() - DifferenceInDays1);
    const [VideoData, setVideoData] = useState(null)
    const [DateFrom, setDateFrom] = useState(date1.toLocaleDateString('en-CA'))
    const [DateTo, setDateTo] = useState(date2.toLocaleDateString('en-CA'))
    const [PrevDateFrom, PrevsetDateFrom] = useState(someDate1.toLocaleDateString('en-CA'))

    useEffect(() => {
        const day1 = new Date(DateTo)
        const day2 = new Date(DateFrom)
        const DifferenceInTime = day1.getTime() - day2.getTime();
        console.log("diff-->", DifferenceInTime)
        const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
        console.log(DifferenceInDays)
        const someDate = new Date(DateFrom);
        someDate.setDate(someDate.getDate() - DifferenceInDays);
        PrevsetDateFrom(someDate.toLocaleDateString('en-CA'))
        console.log("added->", someDate.toLocaleDateString('en-CA'))
        return null;
    }, [DateTo, DateFrom])

    const onSubmitHandler = async () => {
        axios
        .post(getVideoData, { grades: [], fromDate: DateFrom, toDate: DateTo })
        .then((e) => {

            setVideoData(e.data.message)
            // const b = []
            // e.data.message.forEach((event, index) => {
            //     if (index % 3 === 0) {
            //         b.push(event)
            //     }
            // })
            // setbatch([...b])
            console.log("b is", e.data.message)
            // setnoOfCalls(noOfCalls-1)
        })
        .catch((error) => {
            //   console.log(error)
        })
    }

     useEffect(()=>{
        onSubmitHandler()
        return () => {
            // cleanup
        }
    },[DateTo,  DateFrom])


  return (
        <Container fluid className="w-100">
            <Row className="mt-5 mb-5 p-4" style={{
                background: '#2d2f44'
                }}>
                <Col>
                    <Form>
                    <Row className="mb-3">
                        <Col>
                            <h3 className="title">
                                Individual Stats
                            </h3>
                        </Col>
                        <Col>
                            {/* <Button className="float-right">Sort AZ</Button> */}
                        </Col>
                     </Row>
                    {/* <Row>
                            <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 tile-main-box">
                                <Row>
                                    <Col className="tile-img-sec">
                                        <img src="brown_test.webp" alt="test" style={{
                                            opacity: '0.4'
                                        }} />
                                    </Col>
                                </Row>
                                <Row className="tile-text-box">
                                    <Col className="">
                                        <Row>
                                            <Col style={{
                                                marginTop: '10px',
                                            }}>
                                                <span style={{
                                                    background: '#c83232',
                                                    padding: '2px 8px',
                                                    textAlign: 'left',
                                                    marginLeft: '-25px',
                                                    marginTop: '15px',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    letterSpacing: '.12em',
                                                    lineHeight: 'normal',
                                                    textTransform: 'uppercase',
                                                    borderRadius: '4px',
                                                    transition: 'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                                    verticalAlign: 'middle',
                                                    border: '0',
                                                    fontFamily: 'Lato,Helvetica,Arial,sans-serif!important'
                                                }}>LIVE STREAM</span>
                                            </Col>
                                            <Col>
                                                <span style={{
                                                    background: '#ffffff',
                                                    padding: '2px 5px',
                                                    color: '#000000',
                                                    textAlign: 'left',
                                                    float: 'right',
                                                    marginTop: '10px',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    letterSpacing: '.12em',
                                                    lineHeight: 'normal',
                                                    textTransform: 'uppercase',
                                                    borderRadius: '4px',
                                                    transition: 'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                                    verticalAlign: 'middle',
                                                    border: '0',
                                                    fontFamily: 'Lato,Helvetica,Arial,sans-serif!important'
                                                }}>PREMIUM</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <h2 style={{
                                                            fontSize: '27px',
                                                            marginTop: '10px',
                                                            lineHeight: '30px',
                                                            textTransform: 'uppercase',
                                                            fontWeight: '800',
                                                            color: '#ffffff',
                                                            opacity: '.8'
                                                        }}>APPLIED MATH AT HARVARD</h2>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h4 style={{
                                                            fontSize: '24px',
                                                            fontWeight: '600',
                                                            letterSpacing: '-1px',
                                                            lineHeight: '1.2',
                                                            color: '#e6a851'
                                                        }}>Saturday, May 07</h4>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h6 style={{
                                                            fontWeight: '800',
                                                            color: '#fff',
                                                            opacity: '.8',
                                                            lineHeight: '1',
                                                            letterSpacing: '0',
                                                            fontSize: '17px',
                                                            marginTop: '1%'
                                                        }}>07:30 PM</h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Button style={{
                                                            marginTop: '3%',
                                                            background: '#c83232',
                                                            padding: '5px 10px',
                                                            borderRadius: '4px',
                                                            border: 'none',
                                                            color: '#fff',
                                                            fontSize: '14px',
                                                            letterSpacing: '.03rem',
                                                            fontWeight: '500'
                                                        }}>Notify Me</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 tile-main-box">
                                <Row>
                                    <Col className="tile-img-sec">
                                        <img src="brown_test.webp" alt="test" style={{
                                            opacity: '0.4'
                                        }} />
                                    </Col>
                                </Row>
                                <Row className="tile-text-box">
                                    <Col className="">
                                        <Row>
                                            <Col style={{
                                                marginTop: '10px',
                                            }}>
                                                <span style={{
                                                    background: '#c83232',
                                                    padding: '2px 8px',
                                                    textAlign: 'left',
                                                    marginLeft: '-25px',
                                                    marginTop: '15px',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    letterSpacing: '.12em',
                                                    lineHeight: 'normal',
                                                    textTransform: 'uppercase',
                                                    borderRadius: '4px',
                                                    transition: 'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                                    verticalAlign: 'middle',
                                                    border: '0',
                                                    fontFamily: 'Lato,Helvetica,Arial,sans-serif!important'
                                                }}>LIVE STREAM</span>
                                            </Col>
                                            <Col>
                                                <span style={{
                                                    background: '#ffffff',
                                                    padding: '2px 5px',
                                                    color: '#000000',
                                                    textAlign: 'left',
                                                    float: 'right',
                                                    marginTop: '10px',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    letterSpacing: '.12em',
                                                    lineHeight: 'normal',
                                                    textTransform: 'uppercase',
                                                    borderRadius: '4px',
                                                    transition: 'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                                    verticalAlign: 'middle',
                                                    border: '0',
                                                    fontFamily: 'Lato,Helvetica,Arial,sans-serif!important'
                                                }}>PREMIUM</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <h2 style={{
                                                            fontSize: '27px',
                                                            marginTop: '10px',
                                                            lineHeight: '30px',
                                                            textTransform: 'uppercase',
                                                            fontWeight: '800',
                                                            color: '#ffffff',
                                                            opacity: '.8'
                                                        }}>APPLIED MATH AT HARVARD</h2>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h4 style={{
                                                            fontSize: '24px',
                                                            fontWeight: '600',
                                                            letterSpacing: '-1px',
                                                            lineHeight: '1.2',
                                                            color: '#e6a851'
                                                        }}>Saturday, May 07</h4>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h6 style={{
                                                            fontWeight: '800',
                                                            color: '#fff',
                                                            opacity: '.8',
                                                            lineHeight: '1',
                                                            letterSpacing: '0',
                                                            fontSize: '17px',
                                                            marginTop: '1%'
                                                        }}>07:30 PM</h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Button style={{
                                                            marginTop: '3%',
                                                            background: '#c83232',
                                                            padding: '5px 10px',
                                                            borderRadius: '4px',
                                                            border: 'none',
                                                            color: '#fff',
                                                            fontSize: '14px',
                                                            letterSpacing: '.03rem',
                                                            fontWeight: '500'
                                                        }}>Notify Me</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 tile-main-box">
                                <Row>
                                    <Col className="tile-img-sec">
                                        <img src="brown_test.webp" alt="test" style={{
                                            opacity: '0.4'
                                        }} />
                                    </Col>
                                </Row>
                                <Row className="tile-text-box">
                                    <Col className="">
                                        <Row>
                                            <Col style={{
                                                marginTop: '10px',
                                            }}>
                                                <span style={{
                                                    background: '#c83232',
                                                    padding: '2px 8px',
                                                    textAlign: 'left',
                                                    marginLeft: '-25px',
                                                    marginTop: '15px',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    letterSpacing: '.12em',
                                                    lineHeight: 'normal',
                                                    textTransform: 'uppercase',
                                                    borderRadius: '4px',
                                                    transition: 'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                                    verticalAlign: 'middle',
                                                    border: '0',
                                                    fontFamily: 'Lato,Helvetica,Arial,sans-serif!important'
                                                }}>LIVE STREAM</span>
                                            </Col>
                                            <Col>
                                                <span style={{
                                                    background: '#ffffff',
                                                    padding: '2px 5px',
                                                    color: '#000000',
                                                    textAlign: 'left',
                                                    float: 'right',
                                                    marginTop: '10px',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    letterSpacing: '.12em',
                                                    lineHeight: 'normal',
                                                    textTransform: 'uppercase',
                                                    borderRadius: '4px',
                                                    transition: 'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                                    verticalAlign: 'middle',
                                                    border: '0',
                                                    fontFamily: 'Lato,Helvetica,Arial,sans-serif!important'
                                                }}>PREMIUM</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <h2 style={{
                                                            fontSize: '27px',
                                                            marginTop: '10px',
                                                            lineHeight: '30px',
                                                            textTransform: 'uppercase',
                                                            fontWeight: '800',
                                                            color: '#ffffff',
                                                            opacity: '.8'
                                                        }}>APPLIED MATH AT HARVARD</h2>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h4 style={{
                                                            fontSize: '24px',
                                                            fontWeight: '600',
                                                            letterSpacing: '-1px',
                                                            lineHeight: '1.2',
                                                            color: '#e6a851'
                                                        }}>Saturday, May 07</h4>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h6 style={{
                                                            fontWeight: '800',
                                                            color: '#fff',
                                                            opacity: '.8',
                                                            lineHeight: '1',
                                                            letterSpacing: '0',
                                                            fontSize: '17px',
                                                            marginTop: '1%'
                                                        }}>07:30 PM</h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Button style={{
                                                            marginTop: '3%',
                                                            background: '#c83232',
                                                            padding: '5px 10px',
                                                            borderRadius: '4px',
                                                            border: 'none',
                                                            color: '#fff',
                                                            fontSize: '14px',
                                                            letterSpacing: '.03rem',
                                                            fontWeight: '500'
                                                        }}>Notify Me</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="tile-detail">
                                <p>Total No. of views -  </p>         
                                <p>No. of unique views - </p>
                                <p>Viewed on iOS - 800 times </p>
                                <p>Viewed on Android - 1200 times </p>
                                <p>Viewed on website - 240 times </p>
                                <p>Total Minutes consumed on iOS  - 200 mins </p>
                                <p>Total Minutes consumed on Android - 33 mins </p>
                                <p>Total Minutes consumed on website -  120 mins </p>
                                <p>Consumption Pattern (Total time) </p>
                            </Col>
                        </Row> */}
                         <div>
                            {
                                VideoData ? VideoData.map((event, index) => {
                                    // if(index % 3 === 0)
                                    // {
                                    return (index % 3 === 0 ?
                                        <div>
                                            <Row>
                                                <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 tile-main-box"
                                                 onClick={() => {
                                                    // const a = batch
                                                    // a[Math.floor(index / 3)] = VideoData[index]
                                                    // setbatch([...a])
                                                }}
                                                >
                                                    <Row
                                                       >
                                                        <Col className="tile-img-sec">
                                                            <img src="brown_test.webp" alt="test" style={{
                                                                opacity: '0.4'
                                                            }} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="tile-text-box">
                                                        <Col className="">
                                                            <Row

                                                            >
                                                                <Col style={{
                                                                    marginTop: '10px',
                                                                }}>
                                                                    <span style={{
                                                                        background: '#c83232',
                                                                        padding: '2px 8px',
                                                                        textAlign: 'left',
                                                                        marginLeft: '-25px',
                                                                        marginTop: '15px',
                                                                        fontSize: '14px',
                                                                        fontWeight: '600',
                                                                        letterSpacing: '.12em',
                                                                        lineHeight: 'normal',
                                                                        textTransform: 'uppercase',
                                                                        borderRadius: '4px',
                                                                        transition: 'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                                                        verticalAlign: 'middle',
                                                                        border: '0',
                                                                        fontFamily: 'Lato,Helvetica,Arial,sans-serif!important'
                                                                    }}>{VideoData[index].EVENT_TYPE}</span>
                                                                </Col>
                                                                <Col>
                                                                    <span style={{
                                                                        background: '#ffffff',
                                                                        padding: '2px 5px',
                                                                        color: '#000000',
                                                                        textAlign: 'left',
                                                                        float: 'right',
                                                                        marginTop: '10px',
                                                                        fontSize: '14px',
                                                                        fontWeight: '600',
                                                                        letterSpacing: '.12em',
                                                                        lineHeight: 'normal',
                                                                        textTransform: 'uppercase',
                                                                        borderRadius: '4px',
                                                                        transition: 'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                                                        verticalAlign: 'middle',
                                                                        border: '0',
                                                                        fontFamily: 'Lato,Helvetica,Arial,sans-serif!important'
                                                                    }}>{VideoData[index].premium >= 3 ? "PREMIUM" : VideoData[index].premium === 2 ? "PLUS" : "PLUS"}</span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Row>
                                                                        <Col>
                                                                            <h2 style={{
                                                                                fontSize: '27px',
                                                                                marginTop: '10px',
                                                                                lineHeight: '30px',
                                                                                textTransform: 'uppercase',
                                                                                fontWeight: '800',
                                                                                color: '#ffffff',
                                                                                opacity: '.8'
                                                                            }}>{VideoData[index].EVENT}</h2>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <h4 style={{
                                                                                fontSize: '24px',
                                                                                fontWeight: '600',
                                                                                letterSpacing: '-1px',
                                                                                lineHeight: '1.2',
                                                                                color: '#e6a851'
                                                                            }}>{moment
                                                                                .utc(VideoData[index].date_time)
                                                                                .utcOffset(utcAdd)
                                                                                .format('dddd, MMMM DD YYYY')}</h4>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <h6 style={{
                                                                                fontWeight: '800',
                                                                                color: '#fff',
                                                                                opacity: '.8',
                                                                                lineHeight: '1',
                                                                                letterSpacing: '0',
                                                                                fontSize: '17px',
                                                                                marginTop: '1%'
                                                                            }}>{moment
                                                                                .utc(
                                                                                    VideoData[index].date_time

                                                                                )
                                                                                .utcOffset(utcAdd)
                                                                                .format('hh:mm A')}</h6>
                                                                        </Col>
                                                                    </Row>

                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 tile-main-box"
                                                    onClick={() => {
                                                        // const a = batch
                                                        // a[Math.floor(index / 3)] = VideoData[index + 1]
                                                        // setbatch([...a])
                                                    }}
                                                >
                                                    <Row

                                                    >
                                                        <Col className="tile-img-sec">
                                                            <img src="brown_test.webp" alt="test" style={{
                                                                opacity: '0.4'
                                                            }} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="tile-text-box">
                                                        <Col className="">
                                                            <Row>
                                                                <Col style={{
                                                                    marginTop: '10px',
                                                                }}>
                                                                    <span style={{
                                                                        background: '#c83232',
                                                                        padding: '2px 8px',
                                                                        textAlign: 'left',
                                                                        marginLeft: '-25px',
                                                                        marginTop: '15px',
                                                                        fontSize: '14px',
                                                                        fontWeight: '600',
                                                                        letterSpacing: '.12em',
                                                                        lineHeight: 'normal',
                                                                        textTransform: 'uppercase',
                                                                        borderRadius: '4px',
                                                                        transition: 'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                                                        verticalAlign: 'middle',
                                                                        border: '0',
                                                                        fontFamily: 'Lato,Helvetica,Arial,sans-serif!important'
                                                                    }}>{VideoData[index + 1]?.EVENT_TYPE}</span>
                                                                </Col>
                                                                <Col>
                                                                    <span style={{
                                                                        background: '#ffffff',
                                                                        padding: '2px 5px',
                                                                        color: '#000000',
                                                                        textAlign: 'left',
                                                                        float: 'right',
                                                                        marginTop: '10px',
                                                                        fontSize: '14px',
                                                                        fontWeight: '600',
                                                                        letterSpacing: '.12em',
                                                                        lineHeight: 'normal',
                                                                        textTransform: 'uppercase',
                                                                        borderRadius: '4px',
                                                                        transition: 'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                                                        verticalAlign: 'middle',
                                                                        border: '0',
                                                                        fontFamily: 'Lato,Helvetica,Arial,sans-serif!important'
                                                                    }}>{VideoData[index + 1]?.premium >= 3 ? "PREMIUM" : VideoData[index + 1]?.premium === 2 ? "PLUS" : "PLUS"}</span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Row>
                                                                        <Col>
                                                                            <h2 style={{
                                                                                fontSize: '27px',
                                                                                marginTop: '10px',
                                                                                lineHeight: '30px',
                                                                                textTransform: 'uppercase',
                                                                                fontWeight: '800',
                                                                                color: '#ffffff',
                                                                                opacity: '.8'
                                                                            }}>{VideoData[index + 1]?.EVENT}</h2>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <h4 style={{
                                                                                fontSize: '24px',
                                                                                fontWeight: '600',
                                                                                letterSpacing: '-1px',
                                                                                lineHeight: '1.2',
                                                                                color: '#e6a851'
                                                                            }}>{moment
                                                                                .utc(VideoData[index + 1]?.date_time)
                                                                                .utcOffset(utcAdd)
                                                                                .format('dddd, MMMM DD YYYY')}</h4>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <h6 style={{
                                                                                fontWeight: '800',
                                                                                color: '#fff',
                                                                                opacity: '.8',
                                                                                lineHeight: '1',
                                                                                letterSpacing: '0',
                                                                                fontSize: '17px',
                                                                                marginTop: '1%'
                                                                            }}>{moment
                                                                                .utc(
                                                                                    VideoData[index + 1]?.date_time

                                                                                )
                                                                                .utcOffset(utcAdd)
                                                                                .format('hh:mm A')}</h6>
                                                                        </Col>
                                                                    </Row>

                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 tile-main-box"
                                                    onClick={() => {
                                                        // const a = batch
                                                        // a[Math.floor(index / 3)] = VideoData[index + 2]
                                                        // setbatch([...a])
                                                    }}
                                                >
                                                    <Row>
                                                        <Col className="tile-img-sec">
                                                            <img src="brown_test.webp" alt="test" style={{
                                                                opacity: '0.4'
                                                            }} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="tile-text-box">
                                                        <Col className="">
                                                            <Row>
                                                                <Col style={{
                                                                    marginTop: '10px',
                                                                }}>
                                                                    <span style={{
                                                                        background: '#c83232',
                                                                        padding: '2px 8px',
                                                                        textAlign: 'left',
                                                                        marginLeft: '-25px',
                                                                        marginTop: '15px',
                                                                        fontSize: '14px',
                                                                        fontWeight: '600',
                                                                        letterSpacing: '.12em',
                                                                        lineHeight: 'normal',
                                                                        textTransform: 'uppercase',
                                                                        borderRadius: '4px',
                                                                        transition: 'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                                                        verticalAlign: 'middle',
                                                                        border: '0',
                                                                        fontFamily: 'Lato,Helvetica,Arial,sans-serif!important'
                                                                    }}>{VideoData[index + 2]?.EVENT_TYPE}</span>
                                                                </Col>
                                                                <Col>
                                                                    <span style={{
                                                                        background: '#ffffff',
                                                                        padding: '2px 5px',
                                                                        color: '#000000',
                                                                        textAlign: 'left',
                                                                        float: 'right',
                                                                        marginTop: '10px',
                                                                        fontSize: '14px',
                                                                        fontWeight: '600',
                                                                        letterSpacing: '.12em',
                                                                        lineHeight: 'normal',
                                                                        textTransform: 'uppercase',
                                                                        borderRadius: '4px',
                                                                        transition: 'background .25s ease,box-shadow .25s ease,opacity .25s ease',
                                                                        verticalAlign: 'middle',
                                                                        border: '0',
                                                                        fontFamily: 'Lato,Helvetica,Arial,sans-serif!important'
                                                                    }}>{VideoData[index + 2]?.premium >= 3 ? "PREMIUM" : VideoData[index + 2]?.premium === 2 ? "PLUS" : "PLUS"}</span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Row>
                                                                        <Col>
                                                                            <h2 style={{
                                                                                fontSize: '27px',
                                                                                marginTop: '10px',
                                                                                lineHeight: '30px',
                                                                                textTransform: 'uppercase',
                                                                                fontWeight: '800',
                                                                                color: '#ffffff',
                                                                                opacity: '.8'
                                                                            }}>{VideoData[index + 2]?.EVENT}</h2>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <h4 style={{
                                                                                fontSize: '24px',
                                                                                fontWeight: '600',
                                                                                letterSpacing: '-1px',
                                                                                lineHeight: '1.2',
                                                                                color: '#e6a851'
                                                                            }}>{moment
                                                                                .utc(VideoData[index + 2]?.date_time)
                                                                                .utcOffset(utcAdd)
                                                                                .format('dddd, MMMM DD YYYY')}</h4>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <h6 style={{
                                                                                fontWeight: '800',
                                                                                color: '#fff',
                                                                                opacity: '.8',
                                                                                lineHeight: '1',
                                                                                letterSpacing: '0',
                                                                                fontSize: '17px',
                                                                                marginTop: '1%'
                                                                            }}>{moment
                                                                                .utc(
                                                                                    VideoData[index + 2]?.date_time

                                                                                )
                                                                                .utcOffset(utcAdd)
                                                                                .format('hh:mm A')}</h6>
                                                                        </Col>
                                                                    </Row>

                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {/* <Col className="tile-detail">
                                                    <p>Event -  {batch.length > Math.floor(index / 3) ? batch[Math.floor(index / 3)].EVENT : null} </p>
                                                    <p>Total No. of views -  {batch.length > Math.floor(index / 3) ? batch[Math.floor(index / 3)].views : null} </p>
                                                    <p>No. of unique views - {batch.length > Math.floor(index / 3) ? batch[Math.floor(index / 3)].uniqueViews : null} </p>
                                                    <p>Total Minutes consumed - {batch.length > Math.floor(index / 3) ? Math.round(batch[Math.floor(index / 3)].Minutes) : null} mins</p>
                                                    
                                                </Col> */}

                                                {/* <p>Viewed on iOS - 800 times </p> */}
                                                    {/* <p>Viewed on Android - 1200 times </p> */}
                                                    {/* <p>Viewed on website - 240 times </p> */}
                                                    {/* <p>Total Minutes consumed on iOS  - 200 mins </p> */}
                                                    {/* <p>Total Minutes consumed on Android - 33 mins </p> */}
                                                    {/* <p>Total Minutes consumed on website -  120 mins </p> */}
                                                    {/* <p>Consumption Pattern (Total time) </p> */}
                                                <Col className="tile-detail">
                                                    <p>Event -  {VideoData[index]?VideoData[index].EVENT +  VideoData[index].EVENT_ID: null} </p>
                                                    <p>Total No. of views -  {VideoData[index] ? VideoData[index].views : null} </p>
                                                    <p>No. of unique views - {VideoData[index] ? VideoData[index].uniqueViews : null} </p>
                                                    <p>Total Minutes consumed - {VideoData[index] ? Math.round(VideoData[index].Minutes) : null} mins</p>
                                                     <p>iOS Views - 800 times </p>
                                                    <p>Android Views - 1200 times </p>
                                                    <p>website Views - 240 times </p>
                                                    <p>TMC on iOS  - 200 mins </p>
                                                    <p>TMC on Android - 33 mins </p>
                                                    <p>TMC on website -  120 mins </p>

                                                </Col>
                                                <Col className="tile-detail">
                                                    <p>Event -  {VideoData[index+1]?VideoData[index+1].EVENT : null} </p>
                                                    <p>Total No. of views -  {VideoData[index+1] ? VideoData[index+1].views : null} </p>
                                                    <p>No. of unique views - {VideoData[index+1] ? VideoData[index+1].uniqueViews : null} </p>
                                                    <p>Total Minutes consumed - {VideoData[index+1] ? Math.round(VideoData[index+1].Minutes) : null} mins</p>
                                                    <p>iOS Views - 800 times </p>
                                                    <p>Android Views - 1200 times </p>
                                                    <p>website Views - 240 times </p>
                                                    <p>TMC on iOS  - 200 mins </p>
                                                    <p>TMC on Android - 33 mins </p>
                                                    <p>TMC on website -  120 mins </p>
                                                </Col>
                                                <Col className="tile-detail">
                                                    <p>Event -  {VideoData[index+2]?VideoData[index+2].EVENT : null} </p>
                                                    <p>Total No. of views -  {VideoData[index+2] ? VideoData[index+2].views : null} </p>
                                                    <p>No. of unique views - {VideoData[index+2] ? VideoData[index+2].uniqueViews : null} </p>
                                                    <p>Total Minutes consumed - {VideoData[index+2] ? Math.round(VideoData[index+2].Minutes) : null} mins</p>
                                                    <p>iOS Views - 800 times </p>
                                                    <p>Android Views - 1200 times </p>
                                                    <p>website Views - 240 times </p>
                                                    <p>TMC on iOS  - 200 mins </p>
                                                    <p>TMC on Android - 33 mins </p>
                                                    <p>TMC on website -  120 mins </p>
                                                </Col>
                                                
                                            </Row>
                                        </div>
                                        :
                                        null
                                    )

                                    // }
                                }) : null
                                // else{
                                //     return ()
                                // }

                            }

                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
  )
})
