import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FloatingLabel,
  Card,
  Alert,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FormData from 'form-data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import moment from 'moment'
import { APIGetEventUserDetails } from '../../utils/config/API'
import EventData from '../../components/EventData/EventData'
import withLoginRequired from '../../HOC/LoginModal'

export default withLoginRequired(() => {
  const [eventId, setEventId] = useState('')
  const [eventDate, setEventDate] = useState(new Date())
  const [eventDetails, setEventDetails] = useState([])
  const [getEvent, setGetEvent] = useState(false)

  const [showBasicAlert, setShowBasicAlert] = useState(false)
  const [responseStatus, setresponseStatus] = useState(false)
  const [responseMessage, setresponseMessage] = useState('')

  const onEventIdChange = (e) => {
    const id = +e.target.value
    if (Number.isNaN(id) && e.target.value) {
      // return
    } else if (!e.target.value) {
      setEventId(e.target.value)
    } else {
      setEventId(id)
    }
  }

  const handleSubmit = async () => {
    const data = new FormData()
    try {
      console.log('trying')
      const headers = {
        Authorization: `Bearer ${localStorage.token}`,
      }
      console.log('UTC', eventDate)
      // const insertResponse = await fetch(APIGetEventUserDetails)
      const getDetails = await axios.post(
        `${APIGetEventUserDetails}`,
        {
          eventId,
          eventDate: moment(eventDate).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          headers,
        }
      )

      setEventDetails(getDetails.data.data)
      setGetEvent(true)
      if (getDetails.status === 200) {
        // setresponseStatus(true)
        // setresponseMessage(insertResponse.message)
        // console.log('setting sucess message', responseMessage)
      } else {
        setresponseStatus(false)
        setresponseMessage(getDetails.data.message || 'Something went wrong')
        setShowBasicAlert(true)
        console.log('setting unsucessful message', responseMessage)
      }
      // console.log(insertResponse)
    } catch (err) {
      console.log('Error ----------------->', err)
      setresponseStatus(false)
      setresponseMessage('Something went wrong')
      setShowBasicAlert(true)
    }
  }
  return (
    <Container fluid className="w-100">
      <Row className="mb-3">
        <h3 className="title">
          <Link to="/notifications">
            <i className="bi bi-arrow-left"></i>
          </Link>
          PHYSICAL EVENT DETAILS
        </h3>
      </Row>
      <Form>
        <Row>
          <Col className="mb-4">
            {/* <FloatingLabel
              controlId="eventId"
              label="Event ID"
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            > */}
            <Form.Label
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            >
              Event Id
            </Form.Label>
            <Form.Control
              // className="bg-grey color-white"
              type="text"
              placeholder="Event ID"
              // defaultValue={eventId}
              value={eventId}
              name="EventId"
              onChange={onEventIdChange}
            />
            {/* </FloatingLabel> */}
          </Col>
          <Col className="mb-4">
            {/* <FloatingLabel
              controlId="EventDate"
              label="Event Date"
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            > */}
            <Form.Label
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            >
              Event Date
            </Form.Label>
            <DatePicker
              className="form-control"
              showTimeSelect
              timeIntervals={15}
              selected={eventDate}
              onChange={(date) => setEventDate(date)}
              dateFormat="MMM dd, yy h:mm aa"
              showTimeInput
            />
            {/* </FloatingLabel> */}
          </Col>
        </Row>
        <Row>
          <Col className="mb-4">
            <Button
              className="btn-primary"
              style={{
                margin: 'auto!important',
              }}
              onClick={handleSubmit}
            >
              Get Details
            </Button>
          </Col>
        </Row>
        <EventData eventDetails={eventDetails} getEvent={getEvent} />
      </Form>

      <Row className="mt-3 px-2">
        {showBasicAlert && responseStatus === false ? (
          <Alert
            variant="danger"
            onClose={() => setShowBasicAlert(false)}
            dismissible
            className="rounded"
            style={{
              position: 'fixed',
              top: 1,
              // left: 0,
              right: 0,
              zIndex: 999,
              textAlign: 'center',
              width: '40%',
            }}
          >
            {responseMessage}
          </Alert>
        ) : null}
      </Row>
    </Container>
  )
})
