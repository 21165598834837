/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { Container, Row, Col, Form, Button, FloatingLabel, Card, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import moment from 'moment'
import { convertArrayToCSV } from 'convert-array-to-csv'
import { fetchDataById } from '../../actions/eventsActions'
import {
  getEventRegistrationData,
  getEventAttendeeData,
  updateEventDetails,
  insertEventDetails,
  updatePhysicalEventDetails,
  insertPhysicalEventDetails,
  getEventRegistrationDataV1,
} from '../../utils/config/API'
import withLoginRequired from '../../HOC/LoginModal'

const FormData = require('form-data')

export default withLoginRequired(() => {
  const dispatch = useDispatch()
  const eventId = useParams().id
  const [uploadObj, setUploadObj] = useState({
    event_id: '',
    event_name: '',
    event_desc: '',
    event_datetime: '',
    event_zoom_id: '',
    event_password: '',
    event_primeplus: '',
    event_type: 'PHYSICAL',
    event_isdeleted: '',
    event_banner: '',
    event_tile: '',
    event_premium_level: '1',
    event_grade: [],
    mobile_banner: '',
    img_banner: '',
    desktop_phone_banner: '',
    show_hp: 0,
    venue: '',
    share_img_url: '',
  })
  const [selectedEventTags, setSelectedEventTags] = useState([])
  const [selectedGrades, setSelectedGrades] = useState([])
  const [bannerImg, setBannerImg] = useState({ preview: '', raw: '' })

  const [mobileBanner, setMobileBanner] = useState({ preview: '', raw: '' })
  const [imgBanner, setImgBanner] = useState({ preview: '', raw: '' })
  const [desktopMobileImg, setDesktopMobileBanner] = useState({
    preview: '',
    raw: '',
  })

  const [shareImg, setShareBanner] = useState({
    preview: '',
    raw: '',
  })

  // const [bannerVid, setBannerVid] = useState({ preview: '', raw: '' })
  // const [bannerGIF, setBannerGIF] = useState({ preview: '', raw: '' })
  const [eventDateTime, setEventDateTime] = useState()
  const [grades, setgrades] = useState([])
  let { eventByIdData, eventTags } = useSelector((state) => state.eventById)

  const [isLoading, setIsloading] = useState(false)

  const options = [
    { value: '1', label: 'Grade 8' },
    { value: '2', label: 'Grade 9' },
    { value: '3', label: 'Grade 10' },
    { value: '4', label: 'Grade 11' },
    { value: '5', label: 'Grade 12' },
    { value: '6', label: 'Undergraduate' },
    { value: '7', label: 'In College' },
    { value: '8', label: 'Other' },
    { value: '9', label: 'Graduated' },
  ]
  const setSelectedOption = () => {}
  useEffect(() => {
    if (eventId) dispatch(fetchDataById(eventId))
  }, [dispatch, eventId])

  useEffect(() => {
    if (uploadObj.event_grade && Object.keys(uploadObj).length > 0) {
      console.log('event')
      let g = []
      options.forEach((ele) => {
        for (let i = 0; i < uploadObj.event_grade.length; ) {
          if (ele.value === uploadObj.event_grade[i]) {
            g.push(ele)
          }
          i += 1
        }
      })
      console.log('g is', g)
      setSelectedGrades(g)
    }
  }, [uploadObj])

  useEffect(() => {
    if (eventId) {
      console.log('-------------', eventByIdData)

      setUploadObj(eventByIdData)
      setSelectedEventTags(eventByIdData.eventTags)
      setEventDateTime(moment(eventByIdData?.event_datetime).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS'))
    }
    if (eventByIdData?.event_desc === 'null') setUploadObj({ ...uploadObj, event_desc: '' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventByIdData, eventTags, eventId])

  const handleEventTagsChecked = (e) => {
    if (e.target.checked)
      if (!selectedEventTags) setSelectedEventTags([e.target.value])
      else setSelectedEventTags([...selectedEventTags, e.target.value])
    else {
      let filteredArr = selectedEventTags.filter((tag) => tag !== e.target.value)
      setSelectedEventTags(filteredArr)
    }
  }

  const handleBannerChange = (e) => {
    if (e.target.files.length) {
      setBannerImg({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }

  const handleMobileBannerChange = (e) => {
    if (e.target.files.length) {
      setMobileBanner({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }
  const handleImgBannerChange = (e) => {
    if (e.target.files.length) {
      setImgBanner({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }
  const handleDesktopMobileBannerChange = (e) => {
    if (e.target.files.length) {
      setDesktopMobileBanner({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }

  const handleFormShareBannerChange = (e) => {
    if (e.target.files.length) {
      setShareBanner({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }
  // const handleVideoBannerChange = (e) => {
  //   if (e.target.files.length) {

  //     setBannerVid({
  //       preview: URL.createObjectURL(e.target.files[0]),
  //       raw: e.target.files[0],
  //     })
  //   }
  // }
  // const handleGIFBannerChange = (e) => {
  //   if (e.target.files.length) {

  //     setBannerGIF({
  //       preview: URL.createObjectURL(e.target.files[0]),
  //       raw: e.target.files[0],
  //     })
  //   }
  // }

  const handleEdit = async (e) => {
    // console.log("clicked")
    setIsloading(true)
    e.preventDefault()
    let data = new FormData()
    // eslint-disable-next-line guard-for-in

    if (uploadObj.event_id > 0) {
      data.append('event_id', uploadObj.event_id)
    }
    data.append('event_name', uploadObj.event_name)
    data.append('event_desc', uploadObj.event_desc)
    data.append('event_datetime', `${moment(eventDateTime).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS')}Z`)
    data.append('event_zoom_id', uploadObj.event_zoom_id.trim())
    data.append('event_password', uploadObj.event_password.trim())
    data.append('event_primeplus', 0)
    // data.append('event_type', uploadObj.event_type)
    data.append('event_type', 'PHYSICAL')
    data.append('event_isdeleted', 0)
    // data.append('event_banner', bannerImg.raw)
    // data.append('event_banner_vid', bannerVid.raw)
    // data.append('event_banner_gif', bannerGIF.raw)

    // data.append('event_mobile_banner', mobileBanner.raw)
    data.append('event_img_banner', imgBanner.raw)
    data.append('event_desktop_mobile_banner', desktopMobileImg.raw)

    data.append('event_share_img', shareImg.raw)

    data.append('event_tile', bannerImg.raw)
    data.append('event_premium_level', uploadObj.event_premium_level)
    data.append('event_premium_level', 0)
    // console.log(selectedEventTags)
    data.append('tags', !selectedEventTags ? [].join(',') : selectedEventTags)
    data.append('grades', selectedGrades.map((a) => a.value).join(','))
    data.append('show_hp', 0)
    data.append('venue', uploadObj.venue)
    console.log('data is', data)
    // for (const pair of data.entries()) {
    //   console.log(`${pair[0]}, ${pair[1]}`)
    // }
    //   for (let value of data.values()) {
    //     console.log(value);
    //  }
    // 'https://mvfdi3wy33.execute-api.ap-south-1.amazonaws.com/dev/api/v1/updateEventDetails',
    // 'https://mvfdi3wy33.execute-api.ap-south-1.amazonaws.com/dev/api/v1/insertEventDetails',
    try {
      if (uploadObj.event_id > 0) {
        // data.append('eventImageUrl', uploadObj.event_banner)
        // data.append('eventMobileImageUrl', uploadObj.mobile_banner)
        data.append('eventImgBannerURL', uploadObj.img_banner)
        data.append('eventDesktopMobileBannerURL', uploadObj.desktop_phone_banner)
        data.append('eventShareImageURL', uploadObj.share_img_url)

        const updateResponse = await fetch(updatePhysicalEventDetails, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
          body: data,
        })

        if (updateResponse.status === 200) {
          window.open('/physical-events-list', '_self')
          setIsloading(false)
        } else {
          setIsloading(false)
        }
      } else {
        // console.log("inserting")
        const insertResponse = await fetch(insertPhysicalEventDetails, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
          body: data,
        })
        if (insertResponse.status === 200) {
          window.open('/physical-events-list', '_self')
          setIsloading(false)
        } else {
          setIsloading(false)
        }
      }
    } catch (error) {
      setIsloading(false)

      console.error(error)
    }
  }

  const fetchEventRegistrationData = async () => {
    // Axios.get(getEventRegistrationData + eventId, {
    Axios.get(getEventRegistrationDataV1 + eventId, {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
      },
    }).then((e) => {
      const csvFromArrayOfObjects = convertArrayToCSV(
        e.data.body.map((item) => {
          return {
            ...item,
            PHONE: item.PHONE ? '+'.concat(item.PHONE.replace('+').concat('\t').replace('undefined', '')) : null,
          }
        })
      )
      let csvContent = `data:text/csv;charset=utf-8,${csvFromArrayOfObjects}`
      const encodedUri = encodeURI(csvContent)
      const downloadLink = document.createElement('a')
      downloadLink.href = encodedUri
      downloadLink.download = `registration_event_${eventId}.csv`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    })
  }

  return (
    <Container fluid className="w-100">
      <Row className="mb-3">
        <h3 className="title">
          <Link to="/physical-events-list">
            <i className="bi bi-arrow-left"></i>
          </Link>
          {eventId ? 'EDIT EVENT' : 'ADD EVENT'}
        </h3>
      </Row>

      <Row className="mb-3">
        <Form>
          <Row>
            <Col className="mb-4">
              <FloatingLabel
                controlId="eventName"
                label="Event Name"
                className={uploadObj?.event_name ? 'fs-6 color-light-pink' : 'color-light-pink'}
              >
                <Form.Control
                  className="bg-grey color-white"
                  type="text"
                  placeholder="Event Name"
                  defaultValue={uploadObj?.event_name}
                  name="eventName"
                  onChange={(e) =>
                    setUploadObj({
                      ...uploadObj,
                      event_name: e.target.value.toUpperCase(),
                    })
                  }
                />
              </FloatingLabel>
            </Col>

            <Col className="mb-4">
              <FloatingLabel controlId="eventDate" label="Event Date" className="fs-6 color-light-pink">
                <Form.Control
                  className="bg-grey color-white"
                  format="YYYY-MM-DDTHH:mm:ss.SSS"
                  type="datetime-local"
                  placeholder="Event Date"
                  value={eventDateTime}
                  name="eventDate"
                  onChange={(e) => {
                    setEventDateTime(e.target.value)
                  }}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col className="mb-4">
              <FloatingLabel
                controlId="eventDesc"
                label="Event Description"
                className={uploadObj?.event_desc ? 'fs-6 color-light-pink' : 'color-light-pink'}
              >
                <Form.Control
                  className="bg-grey color-white"
                  as="textarea"
                  placeholder="Event Description"
                  defaultValue={uploadObj?.event_desc}
                  name="eventDesc"
                  onChange={(e) => {
                    setUploadObj({
                      ...uploadObj,
                      event_desc: e.target.value !== 'null' ? e.target.value : '',
                    })
                  }}
                  style={{
                    height: 'max-content',
                    paddingTop: '2rem',
                    paddingBottom: '2rem',
                  }}
                />
              </FloatingLabel>
            </Col>
          </Row>

          <Row>
            <Col className="mb-4">
              <FloatingLabel
                controlId="zoomId"
                label="Zoom Id"
                className={uploadObj?.event_zoom_id ? 'fs-6 color-light-pink' : 'color-light-pink'}
              >
                <Form.Control
                  className="bg-grey color-white"
                  type="text"
                  placeholder="Zoom Id"
                  defaultValue={uploadObj?.event_zoom_id}
                  name="zoomId"
                  onChange={(e) =>
                    setUploadObj({
                      ...uploadObj,
                      event_zoom_id: e.target.value,
                    })
                  }
                />
              </FloatingLabel>
            </Col>
            <Col className="mb-4">
              <FloatingLabel
                controlId="zoomPassword"
                label="Zoom Password"
                className={uploadObj?.event_password ? 'fs-6 color-light-pink' : 'color-light-pink'}
              >
                <Form.Control
                  className="bg-grey color-white"
                  type="text"
                  placeholder="Zoom Password"
                  defaultValue={uploadObj?.event_password}
                  name="zoomPassword"
                  onChange={(e) =>
                    setUploadObj({
                      ...uploadObj,
                      event_password: e.target.value,
                    })
                  }
                />
              </FloatingLabel>
            </Col>
          </Row>

          <Row>
            <Col className="mb-4">
              <FloatingLabel
                controlId="Venue"
                label="Venue"
                className={uploadObj?.venue ? 'fs-6 color-light-pink' : 'color-light-pink'}
              >
                <Form.Control
                  className="bg-grey color-white"
                  type="text"
                  placeholder="Venue"
                  defaultValue={uploadObj?.venue}
                  name="Venue"
                  onChange={(e) =>
                    setUploadObj({
                      ...uploadObj,
                      venue: e.target.value,
                    })
                  }
                />
              </FloatingLabel>
            </Col>
            <Col className="mb-4 h-100">
              <FloatingLabel
                controlId="premiumLevel"
                label="Event Type"
                className={uploadObj?.event_premium_level ? 'fs-6 color-light-pink' : 'color-light-pink'}
              >
                <Form.Select
                  className="bg-grey color-white"
                  aria-label="Floating label select example"
                  value={uploadObj?.event_premium_level}
                  name="premiumLevel"
                  onChange={(e) =>
                    setUploadObj({
                      ...uploadObj,
                      event_premium_level: e.target.value,
                    })
                  }
                >
                  <option>--Event Type--</option>
                  <option value="1">Free</option>
                  <option value="2">Paid</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col className="mb-4" label="Grades">
              <Select
                key="grades"
                id="grades"
                value={selectedGrades}
                onChange={(selected) => {
                  // console.log(selectedGrades)
                  setSelectedGrades(selected)
                }}
                options={options}
                isMulti
                placeholder="Grades"
              ></Select>
            </Col>
          </Row>

          <Row className="mh-100">
            <Col className="mb-4 mh-100">
              <Card className="bg-grey color-white p-3 h-100">
                <Form.Label className="fs-6 color-light-pink">
                  Image Banner For Desktop Page :{' '}
                  {uploadObj?.img_banner ? (
                    <a href={uploadObj?.img_banner} alt="image banner" rel="noreferrer" target="_blank">
                      Open current banner image
                    </a>
                  ) : (
                    <span className="color-white">No current banner image</span>
                  )}
                </Form.Label>
                <Form.Control type="file" size="sm" onChange={handleImgBannerChange} />
              </Card>
            </Col>
          </Row>
          <Row className="mh-100">
            <Col className="mb-4 mh-100">
              <Card className="bg-grey color-white p-3 h-100">
                <Form.Label className="fs-6 color-light-pink">
                  Image Banner For Desktop Mobile View :{' '}
                  {uploadObj?.desktop_phone_banner ? (
                    <a href={uploadObj?.desktop_phone_banner} alt="desktop phone image" rel="noreferrer" target="_blank">
                      Open current banner image
                    </a>
                  ) : (
                    <span className="color-white">No current banner image</span>
                  )}
                </Form.Label>
                <Form.Control type="file" size="sm" onChange={handleDesktopMobileBannerChange} />
              </Card>
            </Col>
          </Row>

          <Row className="mh-100">
            <Col className="mb-4 mh-100">
              <Card className="bg-grey color-white p-3 h-100">
                <Form.Label className="fs-6 color-light-pink">
                  Image card for Sharing (990*360):{' '}
                  {uploadObj?.share_img_url ? (
                    <a href={uploadObj?.share_img_url} alt="share image" rel="noreferrer" target="_blank">
                      Open current banner image
                    </a>
                  ) : (
                    <span className="color-white">No current banner image</span>
                  )}
                </Form.Label>
                <Form.Control type="file" size="sm" onChange={handleFormShareBannerChange} />
              </Card>
            </Col>
          </Row>
          {/* <Row className="mh-100">
            <Col className="mb-4 mh-100">
              <Card className="bg-grey color-white p-3 h-100">
                <Form.Label className="fs-6 color-light-pink">
                  Video Banner For Registration Page (File size should be less than 6MB):{' '}
                  {uploadObj?.event_banner_vid ? (
                    <a
                      href={uploadObj?.event_banner_vid}
                      alt="banner video"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Open current banner video
                    </a>
                  ) : (
                    <span className="color-white">No current banner image</span>
                  )}
                </Form.Label>
                <Form.Control
                  type="file"
                  size="sm"
                  onChange={handleVideoBannerChange}
                />
              </Card>
            </Col>
          </Row> */}
          {/* <Row className="mh-100">
            <Col className="mb-4 mh-100">
              <Card className="bg-grey color-white p-3 h-100">
                <Form.Label className="fs-6 color-light-pink">
                  GIF Banner For Registration Page (File size should be less than 6MB):{' '}
                  {uploadObj?.event_banner_gif ? (
                    <a
                      href={uploadObj?.event_banner_gif}
                      alt="banner gif"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Open current banner GIF
                    </a>
                  ) : (
                    <span className="color-white">No current banner gif</span>
                  )}
                </Form.Label>
                <Form.Control
                  type="file"
                  size="sm"
                  onChange={handleGIFBannerChange}
                />
              </Card>
            </Col>
          </Row> */}
          {eventId && (
            <Row>
              <Col className="mb-4 mh-100">
                <Card className="bg-grey color-white p-3 h-100">
                  <Form.Label className="fs-6 color-light-pink">Registration Links</Form.Label>
                  <div className="reg-icon-div">
                    {/* eslint-disable-next-line */}
                    <a
                      href={`https://www.collegepass.org/registration/${uploadObj.event_zoom_id}?utm_source=facebook`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="bi bi-facebook reg-icon"></i>
                    </a>
                    {/* eslint-disable-next-line */}
                    <a
                      href={`https://www.collegepass.org/registration/${uploadObj.event_zoom_id}?utm_source=instagram`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="bi bi-instagram reg-icon"></i>
                    </a>
                    {/* eslint-disable-next-line */}
                    <a href={`https://www.collegepass.org/registration/${uploadObj.event_zoom_id}`} rel="noreferrer" target="_blank">
                      <i className="bi bi-link reg-icon"></i>
                    </a>
                  </div>
                </Card>
              </Col>
              <Col className="mb-4 mh-100">
                <Card className="p-3 h-100 bg-grey color-white">
                  <Form.Label className="fs-6 color-light-pink">Other Info</Form.Label>
                  <div>
                    <div type="button" onClick={fetchEventRegistrationData}>
                      <u>Download Registrered Users Data</u>
                    </div>
                    {/* <div type="button" onClick={fetchEventAttendeeData}>
                      <u>Download Attendees Data</u>
                    </div> */}
                  </div>
                </Card>
              </Col>
            </Row>
          )}
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Button className="btn-primary" onClick={handleEdit} style={{ width: '5rem' }}>
              {isLoading ? <Spinner animation="border" size="sm" /> : eventId ? 'SAVE' : 'ADD'}
            </Button>
          </Row>
        </Form>
      </Row>
    </Container>
  )
})
