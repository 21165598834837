import React, { useCallback } from 'react'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import ReactDatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { validate } from '../../../utils/validators'
import 'react-datepicker/dist/react-datepicker.css' // Import the styles as well

const DatePicker = ({
  id,
  options = [],
  name,
  initialValue = '',
  label,
  defaultDate = null,
  onChange,
  className,
  isError = false,
  errorMessage,
  placeholder,
  dateFormat,
  maxDate,
  minDate,
  inputFormat,
  validators = [],
}) => {
  const onChangeHandler = useCallback(
    (date) => {
      const value = moment(date).format(inputFormat || 'DD MMM, YYYY')
      const isValid = validate(value, validators)
      onChange(id, value, isValid)
      // console.log("Date value", value);
    },
    [id, onChange, validators]
  )

  return (
    <div>
      <div className="mb-1.5">
        {label && (
          <label className="font-medium text-base whitespace-nowrap text-[#091A42]" htmlFor={name}>
            {`${label || 'Date Picker'}`}
          </label>
        )}
      </div>
      <ReactDatePicker
        className={className}
        selected={defaultDate && moment(defaultDate).toDate()}
        value={defaultDate}
        onChange={onChangeHandler}
        placeholderText={placeholder}
        dateFormat={dateFormat || 'dd MMM, yyyy'}
        minDate={minDate && moment(minDate, 'MMMM D, YYYY').toDate()}
        maxDate={maxDate ? moment(maxDate, 'MMMM D, YYYY').toDate() : null}
      />
      {isError && (
        <span className="text-warning text-sm">
          <FontAwesomeIcon icon={faCircleInfo} className="" /> {errorMessage}
        </span>
      )}
    </div>
  )
}

export default DatePicker
