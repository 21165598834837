import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FloatingLabel,
  Card,
  Alert,
  Modal,
  Spinner,
} from 'react-bootstrap'
import Select from 'react-select'
import { Link } from 'react-router-dom'

import { DataGrid, GridToolbar } from '@mui/x-data-grid'
// import styled from '@emotion/styled'

import axios from 'axios'

import {
  APIDeleteArchiveVideo,
  APIUpdateArchiveVideo,
  getArchiveVideos,
  getClassListDropDown,
  getEventListDropDown,
  insertArchiveClasses,
  insertArchiveClassesV1,
  insertArchiveClassesV2,
  insertArchiveLiveStream,
  insertArchiveLiveStreamV1,
  insertArchiveLiveStreamV2,
} from '../../utils/config/API'
import './styles.css'
import withLoginRequired from '../../HOC/LoginModal'
// import { fontWeight } from '@mui/system'

export default withLoginRequired(() => {
  const [events, setEvents] = useState([])
  const [cls, setCls] = useState('')
  const [liveStream, setLiveStream] = useState('')
  const [vimeoId, setVimeoId] = useState('')
  const [selectClass, SetSelectClass] = useState(true)

  const [classes, setClasses] = useState([])
  const [liveStreams, setLiveStreams] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const token = localStorage.getItem('token')
  const [rows, setRows] = useState([])

  const [displayBannerImg, setDisplayBannerImg] = useState({
    preview: '',
    raw: '',
  })

  const VISIBLE_FIELDS = [
    'Archive Name',
    'Date/Time',
    'Vimeo ID',
    'Video Type',
    'Created At',
  ]

  // const rows  = [
  //   { id: 1, col1: 'Hello', col2: 'World' },
  //   { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
  //   { id: 3, col1: 'MUI', col2: 'is Amazing' },
  // ];

  const columns = [
    {
      field: 'ARCHIVE_ID',
      headerName: 'Archive Id',
      hide: true,
    },
    {
      field: 'ARCHIVE_NAME',
      headerName: 'Archive Name',
      flex: 4,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'EVENT_DATE_TIME',
      headerName: 'Date/Time',
      flex: 1.5,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'VIMEO_ID',
      headerName: 'Vimeo ID',
      flex: 1.5,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'TYPE',
      headerName: 'Video Type',
      flex: 1.5,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'CREATED',
      headerName: 'Created At',
      flex: 1.5,
      headerClassName: 'super-app-theme--header',
    },
  ]
  // const { data } = useDemoData({
  //   dataSet: 'Employee',
  //   visibleFields: VISIBLE_FIELDS,
  //   rowLength: 15,
  // })

  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  const fetchData = async () => {
    try {
      const resp = await axios.get(getArchiveVideos, config)
      console.log('Fetched data ----------------->', resp.data.body)
      setEvents(resp.data.body)
      setRows((prev) =>
        resp.data.body.map((item, index) => {
          return {
            ARCHIVE_NAME: item.ARCHIVE_NAME,
            EVENT_DATE_TIME: item.EVENT_DATE_TIME,
            VIMEO_ID: item.VIMEO_ID,
            TYPE: item.TYPE,
            CREATED: item.CREATED,
            id: index,
            ARCHIVE_ID: item.ID,
            DESCRIPTION: item.DESC,
          }
        })
      )

      const clss = await axios.get(getClassListDropDown, config)
      setClasses(clss.data.body)
      const evts = await axios.get(getEventListDropDown, config)
      setLiveStreams(evts.data.body)
    } catch (err) {
      console.log('Error --------->', err)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const onOpen = () => {
    if (!selectClass) {
      SetSelectClass(true)
      setLiveStream('')
      setVimeoId('')
      setIsSubmitting(false)
    }
  }
  const onClose = () => {
    if (selectClass) {
      SetSelectClass(false)
      setCls('')
      setVimeoId('')
      setIsSubmitting(false)
    }
  }
  let liveStreamComp = null
  let classComp = null
  if (liveStreams.length) {
    liveStreamComp = []
    liveStreamComp = liveStreams.map((item, index) => {
      return {
        value: item.ID,
        label: `${item.NAME} (${item.DATE_TIME})`,
      }
    })
  }
  if (classes.length) {
    classComp = []
    classComp = classes.map((item, index) => {
      return {
        value: item.ID,
        label: `${item.NAME} (${item.DATE_TIME})`,
      }
    })
  }
  //   console.log('ClassComp', classComp)
  //   console.log('LiveStreamComp', liveStreamComp)
  // }
  const onSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    if ((!cls && !liveStream) || !vimeoId) return
    // const eventID = selectClass ? cls.value : liveStream.value
    // const URL = selectClass ? insertArchiveClassesV1 : insertArchiveLiveStreamV1
    const URL = selectClass ? insertArchiveClassesV2 : insertArchiveLiveStreamV2

    const vimeoID = vimeoId
      .replace('https://vimeo.com/', '')
      .replace('/', '?h=')
    // eslint-disable-next-line no-use-before-define
    // let body = {
    //   vimeoID,
    // }
    const formData = new FormData()
    formData.append('vimeoID', vimeoID)
    selectClass
      ? formData.append('classID', cls.value)
      : formData.append('eventID', liveStream.value)
    formData.append('recording_banner', displayBannerImg.raw || null)
    // selectClass
    //   ? (body = { ...body, classID: cls.value })
    //   : (body = { ...body, eventID: liveStream.value })

    try {
      const result = await axios.put(URL, formData, config)
      console.log('Result', result.data)

      if (result.status === 200) {
        await fetchData()
        setCls('')
        setLiveStream('')
        setVimeoId('')
        setIsSubmitting(false)
        setDisplayBannerImg({ preview: '', raw: '' })
      } else {
        console.log('Error')
      }
    } catch (err) {
      console.log('Error', err)
    }
  }

  const handleRowClick = (rowData) => {
    // Trigger your function here with the row data
    console.log('Row clicked:', rowData)
  }
  // Custom cell renderer function for the "Name" column
  const nameCellRenderer = ({ value, row }) => (
    <div onClick={() => handleRowClick(row)}>{value}</div>
  )

  const updatedColumns = columns.map((column) => {
    // Add custom cell renderer to the "Name" column
    if (column.field === 'Name') {
      return { ...column, renderCell: nameCellRenderer }
    }
    return column
  })

  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [editVimeoId, setEditVimeoId] = useState('')

  const [description, setDescription] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const handleOpenEditModal = (id, vimeoID, desc) => {
    setShowEditModal(true)
    setEditId(id)
    setEditVimeoId(vimeoID)
    setDescription(desc)
    console.log(id, vimeoID, desc)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setEditId(null)
    setEditVimeoId('')
  }

  const handleEdit = async () => {
    try {
      const data = {
        ID: editId,
        vimeoID: editVimeoId,
        description,
      }
      setIsLoading(true)
      const result = await axios.put(APIUpdateArchiveVideo, data)

      await fetchData()
      setIsLoading(false)
      setShowDeleteModal(false)
      handleCloseEditModal()
    } catch (err) {
      console.log('Error -->', err)
    }
  }

  const handleDelete = async () => {
    try {
      const deleteVideo = await axios.delete(APIDeleteArchiveVideo + editId)
      await fetchData()
      setShowDeleteModal(false)
      handleCloseEditModal()
    } catch (err) {
      console.log('Error -->', err)
    }
  }

  const handleDisplayBannerChange = (e) => {
    if (e.target.files.length) {
      setDisplayBannerImg({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }
  return (
    <Container fluid className="w-100">
      <Row className="mb-3">
        <h3 className="title">
          <Link to="/">
            <i className="bi bi-arrow-left"></i>
          </Link>
          ADD ARCHIVE VIDEO
        </h3>
      </Row>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col className="mb-4">
            <Button className={!selectClass ? 'ot-fcs' : ''} onClick={onOpen}>
              Classes
            </Button>
            <Button className={selectClass ? 'ot-fcs' : ''} onClick={onClose}>
              Live Steam
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mb-4">
            <Form.Label
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            >
              {selectClass ? 'Select Class' : 'Select Live Stream'}
            </Form.Label>
            <Select
              value={selectClass ? cls : liveStream}
              name={selectClass ? 'class' : 'liveStream'}
              onChange={selectClass ? setCls : setLiveStream}
              options={selectClass ? classComp : liveStreamComp}
              placeholder={selectClass ? 'Select Class' : 'Select Live Stream'}
              // className="bg-grey color-white"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  // background: '#2d2f44',
                  // color: 'red!important',
                  boxShadow: 'none',
                  border:
                    isSubmitting && !cls && !liveStream
                      ? '2px solid red!important'
                      : 'none',
                }),
              }}
            />
            {isSubmitting && !cls && !liveStream ? (
              <Form.Text
                // className=""
                style={{
                  color: 'red!important',
                  letterSpacing: '0.03rem',
                }}
              >
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  style={{ color: 'red' }}
                >
                  {' '}
                </i>
                <span style={{ color: 'red' }}>This field is required</span>
              </Form.Text>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col className="mb-4">
            <Form.Label
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            >
              Vimeo Id
            </Form.Label>
            <Form.Control
              placeholder="Vimeo Id"
              type="text"
              name="vimeoId"
              value={vimeoId}
              onChange={(e) => setVimeoId(e.target.value)}
              style={{
                border: isSubmitting && !vimeoId ? '2px solid red' : 'none',
              }}
            />
            {isSubmitting && !vimeoId ? (
              <Form.Text
                style={{
                  color: 'red!important',
                  letterSpacing: '0.03rem',
                }}
              >
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  style={{ color: 'red' }}
                >
                  {' '}
                </i>
                <span style={{ color: 'red' }}>This field is required</span>
              </Form.Text>
            ) : null}
          </Col>
        </Row>
        <Row className="mh-100">
          <Col className="mb-4 mh-100">
            <Card className="bg-grey color-white p-3 h-100">
              <Form.Label className="fs-6 color-light-pink">
                Image Banner :{' '}
              </Form.Label>
              <Form.Control
                type="file"
                size="sm"
                accept=".png,.jpg,.jpeg"
                onChange={handleDisplayBannerChange}
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="mb-4">
            <Button type="submit">Add</Button>
          </Col>
        </Row>
      </Form>

      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          sx={{
            color: 'white',
            fontFamily: 'inherit',
            fontWeight: '400',
            '& .MuiDataGrid-cell': {
              color: 'rgb(235, 232, 232)',
              fontSize: '1rem',
              fontWeight: '400',
            },
            '& .MuiDataGrid-columnHeaders': {
              // backgroundColor: "rgba(0,0,255,0.6)",
              color: 'white',
              fontSize: '1.2rem',
              fontWeight: 'bold',
            },
            '& .css-levciy-MuiTablePagination-displayedRows': {
              fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
              fontWeight: '400',
              fontSize: '1rem',
              color: 'white',
              // alignSelf: 'center',
              // lineHeight: '1.43',
              letterSpacing: '0.01071em',
              // '-webkit-flex-shrink': '0',
              // '-ms-flex-negative': '0',
              flexShrink: '0',
              marginBottom: '0',
            },
            '& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions':
              {
                // '-webkit-flex-shrink': '0',
                // '-ms-flex-negative': '0',
                flexShrink: '0',
                marginLeft: '20px',
                color: 'white',
              },
            '& .css-17jjc08-MuiDataGrid-footerContainer': {
              // display: '-webkit-box',
              // display: '-webkit-flex',
              // display: '-ms-flexbox',
              display: 'flex',
              // '-webkit-box-pack': 'justify',

              // '-webkit-justify-content': 'space-between',
              // '-webkit-align-items': 'center',
              // '-webkit-box-align': 'center',
              // '-ms-flex-align': 'center',
              alignItems: 'center',
              minHeight: '52px',
              borderTop: '1px solid rgba(224, 224, 224, 1)',
            },
          }}
          rows={rows}
          columns={updatedColumns}
          components={{ Toolbar: GridToolbar }}
          pageSize={15}
          rowsPerPageOptions={[15]}
          pagination
          onRowClick={(r) => {
            console.log('Row', r.row)
            handleOpenEditModal(
              r.row.ARCHIVE_ID,
              r.row.VIMEO_ID,
              r.row.DESCRIPTION
            )
          }}
        />
      </div>

      <Modal
        show={showEditModal && editId && editVimeoId.length}
        onHide={() => handleCloseEditModal()}
        size="lg"
      >
        <Modal.Header closeButton>
          <h5 className="text-center ml-4 pt-3 pb-3 width-100">
            Edit Archive Video
          </h5>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Control
                type="text"
                name="context"
                value={editVimeoId}
                onChange={(e) => setEditVimeoId(e.target.value)}
                className="mb-3"
              />
              {/* </Form.Group> */}
              <Form.Control
                // type="text"
                as="textarea"
                rows={4}
                name="context"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mb-3"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <span className="mt-3 mb-3 width-100 text-center d-flex justify-content-center">
            <Button
              variant="primary"
              onClick={() => handleEdit()}
              className="m-2"
              id=""
            >
              {isLoading ? <Spinner animation="border" size="sm" /> : 'Save'}
            </Button>
            <Button
              variant="primary"
              onClick={() => handleCloseEditModal()}
              className="bg-gray m-2"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => setShowDeleteModal(true)}
              className="bg-gray m-2"
            >
              Delete
            </Button>
          </span>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteModal}
        // onHide={() => onHideDeleteModal()}
        size="sm"
      >
        <Modal.Header closeButton>
          <h5 className="text-center ml-4 pt-3 pb-3 width-100">
            Are you sure you want to delete this Recording ?
          </h5>
        </Modal.Header>
        <Modal.Footer>
          <span className="mt-3 mb-3 width-100 text-center">
            <Button
              variant="primary"
              onClick={() => handleDelete()}
              className="mr-2"
              id=""
            >
              Yes
            </Button>
            <Button
              variant="primary"
              onClick={() => setShowDeleteModal(false)}
              className="ml-2"
            >
              No
            </Button>
          </span>
        </Modal.Footer>
      </Modal>
    </Container>
  )
})
