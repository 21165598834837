export const GET_EVENTS_FULL_DATA_REQUEST = 'GET_EVENTS_FULL_DATA_REQUEST'
export const GET_EVENTS_FULL_DATA_SUCCESS = 'GET_EVENTS_FULL_DATA_SUCCESS'
export const GET_EVENTS_FULL_DATA_FAILURE = 'GET_EVENTS_FULL_DATA_FAILURE'

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE'

export const FETCH_DATA_BY_ID_REQUEST = 'FETCH_DATA_BY_ID_REQUEST'
export const FETCH_DATA_BY_ID_SUCCESS = 'FETCH_DATA_BY_ID_SUCCESS'
export const FETCH_DATA_BY_ID_FAILURE = 'FETCH_DATA_BY_ID_FAILURE'
