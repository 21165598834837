/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Row, Col, Button } from 'react-bootstrap'
import Input from '../Shared/Input'
import useForm from '../../hooks/useForm'

import MultiSelectInput from '../Shared/MultiSelectInput'
import { statesOptions, typesOptions } from '../../constants/ngoConstants'
import apiService from '../../utils/apiService'
import { isDeepEqual } from '../../utils/objectDeepEqualCheck'
import Loader from '../Shared/Loader'
import MessageModal from '../Shared/MessageModal'

const addNewNGOFormInitialValue = {
  name: '',
  types: [],
  states: [],
  phone: '',
  email: '',
  website: '',
}
const addNewNGOFormErrors = {
  name: true,
  types: true,
  states: true,
  phone: true,
  email: true,
  website: true,
}

const EditReviewNGOForm = ({ onClose, formDetails, id, reFetchDetails }) => {
  const [addNewNGOInitialValue, setAddNewNGOInitialValue] = useState(addNewNGOFormInitialValue)
  const [addNewNGOInitialError, setAddNewNGOInitialError] = useState(addNewNGOFormErrors)
  const [messageDetails, setMessageDetails] = useState({ show: false, isError: false, message: '' })
  const [showLoader, setShowLoader] = useState(false)

  const auth = useSelector((state) => state.auth)
  const {
    onTextInputChange,
    onRadioInputChange,
    onMultiSelectInputChange,
    values: addCourseFormValues,
    ...addCourseForm
  } = useForm(addNewNGOInitialValue, addNewNGOInitialError)

  useEffect(() => {
    console.log("Made it here", formDetails);
    setAddNewNGOInitialValue({
      name: formDetails.name,
      types: formDetails.types,
      states: formDetails.states,
      phone: formDetails.phone,
      email: formDetails.email,
      website: formDetails.website,
    })
    setAddNewNGOInitialError({
      name: !formDetails.name,
      types: !formDetails.types.length,
      states: !formDetails.states.length,
      phone: !formDetails.phone,
      email: !formDetails.email,
      website: !formDetails.website,
    })
  }, [formDetails])

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      setShowLoader(true)
      const isFormInvalid = Object.values(addCourseForm.errors).reduce((a, b) => a || b)

      if (isFormInvalid) {
        throw Error('Invalid Form Values')
      }

      const payload = {
        ...addCourseFormValues,
      }

      const response = await apiService(`/reviewNGO/${id}`, 'PUT', payload)
      if (response.errors) {
        throw new Error('Update Failed. Try Again!')
      }

      setMessageDetails({
        show: true,
        isError: false,
        message: 'Successfully saved the NGO.',
        onClose: () => {
          reFetchDetails()
        },
      })
    } catch (err) {
      addCourseForm.setShowFormErrors(true)
      setMessageDetails({ show: true, isError: true, message: 'Failed to update NGO details. Try Again' })
    }
    setShowLoader(false)
  }

  const handleReject = async () => {
    try {
      setShowLoader(true)
      await apiService(`/reviewNGO/${id}`, 'DELETE')
      setMessageDetails({
        show: true,
        isError: false,
        message: 'Rejected Successfully.',
        onClose: () => {
          reFetchDetails()
        },
      })
    } catch (e) {
      console.log(e)
      setMessageDetails({ show: true, isError: true, message: 'Failed to reject NGO.' })
    }
    setShowLoader(false)
  }

  const handleApprove = async () => {
    try {
      setShowLoader(true)
      await apiService(`/reviewNGO/approve/${id}`, 'POST')
      setMessageDetails({
        show: true,
        isError: false,
        message: 'Approved Successfully',
        onClose: () => {
          reFetchDetails()
        },
      })
    } catch (e) {
      console.log(e)
      setMessageDetails({ show: true, isError: true, message: 'Failed to approve NGO' })
    }
    setShowLoader(false)
  }

  const isChangeInValues = !isDeepEqual({ ...addNewNGOInitialValue }, { ...addCourseFormValues })

  if (showLoader) {
    return (
      <div>
        <Row style={{ width: '100%', display: 'block', margin: 'auto' }}>
          <Loader className="text-dark" />
        </Row>
      </div>
    )
  }
  console.log('Add form details', addCourseFormValues)
  console.log('Add form details', addNewNGOFormInitialValue)
  console.log('Add form details', formDetails)
  return (
    <div>
      <MessageModal
        showMessage={messageDetails.show}
        isError={messageDetails.isError}
        message={messageDetails.message}
        closeModal={() => {
          setMessageDetails({ show: false })
        }}
        onClose={messageDetails.onClose}
      />
      <Row style={{ width: '100%', display: 'block', margin: 'auto' }}>
        <form onSubmit={submitHandler}>
          <Col>
            <Row>
              <Col>
                <h2
                  style={{
                    marginBottom: '30px',
                    textAlign: 'center',
                  }}
                >
                  Edit New NGO
                </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  id="name"
                  name="name"
                  label="Name"
                  placeholder="Enter name"
                  initialValue={addCourseFormValues.name}
                  onChange={onTextInputChange}
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.name}
                  errorMessage="Please enter some letters."
                  validators={['REQUIRE']}
                />
              </Col>
            </Row>

            <Row>
              <Col className='mb-3'>
                <MultiSelectInput
                  placeholder={'Select types'}
                  id={'types'}
                  name={'types'}
                  label={'Types'}
                  defaultKeys={addCourseFormValues.types}
                  options={typesOptions.map((item) => {
                    return { label: item, value: item }
                  })}
                  onChange={onMultiSelectInputChange}
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.types}
                  errorMessage="Please select types."
                  className="w-1/3"
                />
              </Col>
            </Row>
            <Row>
              <Col className='mb-3'>
                <MultiSelectInput
                  placeholder={'Select states'}
                  id={'states'}
                  name={'states'}
                  label={'States'}
                  defaultKeys={addCourseFormValues.states}
                  options={statesOptions.map((item) => {
                    return { label: item, value: item }
                  })}
                  onChange={onMultiSelectInputChange}
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.states}
                  errorMessage="Please select states."
                  className="w-1/3"
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  id="phone"
                  name="phone"
                  label="Phone number"
                  placeholder="Enter phone number(s)"
                  initialValue={addCourseFormValues.phone}
                  onChange={onTextInputChange}
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.phone}
                  errorMessage="Please enter a valid number."
                  validators={['REQUIRED']}
                />
              </Col>
              <Col>
                <Input
                  id="email"
                  name="email"
                  label="Email"
                  placeholder="Enter organization's email"
                  initialValue={addCourseFormValues.email}
                  onChange={onTextInputChange}
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.email}
                  errorMessage="Please enter a valid email."
                  validators={['REQUIRED', 'EMAIL']}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  id="website"
                  name="website"
                  label="Website Link/URL"
                  placeholder="Enter website url"
                  initialValue={addCourseFormValues.website}
                  onChange={onTextInputChange}
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.website}
                  errorMessage="Please fill this value."
                  validators={['REQUIRE']}
                />
              </Col>
            </Row>

            <Row>
              <Col className="text-center pt-4 pb-4">
                {isChangeInValues ? <Button type="submit">Save NGO Details</Button> : <Button onClick={handleApprove}>Approve</Button>}
                <Button className="m-2" variant="danger" onClick={handleReject}>
                  Reject
                </Button>
              </Col>
            </Row>
          </Col>
        </form>
      </Row>
    </div>
  )
}

export default EditReviewNGOForm
