import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  errorText: {
    margin: '3px 0px 0px 0px!important',
  },
  errorAutoText: {
    margin: '3px 0px 0px 0px!important',
  },
}))
