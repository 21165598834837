import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { APICheckToken } from '../utils/config/API'

const withLoginRequired = (WrappedComponent) => {
  const WithLoginRequired = (props) => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    // const [isLoggedIn, setIsLoggedIn] = useState(false) // replace with your actual login state

    const [message, setMessage] = useState('')
    const checkToken = async (token) => {
      try {
        const isValid = await axios.get(APICheckToken, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        if (isValid.data.message === 'Invalid Token...') {
          setMessage(
            'Sorry, your session has expired or your token is invalid. Please log in again to continue.'
          )
          setShow(true)
        }
      } catch (err) {
        console.log('Error --->', err)
      }
    }
    useEffect(() => {
      const token = localStorage.token
      if (!token) {
        setMessage('Please log in to access this Page.')
        setShow(true)
      } else {
        checkToken(token)
      }
    }, [localStorage.token]) // add an empty dependency array to run the effect only once    if (!token) {

    return (
      <>
        <WrappedComponent {...props} />
        <Modal show={show} onHide={() => setShow(false)} size="l">
          <Modal.Header closeButton>
            <h5 className="text-center ml-4 pt-3 pb-3 width-100">{message}</h5>
          </Modal.Header>
          <Modal.Footer>
            <span className="mt-3 mb-3 width-100 text-center">
              <Button
                variant="primary"
                onClick={() => navigate('/login')}
                className="mr-2"
                id=""
                style={{
                  marginRight: '10px',
                }}
              >
                Login
              </Button>
            </span>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  return WithLoginRequired
}

export default withLoginRequired
