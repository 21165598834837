import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

import './EventData.scss'

function RefereeData({ eventDetails, downloadCSV }) {
  let rows = []
  if (!eventDetails.length) {
    return (
      <div className="mb-4 mt-4 d-flex flex-row justify-content-center">
        <p className="btn-event">DATA NOT AVAILABLE</p>
      </div>
    )
  }
  rows = eventDetails.map((item, index) => {
    return {
      id: index,
      REFEREE_FIRSTNAME: item.REFEREE_FIRSTNAME,
      REFEREE_LASTNAME: item.REFEREE_LASTNAME,
      REFEREE_EMAIL: item.REFEREE_EMAIL,
      REFEREE_MOBILE_NUMBER: item.REFEREE_MOBILE_NUMBER,
      REFEREE_CITY: item.REFEREE_CITY,
      REFERRED_FIRSTNAME: item.REFERRED_FIRSTNAME,
      REFERRED_LASTNAME: item.REFERRED_LASTNAME,
      REFERRED_EMAIL: item.REFERRED_EMAIL,
      REFERRED_MOBILE_NUMBER: item.REFERRED_MOBILE_NUMBER,
      REFERRED_CITY: item.REFERRED_CITY,
      SOURCE: item.SOURCE,
      UTM_SOURCE: item.UTM_SOURCE,
      UTM_MEDIUM: item.UTM_MEDIUM,
      UTM_CAMPAIGN: item.UTM_CAMPAIGN,
      CREATED_AT: item.CREATED_AT,
    }
  })

  const columns = [
    {
      field: 'REFEREE_FIRSTNAME',
      headerName: 'REFEREE_FIRSTNAME',
      flex: 1,
    },
    {
      field: 'REFEREE_LASTNAME',
      headerName: 'REFEREE_LASTNAME',
      flex: 1,
    },
    {
      field: 'REFEREE_EMAIL',
      headerName: 'REFEREE_EMAIL',
      flex: 1,
    },
    {
      field: 'REFEREE_MOBILE_NUMBER',
      headerName: 'REFEREE_MOBILE_NUMBER',
      flex: 1,
    },
    {
      field: 'REFEREE_CITY',
      headerName: 'REFEREE_CITY',
      flex: 1,
    },
    {
      field: 'REFERRED_FIRSTNAME',
      headerName: 'REFERRED_FIRSTNAME',
      flex: 0.5,
    },
    {
      field: 'REFERRED_LASTNAME',
      headerName: 'REFERRED_LASTNAME',
      flex: 0.5,
    },
    {
      field: 'REFERRED_EMAIL',
      headerName: 'REFERRED_EMAIL',
      flex: 0.5,
    },
    {
      field: 'REFERRED_MOBILE_NUMBER',
      headerName: 'REFERRED_MOBILE_NUMBER',
      flex: 0.5,
    },
    {
      field: 'REFERRED_CITY',
      headerName: 'REFERRED_CITY',
      flex: 0.5,
    },
    {
      field: 'SOURCE',
      headerName: 'SOURCE',
      flex: 0.5,
    },
    {
      field: 'UTM_SOURCE',
      headerName: 'UTM_SOURCE',
      flex: 0.5,
    },
    {
      field: 'UTM_MEDIUM',
      headerName: 'UTM_MEDIUM',
      flex: 0.5,
    },
    {
      field: 'UTM_CAMPAIGN',
      headerName: 'UTM_CAMPAIGN',
      flex: 0.5,
    },
    {
      field: 'CREATED_AT',
      headerName: 'CREATED_AT',
      flex: 0.5,
    },
  ]

  return (
    <div className="mb-4 mt-4">
      {/* <CSVLink {...CSVLinkObj} className="btn-event">
        EVENT DETAILS FOR {eventDetails[0].EVENT_ID}
      </CSVLink> */}
      <div className="d-flex justify-content-between mb-2 align-center">
        <p style={{ color: '#e14eca', fontWeight: 'bold', fontSize: '1.5rem' }}>
          REFERRAL INFORMATION
        </p>
        <Button
          className="btn-primary"
          style={{
            margin: 'auto!important',
          }}
          onClick={downloadCSV}
        >
          DOWNLOAD
        </Button>
      </div>
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          sx={{
            color: 'white',
            fontFamily: 'inherit',
            fontWeight: '400',
            '& .MuiDataGrid-cell': {
              color: 'rgb(235, 232, 232)',
              fontSize: '1rem',
              fontWeight: '400',
            },
            '& .MuiDataGrid-columnHeaders': {
              // backgroundColor: "rgba(0,0,255,0.6)",
              color: 'white',
              fontSize: '1.2rem',
              fontWeight: 'bold',
            },
            '& .css-levciy-MuiTablePagination-displayedRows': {
              fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
              fontWeight: '400',
              fontSize: '1rem',
              color: 'white',
              // alignSelf: 'center',
              // lineHeight: '1.43',
              letterSpacing: '0.01071em',
              // '-webkit-flex-shrink': '0',
              // '-ms-flex-negative': '0',
              flexShrink: '0',
              marginBottom: '0',
            },
            '& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions':
              {
                // '-webkit-flex-shrink': '0',
                // '-ms-flex-negative': '0',
                flexShrink: '0',
                marginLeft: '20px',
                color: 'white',
              },
            '& .css-17jjc08-MuiDataGrid-footerContainer': {
              // display: '-webkit-box',
              // display: '-webkit-flex',
              // display: '-ms-flexbox',
              display: 'flex',
              // '-webkit-box-pack': 'justify',

              // '-webkit-justify-content': 'space-between',
              // '-webkit-align-items': 'center',
              // '-webkit-box-align': 'center',
              // '-ms-flex-align': 'center',
              alignItems: 'center',
              minHeight: '52px',
              borderTop: '1px solid rgba(224, 224, 224, 1)',
            },
          }}
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={15}
          rowsPerPageOptions={[15]}
          pagination
        />
      </div>
    </div>
  )
}

export default RefereeData
