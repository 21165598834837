import axios from 'axios'
import { Container, Row, Col, Button, Card, Form, Modal } from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap'
import './styles.css'
import {
  insertSeries,
  updateSeries,
  getSeriesWithEpisodes,
} from '../../utils/config/API'
import withLoginRequired from '../../HOC/LoginModal'

export default withLoginRequired(() => {
  const [show, setShow] = useState(false)
  const [removeIndex, setremoveIndex] = useState(null)
  const [SeriesTitle, setSeriesTitle] = useState(null)

  const [SeriesTrailer, setSeriesTrailer] = useState(null)
  const [ReleaseDate, setReleaseDate] = useState(null)
  const [ReleaseTime, setReleaseTime] = useState('19:00')
  const [SeriesTag, setSeriesTag] = useState([])
  const [CountryTag, setCountryTag] = useState([])
  const [Episodes, setEpisodes] = useState([])
  const [EpisodeName, setEpisodeName] = useState('')
  const [EpisodeisChecked, setEpisodeisChecked] = useState('')
  const [EpisodeLink, setEpisodeLink] = useState('')
  const [EpisodeDuration, setEpisodeDuration] = useState('')
  const [SeriesBanner, setSeriesBanner] = useState(null)
  const [EpisodeBanner, setEpisodeBanner] = useState(null)
  const [EpisodeBanners, setEpisodeBanners] = useState([])

  const [SeriesDescription, setSeriesDescription] = useState(null)
  const [EpisodeDescription, setEpisodeDescription] = useState(null)
  const SeriesId = useParams().id
  console.log(SeriesId)
  useEffect(() => {
    console.log('in series')
    if (SeriesId) {
      console.log('-------->', SeriesId)
      axios
        .post(getSeriesWithEpisodes, { series: SeriesId })
        .then((e) => {
          const data = e.data.message

          setSeriesTitle(data.series.Title)
          setSeriesTrailer(data.series.Trailer)
          setReleaseDate(data.series.Date.split('T')[0])
          setReleaseTime(data.series.Date.split('T')[1].split('.')[0])
          setSeriesDescription(data.series.description)
          setSeriesBanner(data.series.banner)
          setEpisodes(data.episodes)
          setSeriesTag([])
          setCountryTag([])
          console.log(e)

          data.episodes.forEach((element) => {
            console.log('looping**')
            // const response = await fetch('https://static.remove.bg/remove-bg-web/7deb868fb894efaa6d5f6cbfd1a016f4a613fda9/assets/start_remove-c851bdf8d3127a24e2d137a55b1b427378cd17385b01aec6e59d5d4b5f39d2ec.png');
            // const d = await response.blob();
            // const metadata = {
            //     type: 'image/jpeg'
            // };
            // const image = new File([d], "test.jpeg", metadata);
            // console.log(image)
            setEpisodeBanners([...EpisodeBanners, element.banner])
          })
          // console.log("Images are---->",EpisodeBanners)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [SeriesId])
  const onSubmitHandler = async () => {
    const series = {
      id: SeriesId,
      title: SeriesTitle,
      trailer: SeriesTrailer,
      releaseDate: ReleaseDate,
      releaseTime: ReleaseTime,
      premium: 2,
      description: SeriesDescription,
      Tags: [...SeriesTag, ...CountryTag],
    }
    console.log('serise', series)
    const episodes = Episodes
    const data = new FormData()
    data.append('series', JSON.stringify(series))
    data.append('series_banner', SeriesBanner)
    data.append('episodes', JSON.stringify(episodes))
    console.log(EpisodeBanners)
    EpisodeBanners.forEach((image, index) => {
      console.log('looping')
      data.append('series_banner', image)
    })
    console.log('final data', data)
    try {
      console.log('dfdsjfjkdsnkfjsndfs----------->', data.series)
      const insertResponse = await fetch(updateSeries, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        body: data,
      })

      // console.log(insertResponse)
      if (insertResponse.ok) window.open(`series-list`)
    } catch (error) {
      console.log(error)
    }
  }
  const AddEpisode = () => {
    const data = {
      imageUpdate: true,
      TITLE: EpisodeName,
      VIDEO: EpisodeLink,
      IS_PAID: EpisodeisChecked,
      DESCRIPTION: EpisodeDescription,
      DURATION: EpisodeDuration,
    }
    console.log('adding', data)
    console.log('adding iimage', EpisodeBanner)
    console.log([...Episodes, data])
    setEpisodes([...Episodes, data])
    setEpisodeBanners([...EpisodeBanners, EpisodeBanner])
    setEpisodeName('')
    setEpisodeisChecked('')
    setEpisodeLink('')
    setEpisodeDescription('')
    setEpisodeDuration('')
    setEpisodeBanner(null)
  }
  const removeEpisode = (index) => {
    console.log('ddeleteing', index)
    const Episode = Episodes
    const Banners = EpisodeBanners
    Episode.splice(index, 1)
    Banners.splice(index, 1)
    setEpisodes([...Episode])
    setEpisodeBanners([...Banners])
  }
  const handleYesClose = () => {
    removeEpisode(removeIndex)
    // setremoveIndex(null)
    setShow(false)
  }
  const handleNoClose = () => {
    // setremoveIndex(null)
    console.log('dsfsd')
    setShow(false)
  }
  const handleShow = (index) => {
    setremoveIndex(index)
    setShow(true)
  }
  const updateEpisode = (key, value, index) => {
    const c = Episodes
    c[index][key] = value
    console.log('updated value', c)
    setEpisodes([...c])
  }
  const updateEpisodeBanner = (image, index) => {
    const b = EpisodeBanners
    const c = Episodes
    c[index].imageupdate = true
    console.log('updated value', c)
    setEpisodes([...c])
    b[index] = image
    setEpisodeBanners([...b])
  }
  return (
    <Container fluid className="w-100">
      <Row className="mb-3">
        <h3 className="title">
          <Link to="/series">
            <i className="bi bi-arrow-left"></i>
          </Link>
          Series
        </h3>
      </Row>
      <Row
        className="mb-3 p-4"
        style={{
          background: '#2d2f44',
        }}
      >
        <Col>
          <Form>
            <Row>
              <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <Row>
                  <Col className="box box m-2 p-3">
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="value-form"
                      >
                        <h6>Series Name</h6>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="value-form"
                      >
                        <h6>
                          <input
                            type="text"
                            value={SeriesTitle}
                            onChange={(e) => {
                              setSeriesTitle(e.target.value)
                            }}
                          />
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <Row>
                  <Col className="box box m-2 p-3">
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <div
                          className="color-light-pink form-div"
                          style={{
                            fontSize: '10px',
                          }}
                        >
                          Image Banner For Registration Page (990*360):{' '}
                          <a
                            href={SeriesBanner}
                            alt="banner image"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Open current banner image
                          </a>
                        </div>
                      </Col>
                      <Col lg={7} md={7} sm={12} xs={12} className="value-form">
                        <h6>Series Thumbnail</h6>
                      </Col>
                      <Col lg={5} md={5} sm={12} xs={12} className="value-form">
                        <h6
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          <label
                            htmlFor="files"
                            className="btn m-0"
                            style={{
                              background: '#cc52e0',
                              color: '#ffffff',
                              padding: '0px 10px',
                            }}
                          >
                            Select
                          </label>
                          <input
                            id="files"
                            style={{ display: 'none' }}
                            type="file"
                            size="sm"
                            accept=".jpg"
                            onChange={(e) => {
                              e.target.files.length
                                ? setSeriesBanner(e.target.files[0])
                                : null
                            }}
                          />
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <Row>
                  <Col className="box box m-2 p-3">
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="value-form"
                      >
                        <h6>Series Trailer</h6>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="value-form"
                      >
                        <h6>
                          <input
                            type="text"
                            value={SeriesTrailer}
                            onChange={(e) => {
                              setSeriesTrailer(e.target.value)
                            }}
                          />
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="mb-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <Row>
                  <Col className="box box m-2 p-3">
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="value-form"
                      >
                        <h6>
                          <div
                            style={{
                              paddingBottom: '10px',
                            }}
                          >
                            Series Description
                          </div>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            value={SeriesDescription}
                            onChange={(e) => {
                              setSeriesDescription(e.target.value)
                            }}
                          ></textarea>
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="mb-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <Row>
                  <Col className="box box m-2 p-3">
                    <Row>
                      <Col lg={6} md={6} sm={12} xs={12} className="value-form">
                        <h6>Release Date</h6>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12} className="value-form">
                        <h6
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          <Form.Group controlId="rd">
                            <Form.Control
                              type="date"
                              name="rd"
                              placeholder=""
                              value={ReleaseDate}
                              onChange={(e) => {
                                setReleaseDate(e.target.value)
                              }}
                            />
                          </Form.Group>
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="mb-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <Row>
                  <Col className="box box m-2 p-3">
                    <Row>
                      <Col lg={6} md={6} sm={12} xs={12} className="value-form">
                        <h6>Grades</h6>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12} className="value-form">
                        <h6
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          <DropdownMultiselect
                            options={[
                              { key: '1', label: 'Grade 8' },
                              { key: '2', label: 'Grade 9' },
                              { key: '3', label: 'Grade 10' },
                              { key: '4', label: 'Grade 11' },
                              { key: '5', label: 'Grade 12' },
                              { key: '6', label: 'Undergaraduate' },
                            ]}
                            name="Series Tags"
                            value=""
                            placeholder="Select Tags"
                            handleOnChange={(selected) => {
                              setSeriesTag([...selected])
                              console.log(selected)
                            }}
                          />
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="mb-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <Row>
                  <Col className="box box m-2 p-3">
                    <Row>
                      <Col lg={6} md={6} sm={12} xs={12} className="value-form">
                        <h6>Release Time</h6>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12} className="value-form">
                        <h6
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          <Form.Group controlId="rd">
                            <Form.Control
                              type="time"
                              name="rd"
                              placeholder=""
                              value={ReleaseTime}
                              onChange={(e) => {
                                setReleaseTime(e.target.value)
                              }}
                            />
                          </Form.Group>
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="mb-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <Row>
                  <Col className="box box m-2 p-3">
                    <Row>
                      <Col lg={6} md={6} sm={12} xs={12} className="value-form">
                        <h6>Countries</h6>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12} className="value-form">
                        <h6
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          <DropdownMultiselect
                            options={[
                              { key: '1', label: 'UK' },
                              { key: '3', label: 'USA' },
                              { key: '4', label: 'Canada' },
                              { key: '58', label: 'Europe' },
                              { key: '6', label: 'Singapore' },
                              { key: '11', label: 'HongKong' },
                            ]}
                            name="Series Tags"
                            value=""
                            placeholder="Select Tags"
                            handleOnChange={(selected) => {
                              setCountryTag([...selected])
                            }}
                          />
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Row
        className="mb-3 p-4"
        style={{
          background: '#2d2f44',
        }}
      >
        <Col>
          <Form>
            <Row className="plus-form-sep">
              <Col>
                <Row>
                  <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box box m-2 p-3">
                        <Row>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="value-form"
                          >
                            <h6>Episode Name</h6>
                          </Col>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="value-form"
                          >
                            <h6
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              <input
                                type="text"
                                value={EpisodeName}
                                onChange={(e) => {
                                  setEpisodeName(e.target.value)
                                }}
                              />
                            </h6>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box box m-2 p-3">
                        <Row>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="value-form"
                          >
                            <h6>Episode Vimeo Link </h6>
                          </Col>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="value-form"
                          >
                            <h6>
                              <input
                                type="text"
                                value={EpisodeLink}
                                onChange={(e) => {
                                  setEpisodeLink(e.target.value)
                                }}
                              />
                            </h6>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-4 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box box m-2 p-2">
                        <Row>
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <div
                              className="color-light-pink form-div"
                              style={{
                                fontSize: '10px',
                              }}
                            >
                              Image Banner For Registration Page (990*360):{' '}
                            </div>
                          </Col>
                          <Col
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            className="value-form"
                          >
                            <h6>Episode Thumbnail</h6>
                          </Col>

                          <Col
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            className="value-form"
                          >
                            <h6
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              <label
                                htmlFor="filesE"
                                className="btn m-0"
                                style={{
                                  background: '#cc52e0',
                                  color: '#ffffff',
                                  padding: '0px 10px',
                                }}
                              >
                                Select
                              </label>
                              <input
                                id="filesE"
                                style={{ display: 'none' }}
                                type="file"
                                className="form-control"
                                size="sm"
                                accept=".jpg"
                                onChange={(e) => {
                                  console.log(
                                    'setting episode bannner',
                                    e.target.files.length
                                  )
                                  e.target.files.length
                                    ? setEpisodeBanner(e.target.files[0])
                                    : null
                                }}
                              />
                            </h6>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-4 col-lg-1 col-md-1 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box box m-2 p-2">
                        <Row>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="value-form"
                          >
                            <h6
                              style={{
                                textAlign: 'center',
                                fontSize: '12px',
                                color: '#c852e6',
                              }}
                            >
                              PLUS
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  value="option1"
                                  style={{
                                    marginLeft: '-5px',
                                    marginTop: '10px',
                                  }}
                                  onClick={(e) => {
                                    setEpisodeisChecked(e.target.checked)
                                  }}
                                />
                              </div>
                            </h6>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-4 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box box m-2 p-3">
                        <Row>
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="value-form"
                          >
                            <h6
                              style={{
                                textAlign: 'left',
                              }}
                            >
                              <div
                                style={{
                                  paddingBottom: '10px',
                                }}
                              >
                                Episode Description
                              </div>
                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="2"
                                value={EpisodeDescription}
                                onChange={(e) => {
                                  setEpisodeDescription(e.target.value)
                                }}
                              ></textarea>
                            </h6>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-4 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <Row>
                      <Col className="box box m-2 p-3">
                        <Row>
                          <Col
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            className="value-form"
                          >
                            <h6>Duration</h6>
                          </Col>

                          <Col
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            className="value-form"
                          >
                            <h6
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              <select
                                className="custom-select"
                                id="inputGroupSelect01"
                                value={EpisodeDuration}
                                onChange={(e) => {
                                  console.log('duration---->', e.target.value)
                                  setEpisodeDuration(e.target.value)
                                }}
                              >
                                <option selected>00</option>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                                <option value="32">32</option>
                                <option value="33">33</option>
                                <option value="34">34</option>
                                <option value="35">35</option>
                                <option value="36">36</option>
                                <option value="37">37</option>
                                <option value="38">38</option>
                                <option value="39">39</option>
                                <option value="40">40</option>
                                <option value="41">41</option>
                                <option value="42">42</option>
                                <option value="43">43</option>
                                <option value="44">44</option>
                                <option value="45">45</option>
                              </select>
                            </h6>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col className="mb-4 col-lg-1 col-md-1 col-sm-12 col-xs-12">
                                        <Row>
                                            <Col className="mt-5 pt-4">
                                                <i className="fa fa-minus-circle" aria-hidden="true" style=
                                                    {{
                                                        fontSize: '35px',
                                                        cursor: 'pointer',
                                                        color: '#ffffff',
                                                        opacity: '0.6',
                                                    }} onClick={handleShow}></i>
                                            </Col>
                                        </Row>
                                    </Col> */}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="mb-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <Row>
                  <Col className="box box m-2 p-3">
                    <Row>
                      <Col lg={6} md={6} sm={12} xs={12} className="value-form">
                        <h6>Add the Video/Episode</h6>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12} className="value-form">
                        <h6
                          style={{
                            textAlign: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={AddEpisode}
                        >
                          <i
                            className="fa fa-plus-circle"
                            aria-hidden="true"
                            style={{ fontSize: '35px' }}
                          ></i>
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="mb-4 col-lg-6 col-md-6 col-sm-12 col-xs-12"></Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row
        className="mb-3 p-4"
        style={{
          background: '#2d2f44',
        }}
      >
        <Col>
          <Form>
            {Episodes.map((episode, index) => {
              console.log('&&&&', episode)
              return (
                <div>
                  <Row className="plus-form-sep">
                    <Col>
                      <Row>
                        <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <Row>
                            <Col className="box box m-2 p-3">
                              <Row>
                                <Col
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className="value-form"
                                >
                                  <h6>Episode Name</h6>
                                </Col>
                                <Col
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className="value-form"
                                >
                                  <h6
                                    style={{
                                      textAlign: 'right',
                                    }}
                                  >
                                    <input
                                      type="text"
                                      value={episode.TITLE}
                                      onChange={(e) => {
                                        updateEpisode(
                                          'TITLE',
                                          e.target.value,
                                          index
                                        )
                                      }}
                                    />
                                  </h6>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="mb-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <Row>
                            <Col className="box box m-2 p-3">
                              <Row>
                                <Col
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className="value-form"
                                >
                                  <h6>Episode Vimeo Link </h6>
                                </Col>
                                <Col
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className="value-form"
                                >
                                  <h6>
                                    <input
                                      type="text"
                                      value={episode.VIDEO}
                                      onChange={(e) => {
                                        updateEpisode(
                                          'VIDEO',
                                          e.target.value,
                                          index
                                        )
                                      }}
                                    />
                                  </h6>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="mb-4 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <Row>
                            <Col className="box box m-2 p-2">
                              <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                  <div
                                    className="color-light-pink form-div"
                                    style={{
                                      fontSize: '10px',
                                    }}
                                  >
                                    Image Banner For Registration Page
                                    (990*360):{' '}
                                    <a
                                      href={episode.banner}
                                      disabled
                                      alt="banner image"
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      Open current banner image
                                    </a>
                                  </div>
                                </Col>
                                <Col
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  className="value-form"
                                >
                                  <h6>Episode Thumbnail</h6>
                                </Col>

                                <Col
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  className="value-form"
                                >
                                  <h6
                                    style={{
                                      textAlign: 'right',
                                    }}
                                  >
                                    <div
                                      htmlFor={index}
                                      className="btn m-0"
                                      style={{
                                        background: '#cc52e0',
                                        color: '#ffffff',
                                        padding: '0px 10px',
                                      }}
                                    >
                                      Select
                                    </div>
                                    <input
                                      id={index}
                                      style={{ display: 'none' }}
                                      type="file"
                                      size="sm"
                                      accept=".jpg"
                                      onChange={(e) => {
                                        console.log(
                                          'setting episode bannner',
                                          e.target.files.length
                                        )
                                        e.target.files.length
                                          ? updateEpisodeBanner(
                                              e.target.files[0],
                                              index
                                            )
                                          : null
                                      }}
                                    />
                                  </h6>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="mb-4 col-lg-1 col-md-1 col-sm-12 col-xs-12">
                          <Row>
                            <Col className="box box m-2 p-2">
                              <Row>
                                <Col
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className="value-form"
                                >
                                  <h6
                                    style={{
                                      textAlign: 'center',
                                      fontSize: '12px',
                                      color: '#c852e6',
                                    }}
                                  >
                                    PLUS
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox1"
                                        value="option1"
                                        style={{
                                          marginLeft: '-5px',
                                          marginTop: '10px',
                                        }}
                                        checked={episode.IS_PAID}
                                        onClick={(e) => {
                                          updateEpisode(
                                            'IS_PAID',
                                            e.target.checked,
                                            index
                                          )
                                        }}
                                      />
                                    </div>
                                  </h6>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-4 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <Row>
                            <Col className="box box m-2 p-3">
                              <Row>
                                <Col
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className="value-form"
                                >
                                  <h6
                                    style={{
                                      textAlign: 'left',
                                    }}
                                  >
                                    <div
                                      style={{
                                        paddingBottom: '10px',
                                      }}
                                    >
                                      Episode Description
                                    </div>
                                    <textarea
                                      className="form-control"
                                      id="exampleFormControlTextarea1"
                                      rows="2"
                                      value={episode.DESCRIPTION}
                                      onChange={(e) => {
                                        updateEpisode(
                                          'DESCRIPTION',
                                          e.target.value,
                                          index
                                        )
                                      }}
                                    ></textarea>
                                  </h6>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="mb-4 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <Row>
                            <Col className="box box m-2 p-3">
                              <Row>
                                <Col
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  className="value-form"
                                >
                                  <h6>Duration</h6>
                                </Col>

                                <Col
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  className="value-form"
                                >
                                  <h6
                                    style={{
                                      textAlign: 'right',
                                    }}
                                  >
                                    <select
                                      className="custom-select"
                                      id="inputGroupSelect01"
                                      value={episode.DURATION}
                                    >
                                      onChange=
                                      {(e) => {
                                        updateEpisode(
                                          'DURATION',
                                          e.target.value,
                                          index
                                        )
                                      }}
                                      <option selected>00</option>
                                      <option value="1">01</option>
                                      <option value="2">02</option>
                                      <option value="3">03</option>
                                      <option value="4">04</option>
                                      <option value="5">05</option>
                                      <option value="6">06</option>
                                      <option value="7">07</option>
                                      <option value="8">08</option>
                                      <option value="9">09</option>
                                      <option value="10">10</option>
                                      <option value="11">11</option>
                                      <option value="12">12</option>
                                      <option value="13">13</option>
                                      <option value="14">14</option>
                                      <option value="15">15</option>
                                      <option value="16">16</option>
                                      <option value="17">17</option>
                                      <option value="18">18</option>
                                      <option value="19">19</option>
                                      <option value="20">20</option>
                                      <option value="21">21</option>
                                      <option value="22">22</option>
                                      <option value="23">23</option>
                                      <option value="24">24</option>
                                      <option value="25">25</option>
                                      <option value="26">26</option>
                                      <option value="27">27</option>
                                      <option value="28">28</option>
                                      <option value="29">29</option>
                                      <option value="30">30</option>
                                      <option value="31">31</option>
                                      <option value="32">32</option>
                                      <option value="33">33</option>
                                      <option value="34">34</option>
                                      <option value="35">35</option>
                                      <option value="36">36</option>
                                      <option value="37">37</option>
                                      <option value="38">38</option>
                                      <option value="39">39</option>
                                      <option value="40">40</option>
                                      <option value="41">41</option>
                                      <option value="42">42</option>
                                      <option value="43">43</option>
                                      <option value="44">44</option>
                                      <option value="45">45</option>
                                    </select>
                                  </h6>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="mb-4 col-lg-1 col-md-1 col-sm-12 col-xs-12">
                          <Row>
                            <Col className="mt-5 pt-4">
                              <i
                                className="fa fa-minus-circle"
                                aria-hidden="true"
                                style={{
                                  fontSize: '35px',
                                  cursor: 'pointer',
                                  color: '#ffffff',
                                  opacity: '0.6',
                                }}
                                onClick={() => handleShow(index)}
                              ></i>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )
            })}
            {/* </Row> */}

            <Row>
              <Col
                className="value-form mt-4 mb-3"
                style={{
                  textAlign: 'center',
                }}
              >
                <Button onClick={onSubmitHandler}>Save</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Modal show={show} onHide={handleNoClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure? You want to delete this Video/Episode
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleYesClose()}>
            Yes
          </Button>
          <Button variant="primary" onClick={() => handleNoClose()}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
})
