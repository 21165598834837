import React, { useState, useEffect } from 'react'
import {
  Col,
  Container,
  Form,
  Row,
  Table,
  Button,
  Spinner,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import {
  APIGetBlogsByName,
  APIGetBlogsData,
  APIGetBlogsDataAdmin,
} from '../../utils/config/API'
import withLoginRequired from '../../HOC/LoginModal'

export default withLoginRequired(() => {
  const [blogs, setBlogs] = useState([])

  const [searchString, setSearchString] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const getBlogsData = async () => {
    try {
      setIsLoading(true)
      // const result = await axios.get(APIGetBlogsData)
      const result = await axios.get(APIGetBlogsDataAdmin)
      console.log('data ------>', result.data.data)
      if (result.status === 200) {
        setBlogs(result.data.data)
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)

      console.log('error ----->', err)
    }
  }

  // useEffect(() => {
  //   getBlogsData()
  // }, [])

  function formatTime(seconds) {
    if (seconds === 0) {
      return '0 seconds'
    }

    const duration = moment.duration(seconds, 'seconds')
    const hours = Math.floor(duration.asHours())
    const minutes = Math.floor(duration.asMinutes()) - hours * 60
    const remainingSeconds = seconds - hours * 3600 - minutes * 60

    const parts = []
    if (hours > 0) {
      parts.push(`${hours} ${hours > 1 ? 'hours' : 'hour'}`)
    }
    if (minutes > 0) {
      parts.push(`${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`)
    }
    if (remainingSeconds > 0) {
      parts.push(
        `${remainingSeconds} ${remainingSeconds > 1 ? 'seconds' : 'second'}`
      )
    }

    return parts.join(' ')
  }

  useEffect(() => {
    let cancel
    const timerId = setTimeout(() => {
      if (searchString) {
        setIsLoading(true)
        axios
          .get(APIGetBlogsByName + searchString, {
            cancelToken: new axios.CancelToken((c) => {
              // An executor function receives a cancel function as a parameter
              cancel = c
            }),
          })
          .then((response) => {
            setBlogs(response.data.data)
            setIsLoading(false)
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              // Request was canceled
              console.log('Request canceled: ', error.message)
            } else {
              console.log(error)
              setIsLoading(false)
            }
          })
      } else {
        getBlogsData()
      }
    }, 1000)
    return () => {
      clearTimeout(timerId)
      if (cancel) {
        cancel('Request canceled by user')
      }
    }
  }, [searchString])

  let tableBodyComponent = ''
  if (blogs.length) {
    tableBodyComponent = isLoading ? (
      <tr>
        {/* eslint-disable-next-line */}
        <td colSpan={8}>
          <Spinner animation="border" size="sm" />
        </td>
      </tr>
    ) : (
      blogs
        ?.filter((blog) => {
          if (searchString) {
            return blog.TITLE.toLowerCase().includes(searchString.toLowerCase())
          }
          return true
        })
        .map((blog) => {
          return (
            <tr
              key={blog.POST_ID}
              // onClick={() => handleClick(userData.ID)}
              role="button"
              className="data-row"
            >
              <td>{blog.TITLE}</td>
              <td>{moment(blog.ADDED_TIME).format('MMM DD, HH:mm')}</td>
              <td>{blog.CATEGORIES}</td>
              <td>{blog.UNIQUE_USERS}</td>
              <td>{blog.TOTAL_COMMENTS}</td>
              <td>{formatTime(blog.TOTAL_TIME_SPENT)}</td>
              <td>{blog.STATUS}</td>
              <td>
                <Link to={`/edit-blog/${blog.POST_ID}`}>
                  <Button className="btn-primary">Edit</Button>
                </Link>
              </td>
            </tr>
          )
        })
    )
  } else if (!blogs.length && !isLoading) {
    tableBodyComponent = (
      <tr>
        <td colSpan={8}>No Blogs Found!</td>
      </tr>
    )
  }

  const handleAdd = () => {
    window.open('edit-blog')
  }

  return (
    <Container fluid className="w-100">
      <Row className="heading mb-3">
        <h3 className="title">
          Blog List
          {/* {userListData !== undefined
            ? userListData.length > 0
              ? userListData.length
              : 0
            : 0}
          ) */}
        </h3>
      </Row>

      <Row className="mb-3 w-50 pr-3">
        <Col>
          {/* <Form onSubmit={onSearchHandler}> */}
          <Form>
            <Form.Control
              type="text"
              placeholder="Blog Title/Category"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
          </Form>
        </Col>

        <Col sm={4} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Form>
            <Button className="btn-primary" onClick={() => handleAdd()}>
              Add Blog
            </Button>
          </Form>
        </Col>
      </Row>

      <Row>
        <Table responsive className="table">
          <thead>
            <tr>
              <th>Blog Title</th>
              <th>Time Added</th>
              <th>Categories</th>
              <th>Users Count</th>
              <th>Comments Count</th>
              <th>Total Time Spent</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{tableBodyComponent}</tbody>
        </Table>
      </Row>
    </Container>
  )
})
