import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Form, Spinner } from 'react-bootstrap'
// import Image from 'next/image'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
// import { loadUser } from '../../actions/authActions'
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import { purple, red, yellow } from '@mui/material/colors'
import { useNavigate } from 'react-router-dom'

// import dynamic from 'next/dynamic'

import Select from '../../components/Shared/MUIComponents/Select'
import MultiSelect from '../../components/Shared/MUIComponents/MultiSelect'
import AutoComplete from '../../components/Shared/MUIComponents/AutoComplete'
import PhoneInput from '../../components/Shared/MUIComponents/PhoneInput'
import TextField from '../../components/Shared/MUIComponents/TextField'
import Modal from '../../components/Shared/MUIComponents/Modal'
import Button from '../../components/Shared/MUIComponents/Button'

import 'react-phone-number-input/style.css'
import { countries, curriculum, grades } from '../../constants/formConstants'
import useForm from '../../hooks/useForm'
import { useStyles } from '../../components/Shared/MUIComponents/styles'

// const AlertModal = dynamic(() => import('../../components/Modal/AlertModal'))

import {
  APIAddCounsellors,
  APIAddMastersCounsellors,
  APIAddMastersUser,
  APIAddPremiumPlusUser,
  APICaptureMandatoryDetails,
  APITimeZoneData,
  APIUpdateMastersFormStatus,
  APIUpdatePremiumStatus,
  APIgetAllCitiesByName,
  APIgetCitiById,
  getCPUsers,
} from '../../utils/config/API'
import './styles.css'
import withLoginRequired from '../../HOC/LoginModal'

const mastersQualification = [
  // {
  // label: 'In College',
  // options: [
  { value: '12', label: 'Year 1' },
  { value: '13', label: 'Year 2' },
  { value: '14', label: 'Year 3' },
  { value: '15', label: 'Year 4' },
  // ],
  // },
  {
    label: 'Graduated',
    value: '9',
  },
  // {
  //   label: 'Others',
  //   value: '7',
  // },
]

const formInitialValues = {
  firstName: '',
  lastName: '',
  city: '',
  state: '',
  countriesOfInterest: [],
  countryCode: '+91',
  // curriculum: '',
  name: '',
  grade: '',
  phoneNumber: '',
  userEmail: '',
  country: 'IN',
  parentFirstName: '',
  parentLastName: '',
  parent2FirstName: '',
  parent2LastName: '',
  parentEmail: '',
  parentPhone: '',
  parent2Email: '',
  parent2Phone: '',
  school: '',
  note: '',
  // graduationYear: '',
  totalNoOfUniversities: '',
}
const formErrors = {
  firstName: true,
  lastName: true,
  city: true,
  state: true,
  countriesOfInterest: true,
  countryCode: false,
  // curriculum: true,
  grade: true,
  phoneNumber: true,
  userEmail: true,
  country: false,
  parentFirstName: false,
  parentLastName: false,
  parent2FirstName: false,
  parent2LastName: false,
  parentEmail: false,
  parentPhone: false,
  parent2Email: false,
  parent2Phone: false,
  school: true,
  note: false,
  // graduationYear: true,
  totalNoOfUniversities: false,
}

const counsellorInitialValues = {
  leadAdvisor: '',
  advisor: '',
  editor: '',
  researcher: '',
}

const counsellorErrors = {
  leadAdvisor: false,
  advisor: false,
  editor: false,
  researcher: false,
}

const UserMandatoryField = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)

  const classes = useStyles()

  const {
    onTextInputChange,
    onSelectInputChange,
    onMultiSelectInputChange,
    setIsLoading,
    setFormDetails,
    values: registrationFormValues,
    ...registrationForm
  } = useForm(formInitialValues, formErrors)

  const {
    onSelectInputChange: onSelectInputChangeV2,
    values: counsellorFormValues,
    ...counsellorForm
  } = useForm(counsellorInitialValues, counsellorErrors)

  const [showAlert, setAlert] = useState(false)
  const [alertColor, seAlertColor] = useState('success')
  const [alertHeader, setAlertHeader] = useState('Successfully submitted')
  const [alertBody, setAlertBody] = useState('Your query has been successfully submitted')
  const [userType, setuserType] = useState('student')
  const [show, setShow] = useState(false)
  const [submitClicked, setsubmitClicked] = useState(false)
  const [isVerified, setisVerified] = useState(true) // false (default)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [showLogin, setShowLogin] = useState({ status: false, message: '' })

  const [showNote, setShowNote] = useState(false)

  const handleNoteClose = () => setShowNote(false)
  const handleNoteShow = () => setShowNote(true)

  const [value, setValue] = useState('')
  const [suggestion, setSuggestion] = useState([])
  const [isSubmitting, setisSubmitting] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [selectedOption, setSelectedOption] = useState(null)
  const [phoneNumberError, setPhoneNumberError] = useState('')

  const navigate = useNavigate()

  const [allCities, setAllCities] = useState([])

  const [cityName, setCityName] = useState('')

  const [CPUsers, setCPUsers] = useState(null)

  const [userId, setUserId] = useState(null)

  const findCityName = async (v) => {
    const cityDetails = !allCities.length
      ? (await axios.get(APIgetCitiById + v)).data.city
      : allCities.find((city) => {
          return parseInt(city.id, 10) === parseInt(v, 10)
        })
    if (cityDetails !== null && cityDetails !== undefined) {
      setCityName(cityDetails.name)
      return cityDetails.name
    }
    return ''
  }

  const findCityID = (v) => {
    console.log('Value for city', v)
    return allCities.find((city) => {
      return city.name.toLowerCase() === v?.toLowerCase()
    })
  }

  const getCPUsersDB = async () => {
    const config = {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
    await axios.get(getCPUsers, config).then((e) => {
      setCPUsers(
        e.data.body
          .filter((item) =>
            ['Aakash', 'Bushnin', 'Radhika', 'Pooja', 'Diandra', 'Aashima', 'Tejaswi', 'Victoria', 'Angel', 'Liya'].includes(
              item.FIRSTNAME
            )
          )
          .map((item) => {
            return { label: item.FIRSTNAME, value: item.ID }
          })
      )
      console.log('Body:', e.data.body)
    })
  }
  useEffect(() => {
    getCPUsersDB()
  }, [])
  useEffect(() => {
    setFormDetails({ ...registrationFormValues, countriesOfInterest: selectedOption?.map((val) => val.value) })
  }, [selectedOption])
  useEffect(() => {
    const getCountry = async () => {
      try {
        const result = await axios.get(APITimeZoneData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        console.log('Getting result', result)
        setFormDetails({ ...registrationFormValues, country: result.data.location_data.country || 'IN' })
      } catch (err) {
        console.log('Error getting geolocation:', err)
      }
    }
    getCountry()
  }, [])

  useEffect(() => {
    if (registrationFormValues.phoneNumber?.length > 2) {
      if (parsePhoneNumber(registrationFormValues.phoneNumber)?.country === 'IN') {
        if (
          // eslint-disable-next-line
          registrationFormValues.phoneNumber.length - parsePhoneNumber(registrationFormValues.phoneNumber)?.countryCallingCode.length >
          10
        ) {
          setButtonDisabled(false)
        } else setButtonDisabled(true)
      }
      if (
        // eslint-disable-next-line
        registrationFormValues.phoneNumber.length - parsePhoneNumber(registrationFormValues.phoneNumber)?.countryCallingCode.length >
        8
      ) {
        setButtonDisabled(false)
      } else setButtonDisabled(true)
    }
  }, [registrationFormValues.phoneNumber])
  useEffect(() => {
    if (auth.userDetails) {
      setFormDetails({ ...registrationFormValues, userID: auth.userDetails.ID })
      if (auth?.userDetails?.FIRSTNAME?.length < 1 || auth?.userDetails?.FIRSTNAME === null) {
        // redirect to mandatory details page
        // if (show !== true) handleShow()
      }
    }
  }, [auth])
  useEffect(() => {
    if (value) {
      const result = findCityID(value)
      console.log('City Result', result)

      onSelectInputChange('city', result?.id, true)
      onSelectInputChange('state', result?.state_id, true)
      // setFormDetails({ ...registrationFormValues, city: findCityID(value)?.id, state: findCityID(value).state_id })
    }
  }, [value, allCities])

  console.log('value', auth)

  const onChangeForm = (e) => {
    console.log('Form changes', e)
    setFormDetails({ ...registrationFormValues, [e.target.name]: e.target.value })
  }
  //   console.log('EVENT DETAILS', eventDetails)
  const isNullish = (target) => {
    // eslint-disable-next-line
    for (const member in target) {
      if (
        (target[member] === null ||
          target[member] === '' ||
          target[member] === undefined ||
          (Array.isArray(target[member]) && !target[member].length)) &&
        member !== 'lastName2'
      ) {
        return true
      }
    }
    return false
  }
  function isValidEmail(email) {
    // A regular expression that matches the pattern of a valid email address
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
  }
  const onSubmitEventRegistration = async () => {
    try {
      setsubmitClicked(true)
      const isFormInvalid = Object.values(registrationForm.errors).reduce((a, b) => a || b)

      if (isFormInvalid) {
        throw Error('Invalid Form Values')
      }
      // if (isNullish(registrationFormValues)) {
      //   return
      // }
      if (!isValidEmail(registrationFormValues.userEmail)) {
        return
      }
      if (
        !isValidPhoneNumber(registrationFormValues.phoneNumber || '') ||
        !isValidPhoneNumber(registrationFormValues.parentPhone || '')
      ) {
        return
      }
      if (registrationFormValues.parentPhone === registrationFormValues.phoneNumber) {
        return
      }

      const apiBody = {
        firstName: registrationFormValues.firstName ? registrationFormValues.firstName.trim() : '',
        city: registrationFormValues.city || '',
        state: registrationFormValues.state || '',
        countriesOfInterest: registrationFormValues.countriesOfInterest.toString() || '',
        curriculum: registrationFormValues.curriculum || '',
        name: registrationFormValues.name || '',
        firstName2: registrationFormValues.firstName2 || '',
        grade: registrationFormValues.grade || '',
        lastName: registrationFormValues.lastName ? registrationFormValues.lastName.trim() : '',
        lastName2: registrationFormValues.lastName2 || '',
        phoneNumber: registrationFormValues.phoneNumber || '',
        email: registrationFormValues.userEmail ? registrationFormValues.userEmail.trim() : '',
        userID: registrationFormValues.userID || '',
        country: registrationFormValues.country || 'IN',
        parentFirstName: registrationFormValues.parentFirstName ? registrationFormValues.parentFirstName.trim() : '',
        parentLastName: registrationFormValues.parentLastName ? registrationFormValues.parentLastName.trim() : '',
        parent2FirstName: registrationFormValues.parent2FirstName ? registrationFormValues.parent2FirstName.trim() : '',
        parent2LastName: registrationFormValues.parent2LastName ? registrationFormValues.parent2LastName.trim() : '',
        parentEmail: registrationFormValues.parentEmail ? registrationFormValues.parentEmail.trim() : '',
        parentPhone: registrationFormValues.parentPhone || '',
        parent2Email: registrationFormValues.parent2Email ? registrationFormValues.parent2Email.trim() : '',
        parent2Phone: registrationFormValues.parent2Phone || '',
        school: registrationFormValues.school || '',
        note: registrationFormValues.note || '',
        // graduationYear: registrationFormValues.graduationYear || '',
        noOfTotalUniversities: registrationFormValues.totalNoOfUniversities || '',
        countryCode: parsePhoneNumber(registrationFormValues.phoneNumber).countryCallingCode,
        parentCountryCode: registrationFormValues.parentPhone
          ? parsePhoneNumber(registrationFormValues.parentPhone).countryCallingCode
          : '',
        parent2CountryCode: registrationFormValues.parent2Phone
          ? parsePhoneNumber(registrationFormValues.parent2Phone).countryCallingCode
          : '',
      }

      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` },
      }
      // try {
      setisSubmitting(true)
      // const isFormInvalid = Object.values(addCourseForm.errors).reduce((a, b) => a || b)

      // if (isFormInvalid) {
      //   throw Error('Invalid Form Values')
      // }
      const registerUser = await axios.post(`${APIAddMastersUser}`, apiBody, config)
      if (registerUser.data.statusCode === 200) {
        // dispatch(loadUser())
        const approveUser = await axios.post(
          APIUpdateMastersFormStatus,
          {
            uuid: registerUser.data.data,
            status: 'Approved',
          },
          config
        )
        if (approveUser.status === 200) {
          setUserId(approveUser.data.data)
          handleShow()
        }
      } else if (registerUser.data.message === 'Invalid Token...') {
        setShowLogin({ status: true, message: 'Token expired or invalid, please login again!' })
      } else {
        setShowLogin({ status: true, message: 'Something went wrong, please contact tech support!' })
      }
      setisSubmitting(false)
    } catch (err) {
      console.log('Error:', err)
      console.log('body:', registrationForm.errors)
      setisSubmitting(false)
      registrationForm.setShowFormErrors(true)
    }
  }

  const handleCounsellorAddition = async () => {
    try {
      if (
        userId &&
        (counsellorFormValues.advisor ||
          counsellorFormValues.leadAdvisor ||
          counsellorFormValues.researcher ||
          counsellorFormValues.editor)
      ) {
        console.log('Made it here', userId)
        const result = await axios.post(
          APIAddMastersCounsellors,
          {
            advisor: counsellorFormValues.advisor,
            leadAdvisor: counsellorFormValues.leadAdvisor,
            researcher: counsellorFormValues.researcher,
            editor: counsellorFormValues.editor,
            note: registrationFormValues.note,
            userID: userId,
          },
          {
            headers: { Authorization: `Bearer ${localStorage.token}` },
          }
        )

        if (result.status === 200) {
          window.open(window.location.href.replace('add-masters-user', '').concat(`user/edit-user/${userId}`), '_self')
        }
      } else {
        console.log("Can't make it here", userId)
      }
    } catch (err) {
      console.log('Catch:', err)
    }
  }

  useEffect(() => {
    if (value) {
      // setFormDetails({ ...registrationFormValues, city: findCityID(value)?.id })
    }
  }, [value, allCities])

  useEffect(() => {
    const source = axios.CancelToken.source() // Create a cancellation token source
    const getAllCitiesByName = async () => {
      try {
        const result = await axios.get(APIgetAllCitiesByName + cityName, { cancelToken: source.token })
        setAllCities(result.data.cities)
        const inputValue = cityName.trim().toLowerCase()
        const inputLength = inputValue.length

        const suggestions =
          inputLength === 0 ? [] : result.data.cities.filter((city) => city.name.toLowerCase().slice(0, inputLength) === inputValue)
        setSuggestion(suggestions)
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was cancelled
          console.log('Request cancelled:', error.message)
        } else {
          // Handle other errors
          console.error('Error:', error.message)
        }
      }
    }

    setAllCities([])
    cityName && cityName.length >= 3 ? getAllCitiesByName() : setSuggestion([])
    // }, [registrationFormValues.city])
    // Cleanup function to cancel the previous request when the component unmounts or the dependency changes

    return () => {
      source.cancel('Request cancelled: New request initiated')
    }
  }, [cityName])

  const handlePaste = (event) => {
    event.preventDefault()

    // Get the pasted text from the clipboard
    const clipboardData = event.clipboardData.getData('text')

    // Remove double quotes and empty spaces
    const cleanedText = clipboardData.replace(/"| /g, '')

    // Insert the cleaned text at the cursor position
    const input = event.target
    const start = input.selectionStart || 0
    const end = input.selectionEnd || 0
    const newValue = input.value.slice(0, start) + cleanedText + input.value.slice(end)

    // Update the input value with the cleaned text
    input.value = newValue
  }

  console.log('FormDetails', registrationFormValues)
  console.log('FormDetails', registrationForm.errors.graduationYear)

  return (
    <>
      <Row className="sidemodal m-auto w-75">
        <Col>
          <Row>
            <Col className="width-100 text-center">{/* <img src="/holo.png" alt="modal-holo" width={50} height={50} /> */}</Col>
          </Row>
          <Row>
            <Col className="width-100 text-center">
              <h1
                style={{
                  lineHeight: '1.5',
                  color: '#ffffff',
                  fontSize: '20px',
                  fontWeight: '600',
                  textTransform: 'uppercase',
                  paddingTop: '10px',
                }}
              >
                {/* Personalize Your Journey */}
                User Mandatory Fields
                {/* <button type="button" onClick={handleNoteShow}>
                  Click me{' '}
                </button> */}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col className="mt-4">
              <Form className="mandatory-form">
                <Row>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      value={registrationFormValues.firstName}
                      errorClass={classes.errorText}
                      id="firstName"
                      name="firstName"
                      onChange={onTextInputChange}
                      label="First name"
                      error={registrationForm.showFormErrors && registrationForm.errors.firstName}
                      errorMessage="First Name is required"
                      validators={['REQUIRE']}
                      required
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      value={registrationFormValues.lastName}
                      errorClass={classes.errorText}
                      id="lastName"
                      name="lastName"
                      onChange={onTextInputChange}
                      label="Last name"
                      error={registrationForm.showFormErrors && registrationForm.errors.lastName}
                      errorMessage="Last Name is required"
                      validators={['REQUIRE']}
                      required
                    />
                  </Col>
                </Row>
                <Row className="userMendSec">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <PhoneInput
                      label="Phone Number"
                      id="phoneNumber"
                      name="phoneNumber"
                      required
                      country={registrationFormValues.country}
                      error={registrationForm.showFormErrors && registrationForm.errors.phoneNumber}
                      errorMessage={
                        registrationFormValues.phoneNumber ? 'Please enter valid Phone Number' : 'Phone Number is required'
                      }
                      onChange={onTextInputChange}
                      value={registrationFormValues.phoneNumber}
                      errorClass={classes.errorText}
                      validators={['REQUIRE', 'VALID_PHONENUMBER']}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      value={registrationFormValues.school}
                      errorClass={classes.errorText}
                      id="school"
                      name="school"
                      onChange={onTextInputChange}
                      label="Company/College Name"
                      error={registrationForm.showFormErrors && registrationForm.errors.school}
                      errorMessage="School Name is required"
                      validators={['REQUIRE']}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      className="mb-4"
                      value={registrationFormValues.userEmail}
                      errorClass={classes.errorText}
                      type="userEmail"
                      id="userEmail"
                      name="userEmail"
                      onChange={onTextInputChange}
                      label="Email"
                      error={registrationForm.showFormErrors && registrationForm.errors.userEmail}
                      errorMessage={registrationFormValues.userEmail ? 'Please enter valid Email' : 'Email is required'}
                      validators={['REQUIRE', 'EMAIL']}
                      required
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12} className='add-master-custom'>
                    <AutoComplete
                      className="mb-4"
                      freeSolo
                      label="City"
                      id="city"
                      options={suggestion.map((item) => item.name)}
                      value={value}
                      error={registrationForm.showFormErrors && registrationForm.errors.city}
                      errorClass={classes.errorAutoText}
                      errorMessage="City is required"
                      onChange={setValue}
                      onInputChange={setCityName}
                      validators={['REQUIRE']}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: '-10px',
                    }}
                  >
                    <FormControl fullWidth>
                      <Select
                        errorClass={classes.errorText}
                        labelId="grade-select-label"
                        id="grade"
                        className="text-white mt-0"
                        options={mastersQualification}
                        label="Education level *"
                        value={registrationFormValues.grade}
                        error={registrationForm.showFormErrors && registrationForm.errors.grade}
                        name="grade"
                        errorMessage="Education level is required"
                        onChange={onSelectInputChange}
                        validators={['REQUIRE']}
                        required
                      />
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col className='add-master-custom mb-3'>
                    <MultiSelect
                      labelId="coi-select-label"
                      id="countriesOfInterest"
                      className="text-white"
                      isMultiple
                      options={countries}
                      label="Countries of Interest *"
                      value={registrationFormValues.countriesOfInterest}
                      error={registrationForm.showFormErrors && registrationForm.errors.countriesOfInterest}
                      name="countriesOfInterest"
                      errorMessage="Countries of Interest is required"
                      errorClass={classes.errorText}
                      onChange={onMultiSelectInputChange}
                      renderValue="normal"
                      validators={['REQUIRE_ARR']}
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: '-6px',
                    }}
                  >
                    <TextField
                      value={registrationFormValues.parentFirstName}
                      errorClass={classes.errorText}
                      id="parentFirstName"
                      name="parentFirstName"
                      onChange={onTextInputChange}
                      label="Parent First name (Optional)"
                      error={registrationForm.showFormErrors && registrationForm.errors.parentFirstName}
                      errorMessage="Parent First Name is required"
                      // validators={['REQUIRE']}
                      // required
                    />
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: '-6px',
                    }}
                  >
                    <TextField
                      value={registrationFormValues.parentLastName}
                      errorClass={classes.errorText}
                      id="parentLastName"
                      name="parentLastName"
                      onChange={onTextInputChange}
                      label="Parent Last name (Optional)"
                      error={registrationForm.showFormErrors && registrationForm.errors.parentLastName}
                      errorMessage="Parent Last Name is required"
                      // validators={['REQUIRE']}
                      // required
                    />
                  </Col>
                </Row>
                <Row className="userMendSec">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <PhoneInput
                      label="Parent Phone Number (Optional)"
                      id="parentPhone"
                      name="parentPhone"
                      // required
                      country={registrationFormValues.country}
                      error={registrationForm.showFormErrors && registrationForm.errors.parentPhone}
                      errorMessage={
                        registrationFormValues.parentPhone ? 'Please enter valid Phone Number' : 'Parent Phone Number is required'
                      }
                      onChange={onTextInputChange}
                      value={registrationFormValues.parentPhone}
                      errorClass={classes.errorText}
                      // validators={['REQUIRE', 'VALID_PHONENUMBER']}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      value={registrationFormValues.parentEmail}
                      errorClass={classes.errorText}
                      type="parentEmail"
                      id="parentEmail"
                      name="parentEmail"
                      onChange={onTextInputChange}
                      label="Parent Email (Optional)"
                      error={registrationForm.showFormErrors && registrationForm.errors.parentEmail}
                      errorMessage={registrationFormValues.parentEmail ? 'Please enter valid Email' : 'Parent Email is required'}
                      // validators={['REQUIRE', 'EMAIL']}
                      // required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      value={registrationFormValues.parent2FirstName}
                      errorClass={classes.errorText}
                      id="parent2FirstName"
                      name="parent2FirstName"
                      onChange={onTextInputChange}
                      label="Parent 2 First name (Optional)"
                      error={registrationForm.showFormErrors && registrationForm.errors.parent2FirstName}
                      errorMessage="First Name is required"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      value={registrationFormValues.parent2LastName}
                      errorClass={classes.errorText}
                      id="parent2LastName"
                      name="parent2LastName"
                      onChange={onTextInputChange}
                      label="Parent 2 Last name (Optional)"
                      error={registrationForm.showFormErrors && registrationForm.errors.parent2LastName}
                      errorMessage="Last Name is required"
                    />
                  </Col>
                </Row>
                <Row className="userMendSec">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <PhoneInput
                      label="Parent 2 Phone Number (Optional)"
                      id="parent2Phone"
                      name="parent2Phone"
                      // required
                      country={registrationFormValues.country}
                      error={registrationForm.showFormErrors && registrationForm.errors.parent2Phone}
                      errorMessage={
                        registrationFormValues.parent2Phone ? 'Please enter valid Phone Number' : 'Phone Number is required'
                      }
                      onChange={onTextInputChange}
                      value={registrationFormValues.parent2Phone}
                      errorClass={classes.errorText}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      value={registrationFormValues.parent2Email}
                      errorClass={classes.errorText}
                      type="parent2Email"
                      id="parent2Email"
                      name="parent2Email"
                      onChange={onTextInputChange}
                      label="Parent 2 Email (Optional)"
                      error={registrationForm.showFormErrors && registrationForm.errors.parent2Email}
                      errorMessage={registrationFormValues.parent2Email ? 'Please enter valid Email' : 'Email is required'}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    {/* <TextField
                      value={registrationFormValues.totalNoOfUniversities}
                      errorClass={classes.errorText}
                      id="totalNoOfUniversities"
                      name="totalNoOfUniversities"
                      onChange={onTextInputChange}
                      label="Total No. Of Universities"
                      error={registrationForm.showFormErrors && registrationForm.errors.totalNoOfUniversities}
                      errorMessage="School Name is required"
                      // validators={['REQUIRE']}
                    /> */}
                    <FormControl fullWidth>
                      <Select
                        errorClass={classes.errorText}
                        labelId="uni-select-label"
                        id="totalNoOfUniversities"
                        className="text-white mt-0"
                        options={[
                          { label: 4, value: 4 },
                          { label: 6, value: 6 },
                          { label: 8, value: 8 },
                          { label: 10, value: 10 },
                          { label: 12, value: 12 },
                        ]}
                        label="Total No. Of Universities"
                        value={registrationFormValues.totalNoOfUniversities}
                        error={registrationForm.showFormErrors && registrationForm.errors.totalNoOfUniversities}
                        name="totalNoOfUniversities"
                        errorMessage="Total No. of Universities is required"
                        onChange={onSelectInputChange}
                        // validators={['REQUIRE']}
                        // required
                      />
                    </FormControl>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12} className="width-100 text-center mt-0 pb-5">
                    {isSubmitting ? (
                      <Spinner animation="border" role="status" disabled></Spinner>
                    ) : (
                      <Button variant="primary" color={purple} onClick={onSubmitEventRegistration} className="regProceedButton m-auto">
                        Proceed
                      </Button>
                    )}
                  </Col>
                </Row>
                <br />
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal open={showLogin.status} handleClose={() => setShowLogin({ status: false, message: '' })}>
        <>
          <Row style={{ width: '100%' }}>
            <Row
              style={{
                width: '100%',
                fontWeight: 500,
                color: 'white',
                fontSize: '1.5rem',
              }}
              className="d-flex justify-content-center pb-3 pt-3"
            >
              {showLogin.message}
            </Row>
          </Row>
          {showLogin.message === 'Token expired or invalid, please login again!' && (
            <Row className="d-flex justify-content-around">
              <Col className="d-flex justify-content-center">
                <Button
                  onClick={() => {
                    navigate('/login')
                  }}
                  color={purple}
                >
                  Login
                </Button>
              </Col>
              {/* <Col className="d-flex justify-content-center">
            <Button
              onClick={() => {
                window.location.reload()
              }}
            >
              Cancel
            </Button>
          </Col> */}
            </Row>
          )}
        </>
      </Modal>
      {/* <AlertModal show={showAlert} title={alertHeader} message={alertBody} type={alertColor} /> */}
      <Modal open={show} handleClose={handleClose} handleOpen={handleShow}>
        <Row className="d-flex justify-content-center align-content-center">
          <Col>
            <Typography variant="h5" gutterBottom className="text-center" style={{ color: '#ffffff' }}>
              Counsellors
            </Typography>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={6} md={6} sm={12} xs={12}>
            <Select
              errorClass={classes.errorText}
              labelId="la-select-label"
              id="leadAdvisor"
              className="text-black"
              options={CPUsers}
              label="Case Team Leader"
              value={counsellorFormValues.leadAdvisor}
              error={counsellorForm.showFormErrors && counsellorForm.errors.leadAdvisor}
              name="leadAdvisor"
              errorMessage="Lead Advisor is required"
              onChange={onSelectInputChangeV2}
              // validators={['REQUIRE']}
              // required
            />
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <Select
              errorClass={classes.errorText}
              labelId="advisor-select-label"
              id="advisor"
              className="text-black"
              options={CPUsers}
              label="Consultant/Sr. Consultant"
              value={counsellorFormValues.advisor}
              error={counsellorForm.showFormErrors && counsellorForm.errors.advisor}
              name="advisor"
              errorMessage="Advisor is required"
              onChange={onSelectInputChangeV2}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={6} md={6} sm={12} xs={12}>
            <Select
              errorClass={classes.errorText}
              labelId="editor-select-label"
              id="editor"
              className="text-black"
              options={CPUsers}
              label="Editor"
              value={counsellorFormValues.editor}
              error={counsellorForm.showFormErrors && counsellorForm.errors.editor}
              name="editor"
              errorMessage="Editor is required"
              onChange={onSelectInputChangeV2}
            />
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <Select
              errorClass={classes.errorText}
              labelId="researcher-select-label"
              id="researcher"
              className="text-black"
              options={CPUsers}
              label="Associate Researcher"
              value={counsellorFormValues.researcher}
              error={counsellorForm.showFormErrors && counsellorForm.errors.researcher}
              name="researcher"
              errorMessage="Researcher is required"
              onChange={onSelectInputChangeV2}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="d-flex justify-content-center gap-3 text-center m-auto">
            <Button onClick={handleClose} color={purple}>
              Close
            </Button>
            <Button onClick={handleCounsellorAddition} color={purple}>
              Save
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal open={showNote} handleClose={handleNoteClose} handleOpen={handleNoteShow}>
        <Row className="d-flex justify-content-center align-content-center">
          <Col>
            <Typography variant="h5" gutterBottom className="text-center" style={{ color: '#ffffff' }}>
              Note (Optional)
            </Typography>
          </Col>
        </Row>
        <Row className="mt-4">
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <TextField
                value={registrationFormValues.note}
                errorClass={classes.errorText}
                id="note"
                name="note"
                onChange={onTextInputChange}
                label="Note"
                multiline
                rows={3}
                error={registrationForm.showFormErrors && registrationForm.errors.note}
                errorMessage="School Name is required"
                // validators={['REQUIRE']}
              />
            </Col>
          </Row>
        </Row>
        <Row className="mt-4">
          <Col className="d-flex justify-content-center text-center m-auto">
            {/* <Button onClick={handleClose} color={purple}>
              Close
            </Button> */}
            <Button onClick={handleCounsellorAddition} color={purple}>
              Save
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default withLoginRequired(UserMandatoryField)
