import React, { useCallback } from 'react'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import ListSubheader from '@mui/material/ListSubheader'
import { validate } from '../../../../utils/validators'

const Index = ({
  id,
  label,
  labelId,
  name,
  value,
  className = '',
  errorClass = null,
  error = false,
  errorMessage = '',
  required = false,
  onChange = () => {},
  validators = [],
  isMultiple = false,
  options = [],
}) => {
  const onChangeHandler = useCallback(
    (e) => {
      const v = e.target.value
      const isValid = validate(v, validators)
      onChange(id, v, isValid)
    },
    [id, onChange, validators]
  )
  return (
    <FormControl fullWidth error={error}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        sx={{
          fieldset: {
            borderColor: error ? '#d32f2f !important' : '',
          },

          label: {
            color: error ? '#d32f2f !important' : '',
          },
        }}
        labelId={labelId}
        id={id}
        className={className}
        required={required}
        label={label}
        value={value}
        name={name}
        multiple={isMultiple}
        onChange={onChangeHandler}
      >
        {options.map((item, idx) => {
          // Handle grouped options
          if (item.options) {
            return (
              <React.Fragment key={item.value}>
                <ListSubheader>{item.label}</ListSubheader>
                {item.options.map((subItem) => (
                  <MenuItem key={subItem.value} value={subItem.value}>
                    {subItem.label}
                  </MenuItem>
                ))}
              </React.Fragment>
            )
          }
          // Handle flat options
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          )
        })}
      </Select>
      {error && errorMessage && (
        <FormHelperText className={errorClass}>
          <i className="fa fa-exclamation-triangle"></i> {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default Index
