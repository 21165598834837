import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Row, Table, Button, Spinner, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Bar, Chart as Chart2 } from 'react-chartjs-2'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import axios from 'axios'
import moment from 'moment'
import { convertArrayToCSV } from 'convert-array-to-csv'
import { APIGetBlogsData, APIUpdateCampaignTitleAndDescription, getCampaigns } from '../../utils/config/API'
import withLoginRequired from '../../HOC/LoginModal'

export default withLoginRequired(() => {
  const [campaigns, setCampaigns] = useState([])
  const [AnalyticsData, setAnalyticsData] = useState(null)
  const [showData, setShowData] = useState(false)

  const openDataModal = (data) => {
    setAnalyticsData(data)
    setShowData(true)
  }
  const closeDataModal = () => {
    setAnalyticsData(null)
    setShowData(false)
  }
  const getCampaignsData = async () => {
    try {
      const result = await axios.get(getCampaigns)
      console.log('data ------>', result.data.data)
      if (result.status === 200) {
        setCampaigns(result.data.data)
      }
    } catch (err) {
      console.log('error ----->', err)
    }
  }

  useEffect(() => {
    getCampaignsData()
  }, [])

  function formatTime(seconds) {
    if (seconds === 0) {
      return '0 seconds'
    }

    const duration = moment.duration(seconds, 'seconds')
    const hours = Math.floor(duration.asHours())
    const minutes = Math.floor(duration.asMinutes()) - hours * 60
    const remainingSeconds = seconds - hours * 3600 - minutes * 60

    const parts = []
    if (hours > 0) {
      parts.push(`${hours} ${hours > 1 ? 'hours' : 'hour'}`)
    }
    if (minutes > 0) {
      parts.push(`${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`)
    }
    if (remainingSeconds > 0) {
      parts.push(`${remainingSeconds} ${remainingSeconds > 1 ? 'seconds' : 'second'}`)
    }

    return parts.join(' ')
  }

  const [showEdit, setShowEdit] = useState(false)

  const [editId, setEditId] = useState(null)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [isLoading, setIsloading] = useState(false)
  const onEditSentNotification = (notification) => {
    setShowEdit(true)
    setEditId(notification.ID)
    setTitle(notification.TITLE)
    setDescription(notification.DESCRIPTION)
  }

  const handleCloseEditModal = () => {
    setShowEdit(false)
    setEditId(null)
    setTitle('')
    setDescription('')
  }

  const handleEdit = async () => {
    try {
      setIsloading(true)
      const result = await axios.put(APIUpdateCampaignTitleAndDescription, {
        id: editId,
        title,
        description,
      })
      console.log('Result--->', result)
      setIsloading(false)

      setShowEdit(false)
      setEditId(null)
      setTitle('')
      setDescription('')
      getCampaignsData()
    } catch (err) {
      setIsloading(false)
      // setShowEdit(false)
      // setEditId(null)
      // setTitle('')
      // setDescription('')

      console.log('Error', err)
    }
  }

  const pattern = /\$\{\{\{([0-9A-Fa-f]+)\}\}\}/g // pattern to match hexadecimal codes

  const getNotificationData = (data) => {
    // Extract relevant data from the object
    const androidData = {
      OS: 'Android',
      SENT: data.SENT_COUNT_ANDROID || 0,
      FAILED: data.FAILED_COUNT_ANDROID || 0,
      RECEIVED: data.RECEIVED_COUNT_ANDROID || 0,
      OPEN: data.OPEN_COUNT_ANDROID || 0,
    }

    const iosData = {
      OS: 'IOS',
      SENT: data.SENT_COUNT_IOS || 0,
      FAILED: data.FAILED_COUNT_IOS || 0,
      RECEIVED: data.RECEIVED_COUNT_IOS || 0,
      OPEN: data.OPEN_COUNT_IOS || 0,
    }

    // Prepare the transformed data
    const transformedData = [androidData, iosData]
    const csvFromArrayOfObjects = convertArrayToCSV(transformedData)
    const csvContent = `data:text/csv;charset=utf-8,${csvFromArrayOfObjects}`
    const encodedUri = encodeURI(csvContent)
    const downloadLink = document.createElement('a')
    downloadLink.href = encodedUri
    downloadLink.download = `ANALYTICS_${data.TITLE.replace(pattern, (match, p1) => String.fromCodePoint(parseInt(p1, 16)))}_${moment(
      data.SEND_TIME
    ).format('YYYY-MM-DDTHH:mm')}.csv`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  let tableBodyComponent = ''
  if (campaigns.length) {
    tableBodyComponent = campaigns?.map((c) => {
      return (
        <tr
          key={c.POST_ID}
          // onClick={() => handleClick(userData.ID)}
          role="button"
          className="data-row"
        >
          <td>{c.TITLE.replace(pattern, (match, p1) => String.fromCodePoint(parseInt(p1, 16)))}</td>
          <td>{c.SEND_TIME ? moment(c.SEND_TIME).format('MMM D, HH:mm') : '-'}</td>
          {/* <td>
            {c.STATUS === 'SCHEDULED'
              ? '-'
              : `${c.SENT_COUNT_ANDROID}/${c.SENT_COUNT_IOS}`}
          </td>
          <td>
            {c.STATUS === 'SCHEDULED'
              ? '-'
              : `${c.FAILED_COUNT_ANDROID}/${c.FAILED_COUNT_IOS}`}
          </td>
          <td>
            {c.STATUS === 'SCHEDULED'
              ? '-'
              : `${c.RECEIVED_COUNT_ANDROID}/${c.RECEIVED_COUNT_IOS}`}
          </td>
          <td>
            {c.STATUS === 'SCHEDULED'
              ? '-'
              : `${c.OPEN_COUNT_ANDROID}/${c.OPEN_COUNT_IOS}`}
          </td> */}
          <td>{c.STATUS ? c.STATUS : '-'}</td>
          <td>
            {c.STATUS === 'SCHEDULED' ? (
              <Link to={`/notifications/${c.ID}`}>
                <Button className="btn-primary">Edit</Button>
              </Link>
            ) : (
              <Button
                className="btn-primary"
                // disabled
                style={
                  {
                    // cursor: 'none!important',
                  }
                }
                onClick={() => onEditSentNotification(c)}
              >
                Edit
              </Button>
            )}
          </td>
          <td>
            {c.STATUS === 'SCHEDULED' || c.ID < 159 ? (
              '-'
            ) : (
              <Button
                className="btn-primary"
                // disabled
                style={
                  {
                    // cursor: 'none!important',
                  }
                }
                onClick={() => openDataModal(c)}
              >
                View
              </Button>
            )}
          </td>
          <td>
            {c.STATUS === 'SCHEDULED' || c.ID < 159 ? (
              '-'
            ) : (
              <Button
                className="btn-primary"
                // disabled
                style={
                  {
                    // cursor: 'none!important',
                  }
                }
                onClick={() => getNotificationData(c)}
              >
                Download
              </Button>
            )}
          </td>
        </tr>
      )
    })
  }

  const dataLabelsPlugin = {
    id: 'dataLabels',
    afterDraw: (chart) => {
      const ctx = chart.ctx
      // ctx.font = Chart.helpers.fontString(
      //   Chart.defaults.font.size,
      //   Chart.defaults.font.style,
      //   Chart.defaults.font.family
      // )
      ctx.font = '12px Arial' // Set the font properties directly

      ctx.fillStyle = '#000' // Set the color for data labels
      ctx.textAlign = 'center'
      ctx.textBaseline = 'bottom'

      chart.data.datasets.forEach((dataset, datasetIndex) => {
        const meta = chart.getDatasetMeta(datasetIndex)
        meta.data.forEach((element, index) => {
          const value = dataset.data[index]
          if (value !== 0) {
            // Exclude 0 values from displaying data labels
            // eslint-disable-next-line
            ctx.fillText(value, element.x, element.y - 10) // Adjust the position of the data labels
          }
        })
      })
    },
  }

  console.log('Datas', AnalyticsData)
  return (
    <Container fluid className="w-100">
      <Row className="heading mb-3">
        <h3 className="title">
          Campaigns List
          {/* {userListData !== undefined
            ? userListData.length > 0
              ? userListData.length
              : 0
            : 0}
          ) */}
        </h3>
      </Row>

      <Row className="mb-3 w-50 pr-3">
        {/* <Col> */}
        {/* <Form onSubmit={onSearchHandler}> */}
        {/* <Form> */}
        {/* <Form.Control */}
        {/* type="text" */}
        {/* placeholder="Enter Email / Phone" */}
        {/* // value={searchText} */}
        {/* // onChange={(e) => setSearchText(e.target.value)} */}
        {/* /> */}
        {/* </Form> */}
        {/* </Col> */}
        <Col className="w-25 mb-3">
          {/* <Link to="/notifications">
            <Button
              className="btn-primary"
              // onClick={handleSubmit}
              style={{
                padding: '8px 12px',
                fontSize: '1rem',
                marginRight: '15px'
              }}
            >
              ADD
            </Button>
          </Link> */}
          {/* </Col>
        <Col> */}
          <Link to="/onesignal-firebase-notifications">
            <Button
              className="btn-primary"
              // onClick={handleSubmit}
              style={{
                padding: '8px 12px',
                fontSize: '1rem',
              }}
            >
              ADD(Onesignal & Firebase)
            </Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Table responsive className="table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Sent At</th>
              {/* <th>
                Sent
                <br />
                Android/IOS
              </th>
              <th>
                Failed
                <br />
                Android/IOS
              </th>
              <th>
                Received
                <br />
                Android/IOS
              </th>
              <th>
                Opened
                <br />
                Android/IOS
              </th> */}
              <th>Status</th>
              <th>Action</th>
              <th>Analytics</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>{tableBodyComponent}</tbody>
        </Table>
      </Row>

      <Modal show={showEdit && editId && title.length && description.length} onHide={() => handleCloseEditModal()} size="lg">
        <Modal.Header closeButton>
          <h5 className="text-center ml-4 pt-3 pb-3 width-100">Edit Archive Video</h5>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Control type="text" name="context" value={title} onChange={(e) => setTitle(e.target.value)} className="mb-3" />
              {/* </Form.Group> */}
              <Form.Control
                // type="text"
                as="textarea"
                rows={4}
                name="context"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mb-3"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <span className="mt-3 mb-3 width-100 text-center d-flex justify-content-center">
            <Button variant="primary" onClick={() => handleEdit()} className="m-2" id="">
              {isLoading ? <Spinner animation="border" size="sm" /> : 'Save'}
            </Button>
            <Button variant="primary" onClick={() => handleCloseEditModal()} className="bg-gray m-2">
              Cancel
            </Button>
          </span>
        </Modal.Footer>
      </Modal>

      {showData && AnalyticsData && (
        <Modal show={showData && AnalyticsData} onHide={() => closeDataModal()} size="lg">
          <Modal.Body>
            <Bar
              data={{
                labels: ['Android', 'iOS'],
                datasets: [
                  {
                    label: 'Sent',
                    data: [AnalyticsData.SENT_COUNT_ANDROID, AnalyticsData.SENT_COUNT_IOS],
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                  },
                  {
                    label: 'Failed',
                    data: [AnalyticsData.FAILED_COUNT_ANDROID, AnalyticsData.FAILED_COUNT_IOS],
                    // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    backgroundColor: 'red',
                  },
                  {
                    label: 'Received',
                    data: [AnalyticsData.RECEIVED_COUNT_ANDROID, AnalyticsData.RECEIVED_COUNT_IOS],
                    // backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    backgroundColor: 'orange',
                  },
                  {
                    label: 'Opened',
                    data: [AnalyticsData.OPEN_COUNT_ANDROID, AnalyticsData.OPEN_COUNT_IOS],
                    backgroundColor: 'rgba(255, 206, 86, 0.5)',
                  },
                ],
              }}
              options={{
                plugins: {
                  datalabels: {
                    anchor: 'end',
                    align: 'end',
                    formatter: (value) => value, // Display the actual value as the label
                    // offset: 50,
                  },
                  padding: {
                    bottom: 10,
                  },
                },
                scales: {
                  x: {
                    type: 'category',
                    labels: ['Android', 'iOS'],
                    scale: {
                      display: true,
                      title: {
                        display: true,
                        text: 'Platforms',
                      },
                    },
                    ticks: {
                      // padding: 10,
                    },
                  },
                  y: {
                    beginAtZero: true,
                    scaleLabel: {
                      display: true,
                      labelString: 'Count',
                    },
                    // max: 'auto',
                    ticks: {
                      // padding: 10,
                    },
                  },
                },
                layout: {
                  padding: {
                    padding: 100,
                  },
                },
              }}
              plugins={[
                ChartDataLabels,
                dataLabelsPlugin,
                {
                  id: 'increase-legend-spacing',
                  beforeInit(chart) {
                    // Get reference to the original fit function
                    const originalFit = chart.legend.fit

                    // Override the fit function
                    // eslint-disable-next-line
                    chart.legend.fit = function fit() {
                      // Call original function and bind scope in order to use `this` correctly inside it
                      originalFit.bind(chart.legend)()
                      // Change the height as suggested in another answers
                      this.height += 25
                    }
                  },
                },
              ]}
            />
          </Modal.Body>
        </Modal>
      )}
    </Container>
  )
})
