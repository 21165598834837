import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FloatingLabel,
  Card,
  Alert,
  Modal,
} from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'

import axios from 'axios'

import './styles.css'
import {
  APIAddStory,
  APIGetStory,
  APIUpdateStory,
} from '../../utils/config/API'
import withLoginRequired from '../../HOC/LoginModal'

// import { fontWeight } from '@mui/system'

export default withLoginRequired(() => {
  const storyId = useParams().id
  const navigate = useNavigate()

  const token = localStorage.getItem('token')

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [uploadObj, setUploadObj] = useState({
    story_title: '',
    story_description: '',
    story_vimeoId: '',
    story_image_url: '',
  })

  const [displayBannerImg, setDisplayBannerImg] = useState({
    preview: '',
    raw: '',
  })

  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  const getStory = async (id) => {
    try {
      const story = await axios.get(`${APIGetStory}${id}`)
      if (story.status === 200) {
        setUploadObj((prev) => {
          return {
            ...prev,
            story_title: story.data.body.TITLE,
            story_description: story.data.body.DESCRIPTION,
            story_vimeoId: story.data.body.VIMEO_ID,
            story_image_url: story.data.body.IMAGE_URL,
          }
        })
      }
    } catch (err) {
      console.log('Error -------------->', err)
    }
  }
  useEffect(() => {
    if (storyId) {
      getStory(storyId)
    }
  }, [storyId])

  const onSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    if ((!displayBannerImg.raw && !storyId) || !uploadObj.story_title) {
      console.log('Made it here')
      return
    }
    // const eventID = selectClass ? cls.value : liveStream.value
    // const URL = selectClass ? insertArchiveClassesV1 : insertArchiveLiveStreamV1
    // const URL = selectClass ? insertArchiveClassesV2 : insertArchiveLiveStreamV2

    const formData = new FormData()
    uploadObj.story_vimeoId &&
      formData.append(
        'vimeoId',
        uploadObj.story_vimeoId
          .replace('https://vimeo.com/', '')
          .replace('/', '?h=')
      )

    formData.append('image', displayBannerImg.raw || null)
    formData.append('title', uploadObj.story_title)
    uploadObj.story_description &&
      formData.append('description', uploadObj.story_description)

    try {
      let URL
      if (storyId) {
        formData.append('id', storyId)
        formData.append('imageUrl', uploadObj.story_image_url)
        URL = APIUpdateStory
      } else {
        URL = APIAddStory
      }
      const result = await axios.post(URL, formData, config)
      console.log('Result', result.data)

      if (result.status === 201) {
        setUploadObj({
          story_description: '',
          story_image_url: '',
          story_title: '',
          story_vimeoId: '',
        })
        setIsSubmitting(false)
        setDisplayBannerImg({ preview: '', raw: '' })
        navigate('/stories-list')
      } else {
        console.log('Error')
      }
    } catch (err) {
      console.log('Error', err)
    }
  }

  const handleDisplayBannerChange = (e) => {
    if (e.target.files.length) {
      setDisplayBannerImg({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }

  return (
    <Container fluid className="w-100">
      <Row className="mb-3">
        <h3 className="title">
          <Link to="/stories-list">
            <i className="bi bi-arrow-left"></i>
          </Link>
          {`${storyId ? 'Edit' : 'Add'} Story`}
        </h3>
      </Row>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col className="mb-4">
            <FloatingLabel
              controlId="studentName"
              label="Student Name"
              className={
                uploadObj?.story_title
                  ? 'fs-6 color-light-pink'
                  : 'color-light-pink'
              }
            >
              <Form.Control
                className="bg-grey color-white"
                type="text"
                placeholder="Student Name"
                defaultValue={uploadObj?.story_title}
                name="studentName"
                onChange={(e) =>
                  setUploadObj({
                    ...uploadObj,
                    story_title: e.target.value,
                  })
                }
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-4">
            <FloatingLabel
              controlId="storyDescription"
              label="Story Description"
              className={
                uploadObj?.story_description
                  ? 'fs-6 color-light-pink'
                  : 'color-light-pink'
              }
            >
              <Form.Control
                className="bg-grey color-white"
                as="textarea"
                placeholder="Story Description"
                defaultValue={uploadObj?.story_description}
                name="storyDescription"
                onChange={(e) => {
                  setUploadObj({
                    ...uploadObj,
                    story_description:
                      e.target.value !== 'null' ? e.target.value : '',
                  })
                }}
                style={{
                  height: 'max-content',
                  paddingTop: '2rem',
                  paddingBottom: '2rem',
                }}
              />
            </FloatingLabel>
          </Col>
        </Row>

        <Row>
          <Col className="mb-4">
            <Form.Label
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            >
              Vimeo Id
            </Form.Label>
            <Form.Control
              placeholder="Vimeo Id"
              type="text"
              name="vimeoId"
              value={uploadObj.story_vimeoId}
              onChange={(e) =>
                setUploadObj({ ...uploadObj, story_vimeoId: e.target.value })
              }
              // style={{
              //   border: isSubmitting && !vimeoId ? '2px solid red' : 'none',
              // }}
            />
            {/* {isSubmitting && !vimeoId ? (
              <Form.Text
                style={{
                  color: 'red!important',
                  letterSpacing: '0.03rem',
                }}
              >
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  style={{ color: 'red' }}
                >
                  {' '}
                </i>
                <span style={{ color: 'red' }}>This field is required</span>
              </Form.Text>
            ) : null} */}
          </Col>
        </Row>
        <Row className="mh-100">
          <Col className="mb-4 mh-100">
            <Card className="bg-grey color-white p-3 h-100">
              <Form.Label className="fs-6 color-light-pink">
                Image Banner :{' '}
                {uploadObj?.story_image_url ? (
                  <a
                    href={uploadObj.story_image_url}
                    alt="banner image"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Open current banner image
                  </a>
                ) : (
                  <span className="color-white">No current banner image</span>
                )}
              </Form.Label>
              <Form.Control
                type="file"
                size="sm"
                accept=".png,.jpg,.jpeg"
                onChange={handleDisplayBannerChange}
              />
              {isSubmitting &&
              !displayBannerImg.raw &&
              !uploadObj?.story_image_url ? (
                <Form.Text
                  style={{
                    color: 'red!important',
                    letterSpacing: '0.03rem',
                  }}
                >
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    style={{ color: 'red' }}
                  >
                    {' '}
                  </i>
                  <span style={{ color: 'red' }}>This field is required</span>
                </Form.Text>
              ) : null}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="mb-4">
            <Button type="submit">Add</Button>
          </Col>
        </Row>
      </Form>

      {/* <Modal
        show={showDeleteModal}
        // onHide={() => onHideDeleteModal()}
        size="sm"
      >
        <Modal.Header closeButton>
          <h5 className="text-center ml-4 pt-3 pb-3 width-100">
            Are you sure you want to delete this Recording ?
          </h5>
        </Modal.Header>
        <Modal.Footer>
          <span className="mt-3 mb-3 width-100 text-center">
            <Button
              variant="primary"
              onClick={() => handleDelete()}
              className="mr-2"
              id=""
            >
              Yes
            </Button>
            <Button
              variant="primary"
              onClick={() => setShowDeleteModal(false)}
              className="ml-2"
            >
              No
            </Button>
          </span>
        </Modal.Footer>
      </Modal> */}
    </Container>
  )
})
