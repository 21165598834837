import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FloatingLabel,
  Card,
  Alert,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FormData from 'form-data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import moment from 'moment'
import { convertArrayToCSV } from 'convert-array-to-csv'
import {
  APIGetContactLeadDetails,
  APIGetEventUserDetails,
  getCampaignsByDate,
  getReferralBulkData,
} from '../../utils/config/API'
import EventData from '../../components/EventData/EventData'
import withLoginRequired from '../../HOC/LoginModal'
import RefereeData from '../../components/EventData/RefereeData'

export default withLoginRequired(() => {
  const [eventDate, setEventDate] = useState(null)
  const [eventDate2, setEventDate2] = useState(null)
  const [eventDetails, setEventDetails] = useState([])
  const [getEvent, setGetEvent] = useState(false)

  const [showBasicAlert, setShowBasicAlert] = useState(false)
  const [responseStatus, setresponseStatus] = useState(false)
  const [responseMessage, setresponseMessage] = useState('')

  const handleSubmit = async () => {
    try {
      console.log('trying')
      const headers = {
        Authorization: `Bearer ${localStorage.token}`,
      }
      // const insertResponse = await fetch(APIGetEventUserDetails)
      console.log('Date1', moment(eventDate).format('YYYY-MM-DD HH:mm:ss'))
      console.log('Date2', moment(eventDate2).format('YYYY-MM-DD HH:mm:ss'))
      const getDetails = await axios.post(
        getReferralBulkData,
        {
          date: moment(eventDate).toDate(),
          date2: eventDate2 ? moment(eventDate2).toDate() : null,
        },
        {
          headers,
        }
      )

      setEventDetails(getDetails.data.data)

      // const csvFromArrayOfObjects = convertArrayToCSV(
      //   getDetails.data.data && getDetails.data.data.length
      //     ? getDetails.data.data.map((item) => {
      //         return {
      //           ...item,
      //           REFEREE_MOBILE_NUMBER: item.REFEREE_MOBILE_NUMBER
      //             ? '+'.concat(
      //                 item.REFEREE_MOBILE_NUMBER.replace('+')
      //                   .concat('\t')
      //                   .replace('undefined', '')
      //               )
      //             : null,
      //           REFERRED_MOBILE_NUMBER: item.REFERRED_MOBILE_NUMBER
      //             ? '+'.concat(
      //                 item.REFERRED_MOBILE_NUMBER.replace('+')
      //                   .concat('\t')
      //                   .replace('undefined', '')
      //               )
      //             : null,
      //         }
      //       })
      //     : []
      // )
      // const csvContent = `data:text/csv;charset=utf-8,${csvFromArrayOfObjects}`
      // const encodedUri = encodeURI(csvContent)
      // const downloadLink = document.createElement('a')
      // downloadLink.href = encodedUri
      // downloadLink.download = `Referral_Analytics_${moment(eventDate)
      //   .format('DDMMMM')
      //   .concat('-', moment(eventDate2).format('DDMMMM'))}.csv`
      // document.body.appendChild(downloadLink)
      // downloadLink.click()
      // document.body.removeChild(downloadLink)
      // window.open(encodedUri);

      // console.log(insertResponse)
    } catch (err) {
      console.log('Error ----------------->', err)
      setresponseStatus(false)
      setresponseMessage('Something went wrong')
      setShowBasicAlert(true)
    }
  }

  const downloadCSV = () => {
    const csvFromArrayOfObjects = convertArrayToCSV(
      eventDetails && eventDetails.length
        ? eventDetails.map((item) => {
            return {
              ...item,
              REFEREE_MOBILE_NUMBER: item.REFEREE_MOBILE_NUMBER
                ? '+'.concat(
                    item.REFEREE_MOBILE_NUMBER.replace('+')
                      .concat('\t')
                      .replace('undefined', '')
                  )
                : null,
              REFERRED_MOBILE_NUMBER: item.REFERRED_MOBILE_NUMBER
                ? '+'.concat(
                    item.REFERRED_MOBILE_NUMBER.replace('+')
                      .concat('\t')
                      .replace('undefined', '')
                  )
                : null,
            }
          })
        : []
    )
    const csvContent = `data:text/csv;charset=utf-8,${csvFromArrayOfObjects}`
    const encodedUri = encodeURI(csvContent)
    const downloadLink = document.createElement('a')
    downloadLink.href = encodedUri
    downloadLink.download = `Referral_Analytics_${moment(eventDate)
      .format('DDMMMM')
      .concat('-', moment(eventDate2).format('DDMMMM'))}.csv`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
  return (
    <Container fluid className="w-100">
      <Row className="mb-3">
        <h3 className="title">
          <Link to="/">
            <i className="bi bi-arrow-left"></i>
          </Link>
          Referral Analytics
        </h3>
      </Row>
      <Form>
        <Row>
          <Col className="mb-4">
            {/* <FloatingLabel
              controlId="EventDate"
              label="Event Date"
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            > */}
            <Form.Label
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            >
              {/* Select Date/From Date */}
              Select From Date
            </Form.Label>
            <DatePicker
              className="form-control"
              showTimeSelect
              // timeIntervals={15}
              placeholderText="Select Target Date"
              selected={eventDate}
              onChange={(date) => setEventDate(date)}
              dateFormat="MMMM do, yyyy" // showTimeInput
            />
            {/* </FloatingLabel> */}
          </Col>

          <Col className="mb-4">
            {/* <FloatingLabel
              controlId="EventDate"
              label="Event Date"
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            > */}
            <Form.Label
              className={false ? 'fs-6 color-light-pink' : 'color-light-pink'}
            >
              Select To Date
            </Form.Label>
            <DatePicker
              className="form-control"
              showTimeSelect
              // timeIntervals={15}
              placeholderText="Select Target Date"
              selected={eventDate2}
              onChange={(date) => setEventDate2(date)}
              dateFormat="MMMM do, yyyy" // showTimeInput
            />
            {/* </FloatingLabel> */}
          </Col>
        </Row>
        <Row>
          <Col className="mb-4">
            <Button
              className="btn-primary"
              style={{
                margin: 'auto!important',
              }}
              onClick={handleSubmit}
            >
              Get Details
            </Button>
          </Col>
        </Row>
        {eventDetails && eventDetails.length ? (
          <RefereeData eventDetails={eventDetails} downloadCSV={downloadCSV} />
        ) : null}
      </Form>

      <Row className="mt-3 px-2">
        {showBasicAlert && responseStatus === false ? (
          <Alert
            variant="danger"
            onClose={() => setShowBasicAlert(false)}
            dismissible
            className="rounded"
            style={{
              position: 'fixed',
              top: 1,
              // left: 0,
              right: 0,
              zIndex: 999,
              textAlign: 'center',
              width: '40%',
            }}
          >
            {responseMessage}
          </Alert>
        ) : null}
      </Row>
    </Container>
  )
})
