export const majorOptions = [
    "Actuarial Science",
    "Aeronautical Engineering",
    "Anthropology",
    "Architecture",
    "Art",
    "Astrophysics",
    "Biology",
    "Biochemistry",
    "Biomedical Engineering",
    "Business",
    "Chemical Engineering",
    "Civil Engineering",
    "Communications",
    "Chemistry",
    "Cognitive Science",
    "Computer Science",
    "Criminal Justice",
    "Design",
    "Economics",
    "Electrical Engineering",
    "Engineering",
    "Environmental Science",
    "Film",
    "Gender Studies",
    "Geography",
    "History",
    "International Relations",
    "Interior Design",
    "Journalism",
    "Law",
    "Marine Biology",
    "Math",
    "Mechanical Engineering",
    "Medicine",
    "Neuroscience",
    "Nutrition",
    "Physics",
    "Political Science",
    "Psychology",
    "SocialSciences",
    "Social Sciences/Humanities",
    "Sports Management",
    "Statistics",
    "Theater",
    "Fashion Design",
    "Industrial Design",
];
  