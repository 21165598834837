import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

import { validate } from '../../../utils/validators'

const Textfield = ({
  id,
  name,
  label,
  placeholder,
  className = '',
  isError = false,
  errorMessage = 'Invalid value!!!',
  readOnly = false,
  autoComplete,
  initialValue = '',
  onChange = () => {},
  validators = [],
  rows = 3,
  prefix,
  info,
}) => {
  const onChangeHandler = useCallback(
    (e) => {
      const value = e.target.value
      const isValid = validate(value, validators)
      onChange(id, value, isValid)
    },
    [id, onChange, validators]
  )

  const fieldComponent = (
    <textarea
      style={{
        padding: '5px 10px',
        border: '1px solid #898989',
        width: '100%',
        borderRadius: '5px',
      }}
      id={id}
      name={name}
      className=""
      onChange={onChangeHandler}
      value={initialValue}
      placeholder={placeholder}
      readOnly={readOnly}
      autoComplete={autoComplete}
      rows={rows}
    />
  )

  return (
    <div className={'mb-3 w-full ' + className}>
      <div className="mb-1.5">
        <label className="font-medium text-base whitespace-nowrap" htmlFor={name}>
          {`${label}`}
          {info && (
            <span className="relative ml-1 group cursor-pointer">
              <FontAwesomeIcon icon={faCircleInfo} className="text-gray-400" />
              <span className="absolute right-1/2 left-1/2 bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
                <span className="w-60 relative rounded-md z-10 py-3 px-4 bg-gray-100 text-sm whitespace-normal text-labelColor">
                  {info}
                </span>
                <span className="w-4 h-4 -mt-3 rotate-45 bg-gray-100"></span>
              </span>
            </span>
          )}
        </label>
      </div>
      <div className="flex">{fieldComponent}</div>
      {isError && (
        <span className="text-warning text-sm">
          <FontAwesomeIcon icon={faCircleInfo} className="" /> {errorMessage}
        </span>
      )}
    </div>
  )
}

export default Textfield
