/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Container, Row, Col, Form, Button, FloatingLabel, Card, Spinner, Modal } from 'react-bootstrap'
import moment from 'moment'
import axios from 'axios'
import readingTime from 'reading-time'
import DatePicker from 'react-datepicker'

import {
  APIGetBlog,
  APIAddNewBlog,
  APIUpdateBlog,
  APIAddNewBlogV1,
  APICancelScheduledBlog,
  APIDeleteBlog,
} from '../../utils/config/API'
import withLoginRequired from '../../HOC/LoginModal'

const FormData = require('form-data')

export default withLoginRequired(() => {
  const blogId = useParams().id
  const navigate = useNavigate()
  const [uploadObj, setUploadObj] = useState({
    title: '',
    content: '',
    shortNote: '',
    authorName: '',
    img_banner: '',
    img_banner_v2: '',
    mobile_img_banner: '',
    display_img_banner: '',
    author_img: '',
    comments: false,
    tags: [],
    status: '',
    read_time: null,
  })
  const [selectedEventTags, setSelectedEventTags] = useState([])
  const [selectedBlogCategoies, setSelectedBlogCategories] = useState([])
  const [isLoading, setIsloading] = useState(false)

  const [bannerImg, setBannerImg] = useState({ preview: '', raw: '' })
  const [bannerImgV2, setBannerImgV2] = useState({ preview: '', raw: '' })
  const [mobileBannerImg, setMobileBannerImg] = useState({
    preview: '',
    raw: '',
  })
  const [displayBannerImg, setDisplayBannerImg] = useState({
    preview: '',
    raw: '',
  })

  const [authorImg, setAuthorImg] = useState({
    preview: '',
    raw: '',
  })

  const [eventDate, setEventDate] = useState(null)

  const getBlog = async (id) => {
    try {
      const blog = await axios.get(`${APIGetBlog}${id}`)
      if (blog.status === 200) {
        setSelectedBlogCategories(
          blog.data.data.BLOG_CATEGORIES
            ? blog.data.data.BLOG_CATEGORIES.includes(',')
              ? blog.data.data.BLOG_CATEGORIES.split(',')
              : blog.data.data.BLOG_CATEGORIES.split('')
            : []
        )
        setSelectedEventTags(
          blog.data.data.TAGS
            ? blog.data.data.TAGS.includes(',')
              ? blog.data.data.TAGS.split(',')
              : blog.data.data.TAGS.split('')
            : []
        )
        setUploadObj((prev) => {
          return {
            ...prev,
            title: blog.data.data.TITLE,
            content: blog.data.data.CONTENT,
            shortNote: blog.data.data.SHORT_NOTE,
            authorName: blog.data.data.AUTHOR,
            comments: !!blog.data.data.COMMENT_STATUS,
            img_banner: blog.data.data.IMAGE_BANNER_V1 || '',
            img_banner_v2: blog.data.data.IMAGE_BANNER_V2 || '',
            display_img_banner: blog.data.data.POST_IMAGE_BANNER || '',
            author_img: blog.data.data.AUTHOR_IMAGE || '',
            tags: blog.data.data.TAGS,
            status: blog.data.data.STATUS,
            read_time: blog.data.data.READ_TIME || null,
          }
        })
      }
    } catch (err) {
      console.log('Error -------------->', err)
    }
  }
  useEffect(() => {
    if (blogId) {
      getBlog(blogId)
    }
  }, [blogId])

  useEffect(() => {
    if (uploadObj.content) {
      const parser = new DOMParser()
      // const htmlDoc = parser.parseFromString(uploadObj.content, 'text/html')
      const htmlContent = uploadObj.content.replace(/<[^>]*>/g, '')
      // const content = htmlDoc.documentElement.innerHTML
      console.log('parsed content---------------', htmlContent) // Output: <h1>Hello World</h1>
      const readObj = readingTime(htmlContent)
      console.log(readingTime(htmlContent))
      const readTime = readObj.text?.split(' ')[0] && !Number.isNaN(+readObj.text.split(' ')[0]) ? readObj.text?.split(' ')[0] : null
      readTime &&
        setUploadObj((prev) => {
          return {
            ...prev,
            read_time: readTime,
          }
        })
    }
  }, [uploadObj.content])

  const handleEventTagsChecked = (e) => {
    if (e.target.checked)
      if (!selectedEventTags) setSelectedEventTags([e.target.value])
      else setSelectedEventTags([...selectedEventTags, e.target.value])
    else {
      let filteredArr = selectedEventTags.filter((tag) => tag !== e.target.value)
      setSelectedEventTags(filteredArr)
    }
  }

  const handleBlogCategoriesChecked = (e) => {
    if (e.target.checked)
      if (!selectedBlogCategoies) setSelectedBlogCategories([e.target.value])
      else setSelectedBlogCategories([...selectedBlogCategoies, e.target.value])
    else {
      let filteredArr = selectedBlogCategoies.filter((tag) => tag !== e.target.value)
      setSelectedBlogCategories(filteredArr)
    }
  }

  const handleBannerChange = (e) => {
    if (e.target.files.length) {
      setBannerImg({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }

  const handleAuthorImageChange = (e) => {
    if (e.target.files.length) {
      setAuthorImg({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }

  const handleBannerV2Change = (e) => {
    if (e.target.files.length) {
      setBannerImgV2({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }
  const handleMobileBannerChange = (e) => {
    if (e.target.files.length) {
      setMobileBannerImg({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }
  const handleDisplayBannerChange = (e) => {
    if (e.target.files.length) {
      setDisplayBannerImg({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)

  const onCancelNotification = async () => {
    try {
      const url = uploadObj.status === 'SCHEDULED' ? APICancelScheduledBlog : APIDeleteBlog
      const result = await axios.delete(url + blogId)
      if (result.status === 200) {
        setShow(false)
        navigate('/blog-list')
      }
    } catch (err) {
      console.log('Error --------->', err)
    }
  }

  const handleEdit = async (e) => {
    // console.log("clicked")
    e.preventDefault()
    let data = new FormData()
    // eslint-disable-next-line guard-for-in

    // if (uploadObj.event_id > 0) {
    //   data.append('title', uploadObj.title)
    // }

    data.append('title', uploadObj.title)
    data.append('content', uploadObj.content)
    data.append('short_note', uploadObj.shortNote)
    data.append('author_name', uploadObj.authorName)
    data.append('img_banner', bannerImg.raw)
    data.append('img_banner_v2', bannerImgV2.raw)
    data.append('author_img', authorImg.raw)

    data.append('eventDate', eventDate ? moment(eventDate).toDate() : null)

    // data.append('mobile_img_banner', mobileBannerImg.raw)
    data.append('display_img_banner', displayBannerImg.raw)
    data.append('comment_status', uploadObj.comments)
    data.append('categories', !selectedBlogCategoies ? [].join(',') : selectedBlogCategoies.toString())

    // console.log(selectedEventTags)
    data.append('tags', !selectedEventTags ? [].join(',') : selectedEventTags.toString())

    data.append('read_time', uploadObj.read_time)
    console.log('data is', data)

    try {
      setIsloading(true)
      if (blogId) {
        data.append('imageUrl', uploadObj.img_banner)
        data.append('imageUrlV2', uploadObj.img_banner_v2)
        data.append('displayImageUrl', uploadObj.display_img_banner)
        data.append('authorImageUrl', uploadObj.author_img)
        data.append('blogId', blogId)

        const updateResponse = await axios.put(APIUpdateBlog, data, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })

        if (updateResponse.status === 200) {
          // window.open('/events', '_self')
          navigate('/blog-list')

          setIsloading(false)
        } else {
          setIsloading(false)
        }
      } else {
        // console.log("inserting")
        // const insertResponse = await axios.post(APIAddNewBlog, data, {
        const insertResponse = await axios.post(APIAddNewBlogV1, data, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        if (insertResponse.status === 200) {
          // window.open('/events', '_self')
          setIsloading(false)

          navigate('/blog-list')
          console.log('Successfully uploaded to database')
        } else {
          setIsloading(false)
        }
      }
    } catch (error) {
      setIsloading(false)
      console.error('Error ----------->', error)
    }
  }

  console.log('value--->', uploadObj.read_time)

  return (
    <Container fluid className="w-100">
      <Row className="mb-3 d-flex justify-content-between">
        <Col>
          <h3 className="title">
            <Link to="/blog-list">
              <i className="bi bi-arrow-left"></i>
            </Link>
            {blogId ? 'EDIT BLOG' : 'ADD BLOG'}
          </h3>
        </Col>
        {blogId && (
          <Col className="d-flex justify-content-center">
            <Button onClick={() => setShow(true)}>{uploadObj.status === 'SCHEDULED' ? 'CANCEL SCHEDULED BLOG' : 'DELETE'}</Button>
          </Col>
        )}
      </Row>

      <Row className="mb-3">
        <Form>
          <Row>
            <Col className="mb-4">
              <FloatingLabel
                controlId="blogName"
                label="Blog Name"
                className={uploadObj?.title ? 'fs-6 color-light-pink' : 'color-light-pink'}
              >
                <Form.Control
                  className="bg-grey color-white"
                  type="text"
                  placeholder="Blog Name"
                  defaultValue={uploadObj?.title}
                  name="titleName"
                  onChange={(e) =>
                    setUploadObj({
                      ...uploadObj,
                      title: e.target.value,
                    })
                  }
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col className="mb-4">
              <FloatingLabel
                controlId="blogShortNote"
                label="Blog Short Note"
                className={uploadObj?.shortNote ? 'fs-6 color-light-pink' : 'color-light-pink'}
              >
                <Form.Control
                  className="bg-grey color-white"
                  as="textarea"
                  placeholder="Blog Short Note"
                  defaultValue={uploadObj?.shortNote}
                  name="blogShortNote"
                  onChange={(e) => {
                    setUploadObj({
                      ...uploadObj,
                      shortNote: e.target.value !== 'null' ? e.target.value : '',
                    })
                  }}
                  style={{
                    height: 'max-content',
                    paddingTop: '2rem',
                    paddingBottom: '2rem',
                  }}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col className="mb-4">
              <FloatingLabel
                controlId="blogContent"
                label="Blog Content"
                className={uploadObj?.content ? 'fs-6 color-light-pink' : 'color-light-pink'}
              >
                <Form.Control
                  className="bg-grey color-white"
                  as="textarea"
                  placeholder="Blog Content"
                  defaultValue={uploadObj?.content}
                  name="blogContent"
                  onChange={(e) => {
                    setUploadObj({
                      ...uploadObj,
                      content: e.target.value !== 'null' ? e.target.value : '',
                    })
                  }}
                  style={{
                    height: 'max-content',
                    paddingTop: '2rem',
                    paddingBottom: '2rem',
                  }}
                />
              </FloatingLabel>
            </Col>
          </Row>

          <Row>
            <Col className="mb-4">
              <Row>
                <Col className="">
                  <FloatingLabel
                    controlId="authorName"
                    label="Author Name"
                    className={uploadObj?.authorName ? 'fs-6 color-light-pink' : 'color-light-pink'}
                  >
                    <Form.Control
                      className="bg-grey color-white"
                      type="text"
                      placeholder="Author Name"
                      defaultValue={uploadObj?.authorName}
                      name="authorName"
                      onChange={(e) =>
                        setUploadObj({
                          ...uploadObj,
                          authorName: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              {/* <Row>
                <FloatingLabel
                  controlId="commentStatus"
                  label="Comments Allowed"
                  className={uploadObj?.comments ? 'fs-6 color-light-pink' : 'color-light-pink'}
                >
                  <Form.Select
                    className="bg-grey color-white"
                    aria-label="Floating label select example"
                    value={uploadObj?.comments}
                    name="commentStatus"
                    onChange={(e) =>
                      setUploadObj({
                        ...uploadObj,
                        comments: e.target.value,
                      })
                    }
                  >
                    <option>Comment Allowed</option>
                    <option value>Yes</option>
                    <option value={false}>No</option>
                  </Form.Select>
                </FloatingLabel>
              </Row> */}
              <Row className="m-1">
                <Card className="bg-grey color-white p-3 mt-3">
                  <Form.Group>
                    <Form.Label className="fs-6 color-light-pink">Blog Categories</Form.Label>
                    <div className="d-flex justify-content-start">
                      {/* <div
                        style={{
                          marginRight: '10px',
                        }}
                      >
                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('10') : false}
                          type="checkbox"
                          label="Latest"
                          value="10"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />
                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('11') : false}
                          type="checkbox"
                          label="Popular"
                          value="11"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />
                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('2') : false}
                          type="checkbox"
                          label="US"
                          value="2" // maybe typo of 23 here
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />

                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('12') : false}
                          type="checkbox"
                          label="UK"
                          value="12"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />

                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('13') : false}
                          type="checkbox"
                          label="Canada"
                          value="13"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />

                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('3') : false}
                          type="checkbox"
                          label="Undergraduate"
                          value="3"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />

                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('16') : false}
                          type="checkbox"
                          label="Application Components"
                          value="16"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />
                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('17') : false}
                          type="checkbox"
                          label="Masters"
                          value="17"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />
                      </div> */}

                      <div>
                        {/* <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies.includes('7') : false}
                          type="checkbox"
                          label="SAT/ACT"
                          value="7"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />
                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies.includes('1') : false}
                          type="checkbox"
                          label="Visa"
                          value="1"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        /> */}
                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('5') : false}
                          type="checkbox"
                          label="Ivy League"
                          value="5"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />

                        {/* New Blogs */}
                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('18') : false}
                          type="checkbox"
                          label="SAT"
                          value="18"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />
                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('19') : false}
                          type="checkbox"
                          label="Intellectual Curiosity"
                          value="19"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />
                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('20') : false}
                          type="checkbox"
                          label="Holistic Review"
                          value="20"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />
                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('21') : false}
                          type="checkbox"
                          label="Intellectual Vitality"
                          value="21"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />
                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('22') : false}
                          type="checkbox"
                          label="Financial Aid"
                          value="22"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />
                        <Form.Check
                          checked={selectedBlogCategoies ? selectedBlogCategoies?.includes('23') : false}
                          type="checkbox"
                          label="Ivy Plus"
                          value="23"
                          name="eventTag"
                          className="color-white"
                          onChange={(e) => handleBlogCategoriesChecked(e)}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </Card>
              </Row>
            </Col>

            <Col className="mb-4">
              <Card className="bg-grey color-white p-3">
                <Form.Group controlId="w">
                  <Form.Label className="fs-6 color-light-pink">Blog Tags</Form.Label>
                  <div className="d-flex justify-content-start">
                    <div>
                      {/* <Form.Check
                        checked={
                          selectedEventTags
                            ? selectedEventTags.includes('22')
                            : false
                        }
                        type="checkbox"
                        label="Ivy League"
                        value="22"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      /> */}
                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags?.includes('24') : false}
                        type="checkbox"
                        label="Oxbridge"
                        value="24" // maybe typo of 23 here
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />
                      {/* <Form.Check
                        checked={
                          selectedEventTags
                            ? selectedEventTags?.includes('3')
                            : false
                        }
                        type="checkbox"
                        label="US"
                        value="3"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      /> */}
                      {/* <Form.Check
                        checked={
                          selectedEventTags
                            ? selectedEventTags?.includes('2')
                            : false
                        }
                        type="checkbox"
                        label="UK"
                        value="2"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      /> */}
                      {/* <Form.Check
                        checked={
                          selectedEventTags
                            ? selectedEventTags?.includes('4')
                            : false
                        }
                        type="checkbox"
                        label="Canada"
                        value="4"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      /> */}
                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags?.includes('15') : false}
                        type="checkbox"
                        label="Engineering"
                        value="15"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />
                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags.includes('76') : false}
                        type="checkbox"
                        label="MBA"
                        value="76"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />

                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags.includes('77') : false}
                        type="checkbox"
                        label="MIT"
                        value="77"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />
                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags?.includes('78') : false}
                        type="checkbox"
                        label="Oxford"
                        value="78"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />

                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags?.includes('79') : false}
                        type="checkbox"
                        label="Cambridge"
                        value="79"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />
                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags?.includes('80') : false}
                        type="checkbox"
                        label="UToronto"
                        value="80"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />

                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags?.includes('81') : false}
                        type="checkbox"
                        label="UWaterloo"
                        value="81"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />

                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags?.includes('82') : false}
                        type="checkbox"
                        label="Columbia"
                        value="82"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />
                    </div>
                    <div className="ms-4">
                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags?.includes('23') : false}
                        type="checkbox"
                        label="Essay"
                        value="23"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />
                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags?.includes('51') : false}
                        type="checkbox"
                        label="Extracurriculars"
                        value="51"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />
                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags?.includes('83') : false}
                        type="checkbox"
                        label="Stanford"
                        value="83"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />

                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags?.includes('84') : false}
                        type="checkbox"
                        label="Cornell"
                        value="84"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />
                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags?.includes('85') : false}
                        type="checkbox"
                        label="Harvard"
                        value="85"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />

                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags?.includes('87') : false}
                        type="checkbox"
                        label="LGBTQ"
                        value="87"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />
                      <Form.Check
                        checked={selectedEventTags ? selectedEventTags?.includes('88') : false}
                        type="checkbox"
                        label="LOR"
                        value="88"
                        name="eventTag"
                        className="color-white"
                        onChange={(e) => handleEventTagsChecked(e)}
                      />
                    </div>
                  </div>
                </Form.Group>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            
            <Col className="mb-4 h-100" label="Grades">
              <Select
                key="grades"
                id="grades"
                value={selectedGrades}
                onChange={(selected) => {
                  // console.log(selectedGrades)
                  setSelectedGrades(selected)
                }}
                options={options}
                isMulti
                placeholder="Grades"
              ></Select>
            </Col>
          </Row> */}

          <Row className="mh-100">
            <Col className="mb-4 mh-100">
              <Card className="bg-grey color-white p-3 h-100">
                <Form.Label className="fs-6 color-light-pink">
                  Author Image:{' '}
                  {uploadObj?.author_img ? (
                    <a href={uploadObj?.author_img} alt="banner image" rel="noreferrer" target="_blank">
                      Open current banner image
                    </a>
                  ) : (
                    <span className="color-white">No current banner image</span>
                  )}
                </Form.Label>
                <Form.Control type="file" size="sm" accept=".png,.jpg,.jpeg" onChange={handleAuthorImageChange} />
              </Card>
            </Col>
          </Row>

          <Row className="mh-100">
            <Col className="mb-4 mh-100">
              <Card className="bg-grey color-white p-3 h-100">
                <Form.Label className="fs-6 color-light-pink">
                  Image Banner 1(375 * 434):{' '}
                  {uploadObj?.img_banner ? (
                    <a href={uploadObj?.img_banner} alt="banner image" rel="noreferrer" target="_blank">
                      Open current banner image
                    </a>
                  ) : (
                    <span className="color-white">No current banner image</span>
                  )}
                </Form.Label>
                <Form.Control type="file" size="sm" accept=".png,.jpg,.jpeg" onChange={handleBannerChange} />
              </Card>
            </Col>
          </Row>

          {/* <Row className="mh-100">
            <Col className="mb-4 mh-100">
              <Card className="bg-grey color-white p-3 h-100">
                <Form.Label className="fs-6 color-light-pink">
                  Image Banner with text:{' '}
                  {uploadObj?.img_banner_v2 ? (
                    <a
                      href={uploadObj?.img_banner_v2}
                      alt="banner image"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Open current banner image
                    </a>
                  ) : (
                    <span className="color-white">No current banner image</span>
                  )}
                </Form.Label>
                <Form.Control
                  type="file"
                  size="sm"
                  accept=".png,.jpg,.jpeg"
                  onChange={handleBannerV2Change}
                />
              </Card>
            </Col>
          </Row> */}

          {/* <Row className="mh-100">
            <Col className="mb-4 mh-100">
              <Card className="bg-grey color-white p-3 h-100">
                <Form.Label className="fs-6 color-light-pink">
                  Image Banner For Post Page(750 * 556):{' '}
                  {uploadObj?.display_img_banner ? (
                    <a
                      href={uploadObj?.display_img_banner}
                      alt="banner image"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Open current banner image
                    </a>
                  ) : (
                    <span className="color-white">No current banner image</span>
                  )}
                </Form.Label>
                <Form.Control
                  type="file"
                  size="sm"
                  accept=".png,.jpg,.jpeg"
                  onChange={handleDisplayBannerChange}
                />
              </Card>
            </Col>
          </Row> */}

          <Row>
            <Col className="mb-4">
              <DatePicker
                className="form-control"
                showTimeSelect
                // timeIntervals={15}
                placeholderText="Select Target Date"
                selected={eventDate}
                onChange={(date) => setEventDate(date)}
                dateFormat="MMMM d, yyyy HH:mm" // showTimeInput
              />
            </Col>
            <Col className="mb-4">
              <FloatingLabel
                controlId="readTime"
                label="Read Time"
                className={uploadObj?.read_time ? 'fs-6 color-light-pink' : 'color-light-pink'}
              >
                <Form.Control
                  className="bg-grey color-white"
                  type="text"
                  placeholder="Read Time"
                  defaultValue={uploadObj?.read_time}
                  name="readTime"
                  onChange={(e) => {
                    if (!Number.isNaN(e.target.value)) {
                      setUploadObj({
                        ...uploadObj,
                        read_time: e.target.value,
                      })
                    }
                  }}
                />
              </FloatingLabel>
            </Col>
          </Row>

          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Button className="btn-primary" onClick={handleEdit} style={{ width: '5rem' }}>
              {isLoading ? <Spinner animation="border" size="sm" /> : blogId ? 'SAVE' : 'ADD'}
            </Button>
          </Row>
        </Form>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{uploadObj.status === 'SCHEDULED' ? 'Cancel Scheduled Blog' : 'Delete Blog'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {uploadObj.status === 'SCHEDULED'
            ? 'Are you sure you want to Cancel Scheduled Blog ?'
            : 'Are you sure you want to Delete this Blog ?'}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onCancelNotification}>
            Yes
          </Button>

          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
})
