/* eslint-disable no-underscore-dangle */
import { Container, Row, Col, Table } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import './styles.css'
import moment from 'moment'
import withLoginRequired from '../../HOC/LoginModal'
import apiService from '../../utils/apiService'
import ReviewTableItem from '../../components/ReviewTableItem'
import MessageModal from '../../components/Shared/MessageModal'
import Loader from '../../components/Shared/Loader'

export default withLoginRequired(() => {
  const [reviews, setReviews] = useState([])
  const [messageDetails, setMessageDetails] = useState({
    show: false,
    isError: false,
    message: '',
  })
  const [showLoader, setShowLoader] = useState(false)

  const getAllTodoReviews = async () => {
    try {
      setShowLoader(true)
      const allTodoReviews = await apiService('/reviews/all')
      setReviews(allTodoReviews.data || [])
    } catch (e) {
      setMessageDetails({
        show: true,
        isError: true,
        message: 'Failed to fetch reviews. Try refreshing the page.',
      })
    }
    setShowLoader(false)
  }

  useEffect(() => {
    getAllTodoReviews()
  }, [])

  const resourceObject = {
    REVIEW_NGO: 'NGO',
    REVIEW_ONLINECOURSES: 'Online Course',
    REVIEW_SUMMER_COLLEGES: 'Summer Program',
  }

  return (
    <Container fluid className="w-100">
      <Row className="mb-3 mt-3">
        <h3 className="title">Review</h3>
      </Row>
      <Row
        className="mb-3 p-4"
        style={{
          background: '#2d2f44',
        }}
      >
        <Col>
          <Row>
            <Col>
              <MessageModal
                showMessage={messageDetails.show}
                isError={messageDetails.isError}
                message={messageDetails.message}
                closeModal={() => {
                  setMessageDetails({ show: false })
                }}
              />
              <Table responsive="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Raised By</th>
                    <th>Raised At</th>
                    <th>Approved By</th>
                    <th>Approved At</th>
                  </tr>
                </thead>
                {!showLoader && (
                  <tbody className='review-edit-click'>
                    {reviews.length > 0 &&
                      reviews.map((review, index) => {
                        return (
                          <ReviewTableItem
                            key={review._id || review.id}
                            index={index + 1}
                            id={review._id || review.id}
                            type={resourceObject[review.reviewType] || 'Online Course'}
                            name={review.reviewName}
                            status={review.status}
                            raisedBy={review.raisedBy.name.split(' ')[0]}
                            approvedBy={review.reviewedBy && review.reviewedBy.name ? review.reviewedBy.name.split(' ')[0] : '-'}
                            createdAt={review.raisedAt ? moment(review.raisedAt).format('D MMM, HH:mm') : '-'}
                            itemDetails={review}
                            reFetchDetails={getAllTodoReviews}
                            approvedAt={review.approvedAt ? moment(review.approvedAt).format('D MMM, HH:mm') : '-'}
                          />
                        )
                      })}
                  </tbody>
                )}
              </Table>
              {showLoader ? (
                <Loader />
              ) : (
                reviews.length === 0 && <div className="color-white my-auto text-center"> No Pending Reviews </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
})
