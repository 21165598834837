/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
export function isDeepEqual(obj1, obj2) {
  // Check if both arguments are objects
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    console.log('not an object', typeof obj1, obj1, typeof obj2, obj2, obj1 === obj2)
    return obj1 === obj2 // If not objects, compare directly
  }

  // Check if both inputs are arrays
  if (Array.isArray(obj1) || Array.isArray(obj2)) {
    console.log('an array', obj1, obj2, JSON.stringify(obj1.slice().sort()) === JSON.stringify(obj2.slice().sort()))

    // Compare string representations of sorted arrays
    return JSON.stringify(obj1.slice().sort()) === JSON.stringify(obj2.slice().sort())
  }

  // Check if both objects are null
  if (obj1 === null && obj2 === null) {
    console.log('NULL::::', obj1, obj2, obj1 === obj2)

    return true
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    console.log('an object', obj1, obj2, obj1 === obj2)

    return false
  }

  // Check if all key-value pairs are equal recursively
  for (let key of keys1) {
    console.log('Checking recursively', obj1[key], obj2[key], key, obj1, obj2)

    if (!keys2.includes(key) || !isDeepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  // If all checks passed, the objects are equal
  return true
}
