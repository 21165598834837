export const USER_BASIC_DETAILS_REQUEST = 'USER_BASIC_DETAILS_REQUEST'
export const USER_BASIC_DETAILS_SUCCESS = 'USER_BASIC_DETAILS_SUCCESS'
export const USER_BASIC_DETAILS_FAIL = 'USER_BASIC_DETAILS_FAIL'
export const CLEAR_BASIC_DETAILS_MESSAGE = 'CLEAR_BASIC_DETAILS_MESSAGE'

export const USER_PRIME_DETAILS_REQUEST = 'USER_PRIME_DETAILS_REQUEST'
export const USER_PRIME_DETAILS_SUCCESS = 'USER_PRIME_DETAILS_SUCCESS'
export const USER_PRIME_DETAILS_FAIL = 'USER_PRIME_DETAILS_FAIL'

export const USER_PRIMEPLUS_DETAILS_REQUEST = 'USER_PRIMEPLUS_DETAILS_REQUEST'
export const USER_PRIMEPLUS_DETAILS_SUCCESS = 'USER_PRIMEPLUS_DETAILS_SUCCESS'
export const USER_PRIMEPLUS_DETAILS_FAIL = 'USER_PRIMEPLUS_DETAILS_FAIL'

export const UPDATE_BASIC_DETAILS_REQUEST = 'UPDATE_BASIC_DETAILS_REQUEST'
export const UPDATE_BASIC_DETAILS_SUCCESS = 'UPDATE_BASIC_DETAILS_SUCCESS'
export const UPDATE_BASIC_DETAILS_FAIL = 'UPDATE_BASIC_DETAILS_FAIL'

export const UPDATE_PRIME_DETAILS_REQUEST = 'UPDATE_PRIME_DETAILS_REQUEST'
export const UPDATE_PRIME_DETAILS_SUCCESS = 'UPDATE_PRIME_DETAILS_SUCCESS'
export const UPDATE_PRIME_DETAILS_FAIL = 'UPDATE_PRIME_DETAILS_FAIL'
export const CLEAR_PRIME_DETAILS_MESSAGE = 'CLEAR_PRIME_DETAILS_MESSAGE'

export const UPDATE_PRIMEPLUS_DETAILS_REQUEST = 'UPDATE_PRIMEPLUS_DETAILS_REQUEST'
export const UPDATE_PRIMEPLUS_DETAILS_SUCCESS = 'UPDATE_PRIMEPLUS_DETAILS_SUCCESS'
export const UPDATE_PRIMEPLUS_DETAILS_FAIL = 'UPDATE_PRIMEPLUS_DETAILS_FAIL'
export const CLEAR_UPDATE_PRIMEPLUS_DETAILS_MESSAGE = 'CLEAR_UPDATE_PRIMEPLUS_DETAILS_MESSAGE'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'
export const CLEAR_UPDATE_PASSWORD_MESSAGE = 'CLEAR_UPDATE_PASSWORD_MESSAGE'

export const UPDATE_EMAIL_REQUEST = 'UPDATE_EMAIL_REQUEST'
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS'
export const UPDATE_EMAIL_FAIL = 'UPDATE_EMAIL_FAIL'
export const CLEAR_UPDATE_EMAIL_MESSAGE = 'CLEAR_UPDATE_EMAIL_MESSAGE'
