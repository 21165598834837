/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import Input from '../Shared/Input'
import useForm from '../../hooks/useForm'
import SelectInput from '../Shared/SelectInput'
import { majorOptions } from '../../constants/majorConstant'

import RadioButton from '../Shared/RadioButton'
import { isDeepEqual } from '../../utils/objectDeepEqualCheck'
import apiService from '../../utils/apiService'
import MessageModal from '../Shared/MessageModal'
import Loader from '../Shared/Loader'

const CourseLevelList = ['Beginner', 'Intermediate', 'Advanced']

const TypeOfLearning = ['Self Paced', 'Live Online']

const Duration = [
  {
    key: '< 1 month',
    name: '< 1 Month',
    value: '< 1 month',
  },
  {
    key: '1 - 3 months',
    name: '1 - 3 Month',
    value: '1 - 3 months',
  },
  {
    key: '3 - 6 months',
    name: '3 - 6 Month',
    value: '3 - 6 months',
  },
  {
    key: '6 months +',
    name: '6 Month +',
    value: '6 months +',
  },
]

const courseMajorOptions = {}
majorOptions.forEach((item) => {
  courseMajorOptions[item] = { key: item, name: item, value: item }
})

const levelOptions = {}
CourseLevelList.forEach((item) => {
  levelOptions[item] = { key: item, name: item, value: item }
})

const typeOfLearningOptions = {}
TypeOfLearning.forEach((item) => {
  typeOfLearningOptions[item] = { key: item, name: item, value: item }
})

const durationOption = {}
Duration.forEach((item) => {
  durationOption[item.key] = {
    key: item.key,
    name: item.name,
    value: item.value,
  }
})

const addNewCourseFormInitialValue = {
  majors: 'Actuarial Science',
  topic: '',
  level: 'Beginner',
  typeOfLearning: 'Self Paced',
  duration: '< 1 month',
  inrFees: 0.0,
  usdFees: 0.0,
  platform: '',
  weblink: '',
  certificate: 'Yes',
}

const addNewCourseErrors = {
  majors: false,
  topic: true,
  level: false,
  typeOfLearning: false,
  duration: false,
  inrFees: false,
  usdFees: false,
  platform: true,
  weblink: true,
  certificate: false,
}

const EditReviewOnlineCourseForm = ({ onClose, formDetails, id, reFetchDetails }) => {
  const [addNewCourseInitialValue, setAddNewCourseInitialValue] = useState(addNewCourseFormInitialValue)
  const [addNewCourseInitialError, setAddNewCourseInitialError] = useState(addNewCourseErrors)
  const [messageDetails, setMessageDetails] = useState({ show: false, isError: false, message: '' })
  const {
    onTextInputChange,
    onSelectInputChange,
    onRadioInputChange,
    values: addCourseFormValues,
    ...addCourseForm
  } = useForm(addNewCourseInitialValue, addNewCourseInitialError)
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    setAddNewCourseInitialValue({
      majors: formDetails.Majors,
      topic: formDetails.Topic,
      level: formDetails.Level,
      typeOfLearning: formDetails.Typeoflearning,
      duration: formDetails.Duration,
      inrFees: formDetails.INRFees.slice(1),
      usdFees: formDetails.USDFees.slice(1),
      platform: formDetails.Platform,
      weblink: formDetails.Weblink,
      certificate: formDetails.Certificate,
    })
    setAddNewCourseInitialError({
      majors: !formDetails.Majors,
      topic: !formDetails.Topic,
      level: !formDetails.Level,
      typeOfLearning: !formDetails.Typeoflearning,
      duration: !formDetails.Duration,
      inrFees: isNaN(formDetails.INRFees.slice(1)),
      usdFees: isNaN(formDetails.USDFees.slice(1)),
      platform: !formDetails.Platform,
      weblink: !formDetails.Weblink,
      certificate: !formDetails.Certificate,
    })
  }, [formDetails])

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      setShowLoader(true)
      const isFormInvalid = Object.values(addCourseForm.errors).reduce((a, b) => a || b)

      if (isFormInvalid) {
        throw Error('Invalid Form Values')
      }

      const payload = {
        ...addCourseFormValues,
        INRFees: '₹' + addCourseFormValues.inrFees,
        USDFees: '$' + addCourseFormValues.usdFees,
      }

      const response = await apiService(`/reviewOnlineCourse/${id}`, 'PUT', payload)
      if (response.errors) {
        throw new Error('Update Failed. Try Again!')
      }

      setMessageDetails({
        show: true,
        isError: false,
        message: 'Successfully saved the online course.',
        onClose: () => {
          reFetchDetails()
        },
      })
    } catch (err) {
      addCourseForm.setShowFormErrors(true)
      setMessageDetails({ show: true, isError: true, message: 'Failed to update course details. Try Again' })
    }
    setShowLoader(false)
  }

  const handleReject = async () => {
    try {
      setShowLoader(true)
      await apiService(`/reviewOnlineCourse/${id}`, 'DELETE')
      setMessageDetails({
        show: true,
        isError: false,
        message: 'Rejected Successfully.',
        onClose: () => {
          reFetchDetails()
        },
      })
    } catch (e) {
      console.log(e)
      setMessageDetails({ show: true, isError: true, message: 'Failed to reject online course.' })
    }
    setShowLoader(false)
  }

  const handleApprove = async () => {
    try {
      setShowLoader(true)
      await apiService(`/reviewOnlineCourse/approve/${id}`, 'POST')
      setMessageDetails({
        show: true,
        isError: false,
        message: 'Approved Successfully',
        onClose: () => {
          reFetchDetails()
        },
      })
    } catch (e) {
      console.log(e)
      setMessageDetails({ show: true, isError: true, message: 'Failed to approve online course' })
    }
    setShowLoader(false)
  }

  const isChangeInValues = !isDeepEqual({ ...addNewCourseInitialValue }, { ...addCourseFormValues })

  if (showLoader) {
    return (
      <div>
        <Row style={{ width: '100%', display: 'block', margin: 'auto' }}>
          <Loader className="text-dark" />
        </Row>
      </div>
    )
  }
  return (
    <div>
      <MessageModal
        showMessage={messageDetails.show}
        isError={messageDetails.isError}
        message={messageDetails.message}
        closeModal={() => {
          setMessageDetails({ show: false })
        }}
        onClose={messageDetails.onClose}
      />
      <Row style={{ width: '100%', display: 'block', margin: 'auto' }}>
        <form onSubmit={submitHandler}>
          <Col>
            <Row>
              <Col>
                <h2
                  style={{
                    marginBottom: '30px',
                    textAlign: 'center',
                  }}
                >
                  Edit New Course
                </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  id="topic"
                  name="topic"
                  label="Topic"
                  placeholder="Enter Topic"
                  initialValue={addCourseFormValues.topic}
                  onChange={onTextInputChange}
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.topic}
                  errorMessage="Please enter some letters."
                  validators={['REQUIRE']}
                />
              </Col>
              <Col>
                <SelectInput
                  id="level"
                  label="Level"
                  className="w-1/3"
                  options={levelOptions}
                  defaultKey={addCourseFormValues.level}
                  onChange={onSelectInputChange}
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.level}
                  errorMessage="Select the level."
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectInput
                  id="majors"
                  label="Majors"
                  className="w-1/3"
                  options={courseMajorOptions}
                  defaultKey={addCourseFormValues.majors}
                  onChange={onSelectInputChange}
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.majors}
                  errorMessage="Select a major."
                />
              </Col>
            </Row>

            <Row></Row>
            <Row>
              <Col>
                <Input
                  id="inrFees"
                  name="inrFees"
                  label="Fees in INR"
                  placeholder="0.00"
                  initialValue={addCourseFormValues.inrFees}
                  onChange={onTextInputChange}
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.inrFees}
                  errorMessage="Please enter a valid amount."
                  validators={['AMOUNT', 'GREATER_THAN#0']}
                />
              </Col>
              <Col>
                <Input
                  id="usdFees"
                  name="usdFees"
                  label="Fees in USD"
                  placeholder="0.00"
                  initialValue={addCourseFormValues.usdFees}
                  onChange={onTextInputChange}
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.usdFees}
                  errorMessage="Please enter a valid amount."
                  validators={['AMOUNT', 'GREATER_THAN#0']}
                />
              </Col>
              <Col>
                <RadioButton
                  id="certificate"
                  name="certificate"
                  label="Certificate"
                  initialValue={addCourseFormValues.certificate}
                  options={[
                    { label: 'Yes', value: 'Yes' },
                    { label: 'No', value: 'No' },
                  ]}
                  // initialValue={}
                  onChange={onRadioInputChange}
                  validators={['REQUIRED']}
                  errorMessage="This is an informative message about the radio buttons."
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.certificate}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  id="platform"
                  name="platform"
                  label="Platform"
                  placeholder="Enter platform name"
                  initialValue={addCourseFormValues.platform}
                  onChange={onTextInputChange}
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.platform}
                  errorMessage="Please fill this value."
                  validators={['REQUIRE']}
                />
              </Col>

              <Col>
                <SelectInput
                  id="duration"
                  name={'duration'}
                  label="Duration"
                  className="w-1/3"
                  options={durationOption}
                  defaultKey={addCourseFormValues.duration}
                  onChange={onSelectInputChange}
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.duration}
                  errorMessage="Select duration."
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  id="weblink"
                  name="weblink"
                  label="Website Link/URL"
                  placeholder="Enter website url"
                  initialValue={addCourseFormValues.weblink}
                  onChange={onTextInputChange}
                  isError={addCourseForm.showFormErrors && addCourseForm.errors.weblink}
                  errorMessage="Please fill this value."
                  validators={['REQUIRE']}
                />
              </Col>
            </Row>

            <Row>
              <Col className="text-center pt-4 pb-4">
                {isChangeInValues ? (
                  <Button type="submit">Save Course Details</Button>
                ) : (
                  <Button onClick={handleApprove}>Approve</Button>
                )}
                <Button className="m-2" variant="danger" onClick={handleReject}>
                  Reject
                </Button>
              </Col>
            </Row>
          </Col>
        </form>
      </Row>
    </div>
  )
}

export default EditReviewOnlineCourseForm
