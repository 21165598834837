import React, { useCallback } from 'react'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import PhoneInput from 'react-phone-number-input'

import { CustomPhoneInput } from '../Components/PhoneTextField'

import { validate } from '../../../../utils/validators'

import '../styles/mui-styles.css'

const Index = ({
  id,
  label,
  name,
  value,
  className = '',
  errorClass = null,
  error = false,
  errorMessage = '',
  required = false,
  onChange = () => {},
  validators = [],
  country = 'IN',
}) => {
  const onChangeHandler = useCallback(
    (e) => {
      const v = e
      console.log('Event details', e)
      const isValid = validate(v, validators)
      onChange(id, v, isValid)
    },
    [id, onChange, validators]
  )
  return (
    <FormControl fullWidth error={error}>
      <PhoneInput
        className={error ? 'PhoneInput_Error ' : 'PhoneInput ' + className}
        inputProps={{
          name: 'phone',
          required: true,
          // autoFocus: true,
        }}
        id={id}
        name={name}
        required={required}
        label={label}
        inputComponent={CustomPhoneInput}
        defaultCountry={country || 'IN'}
        onChange={onChangeHandler}
        // onChangeHandler={onChangeHandler}
        value={value}
      />
      {error && errorMessage && (
        <FormHelperText className={errorClass}>
          <i className="fa fa-exclamation-triangle"></i> {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default Index
