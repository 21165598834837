import React, { useCallback } from 'react'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'

import { validate } from '../../../../utils/validators'

const Index = ({
  freeSolo = true,
  id,
  label,
  value,
  className = '',
  errorClass = null,
  error = false,
  errorMessage = '',
  required = false,
  onChange = () => {},
  onInputChange = () => {},
  validators = [],
  options = [],
}) => {
  // const onChangeHandler = useCallback(
  //   (value) => {
  //     value
  //     let isValid = validate(value, validators)
  //     onChange(id, value, isValid)
  //   },
  //   [id, onChange, validators]
  // )
  return (
    <FormControl fullWidth error={error}>
      <Autocomplete
        freeSolo={freeSolo}
        getOptionLabel={(option) => option}
        options={options}
        value={value}
        onChange={(event, newValue) => {
          newValue && onChange(newValue || '')
        }}
        onInputChange={(event, newInputValue) => {
          onInputChange(newInputValue)
        }}
        sx={{
          fieldset: {
            borderColor: error ? '#d32f2f !important' : '',
          },
        }}
        renderInput={(params) => <TextField required={required} error={error} {...params} label={label} />}
      />
      {error && errorMessage && (
        <FormHelperText className={errorClass}>
          <i className="fa fa-exclamation-triangle"></i> {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default Index
