/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import moment from 'moment'
import Input from '../Shared/Input'
import useForm from '../../hooks/useForm'
import SelectInput from '../Shared/SelectInput'
import MultiSelectInput from '../Shared/MultiSelectInput'
import Textfield from '../Shared/Textfield'
import DatePicker from '../Shared/DatePicker'

import { isDeepEqual } from '../../utils/objectDeepEqualCheck'
import apiService from '../../utils/apiService'
import MessageModal from '../Shared/MessageModal'
import Loader from '../Shared/Loader'

const Tier = [
  { name: 'Very Selective', value: 'Very Selective', key: 'Very Selective' },
  {
    name: 'Highly Selective',
    value: 'Highly Selective',
    key: 'Highly Selective',
  },
  { name: 'Selective', value: 'Selective', key: 'Selective' },
]

const countries = [
  { name: 'India', value: 'India', key: 'India' },
  { name: 'UK', value: 'UK', key: 'UK' },
  { name: 'USA', value: 'USA', key: 'USA' },
  { name: 'USA/UK', value: 'USA/UK', key: 'USA/UK' },
  { name: 'Canada', value: 'Canada', key: 'Canada' },
  { name: 'USA/India', value: 'USA/India', key: 'USA/India' },
  { name: 'Singapore', value: 'Singapore', key: 'Singapore' },
  { name: 'Australia', value: 'Australia', key: 'Australia' },
]

const applicableFor = [
  { name: 'All', value: 'All', key: 'All' },
  {
    name: 'Indian Nationals Only',
    value: 'Indian Nationals Only',
    key: 'Indian Nationals Only',
  },
  {
    name: 'US Nationals Only',
    value: 'US Nationals Only',
    key: 'US Nationals Only',
  },
]

const months = [
  { name: 'January', value: 'January', key: 'January' },
  { name: 'February', value: 'February', key: 'February' },
  { name: 'March', value: 'March', key: 'March' },
  { name: 'April', value: 'April', key: 'April' },
  { name: 'May', value: 'May', key: 'May' },
  { name: 'June', value: 'June', key: 'June' },
  { name: 'July', value: 'July', key: 'July' },
  { name: 'August', value: 'August', key: 'August' },
  { name: 'September', value: 'September', key: 'September' },
  { name: 'October', value: 'October', key: 'October' },
  { name: 'November', value: 'November', key: 'November' },
  { name: 'December', value: 'December', key: 'December' },
]

const tierOption = {}
Tier.forEach((item) => {
  tierOption[item.key] = {
    key: item.key,
    name: item.name,
    value: item.value,
  }
})

const countryOption = {}
countries.forEach((item) => {
  countryOption[item.key] = {
    key: item.key,
    name: item.name,
    value: item.value,
  }
})

const applicableOptions = {}
applicableFor.forEach((item) => {
  applicableOptions[item.key] = {
    key: item.key,
    name: item.name,
    value: item.value,
  }
})

const monthOptions = {}
months.forEach((item) => {
  monthOptions[item.key] = {
    key: item.key,
    name: item.name,
    value: item.value,
  }
})

const addNewSummerCollegeFormInitialValue = {
  name: '',
  tier: '',
  subject: '',
  institution: '',
  country: '',
  applicableFor: '',
  applicableGrade: [],
  description: '',
  generalAdmissionReq: '',
  gpa: '',
  actSatPsat: '',
  languageReq: '',
  applicationComponent: '',
  programFee: '',
  appFeeDesc: '',
  nationalDeadline: '',
  interntionalDeadline: '',
  website: '',
  programStartMonth: '',
  programFromDate: '',
  programToDate: '',
  appEssays: '',
  toeflScore: '',
  ieltsScore: '',
  contactEmail: '',
  contactPhone: '',
}

const addNewCourseErrors = {
  name: true,
  tier: true,
  subject: true,
  institution: true,
  country: true,
  applicableFor: true,
  applicableGrade: true,
  description: true,
  generalAdmissionReq: false,
  gpa: false,
  actSatPsat: false,
  languageReq: false,
  applicationComponent: false,
  programFee: false,
  appFeeDesc: false,
  nationalDeadline: false,
  interntionalDeadline: false,
  website: true,
  programStartMonth: true,
  programFromDate: false,
  programToDate: false,
  appEssays: false,
  toeflScore: false,
  ieltsScore: false,
  contactEmail: false,
  contactPhone: false,
}

const EditReviewSummerProgramCourseForm = ({ onClose, formDetails, id, reFetchDetails }) => {
  const [addNewSummerCollegeInitialValue, setAddNewSummerCollegeInitialValue] = useState(addNewSummerCollegeFormInitialValue)
  const [addNewSummerCollegeInitialError, setAddNewSummerCollegeInitialError] = useState(addNewCourseErrors)
  const [messageDetails, setMessageDetails] = useState({ show: false, isError: false, message: '' })
  const {
    onTextInputChange,
    onSelectInputChange,
    onRadioInputChange,
    onMultiSelectInputChange,
    onDateInputChange,
    values: addSummerCollegeFormValues,
    ...addSummerCollegeForm
  } = useForm(addNewSummerCollegeInitialValue, addNewSummerCollegeInitialError)
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    setAddNewSummerCollegeInitialValue({
      name: formDetails.Scolleges.name || '',
      tier: formDetails.Scolleges.tier || '',
      subject: formDetails.Scolleges.subject || '',
      institution: formDetails.Scolleges.institution || '',
      country: formDetails.Scolleges.country || '',
      applicableFor: formDetails.Scolleges.applicableFor || '',
      applicableGrade: formDetails.Scolleges.applicableGrade || [],
      description: formDetails.Scolleges.description || '',
      generalAdmissionReq: formDetails.Scolleges.generalAdmissionReq || '',
      gpa: formDetails.Scolleges.gpa || '',
      actSatPsat: formDetails.Scolleges.actSatPsat || '',
      languageReq: formDetails.Scolleges.languageReq || '',
      applicationComponent: formDetails.Scolleges.applicationComponent || '',
      programFee: formDetails.Scolleges.programFee || '',
      appFeeDesc: formDetails.Scolleges.appFeeDesc || '',
      nationalDeadline: formDetails.Scolleges.nationalDeadline ? moment(formDetails.Scolleges.nationalDeadline) : '',
      internationalDeadline: formDetails.Scolleges.internationalDeadline ? moment(formDetails.Scolleges.internationalDeadline) : '',
      website: formDetails.Scolleges.website || '',
      programStartMonth: formDetails.Scolleges.programStartMonth || '',
      programFromDate: formDetails.Scolleges?.programDate?.From ? moment(formDetails.Scolleges.programDate.From) : '',
      programToDate: formDetails.Scolleges?.programDate?.To ? moment(formDetails.Scolleges.programDate.To) : '',
      appEssays: formDetails.Scolleges.appEssays || '',
      toeflScore: formDetails.Scolleges['TOEFL Score'] || '',
      ieltsScore: formDetails.Scolleges['IELTS Score'] || '',
      contactEmail: formDetails.Scolleges.contact_email || '',
      contactPhone: formDetails.Scolleges.contact_phone || '',
    })
    setAddNewSummerCollegeInitialError({
      name: !formDetails.Scolleges.name,
      tier: !formDetails.Scolleges.tier,
      subject: !formDetails.Scolleges.subject,
      institution: !formDetails.Scolleges.institution,
      country: !formDetails.Scolleges.country,
      applicableFor: !formDetails.Scolleges.applicableFor,
      applicableGrade: !formDetails.Scolleges.applicableGrade.length,
      description: !formDetails.Scolleges.description,
      generalAdmissionReq: false,
      gpa: false,
      actSatPsat: false,
      languageReq: false,
      applicationComponent: false,
      programFee: false,
      appFeeDesc: false,
      nationalDeadline: false,
      internationalDeadline: false,
      website: !formDetails.Scolleges.website,
      programStartMonth: false,
      programFromDate: false,
      programToDate: false,
      appEssays: false,
      toeflScore: false,
      ieltsScore: false,
      contactEmail: false,
      contactPhone: false,
    })
  }, [formDetails])

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      setShowLoader(true)
      const isFormInvalid = Object.values(addSummerCollegeForm.errors).reduce((a, b) => a || b)

      if (isFormInvalid) {
        throw Error('Invalid Form Values')
      }

      const payload = {
        ...addSummerCollegeFormValues,

        programDate: {
          from: addSummerCollegeFormValues.programFromDate,
          to: addSummerCollegeFormValues.programToDate,
        },
      }

      const response = await apiService(`/reviewSummerCollege/${id}`, 'PUT', payload)
      if (response.errors) {
        throw new Error('Update Failed. Try Again!')
      }

      setMessageDetails({
        show: true,
        isError: false,
        message: 'Successfully saved the summer college.',
        onClose: () => {
          reFetchDetails()
        },
      })
    } catch (err) {
      addSummerCollegeForm.setShowFormErrors(true)
      setMessageDetails({ show: true, isError: true, message: 'Failed to update course details. Try Again' })
    }
    setShowLoader(false)
  }

  const handleReject = async () => {
    try {
      setShowLoader(true)
      await apiService(`/reviewSummerCollege/${id}`, 'DELETE')
      setMessageDetails({
        show: true,
        isError: false,
        message: 'Rejected Successfully.',
        onClose: () => {
          reFetchDetails()
        },
      })
    } catch (e) {
      console.log(e)
      setMessageDetails({ show: true, isError: true, message: 'Failed to reject summer college.' })
    }
    setShowLoader(false)
  }

  const handleApprove = async () => {
    try {
      setShowLoader(true)
      await apiService(`/reviewSummerCollege/approve/${id}`, 'POST')
      setMessageDetails({
        show: true,
        isError: false,
        message: 'Approved Successfully',
        onClose: () => {
          reFetchDetails()
        },
      })
    } catch (e) {
      console.log(e)
      setMessageDetails({ show: true, isError: true, message: 'Failed to approve summer college' })
    }
    setShowLoader(false)
  }

  const isChangeInValues = !isDeepEqual({ ...addNewSummerCollegeInitialValue }, { ...addSummerCollegeFormValues })

  if (showLoader) {
    return (
      <div>
        <Row style={{ width: '100%', display: 'block', margin: 'auto' }}>
          <Loader className="text-dark" />
        </Row>
      </div>
    )
  }
  return (
    <div>
      <MessageModal
        showMessage={messageDetails.show}
        isError={messageDetails.isError}
        message={messageDetails.message}
        closeModal={() => {
          setMessageDetails({ show: false })
        }}
        onClose={messageDetails.onClose}
      />
      <Row style={{ width: '100%', display: 'block', margin: 'auto' }}>
        <form onSubmit={submitHandler}>
          <Col>
            <Row>
              <Col>
                <h2
                  style={{
                    marginBottom: '30px',
                    textAlign: 'center',
                  }}
                >
                  Edit New Summer College
                </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  id="name"
                  name="name"
                  label="Name"
                  placeholder="Enter name"
                  initialValue={addSummerCollegeFormValues.name}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.name}
                  errorMessage="Please enter some letters."
                  validators={['REQUIRE']}
                />
              </Col>

              <Col>
                <SelectInput
                  id="tier"
                  label="Tier"
                  className="w-1/3"
                  options={tierOption}
                  defaultKey={addSummerCollegeFormValues.tier}
                  onChange={onSelectInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.tier}
                  errorMessage="Select the Tier."
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  id="subject"
                  name="subject"
                  label="Subject"
                  placeholder="Enter subject"
                  initialValue={addSummerCollegeFormValues.subject}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.subject}
                  errorMessage="Please enter some letters."
                  validators={['REQUIRE']}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  id="institution"
                  name="institution"
                  label="Institution"
                  placeholder="Enter institution"
                  initialValue={addSummerCollegeFormValues.institution}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.institution}
                  errorMessage="Please enter some letters."
                  validators={['REQUIRE']}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectInput
                  id="applicableFor"
                  name="applicableFor"
                  label="Applicable For"
                  className="w-1/3"
                  options={applicableOptions}
                  defaultKey={addSummerCollegeFormValues.applicableFor}
                  onChange={onSelectInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.applicableFor}
                  errorMessage="Select the Applicable Countries."
                />
              </Col>
              <Col>
                <MultiSelectInput
                  placeholder={'Select Grades'}
                  id={'applicableGrade'}
                  name={'applicableGrade'}
                  label={'Applicable Grades'}
                  defaultKeys={addSummerCollegeFormValues.applicableGrade}
                  options={[
                    { label: 'Grade 9', value: '9' },
                    { label: 'Grade 10', value: '10' },
                    { label: 'Grade 11', value: '11' },
                    { label: 'Grade 12', value: '12' },
                  ]}
                  onChange={onMultiSelectInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.applicableGrade}
                  errorMessage="Please select applicable grades."
                  className="w-1/3"
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Textfield
                  id="description"
                  name="description"
                  label="Description"
                  placeholder="Enter description"
                  initialValue={addSummerCollegeFormValues.description}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.description}
                  errorMessage="Please enter some letters."
                  // validators={["REQUIRE"]}
                  rows={3}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Textfield
                  id="generalAdmissionReq"
                  name="generalAdmissionReq"
                  label="General Admission Requirement"
                  placeholder="Enter General Admission Requirement"
                  initialValue={addSummerCollegeFormValues.generalAdmissionReq}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.generalAdmissionReq}
                  errorMessage="Please enter some letters."
                  // validators={["REQUIRE"]}
                  rows={2}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  id="gpa"
                  name="gpa"
                  label="GPA"
                  placeholder="Enter GPA"
                  initialValue={addSummerCollegeFormValues.gpa}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.gpa}
                  errorMessage="Please enter some letters."
                  // validators={["REQUIRE"]}
                />
              </Col>

              <Col>
                <Input
                  id="actSatPsat"
                  name="actSatPsat"
                  label="ACT/SAT/PSAT"
                  placeholder="Enter ACT/SAT/PSAT"
                  initialValue={addSummerCollegeFormValues.actSatPsat}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.actSatPsat}
                  errorMessage="Please enter some letters."
                  // validators={["REQUIRE"]}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Textfield
                  id="applicationComponent"
                  name="applicationComponent"
                  label="Application component"
                  placeholder="Enter application component"
                  initialValue={addSummerCollegeFormValues.applicationComponent}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.applicationComponent}
                  errorMessage="Please enter some letters."
                  // validators={["REQUIRE"]}
                  rows={2}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Textfield
                  id="appFeeDesc"
                  name="appFeeDesc"
                  label="Application fee description"
                  placeholder="Enter application fee description"
                  initialValue={addSummerCollegeFormValues.appFeeDesc}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.appFeeDesc}
                  errorMessage="Please enter some letters."
                  // validators={["REQUIRE"]}
                  rows={2}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  id="languageReq"
                  name="languageReq"
                  label="Language Requirement"
                  placeholder="Enter language requirement"
                  initialValue={addSummerCollegeFormValues.languageReq}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.languageReq}
                  errorMessage="Please enter some letters."
                  // validators={["REQUIRE"]}
                />
              </Col>
              <Col>
                <Input
                  id="programFee"
                  name="programFee"
                  label="Program fee"
                  placeholder="Enter program fee"
                  initialValue={addSummerCollegeFormValues.programFee}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.programFee}
                  errorMessage="Please enter some letters."
                  // validators={["REQUIRE"]}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Row>
                  <Col>
                    <DatePicker
                      id="nationalDeadline"
                      name="nationalDeadline"
                      label="National Deadline"
                      placeholder="Enter national deadline"
                      defaultDate={addSummerCollegeFormValues.nationalDeadline}
                      onChange={onDateInputChange}
                      isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.nationalDeadline}
                      errorMessage="Please select date."
                      // validators={["REQUIRE"]}
                      // inputFormat={"MMMM DD"}
                      inputFormat={'MMMM DD'}
                      dateFormat={'MMMM dd'}
                    />
                  </Col>
                  <Col>
                    <DatePicker
                      id="internationalDeadline"
                      name="internationalDeadline"
                      label="International Deadline"
                      placeholder="Enter internation deadline"
                      defaultDate={addSummerCollegeFormValues.internationalDeadline}
                      onChange={onDateInputChange}
                      isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.internationalDeadline}
                      errorMessage="Please select date."
                      // validators={["REQUIRE"]}
                      // inputFormat={"MMMM DD"}
                      inputFormat={'MMMM DD'}
                      dateFormat={'MMMM dd'}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <SelectInput
                  id="programStartMonth"
                  label="Program Start Month"
                  className="w-1/3"
                  options={monthOptions}
                  defaultKey={addSummerCollegeFormValues.programStartMonth}
                  onChange={onSelectInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.programStartMonth}
                  errorMessage="Select the Program Start Month."
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Row>
                  <Col>
                    <DatePicker
                      id="programFromDate"
                      name="programFromDate"
                      label="Program From"
                      placeholder="Pick Program from date"
                      defaultDate={addSummerCollegeFormValues.programFromDate}
                      onChange={onDateInputChange}
                      isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.programFromDate}
                      errorMessage="Please enter some letters."
                      // inputFormat={"MMMM DD"}

                      // validators={["REQUIRE"]}
                      inputFormat={'MMMM DD'}
                      dateFormat={'MMMM dd'}
                    />
                  </Col>
                  <Col>
                    <DatePicker
                      id="programToDate"
                      name="programToDate"
                      label="Program To"
                      placeholder="Pick Program To date"
                      defaultDate={addSummerCollegeFormValues.programToDate}
                      onChange={onDateInputChange}
                      isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.programToDate}
                      errorMessage="Please select date"
                      // validators={["REQUIRE"]}
                      // inputFormat={"MMMM DD"}
                      inputFormat={'MMMM DD'}
                      dateFormat={'MMMM dd'}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Input
                  id="website"
                  name="website"
                  label="Website"
                  className="w-1/3"
                  placeholder="Enter website URL"
                  initialValue={addSummerCollegeFormValues.website}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.website}
                  errorMessage="Enter website URL."
                  validators={['REQUIRED']}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Textfield
                  id="appEssays"
                  name="appEssays"
                  label="App Essays"
                  placeholder="Enter App essay prompt"
                  initialValue={addSummerCollegeFormValues.appEssays}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.appEssays}
                  errorMessage="Please enter some letters."
                  // validators={["REQUIRE"]}
                  rows={3}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  id="toeflScore"
                  name="toeflScore"
                  label="TOEFL Score"
                  placeholder="Enter TOELF Score"
                  initialValue={addSummerCollegeFormValues.toeflScore}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.toeflScore}
                  errorMessage="Please enter some letters."
                  // validators={["REQUIRE"]}
                />
              </Col>

              <Col>
                <Input
                  id="ieltsScore"
                  name="ieltsScore"
                  label="IELTS Score"
                  placeholder="Enter IELTS Score"
                  initialValue={addSummerCollegeFormValues.ieltsScore}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.ieltsScore}
                  errorMessage="Please enter some letters."
                  // validators={["REQUIRE"]}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  id="contactEmail"
                  name="contactEmail"
                  label="Contact Email"
                  placeholder="Enter contact email"
                  initialValue={addSummerCollegeFormValues.contactEmail}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.contactEmail}
                  errorMessage="Please enter some letters."
                  // validators={["REQUIRE"]}
                />
              </Col>

              <Col>
                <Input
                  id="contactPhone"
                  name="contactPhone"
                  label="Contact number"
                  placeholder="Enter contact number"
                  initialValue={addSummerCollegeFormValues.contactPhone}
                  onChange={onTextInputChange}
                  isError={addSummerCollegeForm.showFormErrors && addSummerCollegeForm.errors.contactPhone}
                  errorMessage="Please enter some letters."
                  // validators={["REQUIRE"]}
                />
              </Col>
            </Row>
            <Row>
              <Col className="text-center pt-4 pb-4">
                {isChangeInValues ? (
                  <Button type="submit">Save Program Details</Button>
                ) : (
                  <Button onClick={handleApprove}>Approve</Button>
                )}
                <Button className="m-2" variant="danger" onClick={handleReject}>
                  Reject
                </Button>
              </Col>
            </Row>
          </Col>
        </form>
      </Row>
    </div>
  )
}

export default EditReviewSummerProgramCourseForm
