import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const MessageModal = ({ showMessage, isError, message, closeModal, onClose = ()=> {} }) => {
//   const [show, setShow] = useState(false);

  const handleClose = () => {
    closeModal();
    // setShow(false);
    onClose();
  };

  return (
    <Modal show={showMessage} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isError ? "Error": "Success"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageModal;