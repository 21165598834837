/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Container,
  Row,
  Table,
  Button,
  Form,
  Col,
  Modal,
  Pagination,
  PageItem,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import axios from 'axios'

import { convertArrayToCSV } from 'convert-array-to-csv'
import {
  getEventsFullData,
  deleteEventAction,
} from '../../actions/eventsActions'
import withLoginRequired from '../../HOC/LoginModal'
import {
  getPhysicalEventFullData,
  getReferralCount,
  getReferralData,
} from '../../utils/config/API'

export default withLoginRequired(() => {
  const dispatch = useDispatch()
  // const events = useSelector((state) => state.events.events)
  const [referrals, setReferrals] = useState([])

  const [page, setPage] = useState(1)

  const [sortAsc, setSortAsc] = useState(false)
  const [activeSort, setActiveSort] = useState('date')

  const [searchInput, setSearchInput] = useState('')

  const [totalPosts, setTotalPosts] = useState(0)

  const getReferrals = async () => {
    try {
      if (totalPosts === 0) {
        const totalReferrals = await axios.get(getReferralCount)
        setTotalPosts(totalReferrals.data.count)
      }
      const res = await axios.get(`${getReferralData}?page_no=${page}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      setReferrals(res.data.data)
    } catch (err) {
      console.log('Error --->', err)
    }
  }
  useEffect(() => {
    getReferrals()
  }, [page])

  const handleClick = (eventData) => {
    window.open(`physical-event/edit-event/${eventData.ID}`)
  }

  const handleAdd = () => {
    window.open('physical-event/edit-event')
  }

  let tableBodyComponent = null

  const [show, setShow] = useState(false)
  const [referObj, setReferObj] = useState(null)

  const handleOpen = (refer) => {
    setShow(true)
    setReferObj(refer)
  }

  const handleClose = () => {
    setShow(false)
    setReferObj(null)
  }

  const downloadData = async (data) => {
    try {
      const csvFromArrayOfObjects = convertArrayToCSV([
        {
          ...data,
          REFEREE_MOBILE_NUMBER: data.REFEREE_MOBILE_NUMBER
            ? '+'.concat(
                data.REFEREE_MOBILE_NUMBER.replace('+')
                  .concat('\t')
                  .replace('undefined', '')
              )
            : null,
          REFERRED_MOBILE_NUMBER: data.REFERRED_MOBILE_NUMBER
            ? '+'.concat(
                data.REFERRED_MOBILE_NUMBER.replace('+')
                  .concat('\t')
                  .replace('undefined', '')
              )
            : null,
          CREATED_AT: moment(data.CREATED_AT)
            .add(5, 'hours')
            .add(30, 'minutes')
            .format('YYYY-MM-DDTHH:mm:ss'),
        },
      ])
      const csvContent = `data:text/csv;charset=utf-8,${csvFromArrayOfObjects}`
      const encodedUri = encodeURI(csvContent)
      const downloadLink = document.createElement('a')
      downloadLink.href = encodedUri
      downloadLink.download = `REFERRAL_${data.ID}.csv`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (err) {
      console.log('Error:', err)
    }
  }

  if (referrals !== undefined && referrals !== null && referrals.length > 0) {
    if (searchInput.length > 0) {
      tableBodyComponent = referrals
        .filter((e) => e.NAME.toLowerCase().includes(searchInput))
        .sort((a, b) => {
          switch (activeSort) {
            case 'name': {
              if (sortAsc) return a.NAME > b.NAME ? 1 : -1
              else return a.NAME < b.NAME ? 1 : -1
            }
            case 'date': {
              const dateA = new Date(a.DATE_TIME)
              const dateB = new Date(b.DATE_TIME)
              if (sortAsc) return dateA > dateB ? 1 : -1
              else return dateA < dateB ? 1 : -1
            }
            case 'regCount': {
              if (sortAsc)
                return a.REGISTRATION_COUNT > b.REGISTRATION_COUNT ? 1 : -1
              else return a.REGISTRATION_COUNT < b.REGISTRATION_COUNT ? 1 : -1
            }
            case 'attendeeCount': {
              if (sortAsc) return a.ATTENDEES_COUNT > b.ATTENDEES_COUNT ? 1 : -1
              else return a.ATTENDEES_COUNT < b.ATTENDEES_COUNT ? 1 : -1
            }
            default:
              return 1
          }
        })
        .map((eventData) => {
          return (
            <tr key={eventData.ID} className="data-row">
              <td>{eventData.NAME}</td>
              <td>{moment(eventData.DATE_TIME).utc().format('DD/MM/YYYY')}</td>
              <td>{eventData.ZOOMID}</td>
              <td>{eventData.ZOOM_PASS}</td>
              <td>{eventData.REGISTRATION_COUNT}</td>
              {/* <td>{eventData.ATTENDEES_COUNT}</td> */}
              <td>
                <Button
                  className="btn-primary"
                  // onClick={() => handleClick(eventData)}
                  onClick={() => handleOpen(eventData)}
                >
                  Edit
                </Button>
              </td>

              <td>
                <Button
                  className="btn-dlt"
                  // onClick={() => handleDeleteEvent(eventData.ID)}
                  onClick={() => downloadData(eventData)}
                >
                  DELETE
                </Button>
              </td>
            </tr>
          )
        })
    } else {
      tableBodyComponent = referrals
        // .sort((a, b) => {
        //   switch (activeSort) {
        //     case 'name': {
        //       if (sortAsc) return a.NAME > b.NAME ? 1 : -1
        //       else return a.NAME < b.NAME ? 1 : -1
        //     }
        //     case 'date': {
        //       const dateA = new Date(a.DATE_TIME)
        //       const dateB = new Date(b.DATE_TIME)
        //       if (sortAsc) return dateA > dateB ? 1 : -1
        //       else return dateA < dateB ? 1 : -1
        //     }
        //     case 'regCount': {
        //       if (sortAsc)
        //         return a.REGISTRATION_COUNT > b.REGISTRATION_COUNT ? 1 : -1
        //       else return a.REGISTRATION_COUNT < b.REGISTRATION_COUNT ? 1 : -1
        //     }
        //     case 'attendeeCount': {
        //       if (sortAsc) return a.ATTENDEES_COUNT > b.ATTENDEES_COUNT ? 1 : -1
        //       else return a.ATTENDEES_COUNT < b.ATTENDEES_COUNT ? 1 : -1
        //     }
        //     default:
        //       return 1
        //   }
        // })
        .map((eventData) => {
          return (
            <tr key={eventData.ID} className="data-row">
              <td>
                {eventData.REFEREE_FIRSTNAME.concat(
                  ' ',
                  eventData.REFEREE_LASTNAME
                )}{' '}
              </td>
              <td>
                {eventData.REFERRED_FIRSTNAME.concat(
                  ' ',
                  eventData.REFERRED_LASTNAME
                )}
              </td>
              {/* <td>{eventData.NAME}</td> */}
              <td>
                {moment(eventData.CREATED_AT)
                  .add(5, 'hours')
                  .add(30, 'minutes')
                  .format('DD MMM, YYYY HH:mm')}
              </td>
              {/* <td>{eventData.ZOOMID}</td>
              <td>{eventData.ZOOM_PASS}</td>
              <td>{eventData.REGISTRATION_COUNT}</td> */}
              {/* <td>{eventData.ATTENDEES_COUNT}</td> */}
              <td>
                <Button
                  className="btn-primary"
                  // onClick={() => handleClick(eventData)}
                  onClick={() => handleOpen(eventData)}
                >
                  VIEW
                </Button>
              </td>

              <td>
                <Button
                  className="btn-dlt"
                  // onClick={() => handleDeleteEvent(eventData.ID)}
                  onClick={() => downloadData(eventData)}
                >
                  Download
                </Button>
              </td>
            </tr>
          )
        })
    }
  }

  const handlePageSelect = (event) => {
    setPage(Number(event.target.text))
  }
  const renderPageItems = () => {
    const pageItems = []
    const totalPages = Math.ceil(totalPosts / 10)

    if (totalPages > 1) {
      // Add "First" page item
      pageItems.push(
        <Pagination.First
          key="first"
          disabled={page === 1}
          onClick={() => setPage(1)}
        />
      )

      // Add "Previous" page item
      pageItems.push(
        <Pagination.Prev
          key="prev"
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        />
      )

      // Calculate page range
      let startPage
      let endPage
      const pageRange = 15 // number of pages to show in the pagination
      if (totalPages <= pageRange) {
        startPage = 1
        endPage = totalPages
      } else {
        // eslint-disable-next-line
        if (page <= Math.floor(pageRange / 2) + 1) {
          startPage = 1
          endPage = pageRange
        } else if (page >= totalPages - Math.floor(pageRange / 2)) {
          startPage = totalPages - pageRange + 1
          endPage = totalPages
        } else {
          startPage = page - Math.floor(pageRange / 2)
          endPage = page + Math.floor(pageRange / 2)
        }
      }

      // Add ellipsis before page items
      if (startPage > 1) {
        pageItems.push(<Pagination.Ellipsis key="ellipsis-start" />)
      }

      // Add page items
      // eslint-disable-next-line
      for (let i = startPage; i <= endPage; i++) {
        pageItems.push(
          <PageItem key={i} active={i === page} onClick={handlePageSelect}>
            {i}
          </PageItem>
        )
      }

      // Add ellipsis after page items
      if (endPage < totalPages) {
        pageItems.push(<Pagination.Ellipsis key="ellipsis-end" />)
      }

      // Add "Next" page item
      pageItems.push(
        <Pagination.Next
          key="next"
          disabled={page === totalPages}
          onClick={() => setPage(page + 1)}
        />
      )

      // Add "Last" page item
      pageItems.push(
        <Pagination.Last
          key="last"
          disabled={page === totalPages}
          onClick={() => setPage(totalPages)}
        />
      )
    }

    return pageItems
  }

  console.log('TotalPosts', totalPosts)
  return (
    <Container fluid className="w-100">
      <Row className="heading mb-3">
        <h3 className="title">REFERRALS</h3>
      </Row>

      <Row
        className="mb-3"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {/* <Col xs={8} sm={4}>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              type="text"
              placeholder="Enter Event Title"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Form>
        </Col> */}
        {/* <Col sm={4} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Form>
            <Button className="btn-primary" onClick={() => handleAdd()}>
              Add Event
            </Button>
          </Form>
        </Col> */}
      </Row>

      <Row>
        <Table responsive className="table">
          <thead>
            <tr>
              <th>REFEREE_NAME</th>
              <th>REFERRED_NAME</th>
              <th>Date</th>

              <th>Edit</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>{tableBodyComponent}</tbody>
        </Table>
      </Row>
      {totalPosts && (
        <Row>
          <Pagination>{renderPageItems()}</Pagination>
        </Row>
      )}

      {show && referObj && (
        <Modal show={show && referObj} onHide={() => handleClose()} size="xl">
          <Modal.Header closeButton>
            <h5 className="text-center ml-4 pt-3 pb-3 width-100">
              Referral Info
            </h5>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <div className="width-50 height-100 d-flex flex-column justify-content-between">
                <h5 className="mb-3" style={{ textAlign: 'center' }}>
                  REFEREE INFO
                </h5>
                <p>
                  <strong style={{ fontWeight: 'bold' }}>FIRSTNAME:</strong>{' '}
                  {referObj.REFEREE_FIRSTNAME}
                </p>
                <p>
                  <strong style={{ fontWeight: 'bold' }}>LASTNAME:</strong>{' '}
                  {referObj.REFEREE_LASTNAME}
                </p>
                <p>
                  <strong style={{ fontWeight: 'bold' }}>EMAIL:</strong>{' '}
                  {referObj.REFEREE_EMAIL}
                </p>
                <p>
                  <strong style={{ fontWeight: 'bold' }}>MOBILE:</strong>{' '}
                  {referObj.REFEREE_MOBILE_NUMBER}
                </p>
                <p>
                  <strong style={{ fontWeight: 'bold' }}>CITY:</strong>{' '}
                  {referObj.REFEREE_CITY}
                </p>
              </div>
              <div
                style={{
                  height: '100px!important',
                  margin: '0px 10px !important',
                  width: '10px!important',
                  backgroundColor: 'black!important',
                }}
              ></div>
              <div className="width-50">
                <h5 className="mb-3" style={{ textAlign: 'center' }}>
                  REFERRED INFO
                </h5>
                <p>
                  <strong style={{ fontWeight: 'bold' }}>FIRSTNAME:</strong>{' '}
                  {referObj.REFERRED_FIRSTNAME}
                </p>
                <p>
                  <strong style={{ fontWeight: 'bold' }}>LASTNAME:</strong>{' '}
                  {referObj.REFERRED_LASTNAME}
                </p>
                <p>
                  <strong style={{ fontWeight: 'bold' }}>EMAIL:</strong>{' '}
                  {referObj.REFERRED_EMAIL}
                </p>
                <p>
                  <strong style={{ fontWeight: 'bold' }}>MOBILE:</strong>{' '}
                  {referObj.REFERRED_MOBILE_NUMBER}
                </p>
                <p>
                  <strong style={{ fontWeight: 'bold' }}>CITY:</strong>{' '}
                  {referObj.REFERRED_CITY}
                </p>
                {/* <p>
                  <strong style={{ fontWeight: 'bold' }}>SCHOOL:</strong>{' '}
                  {referObj.REFERRED_SCHOOL}
                </p> */}
                {/* <p>
                  <strong style={{ fontWeight: 'bold' }}>PINCODE:</strong>{' '}
                  {referObj.REFERRED_PINCODE || ''}
                </p> */}
                {/* <p>
                  <strong style={{ fontWeight: 'bold' }}>GRADE:</strong>{' '}
                  {referObj.REFERRED_GRADE}
                </p> */}
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
          <span className="mt-3 mb-3 width-100 text-center d-flex justify-content-center">
            <Button
              variant="primary"
              onClick={() => handleEdit()}
              className="m-2"
              id=""
            >
              {isLoading ? <Spinner animation="border" size="sm" /> : 'Save'}
            </Button>
            <Button
              variant="primary"
              onClick={() => handleCloseEditModal()}
              className="bg-gray m-2"
            >
              Cancel
            </Button>
          </span>
        </Modal.Footer> */}
        </Modal>
      )}
    </Container>
  )
})
