import React, { useState, useCallback, useEffect } from 'react'
import Select from 'react-select'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MultiSelectInput = ({
  id,
  options = [],
  name,
  label,
  defaultKeys = [],
  onChange,
  className,
  isError = false,
  errorMessage,
  placeholder,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    defaultKeys.map((item) => {
      return { label: item, value: item }
    })
  )

  useEffect(() => {
    setSelectedOptions(
      defaultKeys.map((item) => {
        return { label: item, value: item }
      })
    )
  }, [defaultKeys, options])

  const handleChange = useCallback(
    (opts) => {
      onChange(
        id,
        opts.map((item) => item.value),
        !!opts.length
      )
      setSelectedOptions(opts)
    },
    [id, onChange, options]
  )

  const handleClear = (selectedOpts, data) => {
    if (!selectedOpts) {
      // return []
      handleChange([])
    }
    handleChange(selectedOpts.filter((item) => item.value !== data.value))
  }
  return (
    <div>
      <div className="mb-1.5">
        {label && (
          <label className="font-medium text-base whitespace-nowrap text-[#091A42]" htmlFor={name}>
            {`${label || 'MultiSelect'}`}
          </label>
        )}
      </div>

      <Select
        components={{
          MultiValue: ({ data }) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  backgroundColor: 'rgb(230, 230, 230)',
                  borderRadius: '2px',
                  display: 'flex',
                  margin: '2px',
                  minWidth: '0px',
                  boxSizing: 'border-box',
                }}
              >
                <span
                  style={{
                    margin: '2px',
                    boxSizing: 'border-box',
                    color: 'rgb(51, 51, 51)',
                    textOverflow: 'ellipsis',
                    padding: '5px 5px 5px 5px',
                    whiteSpace: 'nowrap',
                    fontSize: '14px',
                    overflow: 'hidden',
                  }}
                >
                  {options.filter((item) => item.value === data.value)[0].label}
                </span>{' '}
                <span
                  style={{
                    display: 'flex',
                    paddingRight: '-8px',
                    cursor: 'pointer',
                    alignItems: 'center',
                  }}
                >
                  <i
                    className="bi bi-x icon"
                    aria-hidden="true"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleClear(selectedOptions, data)
                    }}
                  ></i>
                </span>
              </span>
            </div>
          ),
        }}
        value={selectedOptions}
        onChange={handleChange}
        options={options.map((option) => ({
          value: option.value,
          label: (
            <div className="d-flex justify-content-start">
              <input
                style={{
                  width: '12px',
                  border: '1px solid red',
                  borderRadius: '5px',
                  marginRight: '8px',
                }}
                type="checkbox"
                value={option.value}
                checked={(selectedOptions || []).find((item) => item.value === option.value)}
                onChange={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()} // Prevent dropdown from opening
              />
              {'  '}
              {option.label}
            </div>
          ),
        }))}
        styles={{
          control: (provided, state) => ({
            ...provided,
            border: '1px solid rgb(137, 137, 137)',
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            color: 'black',
          }),
        }}
        isMulti
        placeholder={placeholder}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
      />

      {isError && (
        <span className="text-warning text-sm">
          <FontAwesomeIcon icon={faCircleInfo} className="" /> {errorMessage}
        </span>
      )}
    </div>
  )
}

export default MultiSelectInput
