/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Table, Button, Form, Col } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { Link, useNavigate } from 'react-router-dom'
import { getUserList, getSearchedUsers } from '../../actions/userListActions'
import withLoginRequired from '../../HOC/LoginModal'

export default withLoginRequired(() => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userListData = useSelector((state) => state.userList.userList)
  const searchedUsers = useSelector((state) => state.searchedUsers.searchedUsers)

  const [currPage, setCurrPage] = useState(1)
  const [usersPerPage, setUsersPerPage] = useState(25)
  const [searchText, setSearchText] = useState('')
  const [showSearched, setShowSearched] = useState(false)

  const list = showSearched ? searchedUsers : userListData

  const handleClick = (params) => {
    window.open(`user/edit-user/${params}`)
  }

  useEffect(() => {
    dispatch(getUserList(0, 100))
  }, [dispatch])

  const indexOfLastUser = currPage * usersPerPage
  const indexOfFirstUser = indexOfLastUser - usersPerPage

  let tableBodyComponent = null

  if (list !== undefined && list !== null && typeof list === 'object' && list.length > 0) {
    console.log('Should not make it here if list is empty', list)
    tableBodyComponent = list?.map((userData) => {
      return (
        <tr key={userData.ID} onClick={() => handleClick(userData.ID)} role="button" className="data-row">
          <td>{userData.ID}</td>
          <td>{userData.EMAIL}</td>
          <td>{`${userData.FIRSTNAME} ${userData.LASTNAME}`}</td>
          <td>{userData.PHONENUMBER}</td>

          <td>
            {userData.ISPREMIUM === 4
              ? 'Premium plus'
              : userData.ISPREMIUM === 3
              ? 'Premium'
              : userData.ISPREMIUM === 2
              ? 'Plus'
              : 'Basic'}
          </td>
          <td>
            <Button className="btn-primary">Edit</Button>
          </td>
        </tr>
      )
    })
  } else if (typeof list === 'string' && list.length) {
    tableBodyComponent = (
      <>
        <Row style={{ width: '100%' }}>
          <Row
            style={{
              width: '100%',
              fontWeight: 500,
              color: 'white',
              fontSize: '1.5rem',
            }}
            className="d-flex justify-content-center pb-3 pt-3"
          >
            This email id does not exist. Do you want to create a CollegePass account?
          </Row>
        </Row>
        <Row className="d-flex justify-content-around">
          <Col className="d-flex justify-content-center">
            <Button
              onClick={() => {
                navigate('/add-student')
              }}
            >
              Add
            </Button>
          </Col>
          <Col className="d-flex justify-content-center">
            <Button
              onClick={() => {
                window.location.reload()
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </>
    )
  }
  // <td>{userData.ISPREMIUM === 2 ? 'prime' : 'basic'}</td>

  const onSearchHandler = (e) => {
    if (searchText) {
      e.preventDefault()
      dispatch(getSearchedUsers(searchText))
      setShowSearched(true)
    }
  }

  return (
    <Container fluid className="w-100">
      <Row className="heading mb-3">
        <h3 className="title">
          User List
          {/* {userListData !== undefined
            ? userListData.length > 0
              ? userListData.length
              : 0
            : 0}
          ) */}
        </h3>
      </Row>

      <Row className="mb-3 w-50 pr-3">
        <Col>
          <Form onSubmit={onSearchHandler}>
            <Form.Control
              type="text"
              placeholder="Enter Email / Phone / Name"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Form>
        </Col>
        <Col>
          <Link to="/add-student">
            <Button
              className="btn-primary"
              // onClick={handleSubmit}
              style={{
                padding: '8px 12px',
                fontSize: '1rem',
              }}
            >
              ADD
            </Button>
          </Link>
        </Col>
      </Row>

      {!(typeof list === 'string' && list.length) ? (
        <Row>
          <Table responsive className="table">
            <thead>
              <tr>
                <th>UserId</th>
                <th>Email</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Premium Level</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{tableBodyComponent}</tbody>
          </Table>
        </Row>
      ) : (
        tableBodyComponent
      )}
    </Container>
  )
})
