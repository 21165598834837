import React, { useState } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FloatingLabel,
  Card,
  Alert,
} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import {
  APIregister,
  APIregisterV1,
  APIregistercheckV1,
} from '../../utils/config/API'
import withLoginRequired from '../../HOC/LoginModal'

export default withLoginRequired(() => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [showBasicAlert, setShowBasicAlert] = useState(false)
  const [responseStatus, setresponseStatus] = useState(false)
  const [responseMessage, setresponseMessage] = useState('')

  const onSubmit = async (e) => {
    e.preventDefault()
    // const form = e.currentTarget
    // setLoading(true)
    // if (form.checkValidity() === false) {
    //   e.stopPropagation()
    //   setLoading(false)
    console.log(email, password)
    if (!email || !password) {
      e.stopPropagation()
      return
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    try {
      const res = await axios.post(
        APIregistercheckV1,
        {
          EMAIL: email,
          PASSWORD: password,
        },
        config
      )

      if (!res.data.status && res.data.id) {
        // navigate('/')
        window.open(
          window.location.href
            .replace('add-student', '')
            .concat(`user/edit-user/${res.data.id}`),
          '_self'
        )
        // setErrorText(res.data.message)
      } else {
        const resp = await axios.post(
          // APIregister,
          APIregisterV1,
          {
            EMAIL: email,
            PASSWORD: password,
          },
          config
        )
        console.log('Made it here', resp.data)
        // dispatch(register(email, password))
        if (resp.status === 200) {
          // onSubmitRegister()
          // setLoading(false)
          setresponseStatus(true)
          setresponseMessage('Registered successfully')
          setShowBasicAlert(true)
          setTimeout(() => {
            window.open(
              window.location.href
                .replace('add-student', '')
                .concat(`user/edit-user/${resp.data.id}`),
              '_self'
            )
          }, 500)
        } else {
          setresponseStatus(false)
          setresponseMessage('Something went wrong')
          setShowBasicAlert(true)
        }
        // if (getDetails.status === 200) {
        // setresponseStatus(true)
        // setresponseMessage(insertResponse.message)
        // console.log('setting sucess message', responseMessage)
        // } else {
        // setresponseStatus(false)
        // setresponseMessage(
        //   getDetails.data.message || 'Something went wrong'
        // )
        // setShowBasicAlert(true)
        // console.log('setting unsucessful message', responseMessage)
        // }
      }
    } catch (err) {
      console.log('Error =->', err)
      // setErrorText(
      //   res.data.message || 'Something went wrong, please try again later'
      // )
    }

    // setValidated(true)
  }
  return (
    <Container fluid className="w-100">
      <Row className="mb-3">
        <h3 className="title">
          <Link to="/user-list-100">
            <i className="bi bi-arrow-left"></i>
          </Link>
          ADD STUDENT
        </h3>
      </Row>

      <Row className="mb-3">
        <Form onSubmit={onSubmit}>
          <Row>
            <Col className="mb-4">
              <FloatingLabel
                controlId="Enter Email"
                label="Email"
                className={email ? 'fs-6 color-light-pink' : 'color-light-pink'}
              >
                <Form.Control
                  className="bg-grey color-white"
                  type="email"
                  placeholder="Enter Email"
                  defaultValue={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FloatingLabel>
            </Col>
          </Row>

          <Row>
            <Col className="mb-4">
              <FloatingLabel
                controlId="Enter Password"
                label="Password"
                className={email ? 'fs-6 color-light-pink' : 'color-light-pink'}
              >
                <Form.Control
                  className="bg-grey color-white"
                  type="password"
                  placeholder="Enter Password"
                  defaultValue={password}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FloatingLabel>
            </Col>
          </Row>
          {/* <Row style={{ display: 'flex', justifyContent: 'center' }}> */}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              className="btn-primary"
              // onClick={onSubmit}
              style={{
                padding: '8px 12px',
                fontSize: '1rem',
              }}
              type="submit"
            >
              REGISTER
            </Button>
          </div>
          {/* </Row> */}
        </Form>
      </Row>

      <Row className="mt-3 px-2">
        {showBasicAlert ? (
          <Alert
            variant={responseStatus ? 'success' : 'danger'}
            onClose={() => setShowBasicAlert(false)}
            dismissible
            className="rounded"
            style={{
              position: 'fixed',
              top: 1,
              // left: 0,
              right: 0,
              zIndex: 999,
              textAlign: 'center',
              width: '40%',
            }}
          >
            {responseMessage}
          </Alert>
        ) : null}
      </Row>
    </Container>
  )
})
