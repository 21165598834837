import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import {
  Form,
  Button,
  Col,
  Row,
  DropdownButton,
  Dropdown,
  Container,
  FloatingLabel,
  Card,
  Spinner,
  FormControl,
} from 'react-bootstrap'
import Select, { components, cla } from 'react-select'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  APIAddPost,
  APIGetPost,
  APIUpdatePost,
  getTags,
} from '../../utils/config/API'
import withLoginRequired from '../../HOC/LoginModal'

export default withLoginRequired(() => {
  const postId = useParams().id
  const navigate = useNavigate()

  const [postType, setPostType] = useState('text')

  const [uploadObj, setUploadObj] = useState({
    title: '',
    description: '',
    image_url: '',
    pollOptions: [],
    tags: [],
  })

  const [selectedEventTags, setSelectedEventTags] = useState([])
  const [tags, setTags] = useState([])
  const [isLoading, setIsloading] = useState(false)

  const [postImage, setPostImage] = useState({ preview: '', raw: '' })

  const handleImageChange = (e) => {
    if (e.target.files.length) {
      setPostImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }
  const getAllTags = async () => {
    try {
      const result = await axios.get(getTags)
      if (result.status === 200) {
        setTags(result.data.body)
      }
    } catch (err) {
      console.log('Error ------>', err)
    }
  }
  useEffect(() => {
    getAllTags()
  }, [])

  const getPost = async (id) => {
    try {
      const post = await axios.get(`${APIGetPost}/${id}`)
      if (post.status === 200) {
        setPostType(post.data.body.type)

        const fetchedTags = post.data.body.tags
          ? post.data.body.tags.includes(',')
            ? post.data.body.tags.split(',')
            : post.data.body.tags.split('')
          : []

        const selectedTags = fetchedTags
          .map((tag) => {
            const tagObj = tags.find((t) => t.ID === Number(tag))
            return tagObj ? { label: tagObj.NAME, value: tagObj.ID } : null
          })
          .filter(Boolean)

        setSelectedEventTags(selectedTags)

        setUploadObj((prev) => {
          return {
            ...prev,
            title: post.data.body.title,
            description: post.data.body.description,
            image_url: post.data.body.imageUrl,
            pollOptions:
              post.data.body.pollOptions && post.data.body.pollOptions.length
                ? post.data.body.pollOptions.map((item) => item.text)
                : null,
          }
        })
      }
    } catch (err) {
      console.log('Error -------------->', err)
    }
  }
  useEffect(() => {
    if (postId) {
      getPost(postId)
    }
  }, [postId, tags])

  const handleSubmit = async (e) => {
    e.preventDefault()
    // TODO: Submit form data to backend
    const data = new FormData()

    data.append('type', postType)
    data.append('title', uploadObj.title)
    postType === 'TEXT' && data.append('description', uploadObj.description)
    postType === 'IMAGE' && data.append('image', postImage.raw)
    postType === 'POLL' &&
      data.append('options', JSON.stringify(uploadObj.pollOptions))
    data.append(
      'tags',
      selectedEventTags && selectedEventTags.length > 0
        ? selectedEventTags.map((tag) => tag.value).join(',')
        : ''
    )

    try {
      setIsloading(true)
      if (postId) {
        data.append('imageUrl', uploadObj.image_url)
        data.append('id', postId)
        const updateResponse = await axios.put(APIUpdatePost, data, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        if (updateResponse.status === 201) {
          // window.open('/events', '_self')
          navigate('/post-list')
          setIsloading(false)
        } else {
          setIsloading(false)
        }
      } else {
        console.log('inserting')
        const insertResponse = await axios.post(APIAddPost, data, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        if (insertResponse.status === 201) {
          // window.open('/events', '_self')
          setIsloading(false)
          navigate('/post-list')
          console.log('Successfully uploaded to database')
        } else {
          setIsloading(false)
        }
      }
    } catch (error) {
      setIsloading(false)
      console.error('Error ----------->', error)
    }
  }

  const inputRef = useRef(null)
  const [focusIndex, setFocusIndex] = useState(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [uploadObj.pollOptions])

  const component =
    postType === 'TEXT' ? (
      <>
        <Row>
          <Col>
            <Form.Group controlId="formTitle">
              <FloatingLabel
                controlId="pollTitle"
                label="Title"
                className={
                  uploadObj?.title
                    ? 'fs-6 color-light-pink'
                    : 'color-light-pink'
                }
              >
                <Form.Control
                  className="bg-grey color-white"
                  type="text"
                  placeholder="Enter title"
                  value={uploadObj?.title}
                  onChange={(e) => {
                    setUploadObj({ ...uploadObj, title: e.target.value })
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Group controlId="formDescription">
              <FloatingLabel
                controlId="description"
                label="Description"
                className={
                  uploadObj?.description
                    ? 'fs-6 color-light-pink'
                    : 'color-light-pink'
                }
              >
                <Form.Control
                  className="bg-grey color-white"
                  as="textarea"
                  rows={3}
                  placeholder="Enter description"
                  value={uploadObj?.description}
                  onChange={(e) => {
                    setUploadObj({ ...uploadObj, description: e.target.value })
                  }}
                  style={{
                    height: 'max-content',
                    paddingTop: '2rem',
                    paddingBottom: '2rem',
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
      </>
    ) : postType === 'IMAGE' ? (
      <>
        <Row>
          <Col>
            <Form.Group controlId="formTitle">
              <FloatingLabel
                controlId="pollTitle"
                label="Title"
                className={
                  uploadObj?.title
                    ? 'fs-6 color-light-pink'
                    : 'color-light-pink'
                }
              >
                <Form.Control
                  className="bg-grey color-white"
                  type="text"
                  placeholder="Enter title"
                  value={uploadObj?.title}
                  onChange={(e) => {
                    setUploadObj({ ...uploadObj, title: e.target.value })
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mh-100 mt-3">
          <Col className="mb-4 mh-100">
            <Card className="bg-grey color-white p-3 h-100">
              <Form.Label className="fs-6 color-light-pink">
                Image Banner :{' '}
                {uploadObj?.image_url ? (
                  <a
                    href={uploadObj?.image_url}
                    alt="banner image"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Open current banner image
                  </a>
                ) : (
                  <span className="color-white">No current banner image</span>
                )}
              </Form.Label>
              <Form.Control
                type="file"
                size="sm"
                accept=".png,.jpg,.jpeg"
                onChange={handleImageChange}
              />
            </Card>
          </Col>
        </Row>
      </>
    ) : postType === 'POLL' ? (
      <>
        <Row>
          <Col>
            <Form.Group controlId="formTitle">
              <FloatingLabel
                controlId="pollTitle"
                label="Title"
                className={
                  uploadObj?.title
                    ? 'fs-6 color-light-pink'
                    : 'color-light-pink'
                }
              >
                <Form.Control
                  className="bg-grey color-white"
                  type="text"
                  placeholder="Enter title"
                  value={uploadObj?.title}
                  onChange={(e) => {
                    setUploadObj({ ...uploadObj, title: e.target.value })
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        {uploadObj?.pollOptions.map((option, index) => (
          <Row className="mt-3">
            <Col xs={11}>
              <Form.Group
                controlId={`formPollOption${index}`}
                key={Math.random()}
              >
                <FloatingLabel
                  controlId="pollOptions"
                  label={`Poll Option ${index + 1}`}
                  className={
                    uploadObj?.pollOptions[index]
                      ? 'fs-6 color-light-pink'
                      : 'color-light-pink'
                  }
                >
                  <Form.Control
                    type="text"
                    className="bg-grey color-white"
                    placeholder={`Enter poll option ${index + 1}`}
                    value={uploadObj?.pollOptions[index]}
                    onChange={(e) => {
                      index !== focusIndex && setFocusIndex(index)
                      const tempArr = [...uploadObj.pollOptions]
                      tempArr[index] = e.target.value
                      setUploadObj({ ...uploadObj, pollOptions: tempArr })
                    }}
                    ref={focusIndex === index ? inputRef : null}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col xs={1} className="text-end">
              <Button
                onClick={() => {
                  const arr = [...uploadObj.pollOptions]
                  arr.splice(index, 1)
                  setUploadObj({ ...uploadObj, pollOptions: arr })
                  setFocusIndex(null)
                }}
                className="w-100"
              >
                -
              </Button>
            </Col>
          </Row>
        ))}

        <Button
          onClick={() =>
            setUploadObj({
              ...uploadObj,
              pollOptions: [...uploadObj.pollOptions, ''],
            })
          }
          className="mt-3"
        >
          Add Option
        </Button>
      </>
    ) : null

  console.log('Selected Tags ->', selectedEventTags)

  const ClearAllIndicator = (props, tagName) => {
    const { getStyles, selectProps } = props
    const styles = getStyles('clearIndicator', props)
    return (
      <div
        className="custom-clear-all-indicator"
        style={styles}
        onClick={(e) => {
          e.stopPropagation()
          setSelectedEventTags((prevState) =>
            prevState.filter((tag) => {
              const index = tags.findIndex((i) => i.NAME === tag.label)
              return tags[index].TYPE?.toLowerCase() !== tagName
            })
          )
        }}
      >
        <i
          className="bi bi-x icon"
          aria-hidden="true"
          style={{ cursor: 'pointer', color: 'rgb(30, 30, 30)' }}
        ></i>
      </div>
    )
  }

  const MultiValueRemove = (props) => {
    const { data } = props
    return (
      <components.MultiValueRemove {...props}>
        <i
          onClick={() => {
            setSelectedEventTags((prev) =>
              prev.filter((item) => item.label !== data.label)
            )
          }}
          className="bi bi-x icon"
          aria-hidden="true"
          // style={{ cursor: 'pointer', color: 'rgb(90, 90, 90)' }}
        ></i>
      </components.MultiValueRemove>
    )
  }

  return (
    <Container fluid className="w-100">
      <Row className="mb-3 d-flex justify-content-between">
        <Col>
          <h3 className="title">
            <Link to="/post-list">
              <i className="bi bi-arrow-left"></i>
            </Link>
            FEED POSTS
          </h3>
        </Col>
        {/* {id && (
          <Col className="d-flex justify-content-center">
            <Button onClick={() => setShow(true)}>DELETE</Button>
          </Col>
        )} */}
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="formPostType">
              <Col className="mb-4 h-100">
                <FloatingLabel
                  controlId="postType"
                  label="Post Type"
                  className={
                    postType ? 'fs-6 color-light-pink' : 'color-light-pink'
                  }
                >
                  <Form.Select
                    className="bg-grey color-white"
                    aria-label="Floating label select example"
                    // value={uploadObj?.event_premium_level}
                    value={postType}
                    name="postType"
                    onChange={(e) => !postId && setPostType(e.target.value)}
                    // onChange={(e) =>
                    //   setUploadObj({
                    //     ...uploadObj,
                    //     event_premium_level: e.target.value,
                    //   })
                    // }
                    // disabled={postId}
                  >
                    <option>--Select Post Type--</option>
                    <option disabled={!!postId} value="TEXT">
                      TEXT
                    </option>
                    <option disabled={!!postId} value="IMAGE">
                      IMAGE
                    </option>
                    <option disabled={!!postId} value="POLL">
                      POLL
                    </option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Select
              components={{
                MultiValueRemove,
                ClearIndicator: (props) => ClearAllIndicator(props, 'country'),
              }}
              value={selectedEventTags.filter((item) => {
                const index = tags.findIndex((i) => i.NAME === item.label)
                return (
                  index >= 0 && tags[index].TYPE?.toLowerCase() === 'country'
                )
              })}
              styles={{
                multiValueRemove: (base) => ({
                  ...base,
                  cursor: 'pointer',
                  color: 'rgb(90,90,90)',
                  fontWeight: 'bolder',
                }),
              }}
              // onChange={(e) => {
              //   setSelectedEventTags((prevState) =>
              //     prevState.concat(
              //       e.filter((tag) => {
              //         const index = tags.findIndex((i) => i.NAME === tag.label)
              //         const prevIndex = prevState.findIndex(
              //           (i) => i.label === tag.label
              //         )
              //         return (
              //           index >= 0 &&
              //           tags[index].TYPE?.toLowerCase() === 'country' &&
              //           prevIndex === -1
              //         )
              //       })
              //     )
              //   )
              // }}
              onChange={(e) => {
                setSelectedEventTags((prevState) =>
                  e ? [{ label: e.label, value: e.value }] : []
                )
              }}
              isMulti={false}
              options={tags
                .filter((item) => item.TYPE?.toLowerCase() === 'country')
                .map((item, index) => {
                  return {
                    label: item.NAME,
                    value: item.ID,
                  }
                })}
              // isMulti
              placeholder="Country Tags"
            />
          </Col>
          <Col>
            <Select
              components={{
                MultiValueRemove,
                ClearIndicator: (props) => ClearAllIndicator(props, 'branch'),
              }}
              value={selectedEventTags.filter((item) => {
                const index = tags.findIndex((i) => i.NAME === item.label)
                return (
                  index >= 0 && tags[index].TYPE?.toLowerCase() === 'branch'
                )
              })}
              styles={{
                multiValueRemove: (base) => ({
                  ...base,
                  cursor: 'pointer',
                  color: 'rgb(90,90,90)',
                }),
              }}
              // onChange={(e) => {
              //   setSelectedEventTags((prevState) =>
              //     prevState.concat(
              //       e.filter((tag) => {
              //         const index = tags.findIndex((i) => i.NAME === tag.label)
              //         const prevIndex = prevState.findIndex(
              //           (i) => i.label === tag.label
              //         )
              //         return (
              //           index >= 0 &&
              //           tags[index].TYPE?.toLowerCase() === 'branch' &&
              //           prevIndex === -1
              //         )
              //       })
              //     )
              //   )
              // }}
              onChange={(e) => {
                setSelectedEventTags((prevState) =>
                  e ? [{ label: e.label, value: e.value }] : []
                )
              }}
              isMulti={false}
              options={tags
                .filter((item) => item.TYPE?.toLowerCase() === 'branch')
                .map((item, index) => {
                  return {
                    label: item.NAME,
                    value: item.ID,
                  }
                })}
              // isMulti
              placeholder="Branch Tags"
            />
          </Col>
          <Col>
            <Select
              components={{
                MultiValueRemove,
                ClearIndicator: (props) => ClearAllIndicator(props, 'other'),
              }}
              value={selectedEventTags.filter((item) => {
                const index = tags.findIndex((i) => i.NAME === item.label)
                return index >= 0 && tags[index].TYPE?.toLowerCase() === 'other'
              })}
              styles={{
                multiValueRemove: (base) => ({
                  ...base,
                  cursor: 'pointer',
                  color: 'rgb(90,90,90)',
                }),
              }}
              // onChange={(e) => {
              //   setSelectedEventTags((prevState) =>
              //     prevState.concat(
              //       e.filter((tag) => {
              //         const index = tags.findIndex((i) => i.NAME === tag.label)
              //         const prevIndex = prevState.findIndex(
              //           (i) => i.label === tag.label
              //         )
              //         return (
              //           index >= 0 &&
              //           tags[index].TYPE?.toLowerCase() === 'other' &&
              //           prevIndex === -1
              //         )
              //       })
              //     )
              //   )
              // }}
              onChange={(e) => {
                setSelectedEventTags((prevState) =>
                  e ? [{ label: e.label, value: e.value }] : []
                )
              }}
              isMulti={false}
              options={tags
                .filter((item) => item.TYPE?.toLowerCase() === 'other')
                .map((item, index) => {
                  return {
                    label: item.NAME,
                    value: item.ID,
                  }
                })}
              // isMulti
              placeholder="Other Tags"
            />
          </Col>
        </Row>

        {/* </Row> */}
        {component}
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            // variant="primary"
            type="submit"
            style={{ width: '8rem', textAlign: 'center' }}
            className="mt-3 btn-primary"
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : postId ? (
              'SAVE'
            ) : (
              'ADD'
            )}
          </Button>
        </Row>
      </Form>
    </Container>
  )
})
