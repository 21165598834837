import axios from 'axios'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Container, Row, Table, Button, Form, Col, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getEventsFullData, deleteEventAction } from '../../actions/eventsActions'
import { getSeries, deleteSeries, updateHomeSeries, APIgetSeriesList } from '../../utils/config/API'
import withLoginRequired from '../../HOC/LoginModal'

export default withLoginRequired(() => {
  const [showAddHome, setShowAddHome] = useState(false)
  const handleClose = () => setShowAddHome(false)

  const dispatch = useDispatch()
  // const events = useSelector((state) => state.events.events)
  const [events, setevents] = useState([])
  const [sortAsc, setSortAsc] = useState(false)
  const [activeSort, setActiveSort] = useState('date')
  const [show, setShow] = useState(false)
  const [removeId, setremoveId] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  const [homepageSeries, sethomepageSeries] = useState()
  const [removeHomePage, setremoveHomePage] = useState()
  const handleShowAddHome = (id, choice) => {
    setremoveHomePage(choice)
    sethomepageSeries(id)
    setShowAddHome(true)
  }
  const handleYesHomepage = () => {
    // console.log()
    axios
      .post(updateHomeSeries, { series: homepageSeries, todo: removeHomePage })
      .then((e) => {
        window.location.reload()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const removeSeries = (id) => {
    axios
      .post(deleteSeries, { series: id })
      .then((e) => {
        window.location.reload()
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const handleYesClose = () => {
    removeSeries(removeId)
    // setremoveIndex(null)
    setShow(false)
  }
  const handleNoClose = () => {
    // setremoveIndex(null)
    console.log('dsfsd')
    setShow(false)
  }
  const handleShow = (index) => {
    setremoveId(index)
    setShow(true)
  }

  // useEffect(() => {
  //   dispatch(getEventsFullData())
  // }, [dispatch])

  useEffect(() => {
    axios
      .get(APIgetSeriesList)
      .then((e) => {
        console.log(e)
        setevents(e.data.message)
      })
      .catch((error) => {
        // console.log(error)
      })
  }, [])

  const handleClick = (eventData) => {
    // window.open(`series-list/${eventData.ID}`)
    window.open(`series/edit-series/${eventData.ID}`)
  }

  const handleAdd = () => {
    window.open('series')
  }

  let tableBodyComponent = null

  if (events !== undefined && events !== null && events.length > 0) {
    if (searchInput.length > 0) {
      tableBodyComponent = events
        .filter((e) => e.NAME.toLowerCase().includes(searchInput))
        .sort((a, b) => {
          switch (activeSort) {
            case 'name': {
              if (sortAsc) return a.Title > b.Title ? 1 : -1

              return a.Title < b.Title ? 1 : -1
            }
            case 'date': {
              const dateA = new Date(a.DATE_TIME)
              const dateB = new Date(b.DATE_TIME)
              if (sortAsc) return dateA > dateB ? 1 : -1

              return dateA < dateB ? 1 : -1
            }
            case 'regCount': {
              if (sortAsc) return a.REGISTRATION_COUNT > b.REGISTRATION_COUNT ? 1 : -1

              return a.REGISTRATION_COUNT < b.REGISTRATION_COUNT ? 1 : -1
            }
            case 'attendeeCount': {
              if (sortAsc) return a.ATTENDEES_COUNT > b.ATTENDEES_COUNT ? 1 : -1
              return a.ATTENDEES_COUNT < b.ATTENDEES_COUNT ? 1 : -1
            }
            default:
              return 1
          }
        })
        .map((eventData) => {
          // console.log(eventData)
          return (
            <tr key={eventData.ID} className="data-row">
              <td>{eventData.Title}</td>
              <td>{moment(eventData.DATE_TIME).utc().format('DD/MM/YYYY')}</td>
              <td>{eventData.episodes}</td>
              <td>{eventData.views}</td>
              {/* eslint-disable-next-line */}
              <td>
                {/* eslint-disable-next-line */}
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="option1"
                    checked={eventData.show_hp === 1 ? 'checked' : ''}
                    onClick={(e) => {
                      // console.log("status id", e.target.checked)
                      handleShowAddHome(eventData.ID, e.target.checked)
                    }}
                  />
                </div>
              </td>
              <td>
                <Button className="btn-primary" onClick={() => handleClick(eventData)}>
                  Edit
                </Button>
              </td>
              <td>
                <Button className="btn-dlt" onClick={() => handleShow(eventData.ID)}>
                  DELETE
                </Button>
              </td>
            </tr>
          )
        })
    } else {
      tableBodyComponent = events
        .sort((a, b) => {
          switch (activeSort) {
            case 'name': {
              if (sortAsc) return a.NAME > b.NAME ? 1 : -1
              return a.NAME < b.NAME ? 1 : -1
            }
            case 'date': {
              const dateA = new Date(a.DATE_TIME)
              const dateB = new Date(b.DATE_TIME)
              if (sortAsc) return dateA > dateB ? 1 : -1
              return dateA < dateB ? 1 : -1
            }
            case 'regCount': {
              if (sortAsc) return a.REGISTRATION_COUNT > b.REGISTRATION_COUNT ? 1 : -1
              return a.REGISTRATION_COUNT < b.REGISTRATION_COUNT ? 1 : -1
            }
            case 'attendeeCount': {
              if (sortAsc) return a.ATTENDEES_COUNT > b.ATTENDEES_COUNT ? 1 : -1
              return a.ATTENDEES_COUNT < b.ATTENDEES_COUNT ? 1 : -1
            }
            default:
              return 1
          }
        })
        .map((eventData) => {
          return (
            <tr key={eventData.ID} className="data-row">
              <td>{eventData.Title}</td>
              <td>{moment(eventData.DATE_TIME).utc().format('DD/MM/YYYY')}</td>
              <td>{eventData.episodes}</td>
              <td>{0}</td>
              {/* eslint-disable-next-line */}
              <td>
                {/* eslint-disable-next-line */}
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="option1"
                    checked={eventData.show_hp === 1 ? 'checked' : ''}
                    onClick={(e) => {
                      handleShowAddHome(eventData.ID, e.target.checked)
                    }}
                  />
                </div>
              </td>
              <td>
                <Button className="btn-primary" onClick={() => handleClick(eventData)}>
                  Edit
                </Button>
              </td>
              <td>
                <Button className="btn-dlt" onClick={() => handleShow(eventData.ID)}>
                  DELETE
                </Button>
              </td>
            </tr>
          )
        })
    }
  }

  return (
    <Container fluid className="w-100">
      <Row className="heading mb-3">
        <h3 className="title">Series</h3>
      </Row>

      <Row className="mb-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col xs={8} sm={4}>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              type="text"
              placeholder="Enter Series Title"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Form>
        </Col>
        <Col sm={4} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Form>
            <Button className="btn-primary" onClick={() => handleAdd()}>
              Add Series
            </Button>
          </Form>
        </Col>
      </Row>

      <Row>
        <Table responsive className="table">
          <thead>
            <tr>
              <th className="width-15">
                Series Name{' '}
                <i
                  className={
                    activeSort === 'name'
                      ? 'bi bi-arrow-down-up cursor-pointer active-sort'
                      : 'bi bi-arrow-down-up cursor-pointer inactive-sort'
                  }
                ></i>
              </th>
              <th>
                Release Date{' '}
                <i
                  className={
                    activeSort === 'date'
                      ? 'bi bi-arrow-down-up cursor-pointer active-sort'
                      : 'bi bi-arrow-down-up cursor-pointer inactive-sort'
                  }
                ></i>
              </th>
              <th>
                Number Of Episodes{' '}
                <i
                  className={
                    activeSort === 'regCount'
                      ? 'bi bi-arrow-down-up cursor-pointer active-sort'
                      : 'bi bi-arrow-down-up cursor-pointer inactive-sort'
                  }
                ></i>
              </th>
              <th>
                Number Of Views{' '}
                <i
                  className={
                    activeSort === 'attendeeCount'
                      ? 'bi bi-arrow-down-up cursor-pointer active-sort'
                      : 'bi bi-arrow-down-up cursor-pointer inactive-sort'
                  }
                ></i>
              </th>
              <th>
                Add to Homepage{' '}
                <i
                  className={
                    activeSort === 'attendeeCount'
                      ? 'bi bi-arrow-down-up cursor-pointer active-sort'
                      : 'bi bi-arrow-down-up cursor-pointer inactive-sort'
                  }
                ></i>
              </th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>{tableBodyComponent}</tbody>
        </Table>
      </Row>

      <Modal show={show} onHide={handleNoClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure You want to delete this Video/Episode ?</Modal.Body>
        <Modal.Footer>
          <Row className="width-100">
            <Col
              style={{
                textAlign: 'center',
              }}
            >
              <Button variant="secondary" onClick={() => handleYesClose()}>
                Yes
              </Button>
              <Button variant="primary" onClick={() => handleNoClose()}>
                No
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddHome} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to add this trailer to the homepage ?</Modal.Body>
        {/* Are you sure you want to remove this trailer from the homepage? (button - Remove & Cancel) */}
        <Modal.Footer>
          <Row className="width-100">
            <Col
              style={{
                textAlign: 'center',
              }}
            >
              <Button
                variant="secondary"
                onClick={() => {
                  handleYesHomepage()
                }}
              >
                Yes
              </Button>
              <Button variant="primary" onClick={handleClose}>
                No
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </Container>
  )
})
