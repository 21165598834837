const API_URL = `https://mvfdi3wy33.execute-api.ap-south-1.amazonaws.com/dev/`
const API_URL_NODE = `https://api.collegepass.org`
// const API_URL_NODE = `http://localhost:4000`
//  const API_URL = `http://localhost:5000`

const CMS = "/api/cms";

export const removeCookie = (key) =>{
  localStorage.removeItem(key);
}

export const signOut = () => {
  removeCookie('token');
  window.location = '/';
};

const apiService = async (
  path,
  method,
  body,
  headers,
  addJsonContentType = true,
  checkTokenExpire = true,
) => {
  const url = API_URL_NODE + CMS + path;
  try {
    let headerPayload = {};
    const authToken = localStorage.token;
    if (authToken) {
      headerPayload = {
        ...headerPayload,
        'Authorization': 'Bearer ' + authToken,
      };
    }
    headerPayload = {
      ...headerPayload,
      ...headers,
    };
    if (addJsonContentType) {
      headerPayload['Content-Type'] = 'application/json';
      body = body ? JSON.stringify(body) : null;
    }

    const response = await fetch(url, {
      method: method || 'GET',
      headers: headerPayload,
      body,
    });

    if (checkTokenExpire && response.status === 401) {
      // Remove the token if its invalid or expired.
      signOut();
      return null;
    }

    const data = addJsonContentType ? await response.json() : response;
    return data;
  } catch (e) {
    return null;
  }
};

export default apiService;
