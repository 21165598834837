import React, { useCallback } from 'react'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { validate } from '../../../../utils/validators'

const Index = ({
  id,
  label,
  labelId,
  name,
  value = [],
  className = '',
  errorClass = null,
  error = false,
  errorMessage = '',
  required = false,
  onChange = () => {},
  validators = [],
  renderValue = 'normal',
  options = [],
}) => {
  const onChangeHandler = useCallback(
    (e) => {
      const v = e.target.value
      const isValid = validate(v, validators)
      onChange(id, v, isValid)
    },
    [id, onChange, validators]
  )
  return (
    <FormControl fullWidth error={error}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        sx={{
          fieldset: {
            borderColor: error ? '#d32f2f !important' : '',
          },

          label: {
            color: error ? '#d32f2f !important' : '',
          },
        }}
        labelId={labelId}
        id={id}
        className={className}
        required={required}
        label={label}
        value={value}
        name={name}
        multiple
        onChange={onChangeHandler}
        renderValue={
          renderValue === 'normal'
            ? (selected) =>
                selected
                  .map((item) => options.find((i) => i.value === item)?.label)
                  .filter((item) => item)
                  .join(', ')
            : null
        }
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8,
              width: 250,
            },
          },
        }}
      >
        {options.map((item, idx) => {
          return (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox checked={value.indexOf(item.value) > -1} />
              <ListItemText primary={item.label} />
            </MenuItem>
          )
        })}
      </Select>
      {error && errorMessage && (
        <FormHelperText className={errorClass}>
          <i className="fa fa-exclamation-triangle"></i> {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default Index
