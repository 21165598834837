import React, { useEffect } from 'react'
import { CSVLink } from 'react-csv'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

import './EventData.scss'

function EventData({ eventDetails, getEvent }) {
  if (!getEvent) {
    return null
  }
  let rows = []
  if (!eventDetails.length) {
    return (
      <div className="mb-4 mt-4 d-flex flex-row justify-content-center">
        <p className="btn-event">DATA NOT AVAILABLE</p>
      </div>
    )
  }
  rows = eventDetails.map((item, index) => {
    return {
      date_time: item.DATE_TIME,
      utm_source: item.UTM_SOURCE,
      utm_medium: item.UTM_MEDIUM,
      utm_campaign: item.UTM_CAMPAIGN,
      firstname: item.FIRSTNAME,
      lastname: item.LASTNAME,
      email: item.EMAIL,
      phonenumber: item.PHONENUMBER,
      school: item.SCHOOL,
      grade_name: item.GRADE_NAME,
      curriculum: item.CURRICULUM,
      city: item.CITY,
      ispremium: item.ISPREMIUM,
      usertype: item.USERTYPE,
      firstname2: item.FIRSTNAME2,
      phonenumber2: item.PHONENUMBER2,
      id: index,
    }
  })

  const users = eventDetails.map((item, index) => {
    return {
      date_time: item.DATE_TIME,
      utm_source: item.UTM_SOURCE,
      utm_medium: item.UTM_MEDIUM,
      utm_campaign: item.UTM_CAMPAIGN,
      firstname: item.FIRSTNAME,
      lastname: item.LASTNAME,
      email: item.EMAIL,
      phonenumber: item.PHONENUMBER,
      school: item.SCHOOL,
      grade_name: item.GRADE_NAME,
      curriculum: item.CURRICULUM,
      city: item.CITY,
      ispremium: item.ISPREMIUM,
      usertype: item.USERTYPE,
      firstname2: item.FIRSTNAME2,
      phonenumber2: item.PHONENUMBER2,
    }
  })
  const columns = [
    { field: 'FIRSTNAME'.toLowerCase(), headerName: 'FIRSTNAME', flex: 1 },
    { field: 'LASTNAME'.toLowerCase(), headerName: 'LASTNAME', flex: 1 },
    { field: 'EMAIL'.toLowerCase(), headerName: 'EMAIL', flex: 1 },
    {
      field: 'PHONENUMBER'.toLowerCase(),
      headerName: 'PHONENUMBER',
      flex: 1,
    },
    { field: 'SCHOOL'.toLowerCase(), headerName: 'SCHOOL', flex: 1 },
    {
      field: 'GRADE_NAME'.toLowerCase(),
      headerName: 'GRADE_NAME',
      flex: 0.5,
    },
    {
      field: 'CURRICULUM'.toLowerCase(),
      headerName: 'CURRICULUM',
      flex: 0.5,
    },
    { field: 'CITY'.toLowerCase(), headerName: 'CITY', flex: 0.5 },
    { field: 'ISPREMIUM'.toLowerCase(), headerName: 'ISPREMIUM', flex: 0.5 },
    { field: 'USERTYPE'.toLowerCase(), headerName: 'USERTYPE', flex: 0.5 },
    {
      field: 'FIRSTNAME2'.toLowerCase(),
      headerName: 'FIRSTNAME2',
      flex: 1,
    },
    {
      field: 'PHONENUMBER2'.toLowerCase(),
      headerName: 'PHONENUMBER2',
      flex: 1,
    },
    {
      field: 'UTM_SOURCE'.toLowerCase(),
      headerName: 'UTM_SOURCE',
      flex: 0.5,
    },
    {
      field: 'UTM_MEDIUM'.toLowerCase(),
      headerName: 'UTM_MEDIUM',
      flex: 0.5,
    },
    {
      field: 'UTM_CAMPAIGN'.toLowerCase(),
      headerName: 'UTM_CAMPAIGN',
      flex: 0.5,
    },
  ]

  // const columns = [
  //   {
  //     field: 'FIRSTNAME',
  //     headerName: 'Archive Name',
  //     flex: 4,
  //     headerClassName: 'super-app-theme--header',
  //   },
  //   {
  //     field: 'EVENT_DATE_TIME',
  //     headerName: 'Date/Time',
  //     flex: 1.5,
  //     headerClassName: 'super-app-theme--header',
  //   },
  //   {
  //     field: 'VIMEO_ID',
  //     headerName: 'Vimeo ID',
  //     flex: 1.5,
  //     headerClassName: 'super-app-theme--header',
  //   },
  //   {
  //     field: 'TYPE',
  //     headerName: 'Video Type',
  //     flex: 1.5,
  //     headerClassName: 'super-app-theme--header',
  //   },
  //   {
  //     field: 'CREATED',
  //     headerName: 'Created At',
  //     flex: 1.5,
  //     headerClassName: 'super-app-theme--header',
  //   },
  // ]

  // setRows((prev) =>
  //         resp.data.body.map((item, index) => {
  //           return {
  //             ARCHIVE_NAME: item.ARCHIVE_NAME,
  //             EVENT_DATE_TIME: item.EVENT_DATE_TIME,
  //             VIMEO_ID: item.VIMEO_ID,
  //             TYPE: item.TYPE,
  //             CREATED: item.CREATED,
  //             id: index,
  //           }
  //         })

  // const rows = [1,2,3];
  console.log('Data', users)
  // const headers2 = headers
  // const CSVLinkObj = {
  //   headers,
  //   filename: `event-${eventDetails[0].EVENT_ID}.csv`,
  //   data: users,
  // }
  return (
    <div className="mb-4 mt-4">
      {/* <CSVLink {...CSVLinkObj} className="btn-event">
        EVENT DETAILS FOR {eventDetails[0].EVENT_ID}
      </CSVLink> */}
      <div>
        <p style={{ color: '#e14eca', fontWeight: 'bold', fontSize: '1.5rem' }}>
          EVENT DETAILS FOR - {eventDetails[0].EVENT_NAME}
        </p>
      </div>
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          sx={{
            color: 'white',
            fontFamily: 'inherit',
            fontWeight: '400',
            '& .MuiDataGrid-cell': {
              color: 'rgb(235, 232, 232)',
              fontSize: '1rem',
              fontWeight: '400',
            },
            '& .MuiDataGrid-columnHeaders': {
              // backgroundColor: "rgba(0,0,255,0.6)",
              color: 'white',
              fontSize: '1.2rem',
              fontWeight: 'bold',
            },
            '& .css-levciy-MuiTablePagination-displayedRows': {
              fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
              fontWeight: '400',
              fontSize: '1rem',
              color: 'white',
              // alignSelf: 'center',
              // lineHeight: '1.43',
              letterSpacing: '0.01071em',
              // '-webkit-flex-shrink': '0',
              // '-ms-flex-negative': '0',
              flexShrink: '0',
              marginBottom: '0',
            },
            '& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions':
              {
                // '-webkit-flex-shrink': '0',
                // '-ms-flex-negative': '0',
                flexShrink: '0',
                marginLeft: '20px',
                color: 'white',
              },
            '& .css-17jjc08-MuiDataGrid-footerContainer': {
              // display: '-webkit-box',
              // display: '-webkit-flex',
              // display: '-ms-flexbox',
              display: 'flex',
              // '-webkit-box-pack': 'justify',

              // '-webkit-justify-content': 'space-between',
              // '-webkit-align-items': 'center',
              // '-webkit-box-align': 'center',
              // '-ms-flex-align': 'center',
              alignItems: 'center',
              minHeight: '52px',
              borderTop: '1px solid rgba(224, 224, 224, 1)',
            },
          }}
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={15}
          rowsPerPageOptions={[15]}
          pagination
        />
      </div>
    </div>
  )
}

export default EventData
